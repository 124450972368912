import React, { useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Tooltip } from 'reactstrap';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from 'react-router-dom';

const StockAdd = () => {
    document.title = "Stok Ekle  | İlekaSoft";
    const api = new APIClient();

    class Warehouse {
        constructor(warehouseId, quantity = 0.00, criticalStockAlertLimit = 0.00, companyId, createdUserId, updatedUserId, status , warehouseName ) {
            this.warehouseId = warehouseId;
            this.quantity = quantity;
            this.criticalStockAlertLimit = criticalStockAlertLimit;
            this.companyId = companyId;
            this.createdUserId = authUser.Id;
            this.updatedUserId = authUser.Id;
            this.status = true;  
            this.warehouseName = warehouseName;
        }
    }

const [Depo, setDepo] = useState([]);
const [Warehouses, setWarehouses] = useState([]);
const [totalQuantity, setTotalQuantity] = useState(0.00);

useEffect(() => {
    api.get("/Warehouses/GetAll?companyId=" + companyId)
        .then(res => {
            // API'den gelen verileri al ve state'e set et
            const warehouseData = res.map(warehouse => ({
                Id: warehouse.id,
                Name: warehouse.warehouseName
            }));
            setDepo(warehouseData);

            // API'den gelen her depo için bir Warehouse modeli oluştur ve diziye ekle
            const warehouseModels = res.map(warehouse => new Warehouse(
                warehouse.id,
                0.00,  
                0.00,  
                companyId,  
                authUser.Id,  
                authUser.Id,  
                true, 
                warehouse.warehouseName,
            ));
            setWarehouses(warehouseModels);
        })
        .catch(err => console.log(err));
}, [companyId]);

const handleQuantityChange = (index, value) => {
    const updatedWarehouses = [...Warehouses];
    updatedWarehouses[index].quantity = parseFloat(value);
    setWarehouses(updatedWarehouses);
    updateTotalQuantity(updatedWarehouses);

};

const updateTotalQuantity = (warehouses) => {
    const total = warehouses.reduce((sum, warehouse) => sum + warehouse.quantity, 0.00);
    setTotalQuantity(total);
};

const handleCriticalStockAlertLimitChange = (index, value) => {
    const updatedWarehouses = [...Warehouses];
    updatedWarehouses[index].criticalStockAlertLimit = parseFloat(value);
    setWarehouses(updatedWarehouses);
};

    const [fileInput, setFileInput] = useState(null);
    const [picture, setPicture] = useState(null);

    const handleFileChange = (e, setFieldValue) => {
        const file = e.target.files[0];
        setFileInput(file);
        setPictureError(false);
    };

    const [selectedFile, setSelectedFile] = useState(null);

    const [userGrupInfo, setUserGrupInfo] = useState([])
    const authUser = getLoggedinUser();
    const [user, setUser] = useState({})

    const [gtipkodu, setGtipkodu] = useState("");

    const handleInputChange = (event) => {
      setGtipkodu(event.target.value);
    };
    

    const [stockName, setStockName] = useState(""); // string StockName
    const [stockCode, setStockCode] = useState(""); // string StockCode
    const [stockBarcode, setStockBarcode] = useState(""); // string StockBarcode
    const [stockStartingQuantity, setStockStartingQuantity] = useState("");
    const [stockGroup_ID, setStockGroup_ID] = useState(""); // int StockGroup_ID
    const [stockUnit_ID, setStockUnit_ID] = useState(""); // int StockUnit_ID
    const [stockPurchasePrice, setStockPurchasePrice] = useState(""); // decimal StockPurchasePrice
    const [stockPurchaseKDV_ID, setStockPurchaseKDV_ID] = useState(""); // int StockPurchaseKDV_ID
    const [stockSalesPrice, setStockSalesPrice] = useState(""); // decimal StockSalesPrice
    const [stockSalesKDV_ID, setStockSalesKDV_ID] = useState(""); // int StockSalesKDV_ID
    const [criticalStockAlertLimit, setCriticalStockAlertLimit] = useState("");  // decimal? CriticalStockAlertLimit
  //    bool TotalProductCriticalLevel            //  bool WarehousesCriticalLevel
    
    const [stockDescription, setStockDescription] = useState(""); // string StockDescription
    const [stockUnitInfo, setStockUnitInfo] = useState([]);
    const [purchasePriceKDV, setPurchasePriceKDV] = useState([]);
    const [salesPriceKDV, setSalesPriceKDV] = useState([]);
   const [stokTakibi,setStokTakibi]=useState(false);
   const [toplamUrunMiktari,setToplamUrunMiktari]=useState(false);
   const [depoUrunMiktari,setDepoUrunMiktari]=useState(false); //decimal StockTotalQuantity
   const [fallowthecriticals,setFallowthecriticals]=useState(false); //decimal StockTotalQuantity

    const [isSuccess, setIsSuccess] = useState(false);

    var companyId = authUser.CompanyId;

    const UserGroup = {
        stockName: stockName,
        stockCode: stockCode,
        stockBarcode: stockBarcode,
        stockStartingQuantity: stockStartingQuantity,
        stockGroup_ID: stockGroup_ID,
        stockUnit_ID: stockUnit_ID,
        stockPurchasePrice: stockPurchasePrice,
        stockPurchaseKDV_ID: stockPurchaseKDV_ID,
        stockSalesPrice: stockSalesPrice,
        stockSalesKDV_ID: stockSalesKDV_ID,
        criticalStockAlertLimit: criticalStockAlertLimit,
        stockDescription: stockDescription,
        companyId: authUser.CompanyId,
        createdUserId: authUser.Id,
        updatedUserId: authUser.Id,
        status: true

    }

    const [kritikstokalertlimit, setKritikstokalertlimit] = useState(0);

    const handlekritikstokalertlimitChange = (event) => {
        setKritikstokalertlimit(event.target.value);
    };
 
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = error => reject(error);
        });
    };

    if (fileInput) {
        convertToBase64(fileInput).then(base64String => {
            setPicture(base64String);
        });
    }
    

            

     //VALİDATİON İŞLEMİ
     const [stockNameError, setStockNameError] = useState(false);   // Ürün Veya Hizmet Adı:

     const [stockCodeError, setStockCodeError] = useState(false);  //  Ürün / Stok Kodu:

     const [stockBarcodeError, setStockBarcodeError] = useState(false);  // Barkod Numarası:

     const [stockGroup_IDError, setStockGroup_IDError] = useState(false); //  Ürün/Stok Grup Kodu:    

     const [pictureError, setPictureError] = useState(false); //  Ürün Resmi:

     const [stockUnit_IDError, setStockUnit_IDError] = useState(false); // Stok / Ürün Birimi:

     const [gtipkoduError, setGtipkoduError] = useState(false);  // Ürün Tanımlama Kodları:

     const [stockPurchasePriceError, setStockPurchasePriceError] = useState(false);  // Alış Fiyatı:

     const [stockPurchaseKDV_IDError, setStockPurchaseKDV_IDError] = useState(false);  // Alış KDV:

     const [stockSalesPriceError, setStockSalesPriceError] = useState(false);  // Satış Fiyatı:

     const [stockSalesKDV_IDError, setStockSalesKDV_IDError] = useState(false);  // Satış KDV:

     const [stockDescriptionError, setStockDescriptionError] = useState(false);  // Satış KDV:

     const [stokTakibiError, setStokTakibiError] = useState(false);  // Satış KDV:

     
     
     
     
     

     const Stok = {
        "stockName": stockName, //  Ürün Veya Hizmet Adı alanından alındı. DOĞRU

        "productImage": picture,   //  Ürün Resmi alanından   DOĞRU

        "stockCode": stockCode,  //  Ürün / Stok Kodu alanından alıdı.  DOĞRU

        "stockBarcode": stockBarcode, //  Barkod Numarası alanından alındı.  DOĞRU

        "stockTotalQuantity": parseInt(totalQuantity),  //  Başlangıç Stok Miktarı: alanındaki tüm depoların toplamı alındı.DOĞRU
        
        "stockGroup_ID": stockGroup_ID, //  Ürün/Stok Grup Kodu alanından alındı. DOĞRU

        "stockUnit_ID": stockUnit_ID, //  Stok / Ürün Birimi alanından alındı. DOĞRU

        "stockPurchasePrice": stockPurchasePrice,  // Alış Fiyatı alanından alındı. DOĞRU

        "stockPurchaseKDV_ID": stockPurchaseKDV_ID, // Alış KDV alanından alındı. DOĞRU

        "stockSalesPrice": stockSalesPrice, // Satış Fiyatı alanından alındı. DOĞRU

        "stockSalesKDV_ID": stockSalesKDV_ID, // Satış KDV alanından alındı. DOĞRU

        "criticalStockAlertLimit": parseInt(kritikstokalertlimit),  // Kritik Stok Uyarısı alanından alındı.DOĞRU

        "totalProductCriticalLevel": toplamUrunMiktari, // Toplam ürün miktarı için belirle checkboxı çeklendiyse. DOĞRU

        "warehousesCriticalLevel": depoUrunMiktari, // Depo bazında ürün miktarları için belirle. DOĞRU
        
        "gtipCode": gtipkodu, //  Ürün Tanımlama Kodları: GTİP KODU alanından alındı. DOĞRU

        "stockDescription": stockDescription, // Stok Açıklama alanından alındı. DOĞRU

        "fallowtheCriticals": fallowthecriticals,

        "stockTracking": stokTakibi,

        "companyId": parseInt(companyId),  //DOĞRU
        "createdUserId": parseInt(authUser.Id), //DOĞRU
        "updatedUserId": parseInt(authUser.Id),  //DOĞRU
        "status": true, //DOĞRU
        "stockWarehouses": Warehouses.map((warehouse) => ({
            warehouseId:parseInt(warehouse.warehouseId),
            quantity: parseInt(warehouse.quantity.toFixed(2)),
            criticalStockAlertLimit: parseInt(warehouse.criticalStockAlertLimit),
            companyId: parseInt(companyId),
            createdUserId: parseInt(authUser.Id),
            updatedUserId: parseInt(authUser.Id),
            status: true,
            warehouseName:warehouse.warehouseName,
          }))
      }




    const handleSubmit = () => {
        console.log('warehouses yazırılacak birazdan');
        console.log(Warehouses);
        console.log('stok yazdırılacak');
        console.log(Stok);

if(Stok.stockName == "")
{
    setStockNameError(true);
}
if(Stok.stockCode == "")
{
    setStockCodeError(true);
}
if(Stok.stockBarcode == "")
{
    setStockBarcodeError(true);
}            
if(Stok.stockGroup_ID == "")
{
    setStockGroup_IDError(true);
}                
if(Stok.productImage == null)
{
    setPictureError(true);
}                    
if(Stok.stockUnit_ID == "")
{
    setStockUnit_IDError(true);
}    
if(Stok.gtipCode == "")
{
    setGtipkoduError(true);
}            
if(Stok.stockPurchasePrice == "")
{
    setStockPurchasePriceError(true);
}                
if(Stok.stockPurchaseKDV_ID == "")
{
    setStockPurchaseKDV_IDError(true);
}                    
if(Stok.stockSalesPrice == "")
{
    setStockSalesPriceError(true);
}
if(Stok.stockSalesKDV_ID == "")
{
    setStockSalesKDV_IDError(true);
}                
if(Stok.stockDescription == "")
{
    setStockDescriptionError(true);
}
if(Stok.stockDescription == "")
{
    setStokTakibiError(true);
}





Stok.stockGroup_ID = parseInt(Stok.stockGroup_ID);
Stok.stockUnit_ID = parseInt(Stok.stockUnit_ID);
Stok.stockPurchasePrice = parseInt(Stok.stockPurchasePrice);
Stok.stockPurchaseKDV_ID = parseInt(Stok.stockPurchaseKDV_ID);
Stok.stockSalesPrice = parseInt(Stok.stockSalesPrice);
Stok.stockSalesKDV_ID = parseInt(Stok.stockSalesKDV_ID);


        api.create("/Stock/CreateStock", Stok)
            .then(res => {
                console.log("başarılı");
             window.location.href = `/Stoklar`;
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
               // navigate('/Stoklar')
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            });
    }


    useEffect(() => {
        setUser(authUser);
        fetchData();
        StokUnitData();
        KDVData();
    }, [])
    const fetchData = () => {
        api.get("/StockGroup/GetAll?companyId=" + companyId).then(res => {
            setUserGrupInfo(res)
        }).catch(er => console.log(er))
    }
    const StokUnitData = () => {
        api.get("/StockUnit/GetAll").then(res => {
            setStockUnitInfo(res)
        }).catch(er => console.log(er))
    }
    const KDVData = () => {
        api.get("/KDV/GetAll").then(res => {
            setPurchasePriceKDV(res);
            setSalesPriceKDV(res);

        }).catch(er => console.log(er))
    }
   const handleStockTrackingChange =(e)=>{

    if(e.target.value==="yap"){
        setStokTakibi(true);
        setStokTakibiError(false);
    }
    else{
        setStokTakibi(false);
        setStokTakibiError(false);
    }       
   }

   
   const handleStockTrackingChangesecond =(e)=>{
    setStokTakibi(false);
    setStokTakibiError(false);
   }

   const handleTotalProduct =(e)=>{
    if(e.target.checked===true){
        setToplamUrunMiktari(true);
    }
    else{
        setToplamUrunMiktari(false);
    }
   }
   const handleWarehousesProduct=(e)=>{
    if(e.target.checked===true){
        setDepoUrunMiktari(true);
    }
    else{
        setDepoUrunMiktari(false);
    }
   }

   const handleFallowthecriticalsProduct=(e)=>{
    if(e.target.checked===true){
        setFallowthecriticals(true);
    }
    else{
        setFallowthecriticals(false);
    }
    //alert(fallowthecriticals);
   }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Stok Ekle" breadcrumbItem="Stoklar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody style={{ margin: "35px" }}>
                                    <Row className="mb-3 align-items-center">
                                        <Col lg="3">
                                            <label htmlFor="example-text-input" className="col-form-label">
                                                <i className="mdi mdi-cube-outline" style={{ fontSize: "26px", verticalAlign: "middle" }}></i> Ürün Veya Hizmet Adı:
                                            </label>
                                        </Col>
                                        <Col lg="9">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Stok Adı"
                                                id="StockName"
                                                onChange={x => setStockName(x.target.value)}
                                                onClick={() => setStockNameError(false)}
                                                required
                                            />  {stockNameError && <div className="error-message">Bu alan gereklidir</div>}

                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row className="mb-3 align-items-center">
                                        <Col lg="3">
                                            <label htmlFor="example-text-input" className="col-form-label">
                                                <i className="mdi mdi-pound" style={{ fontSize: "26px", verticalAlign: "middle" }}></i>  Ürün / Stok Kodu:
                                            </label>
                                        </Col>
                                        <Col lg="9">
                                            <input className="form-control" type="text" placeholder="Stok Kodu" id="StockCode" onChange={x => setStockCode(x.target.value)}         onClick={() => setStockCodeError(false)}
 required />
                                            {stockCodeError && <div className="error-message">Bu alan gereklidir</div>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 align-items-center">
                                        <Col lg="3">
                                            <label htmlFor="example-text-input" className="col-form-label">
                                                <i className="mdi mdi-barcode" style={{ fontSize: "26px", verticalAlign: "middle" }}></i> Barkod Numarası:
                                            </label>
                                        </Col>
                                        <Col lg="9">
                                            <input className="form-control" type="text" placeholder="Stok Barkodu" id="StockBarcode" onChange={x => setStockBarcode(x.target.value)}  onClick={() => setStockBarcodeError(false)} required />
                                            {stockBarcodeError && <div className="error-message">Bu alan gereklidir</div>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 align-items-center">
                                        <Col lg="3">
                                            <label htmlFor="example-text-input" className="col-form-label">
                                                <i className="mdi mdi-folder-outline" style={{ fontSize: "26px", verticalAlign: "middle" }}></i> Ürün/Stok Grup Kodu:
                                            </label>
                                        </Col>
                                        <Col lg="9" onClick={() => setStockGroup_IDError(false)}
                                         >
                                            <select className="form-control" id="StockGroup_ID" onChange={x => setStockGroup_ID(x.target.value)} required >
                                                <option>Lütfen stok grup kodunu seçiniz</option>
                                                {
                                                    userGrupInfo.map((item, key) => {
                                                        return (
                                                            <option value={item.id} key={key}>{item.stockGroupCode}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {stockGroup_IDError && <div className="error-message">Bu alan gereklidir</div>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 align-items-center">
                                         <Col lg="3">
                <label htmlFor="example-text-input" className="col-form-label">
                    <i className="mdi mdi-image-outline" style={{ fontSize: "26px", verticalAlign: "middle" }}></i> Ürün Resmi:
                </label>
            </Col>
            <Col lg="9">
                <input
                    className="form-control"
                    type="file"
                    name="ProductImage"
                    id="ProductImage"
                    accept="image/*"
                    required
                    onChange={(e) => handleFileChange(e)}/>
        {pictureError && <div className="error-message">Bu alan gereklidir</div>}
            </Col>
           {/* {selectedFile && (
                <Col lg="12">
                    <p>Selected file: {selectedFile.name}</p>
                </Col>
            )} */}
                                    </Row>
                                    <Row className="mb-3 align-items-center">
                                        <Col lg="3">
                                            <label htmlFor="example-text-input" className="col-form-label">
                                                <i className="mdi mdi-ruler" style={{ fontSize: "26px", verticalAlign: "middle" }}></i> Stok / Ürün Birimi:
                                            </label>
                                        </Col>
                                        <Col lg="9"         onClick={() => setStockUnit_IDError(false)}
                                        >
                                            <select className="form-control" id="StockUnit_ID" onChange={x => setStockUnit_ID(x.target.value)} required >
                                                <option>Lütfen ürün birimini seçiniz</option>
                                                {
                                                    stockUnitInfo.map((item, key) => {
                                                        return (
                                                            <option value={item.id} key={key}>{item.stockUnitName} - {item.unitCode}</option>
                                                        )
                                                    })
                                                }

                                            </select>
                                            {stockUnit_IDError && <div className="error-message">Bu alan gereklidir</div>}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 align-items-center">
                                        <Col lg="3">
                                            <label htmlFor="example-text-input" className="col-form-label">
                                                <i className="mdi mdi-account" style={{ fontSize: "26px", verticalAlign: "middle" }}></i> Ürün Tanımlama Kodları:
                                            </label>
                                        </Col>
                                        <Col lg="9">
                                        <input
        className="form-control"
        type="text"
        placeholder="GTIP KODU"
        id="StockBarcode"
        required
        value={gtipkodu}
        onChange={handleInputChange}
        onClick={() => setGtipkoduError(false)}
      />
      {gtipkoduError && <div className="error-message">Bu alan gereklidir</div>}
      <small className="form-text text-muted">
        GTIP Kodu, uluslararası ticarette kullanılan bir ürün tanımlama kodudur. Yalnızca ihracat faturalarında kullanılır. GTIP kodunu{' '}
        <a href="https://uygulama.gtb.gov.tr/Tara" target="_blank" rel="noopener noreferrer">buradan öğrenebilirsiniz</a>.
      </small>

                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row className="mb-3 align-items-center">
                                        <Col lg="3">
                                            <label htmlFor="example-text-input" className="col-form-label">
                                                <i className="mdi mdi-alarm-light" style={{ fontSize: "26px", verticalAlign: "middle" }}></i>   Stok Takibi:
                                            </label>
                                        </Col>
                                        <Col lg="9">
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="stokTakibiYap"
                                                    name="stokTakibi"
                                                    value="yap"
                                                     onChange={(e)=>handleStockTrackingChange(e)}
                                                    required
                                                />
                                                <label htmlFor="stokTakibiYap" style={{ marginLeft: "5px", marginRight: "15px" }}>Yapsın</label>

                                                <input
                                                    type="radio"
                                                    id="stokTakibiYapma"
                                                    name="stokTakibi"
                                                    value="yapma"
                                                    onChange={(e)=>handleStockTrackingChangesecond(e)}
                                                    />
                                                <label htmlFor="stokTakibiYapma" style={{ marginLeft: "5px" }}>Yapmasın</label>
                                                {stokTakibiError && <div className="error-message">Bu alan gereklidir</div>}
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                     stokTakibi===true?(
                                        <>
                                         <Row className="mb-3 align-items-center">
                                        <Col lg="3">
                                            <label htmlFor="example-text-input" className="col-form-label">
                                                <i className="mdi mdi-dice-multiple" style={{ fontSize: "26px", verticalAlign: "middle" }}></i> Başlangıç Stok Miktarı:
                                            </label>
                                        </Col>
                                        <Col lg="9">
                                         
                                            <fieldset style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "5px", marginTop: "5px" }}>

                                              
                                            <div id="ilkalan">
    {Depo.map((item, index) => (
        <Row style={{ marginTop: "10px" }} key={index}>
            <Col lg="3">
                <label htmlFor={`ilk-stock-barcode-${index}`} className="col-form-label" style={{ color: "gray" }}>
                    {item.Name}:
                </label>
            </Col>
            <Col lg="9">
                <input
                    className="form-control"
                    type="number"
                    defaultValue="0.00"
                    id={`ilk-stock-barcode-${index}`}
                    required
                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                />
            </Col>
        </Row>
    ))}
</div>
                                            </fieldset>

                                        </Col>
                                    </Row>

                                    <Row className="mb-3 align-items-center">
                                        <Col lg="3">
                                            <label htmlFor="example-text-input" className="col-form-label">
                                                <i className="mdi mdi-alert-circle" style={{ fontSize: "26px", verticalAlign: "middle" }}></i> Kritik Stok Uyarısı:
                                            </label>
                                        </Col>
                                        <Col lg="9">
                                            <fieldset style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "5px" }}>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="toplamUrunMiktari"
                                                        name="toplamUrunMiktari"
                                                        onChange={(e)=>handleTotalProduct(e)}
                                                    />
                                                    <label htmlFor="toplamUrunMiktari" style={{ marginLeft: "5px", marginRight: "15px" }}>Toplam ürün miktarı için belirle</label>
                                                </div>
                                                {toplamUrunMiktari ? (
        <>
          <div>
            <Row style={{ marginTop: '10px' }}>
              <Col lg="3">
                <label htmlFor="example-text-input" className="col-form-label" style={{ color: 'gray' }}>
                  Kritik Stok Uyarısı:
                </label>
              </Col>
              <Col lg="9">
                <input
                  className="form-control"
                  type="number"
                  defaultValue="0.00"
                  id="StockBarcode"
                  required
                  onChange={handlekritikstokalertlimitChange}
                />
              </Col>
            </Row>
          </div>
        </>
      ) : null}
                                              
                                            </fieldset>
                                            <fieldset style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "5px", marginTop: "5px" }}>
<Row>
    <Col>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="depoBazindaUrunMiktari"
                                                        name="depoBazindaUrunMiktari"
                                                        onChange={(e)=>handleWarehousesProduct(e)}
                                                    />
                                                    <label htmlFor="depoBazindaUrunMiktari" style={{ marginLeft: "5px" }}>Depo bazında ürün miktarları için belirle</label>
                                                </div>
                                                </Col>
                                               
                                                </Row>
                                                {
    depoUrunMiktari === true && (
        <div id="ikincialan">
            {Depo.map((item, index) => (
                <Row style={{ marginTop: "10px" }} key={index}>
                    <Col lg="3">
                        <label htmlFor={`ikinci-stock-barcode-${index}`} className="col-form-label" style={{ color: "gray" }}>
                            {item.Name}:
                        </label>
                    </Col>
                    <Col lg="9">
                        <input
                            className="form-control"
                            type="number"
                            defaultValue="0.00"
                            id={`ikinci-stock-barcode-${index}`}
                            required
                            onChange={(e) => handleCriticalStockAlertLimitChange(index, e.target.value)}
                        />
                    </Col>
                </Row>
            ))}
        </div>
    )
}
                                               
                                            </fieldset>
                                            <fieldset style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "5px", marginTop: "5px" }}>
                                    <Col>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e)=>handleFallowthecriticalsProduct(e)}
                                                    />
                                                    <label style={{ marginLeft: "5px" }}>Kritik miktarın altına düşmesin</label>
                                                </div>
                                                </Col>
                                                </fieldset>
                                        </Col>
                                    </Row>
                                        </>
                                     ):null
                                    }
                                   
                             
                                    <hr/>
                                
                                    <Row className="mb-3">
                                        <Col lg="6">
                                         <Row>
                                            <Col lg="4">
                                            <label htmlFor="example-text-input" className="col-md-4 col-form-label" >
                                                Alış KDV:
                                            </label>
                                            </Col>
                                            <Col lg="8">
                                            <div className="col-md-12" >
                                                <select className="form-control" id="StockPurchaseKDV_ID" onChange={x => setStockPurchaseKDV_ID(x.target.value)}               onClick={() => setStockPurchaseKDV_IDError(false)}
 required >
                                                    <option>Lütfen alış KDV oranını seçiniz</option>
                                                    {
                                                        purchasePriceKDV.map((item, key) => {
                                                            return (
                                                                <option value={item.id} key={key}>{item.kdvRate}</option>
                                                            )
                                                        })
                                                    }
                                                </select>{stockPurchaseKDV_IDError && <div className="error-message">Bu alan gereklidir</div>}
                                            </div>
                                            </Col>
                                         </Row>
                                           
                                           
                                        </Col>
                                        <Col lg="6">
                                        <Row>
                                            <Col lg="4">
                                            <label htmlFor="example-text-input" className="col-md-6 col-form-label" >
                                            Alış Fiyatı:
                                            </label>
                                            </Col>
                                            <Col lg="8">
                                            <div className="col-md-12">
                                            <input className="form-control" type="text" placeholder="Alış Fiyatı" id="StockPurchasePrice" onChange={x => setStockPurchasePrice(x.target.value)} onClick={() => setStockPurchasePriceError(false)} required />
                                            {stockPurchasePriceError && <div className="error-message">Bu alan gereklidir</div>}
                                            </div>
                                            </Col>
                                         </Row>
                                           
                                          
                                        </Col>

                                    </Row>
                                    
                                    <Row className="mb-3">
                                        <Col lg="6">
                                         <Row>
                                            <Col lg="4">
                                            <label htmlFor="example-text-input" className="col-md-4 col-form-label" >
                                                Satış KDV:
                                            </label>
                                            </Col>
                                            <Col lg="8">
                                            <div className="col-md-12">
                                                <select className="form-control" id="StockSalesKDV_ID" onChange={x => setStockSalesKDV_ID(x.target.value)}  onClick={() => setStockSalesKDV_IDError(false)} required >
                                                    <option>Lütfen satış KDV oranını seçiniz</option>
                                                    {
                                                        salesPriceKDV.map((item, key) => {
                                                            return (
                                                                <option value={item.id} key={key}>{item.kdvRate}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                                {stockSalesKDV_IDError && <div className="error-message">Bu alan gereklidir</div>}
                                            </div>
                                            </Col>
                                         </Row>
                                           
                                           
                                        </Col>
                                        <Col lg="6">
                                        <Row>
                                            <Col lg="4">
                                            <label htmlFor="example-text-input" className="col-md-6 col-form-label" >
                                            Satış Fiyatı:
                                            </label>
                                            </Col>
                                            <Col lg="8">
                                            <div className="col-md-12">
                                            <input className="form-control" type="text" placeholder="Satış Fiyatı" id="StockSalesPrice" onChange={x => setStockSalesPrice(x.target.value)}  onClick={() => setStockSalesPriceError(false)}  required />
                                            {stockSalesPriceError && <div className="error-message">Bu alan gereklidir</div>}
                                            </div>
                                            </Col>
                                         </Row>
                                           
                                          
                                        </Col>

                                    </Row>
                                    <hr/>
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <label className="col-md-2 col-form-label">Stok Açıklama</label>
                                            <div className="col-md-12">
                                                <textarea className="form-control" rows="3" placeholder="Stok Açıklama" id="StockDescription" onChange={x => setStockDescription(x.target.value)} onClick={() => setStockDescriptionError(false)}required></textarea>
                                                {stockDescriptionError && <div className="error-message">Bu alan gereklidir</div>}</div>
                                        </Col>

                                    </Row>
                                  {/*  <div style={{ marginTop: "20px" }}>
        <strong>Total:</strong> {toplam.toFixed(2)}
      </div>*/}
                                    <Row className="mb-3">
                                        <Col>
                                            <button
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light"
                                                onClick={handleSubmit}
                                            >
                                                Kaydet
                                            </button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default StockAdd;


