import React, { useEffect, useState } from 'react'
import { Button,  Col, Container, Modal, Row } from 'reactstrap';
import { APIClient } from '../../helpers/api_helper';
import { toast } from 'react-toastify';

export const GroupAddModal = ({user,addChange}) => {
    const [open, setOpen] = useState(false);
    const [authUser,setAuthUser] = useState(user);
    function openModal() {
        setOpen(!open)
    }
    const api = new APIClient();
    const handleChange=()=>{
        addChange()
    }
    const [groupName, setGroupName] = useState("");
    useEffect(()=>{
        setAuthUser(user)
    },[user])
    const UserGroup={
        groupName:groupName,
        companyId: authUser.CompanyId ,
        createdUserId: authUser.Id,
        updatedUserId: authUser.Id,
        status:true,
    }
 
    const handleSubmit = () => {
        api.create("/UserGroup/AddUserGroup", UserGroup).then(res => {
            openModal()
            handleChange()
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    }).catch(e => toast.warning("İznin yok")).finally(e=>{
        toast.error("Kaydedilemedi", {
            position: "bottom-right",
            autoClose: 10000 
        });

            console.log(e)
        })
    }
    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col>

                            <div className="d-flex gap-1">
                                <Button color="success" className="add-btn" onClick={() => {
                                    openModal();
                                }} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Ekle</Button>
                                <Button color="soft-danger"
                                // onClick="deleteMultiple()"
                                ><i className="ri-delete-bin-2-line"></i></Button>
                            </div>


                            <Modal
                                isOpen={open}
                                toggle={() => {
                                    openModal();
                                }}

                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                         Ekle
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                                Kullanıcı Grubu 
                                            </label>
                                            <input className="form-control" type="text" placeholder='Kullanıcı Grup Adı Giriniz' onChange={x => setGroupName(x.target.value)} />

                                        </div>

                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        Kapat
                                    </button>
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary">
                                        Kaydet
                                    </button>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}