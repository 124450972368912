import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { ModuleList } from '../../components/Module/ModuleList';
import { ModuleAddModal } from '../../components/Module/ModuleAddModal';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const Module = () => {
    document.title = "Modüller | İlekaSoft";

    const [modules, setModules] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [user, setUser] = useState({});

    const authUser = getLoggedinUser();
    const companyId = authUser.CompanyId;
    const api = new APIClient();

    useEffect(() => {
        setUser(authUser);
        fetchData();
    }, [authUser]);

    const fetchData = () => {
        api.get(`/Module/ModuleGetAll?companyId=${companyId}`)
            .then(res => setModules(res))
            .catch(err => console.error(err));
    };

    const handleChange = () => {
        fetchData();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = modules.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < Math.ceil(modules.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPages = Math.ceil(modules.length / itemsPerPage);

        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <li key={i} className="page-item">
                    <Button className={`page-link ${currentPage === i ? 'active' : ''}`} onClick={() => paginate(i)}>
                        {i}
                    </Button>
                </li>
            );
        }

        return pageNumbers;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <ToastContainer />
                    <Breadcrumbs title="Modüller" breadcrumbItem="Modüller" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <ModuleAddModal user={user} addChange={handleChange} />
                                                <Link className='btn btn-secondary' to={"/modülSatınAl"}>Modül Satın Al</Link>
                                            </div>
                                        </Col>
                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <div className="search-box ms-2">
                                                    <input type="text" className="form-control search" placeholder="Search..." />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <ModuleList user={user} module={currentItems} isChanges={handleChange} />
                                    </div>
                                </CardBody>

                                <CardBody>
                                    <div className="d-flex justify-content-end">
                                        <div className="pagination-wrap hstack gap-2">
                                            <Button className="page-item pagination-prev" onClick={prevPage} disabled={currentPage === 1}>
                                            Önceki
                                            </Button>
                                            <ul className="pagination listjs-pagination mb-0">
                                                {renderPageNumbers()}
                                            </ul>
                                            <Button className="page-item pagination-next" onClick={nextPage} disabled={currentPage === Math.ceil(modules.length / itemsPerPage)}>
                                                Sonraki
                                            </Button>
                                        </div>
                                    </div>
                                    <style jsx>{`
                                        .pagination .page-link {
                                            color: grey; /* Tüm sayfa numaraları için gri renk */
                                        }
                                        .pagination .page-link.active {
                                            color: white; /* Aktif sayfa numarası için beyaz renk */
                                        }
                                    `}</style>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Module;
