
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'
import { useNavigate } from 'react-router-dom';

const BankUpdate = (props) => {
    const api =new APIClient();
    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const [account_Number, setAccount_Number] = useState(props.selectedItem.account_Number);
    const [iban, setIban] = useState(props.selectedItem.iban);
    const [currency, setCurrency] = useState(props.selectedItem.currency);
    const [account_Starting_Balance, setAccount_Starting_Balance] = useState(props.selectedItem.account_Starting_Balance);
    const [account_Name, setAccount_Name] = useState(props.selectedItem.account_Name);
    const [bank_Name, setBank_Name] = useState(props.selectedItem.bank_Name);
    const [bank_Branch, setBank_Branch] = useState(props.selectedItem.bank_Branch);
    const [branch_Phone_Number, setBranch_Phone_Number] = useState(props.selectedItem.branch_Phone_Number);
    const [branch_Representative, setBranch_Representative] = useState(props.selectedItem.branch_Representative);
    const [branch_Representative_eMail, setBranch_Representative_eMail] = useState(props.selectedItem.branch_Representative_eMail);
    const [branch_Address, setBranch_Address] = useState(props.selectedItem.branch_Address);



    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedBank = {
            id: props.selectedItem.id,
            account_Number: account_Number,
            iban: iban,
            currency: currency,
            account_Starting_Balance: account_Starting_Balance,
            account_Name: account_Name,
            bank_Name: bank_Name,
            bank_Branch: bank_Branch,
            branch_Phone_Number: branch_Phone_Number,
            branch_Representative: branch_Representative,
            branch_Representative_eMail: branch_Representative_eMail,
            branch_Address: branch_Address,
            companyId: authUser.CompanyId,
            createdUserId: authUser.Id,
            status:true
        };

        try {
           //await axios.post("/Bank/UpdateBank", updatedBank);
           await api.create("/Bank/UpdateBank", updatedBank);
            props.handleClose2(); // Modalı kapatıyoruz
            navigate(`/Bankalar`);
           // window.location.href = `/Bankalar`;
        } catch (error) {
            console.error("Error updating current group:", error);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Account_Number</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Account_Number"
                    name="account_Number"
                    value={account_Number}
                    onChange={(e) => setAccount_Number(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Iban</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Iban"
                    name="iban"
                    value={iban}
                    onChange={(e) => setIban(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Currency</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Currency"
                    name="currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Account_Starting_Balance</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Account_Starting_Balance"
                    name="account_Starting_Balance"
                    value={account_Starting_Balance}
                    onChange={(e) => setAccount_Starting_Balance(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Account_Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Account_Name"
                    name="account_Name"
                    value={account_Name}
                    onChange={(e) => setAccount_Name(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Bank_Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Bank_Name"
                    name="bank_Name"
                    value={bank_Name}
                    onChange={(e) => setBank_Name(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Bank_Branch</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Bank_Branch"
                    name="bank_Branch"
                    value={bank_Branch}
                    onChange={(e) => setBank_Branch(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Branch_Phone_Number</Form.Label>
                <Form.Control
                    type="tel"
                    placeholder="Branch_Phone_Number"
                    name="branch_Phone_Number"
                    value={branch_Phone_Number}
                    onChange={(e) => setBranch_Phone_Number(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Branch_Representative</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Branch_Representative"
                    name="branch_Representative"
                    value={branch_Representative}
                    onChange={(e) => setBranch_Representative(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Branch_Representative_eMail</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Branch_Representative_eMail"
                    name="branch_Representative_eMail"
                    value={branch_Representative_eMail}
                    onChange={(e) => setBranch_Representative_eMail(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Branch_Address</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Branch_Address"
                    name="branch_Address"
                    value={branch_Address}
                    onChange={(e) => setBranch_Address(e.target.value)}
                    required
                />
            </Form.Group>

            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
            Banka Düzenle
            </Button>
        </Form>
    );
};

export default BankUpdate;



