import React, { useEffect, useState } from 'react'
import { APIClient } from '../../helpers/api_helper'
import { Col, Row } from 'reactstrap'
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select'
import {  toast } from 'react-toastify'

export const ModuleCompanyAdd = ({userInfo,handleSubmitBtn}) => {
    const api = new APIClient()
    const [selectedModuleOption, setSelectedModuleOption] = useState(null);

    const [moduleId, setModuleId] = useState("")
    const [salesMonth, setSalesMonth] = useState("")
    const [moduleCompanyId, setModuleCompanyId] = useState("")
    const [moduleOptions,setModuleOptions] = useState([])
    const [guidId,setGuidId] = useState("")
    const [updateBtn,setUpdateBtn] = useState(false)

    const ModuleCompany = {
        moduleId: moduleId,
        salesMonth: salesMonth,
        moduleCompanyId:  localStorage.getItem("companyId"),
        createdUserId:userInfo.Id,
        updatedUserId:userInfo.Id,
        companyId:userInfo.CompanyId,
        status:false,
        guidId:guidId,
        status:false,
    }
    useEffect(() => {
        api.get("/Module/ModuleGetAll").then(res=>{
            const formattedOptions = res.map(item => ({
                label: `${item.moduleName} - Fiyat:${item.salesPrice}`, // API'den gelen verilerin label ve value özelliklerine uygun bir şekilde dönüştürülmesi gerekiyor
                value: item.id
            }));
            setModuleOptions(formattedOptions)
        })

    }, [])
    const handleModule=(selectedOption)=>{
        setSelectedModuleOption(selectedOption)
        console.log(selectedOption.value)
        setModuleId(selectedOption == null ? selectedOption : selectedOption.value)
    }
 
    const  handleSubmit = async () => {
        console.log(moduleCompanyId)
        let guidIdBtn = uuidv4()
        await setGuidId(guidIdBtn)
        ModuleCompany.guidId=guidIdBtn;
        localStorage.setItem("guidId",guidIdBtn)
        console.log(guidId)
        await api.create("/ModuleCompany/AddModuleCompany",ModuleCompany).then(res => {
            console.log(res)
            handleSubmitBtn()
            toast.error("Kaydedilemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });
        setUpdateBtn(true)

        }).catch(e=>console.log(e))
    }
    const handleSubmitUpdate = () => {
        console.log(guidId)

        api.create("/ModuleCompany/UpdateModuleCompany", ModuleCompany).then(res => {
            handleSubmitBtn()
            toast.success("Güncelleme Başarılı");
            setUpdateBtn(true)

        }).catch(e => console.log(e))
        toast.error("Kaydedilemedi", {
            position: "bottom-right",
            autoClose: 10000 
        });

    }
    return (
        <form>
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Modüller
                    </label>
                    <div className="col-md-9">
                    <Select
                            value={selectedModuleOption}
                            onChange={handleModule}
                            options={moduleOptions}
                            placeholder="Modül Seçiniz"
                        />
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Kullanım süresi-Ay
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setSalesMonth(x.target.value)} placeholder="Kullanım süresi seçiniz" />
                    </div>
                </Col>

            </Row>
          
            <Row className="mb-3 mt-5">
            {updateBtn? <Col className="d-flex justify-content-center">
                    <button
                        onClick={handleSubmitUpdate}
                        type="button"
                        className="btn btn-warning waves-effect waves-light"
                    >
                        Güncelle
                    </button>
                </Col>:<Col className="d-flex justify-content-center">
                    <button
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                    >
                        Kaydet
                    </button>
                </Col>
}
               
            </Row>
        </form>
    )
}
