import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object().shape({
    heOrBvOrBkt: yup.string().required('Bu alan gereklidir'),
    bankId: yup.string().required('Bu alan gereklidir'),
    currentAccountId: yup.string().required('Bu alan gereklidir'),
    sourceBankId: yup.string().required('Bu alan gereklidir'),
    targetBankId: yup.string().required('Bu alan gereklidir'),
    targetCashboxId: yup.string().required('Bu alan gereklidir'),
    date: yup.string().required('Bu alan gereklidir'),
    amount: yup.string().required('Bu alan gereklidir'),
    operationType: yup.string().required('Bu alan gereklidir'),
    explanation: yup.string().required('Bu alan gereklidir'),
    document_Receipt_No: yup.string().required('Bu alan gereklidir'),
});


const BankTransferUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const authUser = getLoggedinUser();

    const navigate = useNavigate();

  

    const [bankIdOptions, setBankIdOptions] = useState([]);
    const [selectedBankIdOption, setSelectedBankIdOption] = useState(null);

    const [currentAccountIdOptions, setCurrentAccountIdOptions] = useState([]);
    const [selectedCurrentAccountIdOption, setSelectedCurrentAccountIdOption] = useState(null);

    const [sourceBankIdOptions, setSourceBankIdOptions] = useState([]);
    const [selectedSourceBankIdOption, setSelectedSourceBankIdOption] = useState(null);

    const [targetBankIdOptions, setTargetBankIdOptions] = useState([]);
    const [selectedTargetBankIdOption, setSelectedTargetBankIdOption] = useState(null);

    const [targetCashboxIdOptions, setTargetCashboxIdOptions] = useState([]);
    const [selectedTargetCashboxIdOption, setSelectedTargetCashboxIdOption] = useState(null);
  
    const [operationTypeOptions, setOperationTypeOptions] = useState([]);
    const [selectedOperationTypeOption, setSelectedOperationTypeOption] = useState(null);
    
    const [heOrBvOrBktOptions, setHeOrBvOrBktOptions] = useState([]);
    const [selectedHeOrBvOrBktOption, setSelectedHeOrBvOrBktOption] = useState(null);

    const [selectedTransferType, setSelectedTransferType] = useState('');


    
    useEffect(() => {
        const companyId = authUser.CompanyId;

        const operationTypes = [
            { label: "Gelen", value: "Gelen" }, // Operasyon tiplerini burada tanımlayın
            { label: "Giden", value: "Giden" },
            // Diğer operasyon tiplerini ekleyin
        ];
        setOperationTypeOptions(operationTypes);

        // Transfer türü seçenekleri
        const options = [
            { label: "Havale / Eft", value: "Havale / Eft" },
            { label: "Banka Virman", value: "Banka Virman" },
            { label: "Bankadan Kasaya Transfer", value: "Bankadan Kasaya Transfer" }
        ];
        setHeOrBvOrBktOptions(options);

  

        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.account_Name,
                value: item.id
            }));
            setBankIdOptions(formattedOptions);
        });

        api.get("/CurrentAccount/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id
            }));
            setCurrentAccountIdOptions(formattedOptions);
        });

        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.account_Name,
                value: item.id
            }));
            setSourceBankIdOptions(formattedOptions);
        });

        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.account_Name,
                value: item.id.toString()
            }));
            setTargetBankIdOptions(formattedOptions);
        });

        api.get("/Cashbox/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id
            }));
            setTargetCashboxIdOptions(formattedOptions);
        });


    }, []);

    useEffect(() => {
        api.get('/BankTransfer/GetBankTransferById?id=' + _id)
            .then(res => {
                const selectedTransferType = res.heOrBvOrBkt;
                setSelectedTransferType(selectedTransferType);

                formik.setValues({
                    id: res.id,
                    heOrBvOrBkt :res.heOrBvOrBkt,
                    bankId:res.bankId,
                    currentAccountId:res.currentAccountId,
                    sourceBankId:res.sourceBankId,
                    targetBankId:res.targetBankId,
                    targetCashboxId:res.targetCashboxId,
                    date:res.date.split('T')[0],
                    amount:res.amount,
                    operationType:res.operationType,
                    explanation:res.explanation,
                    document_Receipt_No:res.document_Receipt_No,

                    updatedUserId: authUser.Id,
                });

              
                setSelectedBankIdOption({ label: res.bankIdName, value: res.bankId });
                setSelectedCurrentAccountIdOption({ label: res.currentAccountIdName, value: res.currentAccountId });
                setSelectedSourceBankIdOption({ label: res.sourceBankIdName, value: res.sourceBankId });
                setSelectedTargetBankIdOption({ label: res.targetBankIdName, value: res.targetBankId });
                setSelectedTargetCashboxIdOption({ label: res.targetCashboxIdName, value: res.targetCashboxId });


                setSelectedHeOrBvOrBktOption({ label: res.heOrBvOrBkt, value: res.heOrBvOrBkt });
                setSelectedOperationTypeOption({ label: res.operationType, value: res.operationType });


                if(res.heOrBvOrBkt == "Havale / Eft")
                {
                    formik.setFieldValue('sourceBankId', 0);
                    formik.setFieldValue('targetBankId', 0);
                    formik.setFieldValue('targetCashboxId', 0);
                }
               else if(res.heOrBvOrBkt == "Banka Virman")
               {
                formik.setFieldValue('bankId', 0);
                formik.setFieldValue('currentAccountId', 0);
                formik.setFieldValue('targetCashboxId', 0);
                formik.setFieldValue('operationType', "0");

               }
               else if (res.heOrBvOrBkt == "Bankadan Kasaya Transfer")
               {

                formik.setFieldValue('bankId', 0);
                formik.setFieldValue('currentAccountId', 0);
                formik.setFieldValue('targetBankId', 0);
                formik.setFieldValue('operationType', "0");


               }

            })
            .catch(err => console.log(err));
    }, [_id]);

   

    const handleBankIdSelectChange = (selectedOption) => {
        setSelectedBankIdOption(selectedOption);
        formik.setFieldValue('bankId', selectedOption ? selectedOption.value : '');
    };

    const handleCurrentAccountIdSelectChange = (selectedOption) => {
        setSelectedCurrentAccountIdOption(selectedOption);
        formik.setFieldValue('currentAccountId', selectedOption ? selectedOption.value : '');
    };


    const handleSourceBankIdSelectChange = (selectedOption) => {
        setSelectedSourceBankIdOption(selectedOption);
        formik.setFieldValue('sourceBankId', selectedOption ? selectedOption.value : '');
    };


    const handleTargetBankIdSelectChange = (selectedOption) => {
        setSelectedTargetBankIdOption(selectedOption);
        formik.setFieldValue('targetBankId', selectedOption ? selectedOption.value : '');
    };


    const handleTargetCashboxIdSelectChange = (selectedOption) => {
        setSelectedTargetCashboxIdOption(selectedOption);
        formik.setFieldValue('targetCashboxId', selectedOption ? selectedOption.value : '');
    };


    const handleHeOrBvOrBktSelectChange = (selectedOption) => {
        setSelectedHeOrBvOrBktOption(selectedOption);
        if(selectedOption.value == "Havale / Eft")
        {
          
           formik.setFieldValue('bankId', null);
           formik.setFieldValue('currentAccountId', null);
           formik.setFieldValue('operationType', null);

        }
        if(selectedOption.value == "Banka Virman")
        {
          
            formik.setFieldValue('sourceBankId', null);
            formik.setFieldValue('targetBankId', null);
           

        }

        if(selectedOption.value == "Bankadan Kasaya Transfer")
        {
        
            formik.setFieldValue('sourceBankId', null);
            formik.setFieldValue('targetCashboxId', null);
            

        }


        formik.setFieldValue('heOrBvOrBkt', selectedOption ? selectedOption.value : '');
    };
    

    const handleOperationTypeSelectChange = (selectedOption) => {
        setSelectedOperationTypeOption(selectedOption);
        formik.setFieldValue('operationType', selectedOption ? selectedOption.value : '');
    };

    const formik = useFormik({
        initialValues: {
        heOrBvOrBkt :'',
        bankId:'',
        currentAccountId:'',
        sourceBankId:'',
        targetBankId:'',
        targetCashboxId:'',
        date:'',
        amount:'',
        operationType:'',
        explanation:'',
        document_Receipt_No:'',

            updatedUserId: authUser.Id,
        },
        validationSchema,
        onSubmit: (values) => {
            api.create("/BankTransfer/UpdateBankTransfer", values)
                .then(res => {
                    console.log("başarılı");
                    navigate('/BankaTransferler')
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Banka Transferi Güncelle" breadcrumbItem="Banka Transferleri" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>



                                    <div className="mb-3">
                                            <label htmlFor="heOrBvOrBkt" className="form-label">Transfer Türü</label>
                                            <Select
                                                value={selectedHeOrBvOrBktOption}
                                                onChange={handleHeOrBvOrBktSelectChange}
                                                options={heOrBvOrBktOptions}
                                                placeholder={formik.values.heOrBvOrBkt}
                                                className={`react-select ${formik.touched.heOrBvOrBkt && formik.errors.heOrBvOrBkt ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.kvOrKbt && formik.errors.kvOrKbt ? (
                                                <div className="invalid-feedback d-block">{formik.errors.heOrBvOrBkt}</div>
                                            ) : null}
                                        </div>

                                        {formik.values.heOrBvOrBkt === 'Havale / Eft' && (

                                        <div className="mb-3">
                                                <label htmlFor="bankId" className="form-label">Banka Hesabı</label>
                                                <Select
                                                    value={selectedBankIdOption}
                                                    onChange={handleBankIdSelectChange}
                                                    options={bankIdOptions}
                                                    placeholder="BankId Seçiniz"
                                                    className={`react-select ${formik.touched.bankId && formik.errors.bankId ? 'is-invalid' : ''}`}
                                                />
                                                {formik.touched.bankId && formik.errors.bankId ? (
                                                    <div className="invalid-feedback d-block">{formik.errors.bankId}</div>
                                                ) : null}
                                            </div>
                                        )}

{formik.values.heOrBvOrBkt === 'Havale / Eft' && (

                                            <div className="mb-3">
                                                <label htmlFor="currentAccountId" className="form-label">Cari Adı	</label>
                                                <Select
                                                    value={selectedCurrentAccountIdOption}
                                                    onChange={handleCurrentAccountIdSelectChange}
                                                    options={currentAccountIdOptions}
                                                    placeholder="CurrentAccountId Seçiniz"
                                                    className={`react-select ${formik.touched.currentAccountId && formik.errors.currentAccountId ? 'is-invalid' : ''}`}
                                                />
                                                {formik.touched.currentAccountId && formik.errors.currentAccountId ? (
                                                    <div className="invalid-feedback d-block">{formik.errors.currentAccountId}</div>
                                                ) : null}
                                            </div>
)}

{(formik.values.heOrBvOrBkt === 'Banka Virman' || formik.values.heOrBvOrBkt === 'Bankadan Kasaya Transfer') && (
    <div className="mb-3">
        <label htmlFor="sourceBankId" className="form-label">Kaynak Banka Hesabı</label>
        <Select
            value={selectedSourceBankIdOption}
            onChange={handleSourceBankIdSelectChange}
            options={sourceBankIdOptions}
            placeholder="Kaynak Banka Seçiniz"
            className={`react-select ${formik.touched.sourceBankId && formik.errors.sourceBankId ? 'is-invalid' : ''}`}
        />
        {formik.touched.sourceBankId && formik.errors.sourceBankId ? (
            <div className="invalid-feedback d-block">{formik.errors.sourceBankId}</div>
        ) : null}
    </div>
)}

{formik.values.heOrBvOrBkt === 'Banka Virman' && (
    <div className="mb-3">
        <label htmlFor="targetBankId" className="form-label">Hedef Banka Hesabı</label>
        <Select
            value={selectedTargetBankIdOption}
            onChange={handleTargetBankIdSelectChange}
            options={targetBankIdOptions}
            placeholder="TargetBankId Seçiniz"
            className={`react-select ${formik.touched.targetBankId && formik.errors.targetBankId ? 'is-invalid' : ''}`}
        />
        {formik.touched.targetBankId && formik.errors.targetBankId ? (
            <div className="invalid-feedback d-block">{formik.errors.targetBankId}</div>
        ) : null}
    </div>
)}
{formik.values.heOrBvOrBkt === 'Bankadan Kasaya Transfer' && (
    <div className="mb-3">
        <label htmlFor="targetCashboxId" className="form-label">Hedef Kasa Adı</label>
        <Select
            value={selectedTargetCashboxIdOption}
            onChange={handleTargetCashboxIdSelectChange}
            options={targetCashboxIdOptions}
            placeholder="TargetCashboxId Seçiniz"
            className={`react-select ${formik.touched.targetCashboxId && formik.errors.targetCashboxId ? 'is-invalid' : ''}`}
        />
        {formik.touched.targetCashboxId && formik.errors.targetCashboxId ? (
            <div className="invalid-feedback d-block">{formik.errors.targetCashboxId}</div>
        ) : null}
    </div>
)}
                                            <div className="mb-3">
                                            <label htmlFor="date" className="form-label">Tarih</label>
                                            <input
                                                className={`form-control ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                                                type="date"
                                                name="date"
                                                value={formik.values.date}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.date && formik.errors.date ? (
                                                <div className="invalid-feedback">{formik.errors.date}</div>
                                            ) : null}
                                        </div>

                                        {formik.values.heOrBvOrBkt === 'Havale / Eft' && (
    <div className="mb-3">
        <label htmlFor="amount" className="form-label">Tutar</label>
        <input
            className={`form-control ${formik.touched.amount && formik.errors.amount ? 'is-invalid' : ''}`}
            type="text"
            name="amount"
            placeholder="Tutarı Giriniz"
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        {formik.touched.amount && formik.errors.amount ? (
            <div className="invalid-feedback">{formik.errors.amount}</div>
        ) : null}
    </div>
)}


{formik.values.heOrBvOrBkt === 'Havale / Eft' && (
    <div className="mb-3">
        <label htmlFor="operationType" className="form-label">İşlem Tipi</label>
        <Select
            value={selectedOperationTypeOption}
            onChange={handleOperationTypeSelectChange}
            options={operationTypeOptions} // Tüm operasyon tiplerini göstermek için
            placeholder="Operasyon Tipini Seçiniz"
            className={`react-select ${formik.touched.operationType && formik.errors.operationType ? 'is-invalid' : ''}`}
        />
        {formik.touched.operationType && formik.errors.operationType ? (
            <div className="invalid-feedback d-block">{formik.errors.operationType}</div>
        ) : null}
    </div>
)}
                                        <div className="mb-3">
                                            <label htmlFor="explanation" className="form-label">Açıklama</label>
                                            <input
                                                className={`form-control ${formik.touched.explanation && formik.errors.explanation ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="explanation"
                                                placeholder="Açıklama Giriniz"
                                                value={formik.values.explanation}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.explanation && formik.errors.explanation ? (
                                                <div className="invalid-feedback">{formik.errors.explanation}</div>
                                            ) : null}
                                        </div>



                                        <div className="mb-3">
                                            <label htmlFor="document_Receipt_No" className="form-label">Belge / Makbuz No</label>
                                            <input
                                                className={`form-control ${formik.touched.document_Receipt_No && formik.errors.document_Receipt_No ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="document_Receipt_No"
                                                placeholder="Belge / Makbuz No Giriniz"
                                                value={formik.values.document_Receipt_No}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.document_Receipt_No && formik.errors.document_Receipt_No ? (
                                                <div className="invalid-feedback">{formik.errors.document_Receipt_No}</div>
                                            ) : null}
                                        </div>


                                        <div>
                                            <button className="btn btn-primary" type="submit">Güncelle</button>
                                        </div>
                                    </form>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BankTransferUpdatePage;

{/*
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object().shape({
    // heOrBvOrBkt: yup.string().required('Transfer türü gereklidir'),
    // bankIdName: yup.string().required('Transfer türü gereklidir'),
    // currentAccountIdName: yup.string().required('Transfer türü gereklidir'),
    // sourceBankIdName: yup.string().required('Transfer türü gereklidir'),
    // sargetBankIdName: yup.string().required('Transfer türü gereklidir'),
    // targetCashboxIdName: yup.string().required('Transfer türü gereklidir'),
    // date: yup.string().required('Transfer türü gereklidir'),
    // amount: yup.string().required('Transfer türü gereklidir'),
    // operationType: yup.string().required('Transfer türü gereklidir'),
    // explanation: yup.string().required('Transfer türü gereklidir'),
    // document_Receipt_No: yup.string().required('Transfer türü gereklidir'),

});

const BankTransferUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const authUser = getLoggedinUser();

    const navigate = useNavigate();

  

    const [bankIdOptions, setBankIdOptions] = useState([]);
    const [selectedBankIdOption, setSelectedBankIdOption] = useState(null);

    const [currentAccountIdOptions, setCurrentAccountIdOptions] = useState([]);
    const [selectedCurrentAccountIdOption, setSelectedCurrentAccountIdOption] = useState(null);

    const [sourceBankIdOptions, setSourceBankIdOptions] = useState([]);
    const [selectedSourceBankIdOption, setSelectedSourceBankIdOption] = useState(null);

    const [targetBankIdOptions, setTargetBankIdOptions] = useState([]);
    const [selectedTargetBankIdOption, setSelectedTargetBankIdOption] = useState(null);

    const [targetCashboxIdOptions, setTargetCashboxIdOptions] = useState([]);
    const [selectedTargetCashboxIdOption, setSelectedTargetCashboxIdOption] = useState(null);
  
    const [operationTypeOptions, setOperationTypeOptions] = useState([]);
    const [selectedOperationTypeOption, setSelectedOperationTypeOption] = useState(null);
    
    const [heOrBvOrBktOptions, setHeOrBvOrBktOptions] = useState([]);
    const [selectedHeOrBvOrBktOption, setSelectedHeOrBvOrBktOption] = useState(null);

    const [selectedTransferType, setSelectedTransferType] = useState('');


    
    useEffect(() => {
        const companyId = authUser.CompanyId;

        // Transfer türü seçenekleri
        const options = [
            { label: "Havale / Eft", value: "Havale / Eft" },
            { label: "Banka Virman", value: "Banka Virman" },
            { label: "Bankadan Kasaya Transfer", value: "Bankadan Kasaya Transfer" }
        ];
        setHeOrBvOrBktOptions(options);

  

        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id.toString()
            }));
            setBankIdOptions(formattedOptions);
        });

        api.get("/CurrentAccount/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id.toString()
            }));
            setCurrentAccountIdOptions(formattedOptions);
        });

        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id.toString()
            }));
            setSourceBankIdOptions(formattedOptions);
        });

        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id.toString()
            }));
            setTargetBankIdOptions(formattedOptions);
        });

        api.get("/Cashbox/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id.toString()
            }));
            setTargetCashboxIdOptions(formattedOptions);
        });


    }, []);

    useEffect(() => {
        api.get('/BankTransfer/GetBankTransferById?id=' + _id)
            .then(res => {
                const selectedTransferType = res.kvOrKbt;
                setSelectedTransferType(selectedTransferType);

                formik.setValues({
                    id: res.id,
                    heOrBvOrBkt :res.heOrBvOrBkt,
                    bankId:res.bankId,
                    currentAccountId:res.currentAccountId,
                    sourceBankId:res.sourceBankId,
                    targetBankId:res.targetBankId,
                    targetCashboxId:res.targetCashboxId,
                    date:res.date.split('T')[0],
                    amount:res.operationType,
                    operationType:res.operationType,
                    explanation:res.explanation,
                    document_Receipt_No:res.document_Receipt_No,

                    updatedUserId: authUser.Id,
                });

              
                setSelectedBankIdOption({ label: res.bankIdName, value: res.bankId });
                setSelectedCurrentAccountIdOption({ label: res.currentAccountIdName, value: res.currentAccountId });
                setSelectedSourceBankIdOption({ label: res.sourceBankIdName, value: res.sourceBankId });
                setSelectedTargetBankIdOption({ label: res.targetBankIdName, value: res.targetBankId });
                setSelectedTargetCashboxIdOption({ label: res.targetCashboxIdName, value: res.targetCashboxId });


                setSelectedHeOrBvOrBktOption({ label: res.heOrBvOrBkt, value: res.heOrBvOrBkt });
                setSelectedOperationTypeOption({ label: res.operationType, value: res.operationType });

            })
            .catch(err => console.log(err));
    }, [_id]);

   

    const handleBankIdSelectChange = (selectedOption) => {
        setSelectedBankIdOption(selectedOption);
        formik.setFieldValue('bankId', selectedOption ? selectedOption.value : '');
    };

    const handleCurrentAccountIdSelectChange = (selectedOption) => {
        setSelectedCurrentAccountIdOption(selectedOption);
        formik.setFieldValue('currentAccountId', selectedOption ? selectedOption.value : '');
    };


    const handleSourceBankIdSelectChange = (selectedOption) => {
        setSelectedSourceBankIdOption(selectedOption);
        formik.setFieldValue('sourceBankId', selectedOption ? selectedOption.value : '');
    };


    const handleTargetBankIdSelectChange = (selectedOption) => {
        setSelectedTargetBankIdOption(selectedOption);
        formik.setFieldValue('targetBankId', selectedOption ? selectedOption.value : '');
    };


    const handleTargetCashboxIdSelectChange = (selectedOption) => {
        setSelectedTargetCashboxIdOption(selectedOption);
        formik.setFieldValue('targetCashboxId', selectedOption ? selectedOption.value : '');
    };


    const handleHeOrBvOrBktSelectChange = (selectedOption) => {
        setSelectedHeOrBvOrBktOption(selectedOption);
        formik.setFieldValue('heOrBvOrBkt', selectedOption ? selectedOption.value : '');
        setSelectedHeOrBvOrBktOption(selectedOption.value);
    };

    const handleOperationTypeSelectChange = (selectedOption) => {
        setSelectedOperationTypeOption(selectedOption);
        formik.setFieldValue('operationType', selectedOption ? selectedOption.value : '');
        setSelectedOperationTypeOption(selectedOption.value);
    };

    const formik = useFormik({
        initialValues: {
        heOrBvOrBkt :'',
        bankId:'',
        currentAccountId:'',
        sourceBankId:'',
        targetBankId:'',
        targetCashboxId:'',
        date:'',
        amount:'',
        operationType:'',
        explanation:'',
        document_Receipt_No:'',

            updatedUserId: authUser.Id,
        },
        validationSchema,
        onSubmit: (values) => {
            api.create("/BankTransfer/Update BankTransfer", values)
                .then(res => {
                    console.log("başarılı");
                    navigate('/BankaTransferler')
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Banka Transferi Güncelle" breadcrumbItem="Banka Transferleri" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>



                                    <div className="mb-3">
                                            <label htmlFor="heOrBvOrBkt" className="form-label">heOrBvOrBkt</label>
                                            <Select
                                                value={selectedHeOrBvOrBktOption}
                                                onChange={handleHeOrBvOrBktSelectChange}
                                                options={heOrBvOrBktOptions}
                                                placeholder="heOrBvOrBkt Seç"
                                                className={`react-select ${formik.touched.heOrBvOrBkt && formik.errors.heOrBvOrBkt ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.kvOrKbt && formik.errors.kvOrKbt ? (
                                                <div className="invalid-feedback d-block">{formik.errors.heOrBvOrBkt}</div>
                                            ) : null}
                                        </div>

                                        {selectedTransferType === 'Havale / Eft' && (

                                        <div className="mb-3">
                                                <label htmlFor="bankId" className="form-label">BankId</label>
                                                <Select
                                                    value={selectedBankIdOption}
                                                    onChange={handleBankIdSelectChange}
                                                    options={bankIdOptions}
                                                    placeholder="BankId Seç"
                                                    className={`react-select ${formik.touched.bankId && formik.errors.bankId ? 'is-invalid' : ''}`}
                                                />
                                                {formik.touched.bankId && formik.errors.bankId ? (
                                                    <div className="invalid-feedback d-block">{formik.errors.bankId}</div>
                                                ) : null}
                                            </div>
                                        )}

{selectedTransferType === 'Havale / Eft' && (

                                            <div className="mb-3">
                                                <label htmlFor="currentAccountId" className="form-label">currentAccountId</label>
                                                <Select
                                                    value={selectedCurrentAccountIdOption}
                                                    onChange={handleCurrentAccountIdSelectChange}
                                                    options={currentAccountIdOptions}
                                                    placeholder="CurrentAccountId Seç"
                                                    className={`react-select ${formik.touched.currentAccountId && formik.errors.currentAccountId ? 'is-invalid' : ''}`}
                                                />
                                                {formik.touched.currentAccountId && formik.errors.currentAccountId ? (
                                                    <div className="invalid-feedback d-block">{formik.errors.currentAccountId}</div>
                                                ) : null}
                                            </div>
)}


{selectedTransferType === 'Banka Virman' || 'Bankadan Kasaya Transfer' && (

                                            <div className="mb-3">
                                                <label htmlFor="sourceBankId" className="form-label">sourceBankId</label>
                                                <Select
                                                    value={selectedSourceBankIdOption}
                                                    onChange={handleSourceBankIdSelectChange}
                                                    options={sourceBankIdOptions}
                                                    placeholder="SourceBankId Seç"
                                                    className={`react-select ${formik.touched.sourceBankId && formik.errors.sourceBankId ? 'is-invalid' : ''}`}
                                                />
                                                {formik.touched.sourceBankId && formik.errors.sourceBankId ? (
                                                    <div className="invalid-feedback d-block">{formik.errors.sourceBankId}</div>
                                                ) : null}
                                            </div>
)}
{selectedTransferType === 'Banka Virman'  && (


                                            <div className="mb-3">
                                                <label htmlFor="targetBankId" className="form-label">targetBankId</label>
                                                <Select
                                                    value={selectedTargetCashboxIdOption}
                                                    onChange={handleTargetBankIdSelectChange}
                                                    options={targetBankIdOptions}
                                                    placeholder="TargetBankId Seç"
                                                    className={`react-select ${formik.touched.targetBankId && formik.errors.targetBankId ? 'is-invalid' : ''}`}
                                                />
                                                {formik.touched.targetBankId && formik.errors.targetBankId ? (
                                                    <div className="invalid-feedback d-block">{formik.errors.targetBankId}</div>
                                                ) : null}
                                            </div>
)}
{selectedTransferType === 'Bankadan Kasaya Transfer'  && (

                                            <div className="mb-3">
                                                <label htmlFor="targetCashboxId" className="form-label">targetCashboxId</label>
                                                <Select
                                                    value={selectedTargetBankIdOption}
                                                    onChange={handleTargetCashboxIdSelectChange}
                                                    options={targetCashboxIdOptions}
                                                    placeholder="TargetCashboxId Seç"
                                                    className={`react-select ${formik.touched.targetCashboxId && formik.errors.targetCashboxId ? 'is-invalid' : ''}`}
                                                />
                                                {formik.touched.targetCashboxId && formik.errors.targetCashboxId ? (
                                                    <div className="invalid-feedback d-block">{formik.errors.targetCashboxId}</div>
                                                ) : null}
                                            </div>
)}

                                            <div className="mb-3">
                                            <label htmlFor="date" className="form-label">Tarih</label>
                                            <input
                                                className={`form-control ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                                                type="date"
                                                name="date"
                                                value={formik.values.date}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.date && formik.errors.date ? (
                                                <div className="invalid-feedback">{formik.errors.date}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="amount" className="form-label">Tutar</label>
                                            <input
                                                className={`form-control ${formik.touched.amount && formik.errors.amount ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="amount"
                                                value={formik.values.amount}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.amount && formik.errors.amount ? (
                                                <div className="invalid-feedback">{formik.errors.amount}</div>
                                            ) : null}
                                        </div>


                                        {selectedTransferType === 'Havale / Eft'  && (

<div className="mb-3">
<label htmlFor="operationType" className="form-label">OperationType</label>
<Select
    value={selectedOperationTypeOption}
    onChange={handleOperationTypeSelectChange}
    options={operationTypeOptions}
    placeholder="OperationType Seç"
    className={`react-select ${formik.touched.operationType && formik.errors.operationType ? 'is-invalid' : ''}`}
/>
{formik.touched.operationType && formik.errors.operationType ? (
    <div className="invalid-feedback d-block">{formik.errors.operationType}</div>
) : null}
</div>
)}
                                        <div className="mb-3">
                                            <label htmlFor="explanation" className="form-label">Açıklama</label>
                                            <input
                                                className={`form-control ${formik.touched.explanation && formik.errors.explanation ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="explanation"
                                                value={formik.values.explanation}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.explanation && formik.errors.explanation ? (
                                                <div className="invalid-feedback">{formik.errors.explanation}</div>
                                            ) : null}
                                        </div>

                                        {selectedTransferType === 'Banka Virman' || 'Bankadan Kasaya Transfer' && (

                                        <div className="mb-3">
                                            <label htmlFor="document_Receipt_No" className="form-label">Belge / Makbuz No</label>
                                            <input
                                                className={`form-control ${formik.touched.document_Receipt_No && formik.errors.document_Receipt_No ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="document_Receipt_No"
                                                value={formik.values.document_Receipt_No}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.document_Receipt_No && formik.errors.document_Receipt_No ? (
                                                <div className="invalid-feedback">{formik.errors.document_Receipt_No}</div>
                                            ) : null}
                                        </div>
)}

                                        <div>
                                            <button className="btn btn-primary" type="submit">Güncelle</button>
                                        </div>
                                    </form>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BankTransferUpdatePage;
*/}