import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Input, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const CashboxcollectionAdd = () => {
    const api =new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    const [cashboxcollection, setCashboxcollection] = useState({
        Document_Receipt_No: '',
        Cashbox_ID: '',
        Date: '',
        Current_ID: 0,
        Explanation: '',
        CompanyId: authUser.CompanyId,
CreatedUserId: authUser.Id,
status:true

    });


    const [selectCashbox, setSelectCashbox] = useState(null);
    const [cashbox, setCashbox] = useState([]);
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);

    var companyId = authUser.CompanyId;

    useEffect(() => {
            api.get("/Cashbox/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id
            }));
            setCashbox(formattedOptions);
        });
        api.get("/CurrentAccount/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id
                        }));
            setCurrentAccount(formattedOptions);
        });
    }, []);

    const handleCashbox = (selectedOption) => {
        setSelectCashbox(selectedOption);
        setCashboxcollection({ ...cashboxcollection, Cashbox_ID: selectedOption ? selectedOption.value : '' });
    };

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCashboxcollection({ ...cashboxcollection, Current_ID: selectedOption ? selectedOption.value : '' });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCashboxcollection({ ...cashboxcollection, [name]: value });
    };

 


    const handleSubmit = () => {
        api.create("/Cashboxcollection/AddCashboxcollection", cashboxcollection)
            .then(res => {
                console.log("başarılı");
                navigate(`/KasaTahsilatlar`);
                //window.location.href = `/KasaTahsilatlar`;
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }

    function formatDate(date) {
        const d = new Date(date);
        return d.toISOString().split('T')[0];
    }

    return (
        <React.Fragment>
              {/*
                          <div className="page-content">
  <Container fluid={true}>
                    <Breadcrumbs title="KasaTahsilatlar" breadcrumbItem="KasaTahsilat Ekle" />
                    <Row>
                        <Col>
                            <Card>
    <CardBody>*/}
                                    <FormGroup>
                                        <Label for="Document_Receipt_No">Belge / Makbuz No</Label>
                                        <Input
                                            type="text"
                                            name="Document_Receipt_No"
                                            placeholder='Belge / Makbuz No Giriniz'
                                            id="Document_Receipt_No"
                                            value={cashboxcollection.Document_Receipt_No}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                   {/* <FormGroup>
                                        <Label for="Cashbox_ID">Cashbox_ID</Label>
                                        <Input
                                            type="text"
                                            name="Cashbox_ID"
                                            id="Cashbox_ID"
                                            value={cashboxcollection.Cashbox_ID}
                                            onChange={handleChange}
                                        />
    </FormGroup>*/}

<div className="mb-3">
                                        <label htmlFor="Cashbox_ID" className="form-label">Kasa</label>
                                        <Select
                                            value={selectCashbox}
                                            onChange={handleCashbox}
                                            options={cashbox}
                                            placeholder="CashboxId Seç"
                                        />
                                    </div>
                                    <FormGroup>
                                        <Label for="Date">Tarih</Label>
                                        <Input
                                            type="date"
                                            name="Date"
                                            id="Date"
                                            value={cashboxcollection.Date}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                   {/* <FormGroup>
                                        <Label for="Current_ID">Current_ID</Label>
                                        <Input
                                            type="number"
                                            name="Current_ID"
                                            id="Current_ID"
                                            value={cashboxcollection.Current_ID}
                                            onChange={handleChange}
                                        />
</FormGroup>*/}
<div className="mb-3"> 
                                        <label htmlFor="Current_ID" className="form-label">Cari</label>
                                        <Select
                                            value={selectCurrentAccount}
                                            onChange={handleCurrentAccount}
                                            options={currentAccount}
                                            placeholder="CurrentAccount Seç"
                                        />
    </div>
                                    <FormGroup>
                                        <Label for="Explanation">Açıklama</Label>
                                        <Input
                                            type="textarea"
                                            name="Explanation"
                                            id="Explanation"
                                            value={cashboxcollection.Explanation}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    <Button color="primary" onClick={handleSubmit}>Kaydet</Button>
                               {/* </CardBody>
                            </Card>
                        </Col>
                    </Row>
                                </div>

</Container>*/}
        </React.Fragment>
    );
};

export default CashboxcollectionAdd;
