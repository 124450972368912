
import React from "react";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LabelUpdate = (props) => {
  const api = new APIClient();
  const authUser = getLoggedinUser();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    label_Name: Yup.string().required("Etiket adı gerekli"),
  });

  const formik = useFormik({
    initialValues: {
      label_Name: props.selectedItem.label_Name,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const updatedLabel = {
          id: props.selectedItem.id,
          label_Name: values.label_Name,
          updatedUserId: authUser.Id,
        };
        await api.create("/Label/UpdateLabel", updatedLabel);
        toast.success("Başarıyla güncellendi", {
          position: "bottom-right",
          autoClose: 10000,
        });
        window.location.href = `/Etiketler`;
        // navigate("/Etiketler");
      } catch (error) {
        toast.error("Güncellenemedi", {
          position: "bottom-right",
          autoClose: 10000,
        });
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Group style={{ marginTop: "10px" }}>
        <Form.Label>Etiket Adı </Form.Label>
        <Form.Control
          type="text"
          name="label_Name"
          value={formik.values.label_Name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
        />
        {formik.touched.label_Name && formik.errors.label_Name ? (
          <div className="text-danger">{formik.errors.label_Name}</div>
        ) : null}
      </Form.Group>

      <Button
        variant="success"
        type="submit"
        block
        style={{
          marginTop: "10px",
          width: "200px",
          marginLeft: "130px",
        }}
      >
        Etiketi Güncelle
      </Button>
    </Form>
  );
};

export default LabelUpdate;
{/*import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

const LabelUpdate = (props) => {
    const api =new APIClient();
    const authUser = getLoggedinUser();

    const [label_Name, setLabel_Name] = useState(props.selectedItem.label_Name);
    const [updatedUserId, setUpdatedUserId] = useState(authUser.Id);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedLabel = {
            id: props.selectedItem.id,
            label_Name: label_Name,
            updatedUserId: updatedUserId,
           // status:true



        };

        try {
           //await axios.post("/Bank/UpdateBank", updatedBank);
           await api.create("/Label/UpdateLabel", updatedLabel);
           toast.success("Başarıyla güncellendi", {
            position: "bottom-right",
            autoClose: 10000 
        });
    //window.location.href = `/Etiketler`;
    navigate('/Etiketler')

        } catch (error) {
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Etiket Adı </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Label_Name"
                    name="label_Name"
                    value={label_Name}
                    onChange={(e) => setLabel_Name(e.target.value)}
                    required
                />
            </Form.Group>

            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
            Etiketi Güncelle
            </Button>
        </Form>
    );
};

export default LabelUpdate;
*/}

