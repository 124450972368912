import React from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";



// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";

// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";
import Register1 from "../Pages/AuthenticationPages/Register";
import RecoverPassword from "../Pages/AuthenticationPages/RecoverPassword";
import LockScreen from "../Pages/AuthenticationPages/LockScreen";
import CurrentGroupList from "../Pages/CurrentGroup/CurrentGroupList";
import UserGroup from "../Pages/UserGroup";
import { UserGroupAdd } from "../Pages/UserGroup/UserGroupAdd";
import CurrentAccount from "../Pages/CurrentAccount/index";
import CurrentAccountAdd from "../Pages/CurrentAccount/CurrentAccountAdd";
import CurrentAccountUpdate from "../Pages/CurrentAccount/CurrentAccountUpdate";

import User  from "../Pages/User";
import UserAdd from "../Pages/User/UserAdd";
import UserUpdate from "../Pages/User/UserUpdate";


import Invoice_Serial_Management from "../Pages/Invoice_Serial_Management";
import Invoice_Serial_Management_E_ArchiveAdd from "../Pages/Invoice_Serial_Management/Invoice_Serial_Management_E_ArchiveAdd";
import Invoice_Serial_Management_E_ArchiveUpdate from "../Pages/Invoice_Serial_Management/Invoice_Serial_Management_E_ArchiveUpdate";
import Invoice_Serial_Management_E_InvoiceAdd from "../Pages/Invoice_Serial_Management/Invoice_Serial_Management_E_InvoiceAdd";
import Invoice_Serial_Management_E_InvoiceUpdate from "../Pages/Invoice_Serial_Management/Invoice_Serial_Management_E_InvoiceUpdate";



import Cost from "../Pages/Cost";
import CostAdd from "../Pages/Cost/CostAdd";
import CostUpdatePage from "../Pages/Cost/CostUpdatePage";

import Bank from "../Pages/Bank";
import BankAdd from "../Pages/Bank/BankAdd";
import BankUpdate from "../Pages/Bank/BankUpdate";
import BankUpdatePage from "../Pages/Bank/BankUpdatePage";

import BankTransfer from "../Pages/BankTransfer";
import BankTransferAdd from "../Pages/BankTransfer/BankTransferAdd";
import BankTransferUpdate from "../Pages/BankTransfer/BankTransferUpdate";
import BankTransferUpdatePage from "../Pages/BankTransfer/BankTransferUpdatePage";

import Cashboxmovements from "../Pages/Cashboxmovements";


import Cashbox from "../Pages/Cashbox";
import CashboxAdd from "../Pages/Cashbox/CashboxAdd";
import CashboxUpdate from "../Pages/Cashbox/CashboxUpdate";
import CashboxUpdatePage from "../Pages/Cashbox/CashboxUpdatePage";

import Cashboxcollection  from "../Pages/Cashboxcollection";
import CashboxcollectionAdd from "../Pages/Cashboxcollection/CashboxcollectionAdd";
import CashboxcollectionUpdate from "../Pages/Cashboxcollection/CashboxcollectionUpdate";
import CashboxcollectionUpdatePage from "../Pages/Cashboxcollection/CashboxcollectionUpdatePage";

import Cashboxtransactions  from "../Pages/Cashboxtransactions";
import CashboxtransactionsAdd from "../Pages/Cashboxtransactions/CashboxtransactionsAdd";
import CashboxtransactionsUpdate from "../Pages/Cashboxtransactions/CashboxtransactionsUpdate";
import CashboxtransactionsUpdatePage from "../Pages/Cashboxtransactions/CashboxtransactionsUpdatePage";

import CashboxTransfers   from "../Pages/CashboxTransfers";
import CashboxTransfersAdd from "../Pages/CashboxTransfers/CashboxTransfersAdd";
import CashboxTransfersUpdate from "../Pages/CashboxTransfers/CashboxTransfersUpdate";
import CashboxTransfersUpdatePage from "../Pages/CashboxTransfers/CashboxTransfersUpdatePage";

import Cashboxtype   from "../Pages/Cashboxtype";
import CashboxtypeAdd from "../Pages/Cashboxtype/CashboxtypeAdd";
import CashboxtypeUpdate from "../Pages/Cashboxtype/CashboxtypeUpdate";
import CashboxtypeUpdatePage from "../Pages/Cashboxtype/CashboxtypeUpdatePage";

import Banklist from "../Pages/Banklist";
import BanklistAdd from "../Pages/Banklist/BanklistAdd";
import BanklistUpdate from "../Pages/Banklist/BanklistUpdate";
import BanklistUpdatePage from "../Pages/Banklist/BanklistUpdatePage";

import CheckPortfolio from "../Pages/CheckPortfolio";
import CheckPortfolioAdd from "../Pages/CheckPortfolio/CheckPortfolioAdd";
import CheckPortfolioUpdate from "../Pages/CheckPortfolio/CheckPortfolioUpdate";
import CheckPortfolioUpdatePage from "../Pages/CheckPortfolio/CheckPortfolioUpdatePage";

import Promissory_NotePortfolio from "../Pages/Promissory_NotePortfolio";
import Promissory_NotePortfolioAdd from "../Pages/Promissory_NotePortfolio/Promissory_NotePortfolioAdd";
import Promissory_NotePortfolioUpdate from "../Pages/Promissory_NotePortfolio/Promissory_NotePortfolioUpdate";
import Promissory_NotePortfolioUpdatePage from "../Pages/Promissory_NotePortfolio/Promissory_NotePortfolioUpdatePage";

import CostType from "../Pages/CostType";
import CostTypeAdd from "../Pages/CostType/CostTypeAdd";
import CostTypeUpdate from "../Pages/CostType/CostTypeUpdate";
import CostTypeUpdatePage from "../Pages/CostType/CostTypeUpdatePage";

import Label from "../Pages/Label";
import LabelAdd from "../Pages/Label/LabelAdd";
import LabelUpdate from "../Pages/Label/LabelUpdate";
import LabelUpdatePage from "../Pages/Label/LabelUpdatePage";

import Company from "../Pages/Company";
import CompanyAdd from "../Pages/Company/CompanyAdd";
import CompanyUpdate from "../Pages/Company/CompanyUpdate";
import CompanyUpdatePage from "../Pages/Company/CompanyUpdatePage";
import CompanyUserAdd from "../Pages/Company/CompanyUserAdd";
import CompanyUserAddModal from "../Pages/Company/CompanyUserAddModal";


import Module  from "../Pages/Module";
import StockAdd from "../Pages/Stock/StockAdd";
import StockUpdate from "../Pages/Stock/StockUpdate";
import Stock from "../Pages/Stock";
import StockGroup from "../Pages/StockGroup";

import DraftInvoice from "../Pages/Invoice/DraftInvoice";
import EInvoice from "../Pages/Invoice/EInvoice";
import EArchive from "../Pages/Invoice/EArchive";
import Waybill from "../Pages/Invoice/Waybill";
import EInvoiceAdd from "../Pages/Invoice/EInvoiceAdd";
import ESelfEmployment from "../Pages/Invoice/ESelfEmployment";

import Warehouses from "../Pages/Warehouses/index";
import ModuleCompany from "../Pages/ModuleCompany";
import ModuleCompanyBuy from "../Pages/ModuleCompany/ModuleCompanyBuy";
import { Forbiden } from "../Pages/Errors/forbiden";
import EArchiveAdd from "../Pages/Invoice/EArchiveAdd";


const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/cariGruplari", component: <CurrentGroupList /> },
  { path: "/depolar", component: < Warehouses/> },
  { path: "/taslakFaturaListesi", component: < DraftInvoice/> },
  { path: "/eFaturaListesi", component: <EInvoice /> },
  { path: "/eFaturaListesi/EfaturaOlustur", component: <EInvoiceAdd /> },
  { path: "/eArsivListesi/EArsivOlustur", component: <EArchiveAdd /> },
  { path: "/eArsivListesi", component: <EArchive /> },

  { path: "/kullaniciGrup", component: <UserGroup /> },
  { path: "/kullaniciGrup/ekle", component: <UserGroupAdd /> },
  { path: "/kullanici", component: <User /> },
  { path: "/kullanici/kullaniciEkle", component: <UserAdd /> },
  { path: "/Kullanici/KullaniciGuncelle/:id", component: <UserUpdate /> },

  { path: "/stoklar", component: <Stock /> },
  { path: "/stok/stokEkle", component: <StockAdd /> },
  { path: "/Stok/StokGuncelle/:id", component: <StockUpdate /> },

  
  { path: "/stokGruplari", component: <StockGroup /> },

  { path: "/bankaTransferler", component: < BankTransfer /> },
  { path: "/bankaTransferEkle", component: < BankTransferAdd /> },
  { path: "/bankaTransferGuncelle", component: < BankTransferUpdate /> },
  { path: "/bankaTransferGuncelleSayfa", component: < BankTransferUpdatePage /> },

  { path: "/cariler", component: <CurrentAccount /> },
  { path: "/cari/cariEkle", component: <CurrentAccountAdd /> },
  { path: "/Cariler/CariGuncelle/:id", component: <CurrentAccountUpdate /> },

  { path: "/bankaTransferler", component: <BankTransfer /> },
  { path: "/bankaTransferEkle", component: <BankTransferAdd /> },
  { path: "/bankaTransferGuncelle", component: <BankTransferUpdate /> },
  { path: "/bankaransferGuncelleSayfa", component: <BankTransferUpdatePage /> },

  { path: "/bankalar", component: <Bank /> },
  { path: "/bankaEkle", component: <BankAdd /> },
  { path: "/bankaGuncelle", component: <BankUpdate /> },
  { path: "/bankaGuncelleSayfa", component: <BankUpdatePage /> },

  { path: "/giderler", component: <Cost /> },
  { path: "/giderEkle", component: <CostAdd /> },
  { path: "/giderGuncelleSayfa", component: <CostUpdatePage /> },

  { path: "/kasaHareketler", component: <Cashboxmovements /> },


  { path: "/seriYönetimler", component: <Invoice_Serial_Management /> },
  { path: "/seriYönetimEArşivEkle", component: <Invoice_Serial_Management_E_ArchiveAdd /> },
  { path: "/seriYönetimEArşivGuncelle", component: <Invoice_Serial_Management_E_ArchiveUpdate /> },
  { path: "/seriYönetimEFaturaEkle", component: <Invoice_Serial_Management_E_InvoiceAdd /> },
  { path: "/seriYönetimEFaturaGuncelle", component: <Invoice_Serial_Management_E_InvoiceUpdate /> },

  { path: "/kasalar", component: <Cashbox  /> },
  { path: "/kasaEkle", component: <CashboxAdd /> },
  { path: "/kasaGuncelle", component: <CashboxUpdate /> }, 
  { path: "/kasaGuncelleSayfa", component: <CashboxUpdatePage /> },

  { path: "/kasaTahsilatlar", component: <Cashboxcollection /> },
  { path: "/kasaTahsilatEkle", component: <CashboxcollectionAdd /> },
  { path: "/kasaTahsilatGuncelle", component: <CashboxcollectionUpdate /> },
  { path: "/kasaTahsilatGuncelleSayfa", component: <CashboxcollectionUpdatePage /> },

  
  { path: "/kasaTransferler", component: <CashboxTransfers /> },
  { path: "/kasaTransferEkle", component: <CashboxTransfersAdd /> },
  { path: "/kasaTransferGuncelle", component: <CashboxTransfersUpdate /> },
  { path: "/kasaTransferGuncelleSayfa", component: <CashboxTransfersUpdatePage /> },


  { path: "/kasaIslemleri", component: <Cashboxtransactions /> },
  { path: "/kasaIslemiEkle", component: <CashboxtransactionsAdd /> },
  { path: "/kasaIslemiGuncelle", component: <CashboxtransactionsUpdate /> },
  { path: "/kasaIslemiGuncelleSayfa", component: <CashboxtransactionsUpdatePage /> },

  { path: "/kasaTurler", component: <Cashboxtype  /> },
  { path: "/kasaTurEkle", component: <CashboxtypeAdd /> },
  { path: "/kasaTurGuncelle", component: <CashboxtypeUpdate /> },
  { path: "/kasaTurGuncelleSayfa", component: <CashboxtypeUpdatePage /> },

  { path: "/bankaListeler", component: <Banklist /> },
  { path: "/bankaListeEkle", component: <BanklistAdd /> },
  { path: "/bankaListeGuncelle", component: <BanklistUpdate /> },
  { path: "/bankaListeGuncelleSayfa", component: <BanklistUpdatePage /> },

  { path: "/çekPortfoyler", component: <CheckPortfolio /> },
  { path: "/çekPortfoyEkle", component: <CheckPortfolioAdd /> },
  { path: "/çekPortfoyGuncelle", component: <CheckPortfolioUpdate /> },
  { path: "/çekPortfoyGuncelleSayfa", component: <CheckPortfolioUpdatePage /> },

  { path: "/senetPortfoyler", component: <Promissory_NotePortfolio /> },
  { path: "/senetPortfoyEkle", component: <Promissory_NotePortfolioAdd /> },
  { path: "/senetPortfoyGuncelle", component: <Promissory_NotePortfolioUpdate /> },
  { path: "/senetPortfoyGuncelleSayfa", component: <Promissory_NotePortfolioUpdatePage /> },

  
  { path: "/giderTürler", component: <CostType /> },
  { path: "/giderTürEkle", component: <CostTypeAdd /> },
  { path: "/giderTürGuncelle", component: <CostTypeUpdate /> },
  { path: "/giderTürGuncelleSayfa", component: <CostTypeUpdatePage /> },

  { path: "/etiketler", component: <Label /> },
  { path: "/etiketEkle", component: <LabelAdd /> },
  { path: "/etiketGuncelle", component: <LabelUpdate /> },
  { path: "/etiketGuncelleSayfa", component: <LabelUpdatePage /> },

  { path: "/firmalar", component: <Company /> },
  { path: "/firmaEkle", component: <CompanyAdd /> },
  { path: "/firmaGuncelle", component: <CompanyUpdate /> },
  { path: "/firmaGuncelleSayfa", component: <CompanyUpdatePage /> },
  { path: "/firmaKullanıcıEkle/:lastcompany", component: <CompanyUserAdd /> },
  { path: "/firmaKullanıcıEkleModal", component: <CompanyUserAddModal /> },


  { path: "/userprofile", component: <UserProfile /> },
  { path: "/modül", component: <Module /> },
  { path: "/firmaModül", component: <ModuleCompany /> },
  { path: "/modülSatınAl",component:<ModuleCompanyBuy/>},

  { path: "/yasak",component:<Forbiden/>},
  
  

  
  

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  // Authentication Inner Pages
  { path: "/auth-login", component: <Login1 /> },
  { path: "/auth-register", component: <Register1 /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },

];

export { authProtectedRoutes, publicRoutes };


