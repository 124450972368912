import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    currentType: Yup.string().required('Cari türü gereklidir.'),
    //currentName: Yup.string().required('Cari ünvanı gereklidir.'),
    //currentFirstName: Yup.string().required('Cari adı gereklidir.'),
    //currentLastName: Yup.string().required('Cari soyadı gereklidir.'),    

    currentFirstName: Yup.string().test(
      'currentFirstName',
      'Bu alan gereklidir',
      function (value) {
          const { currentType } = this.parent;
          if (currentType === '1') {
              return value !== undefined && value !== '';
          }
          return true;
      }
  ),
  currentLastName: Yup.string().test(
      'currentLastName',
      'Bu alan gereklidir',
      function (value) {
          const { currentType } = this.parent;
          if (currentType === '1') {
              return value !== undefined && value !== '';
          }
          return true;
      }
  ),
  currentName: Yup.string().test(
    'currentName',
    'Bu alan gereklidir',
    function (value) {
        const { currentType } = this.parent;
        if (currentType === '2') {
            return value !== undefined && value !== '';
        }
        return true;
    }
),
     currentCode: Yup.string().required('Cari kodu gereklidir.'),
     currentTCKN_VKN: Yup.string().required('TCKN/VKN numarası gereklidir.'),
     currentTaxAdministration: Yup.string().required('Vergi dairesi gereklidir.'),
    // province: Yup.number().required('İl gereklidir.'),
    // district: Yup.number().required('İlçe gereklidir.'),
     currentAddress: Yup.string().required('Adres gereklidir.'),
     currentWebsite: Yup.string().required('Web sitesi gereklidir.'),
     currentEmail: Yup.string().email('Geçerli bir email adresi giriniz.').required('Email adresi gereklidir.'),
     currentPhoneNumber: Yup.string().required('Telefon numarası gereklidir.'),
     currentGroup_ID: Yup.number().required('Cari grubu gereklidir.'),
});

const CurrentAccountUpdate = () => {
    const api = new APIClient();
    const params = useParams();

    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    const [currentGroup_IDOptions, setCurrentGroup_IDOptions] = useState([]);
    const [selectedCurrentGroup_IDOption, setSelectedCurrentGroup_IDOption] = useState(null);


    const [provinceOptions, setProvinceOptions] = useState([]);
    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [distinctOptions, setDistinctOptions] = useState([]);
    const [selectedDistinctOption, setSelectedDistinctOption] = useState(null);

    const [currentTypeOptions, setCurrentTypeOptions] = useState([]);
    const [selectedCurrentTypeOption, setSelectedCurrentTypeOption] = useState(null);

    const [selectedCurrentType, setSelectedCurrentType] = useState('');

    useEffect(() => {
        const companyId = authUser.CompanyId;

        // Transfer türü seçenekleri
        const options = [
            { label: "Bireysel", value: 1 },
            { label: "Kurumsal", value: 2 }
        ];
        setCurrentTypeOptions(options);

        api.get("/CurrentGroup/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentGroupName,
                value: item.id
            }));
            setCurrentGroup_IDOptions(formattedOptions);
        });
      
    }, []);

    const formik = useFormik({
        initialValues: {
            id:params.id,
            currentType: '',
            currentName:'',
            currentFirstName:'',
            currentLastName:'',
            currentCode:'',
            currentTCKN_VKN:'',
            currentTaxAdministration:'',
            currentProvince_ID:'',
            currentDistrict_ID:'',
            currentAddress:'',
            currentWebsite:'',
            currentEmail:'',
            currentPhoneNumber:'',
            currentGroup_ID:'',
            createdUserId:'',
            alisPK:'',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            values.companyId = parseInt(authUser.CompanyId);
            values.updatedUserId = parseInt(authUser.Id);
            values.status = true;
            values.id = parseInt(params.id);
            values.alisPK = "default";
            alert('submite düştü');
            console.log('values değerleri gösterilecek');
            console.log(values);
            api.create("/CurrentAccount/UpdateCurrentAccount", values)
                .then(res => {
                    console.log("Başarılı");
                    navigate('/Cariler');
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    useEffect(() => {
        api.get("/Province/GetAll")
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.name,
                    value: item.id
                }));
                setProvinceOptions(formattedOptions);
            })
            .catch(error => {
                console.error("Hata oluştu:", error);
                // Hata durumunda gerekli işlemler yapılabilir, örneğin hata mesajı gösterilebilir.
            });
    }, []);

    useEffect(() => {
        if (params.id) {
            api.get('/CurrentAccount/GetCurrentAccountById?id=' + params.id)
                .then(res => {
                    formik.setValues({
                        currentType:res.currentType,
                        currentName:res.currentName,
                        currentFirstName:res.currentFirstName,
                        currentLastName:res.currentLastName,
                        currentCode:res.currentCode,
                        currentTCKN_VKN:res.currentTCKN_VKN,
                        currentTaxAdministration:res.currentTaxAdministration,
                        currentProvince_ID:res.currentProvince_ID,
                        currentDistrict_ID:res.currentDistrict_ID,
                        currentAddress:res.currentAddress,
                        currentWebsite:res.currentWebsite,
                        currentEmail:res.currentEmail,
                        currentPhoneNumber:res.currentPhoneNumber,
                        currentGroup_ID:parseInt(res.currentGroup_ID),
                        createdUserId:res.createdUserId,
                        alisPK:res.alisPK,
                    });

                    setSelectedProvinceOption({ label: res.provinceName, value: res.currentProvince_ID });
                    setSelectedDistinctOption({ label: res.distinctName, value: res.currentDistrict_ID });
                    setSelectedCurrentGroup_IDOption({ label: res.currentGroup_Name, value: res.currentGroup_ID });

                    if(res.currentType =="1")
                    {
                        setSelectedCurrentTypeOption({ label: "Bireysel", value: res.currentType });

                    }
else  if(res.currentType =="2")
    {
        setSelectedCurrentTypeOption({ label: "Kurumsal", value: res.currentType });

    }
                    api.get("/District/GetDistrictByProvinceId?id=" + res.currentProvince_ID)
                        .then(res => {
                            const formattedOptions = res.map(item => ({
                                label: item.districtName,
                                value: item.districtId
                            }));
                            setDistinctOptions(formattedOptions);
                        })
                        .catch(error => {
                            console.error("Hata oluştu:", error);
                        });
                })
                .catch(err => console.log(err));
        }
    }, [params.id]);

    const handleCurrentGroup_IDSelectChange = (selectedOption) => {
        setSelectedCurrentGroup_IDOption(selectedOption);
        formik.setFieldValue('currentGroup_ID', selectedOption ? selectedOption.value : '');
    };

    const handleProvinceSelectChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption);
        formik.setFieldValue('currentProvince_ID', selectedOption ? selectedOption.value : '');
        setSelectedDistinctOption({ label: "İlçe Seçiniz", value: "" });

        api.get("/District/GetDistrictByProvinceId?id=" + selectedOption.value)
        .then(res => {
            const formattedOptions = res.map(item => ({
                label: item.districtName,
                value: item.districtId
            }));
            setDistinctOptions(formattedOptions);
        })
        .catch(error => {
            console.error("Hata oluştu:", error);
        });
    };

    const handleDistinctSelectChange = (selectedOption) => {
        setSelectedDistinctOption(selectedOption);
        formik.setFieldValue('currentDistrict_ID', selectedOption ? selectedOption.value : '');
    };
  
    

    const handleCurrentTypeSelectChange = (selectedOption) => {
        setSelectedCurrentTypeOption(selectedOption);
        formik.setFieldValue('currentType', selectedOption ? selectedOption.value : '');
        setSelectedCurrentType(selectedOption.value);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Cari Güncelle" breadcrumbItem="Cariler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>

                                    <Row className="mb-3">
                                    <Col lg="6">
                                    <div className="mb-3">
                                            <label htmlFor="currentType" className="form-label">Cari Türü</label>
                                            <Select
                                                value={selectedCurrentTypeOption}
                                                onChange={handleCurrentTypeSelectChange}
                                                options={currentTypeOptions}
                                                placeholder="Cari Türü Seç"
                                                className={`react-select ${formik.touched.currentType && formik.errors.currentType ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.currentType && formik.errors.currentType ? (
                                                <div className="invalid-feedback d-block">{formik.errors.currentType}</div>
                                            ) : null}
                                        </div>
</Col>
<Col lg="6">
                                        <div className="mb-3">
                                            <label htmlFor="currentCode" className="form-label">Cari Kodu</label>
                                            <input
                                                className={`form-control ${formik.touched.currentCode && formik.errors.currentCode ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentCode"
                                                name="currentCode"
                                                placeholder="Cari Kodu Giriniz"
                                                value={formik.values.currentCode}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentCode && formik.errors.currentCode ? (
                                                <div className="invalid-feedback">{formik.errors.currentCode}</div>
                                            ) : null}
                                        </div>
                                        </Col>
                                        </Row>
                                    {/*    <div className="mb-3">
                                            <label htmlFor="currentType" className="form-label">Cari Türü</label>
                                            <input
                                                className={`form-control ${formik.touched.currentType && formik.errors.currentType ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentType"
                                                name="currentType"
                                                placeholder="Cari Türü Giriniz"
                                                value={formik.values.currentType}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentType && formik.errors.currentType ? (
                                                <div className="invalid-feedback">{formik.errors.currentType}</div>
                                            ) : null}
                                        </div>      */}
                                            {formik.values.currentType === 2 && (

 <Row className="mb-3">
 <Col lg="6">
                                        <div className="mb-3">
                                            <label htmlFor="currentName" className="form-label">Cari Ünvanı</label>
                                            <input
                                                className={`form-control ${formik.touched.currentName && formik.errors.currentName ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentName"
                                                name="currentName"
                                                placeholder="Cari Ünvanı Giriniz"
                                                value={formik.values.currentName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentName && formik.errors.currentName ? (
                                                <div className="invalid-feedback">{formik.errors.currentName}</div>
                                            ) : null}
                                        </div>
</Col></Row>
                                            )}
                                                {formik.values.currentType ===  1 && (

<Row className="mb-3">
<Col lg="6">
                                        <div className="mb-3">
                                            <label htmlFor="currentFirstName" className="form-label">Cari Adı</label>
                                            <input
                                                className={`form-control ${formik.touched.currentFirstName && formik.errors.currentFirstName ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentFirstName"
                                                name="currentFirstName"
                                                placeholder="Cari Adı Giriniz"
                                                value={formik.values.currentFirstName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentFirstName && formik.errors.currentFirstName ? (
                                                <div className="invalid-feedback">{formik.errors.currentFirstName}</div>
                                            ) : null}
                                        </div>
</Col><Col lg="6">
                                        <div className="mb-3">
                                            <label htmlFor="currentLastName" className="form-label">Cari Soyadı</label>
                                            <input
                                                className={`form-control ${formik.touched.currentLastName && formik.errors.currentLastName ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentLastName"
                                                name="currentLastName"
                                                placeholder="Cari Soyadı Giriniz"
                                                value={formik.values.currentLastName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentLastName && formik.errors.currentLastName ? (
                                                <div className="invalid-feedback">{formik.errors.currentLastName}</div>
                                            ) : null}
                                        </div>
                                        </Col>
                                        </Row>
                                         )}
                                        <Row className="mb-3">
<Col lg="6">
                                        <div className="mb-3">
                                            <label htmlFor="currentTCKN_VKN" className="form-label">TCKN/VKN No</label>
                                            <input
                                                className={`form-control ${formik.touched.currentTCKN_VKN && formik.errors.currentTCKN_VKN ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentTCKN_VKN"
                                                name="currentTCKN_VKN"
                                                placeholder="TCKN/VKN No Giriniz"
                                                value={formik.values.currentTCKN_VKN}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentTCKN_VKN && formik.errors.currentTCKN_VKN ? (
                                                <div className="invalid-feedback">{formik.errors.currentTCKN_VKN}</div>
                                            ) : null}
                                        </div>
                                        </Col>
                                        <Col lg="6">
                                        <div className="mb-3">
                                            <label htmlFor="currentTaxAdministration" className="form-label">Vergi Adresi</label>
                                            <input
                                                className={`form-control ${formik.touched.currentTaxAdministration && formik.errors.currentTaxAdministration ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentTaxAdministration"
                                                name="currentTaxAdministration"
                                                placeholder="Vergi Adresi Giriniz"
                                                value={formik.values.currentTaxAdministration}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentTaxAdministration && formik.errors.currentTaxAdministration ? (
                                                <div className="invalid-feedback">{formik.errors.currentTaxAdministration}</div>
                                            ) : null}
                                        </div>
                                        </Col>
                                        </Row>

                                        <Row className="mb-3">
                                        <Col lg="6">
                                        <div className="mb-3">
                                            <label htmlFor="currentAddress" className="form-label">Cari Adresi</label>
                                            <input
                                                className={`form-control ${formik.touched.currentAddress && formik.errors.currentAddress ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentAddress"
                                                name="currentAddress"
                                                placeholder="Cari Adresi Giriniz"
                                                value={formik.values.currentAddress}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentAddress && formik.errors.currentAddress ? (
                                                <div className="invalid-feedback">{formik.errors.currentAddress}</div>
                                            ) : null}
                                        </div>
                                        </Col>
                                        <Col lg="6">
                                        <div className="mb-3">
                                            <label htmlFor="currentEmail" className="form-label">Cari Email</label>
                                            <input
                                                className={`form-control ${formik.touched.currentEmail && formik.errors.currentEmail ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentEmail"
                                                name="currentEmail"
                                                placeholder="Cari Email Giriniz"
                                                value={formik.values.currentEmail}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentEmail && formik.errors.currentEmail ? (
                                                <div className="invalid-feedback">{formik.errors.currentEmail}</div>
                                            ) : null}
                                        </div>
                                        </Col>
                                        </Row>
                                        <Row className="mb-3">
                                        <Col lg="6">
                                        <div className="mb-3">
                                            <label htmlFor="currentPhoneNumber" className="form-label">Cari Telefon Numarası</label>
                                            <input
                                                className={`form-control ${formik.touched.currentPhoneNumber && formik.errors.currentPhoneNumber ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentPhoneNumber"
                                                name="currentPhoneNumber"
                                                placeholder="Cari Telefon Numarası Giriniz"
                                                value={formik.values.currentPhoneNumber}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentPhoneNumber && formik.errors.currentPhoneNumber ? (
                                                <div className="invalid-feedback">{formik.errors.currentPhoneNumber}</div>
                                            ) : null}
                                        </div>
</Col>
<Col lg="6">
                                        <div className="mb-3">
                                            <label htmlFor="currentWebsite" className="form-label">Cari Web Sitesi</label>
                                            <input
                                                className={`form-control ${formik.touched.currentWebsite && formik.errors.currentWebsite ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentWebsite"
                                                name="currentWebsite"
                                                placeholder="Cari Web Sitesi Giriniz"
                                                value={formik.values.currentWebsite}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentWebsite && formik.errors.currentWebsite ? (
                                                <div className="invalid-feedback">{formik.errors.currentWebsite}</div>
                                            ) : null}
                                        </div>
                                        </Col>
                                        </Row>
                                        

                                       
{/*
                                        <div className="mb-3">
                                            <label htmlFor="currentGroup_ID" className="form-label">Cari Grup</label>
                                            <input
                                                className={`form-control ${formik.touched.currentGroup_ID && formik.errors.currentGroup_ID ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="currentGroup_ID"
                                                name="currentGroup_ID"
                                                placeholder="Cari Grup Numarası Giriniz"
                                                value={formik.values.currentGroup_ID}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.currentGroup_ID && formik.errors.currentGroup_ID ? (
                                                <div className="invalid-feedback">{formik.errors.currentGroup_ID}</div>
                                            ) : null}
                                        </div>
*/}
<Row className="mb-3">
<Col lg="6">
 <div className="mb-3">
                                            <label htmlFor="currentGroup_ID" className="form-label">Cari Grup</label>
                                            <Select
                                                value={selectedCurrentGroup_IDOption}
                                                onChange={handleCurrentGroup_IDSelectChange}
                                                options={currentGroup_IDOptions}
                                                placeholder="Cari Grubu Seçiniz"
                                                className={`react-select ${formik.touched.currentGroup_ID && formik.errors.currentGroup_ID ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.currentGroup_ID && formik.errors.currentGroup_ID ? (
                                                <div className="invalid-feedback d-block">{formik.errors.currentGroup_ID}</div>
                                            ) : null}
                                        </div>
</Col>
</Row>
<Row className="mb-3">
<Col lg="6">

                                        <div className="mb-3">
                                            <label htmlFor="currentProvince_ID" className="form-label">İl</label>
                                            <Select
                                                value={selectedProvinceOption}
                                                onChange={handleProvinceSelectChange}
                                                options={provinceOptions}
                                                placeholder="İl Seçiniz"
                                                className={`react-select ${formik.touched.currentProvince_ID && formik.errors.currentProvince_ID ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.currentProvince_ID && formik.errors.currentProvince_ID ? (
                                                <div className="invalid-feedback d-block">{formik.errors.currentProvince_ID}</div>
                                            ) : null}
                                        </div>
                                        </Col>
                                        <Col lg="6">

                                        
                                        <div className="mb-3">
                                            <label htmlFor="currentDistrict_ID" className="form-label">İlçe</label>
                                            <Select
                                                value={selectedDistinctOption}
                                                onChange={handleDistinctSelectChange}
                                                options={distinctOptions}
                                                placeholder="İlçe Seçiniz"
                                                className={`react-select ${formik.touched.currentDistrict_ID && formik.errors.currentDistrict_ID ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.currentDistrict_ID && formik.errors.currentDistrict_ID ? (
                                                <div className="invalid-feedback d-block">{formik.errors.currentDistrict_ID}</div>
                                            ) : null}
                                        </div>
                                        </Col>
  </Row>

                                        <div>
                                            <button className="btn btn-primary" type="submit">Güncelle</button>
                                        </div>
                                    </form>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CurrentAccountUpdate;



