import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { APIClient } from '../../helpers/api_helper';
import { toast } from 'react-toastify';

export const GroupUpdateModal = ({user,isChange,group}) => {

    const [open, setOpen] = useState(false);
    const [authUser,setAuthUser] = useState(user);

    function openModal() {
        setOpen(!open)
        console.log(authUser)

    }
    const api = new APIClient();
    const handleChange=()=>{
        isChange()
    }
    useEffect(()=>{
        setAuthUser(user)
    },[user])
    const [groupName, setGroupName] = useState(group.groupName);

    const UserGroup={
        id:group.id,
        groupName:groupName,
       // companyId: authUser.CompanyId ,
        updatedUserId: authUser.Id,
        status:true,
    }
    const handleSubmit = () => {
        api.create("/UserGroup/UpdateUserGroup", UserGroup).then(res => {
            openModal()
            handleChange()
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000 
            });

        }).catch(e => console.log(e))
        toast.error("Güncellenemdi", {
            position: "bottom-right",
            autoClose: 10000 
        });

    }

    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col>

                            <div className="d-flex gap-1">
                            <button className="btn btn-sm btn-warning edit-item-btn"
                                            data-bs-toggle="modal" onClick={() => {
                                                openModal();
                                            }}  data-bs-target="#showModal">Düzenle</button>
                                
                            </div>


                            <Modal
                                isOpen={open}
                                toggle={() => {
                                    openModal();
                                }}

                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Güncelle
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                                Kullanıcı Grubu 
                                            </label>
                                            <input className="form-control" type="text" placeholder='Kullanıcı Grup Adı Giriniz' defaultValue={group.groupName} onChange={x => setGroupName(x.target.value)} />
                                            {/* <input type='hidden' defaultValue={group.id}/> */}

                                        </div>

                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        Kapat
                                    </button>
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary">
                                        Kaydet
                                    </button>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}