import React from 'react'
import { DeleteModal } from '../Common/DeleteModal'
import { ModuleUpdateModal } from './ModuleUpdateModal'
import { Link } from 'react-router-dom'

export const ModuleList = ({user,module,isChanges}) => {
    return (
        <React.Fragment>
            <table className="table align-middle table-nowrap" id="customerTable">
                <thead className="table-light">
                    <tr>
                        <th scope="col" style={{ width: "50px" }}>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                            </div>
                        </th>

                        <th className="sort" data-sort="id">Id</th>
                        <th className="sort" data-sort="moduleName">Modül Adı</th>
                        <th className="sort" data-sort="salesPrice">Modül Fiyatı/Yıllık</th>
                     


                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody className="list form-check-all" >
                    {
                    module.map((item, index) => (
                        <tr key={item.id}>
                            <th scope="row">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                </div>
                            </th>

                            <td data-sort="id">{item.id}</td>
                            <td data-sort="moduleName">{item.moduleName}</td>
                            <td data-sort="salesPrice">{item.salesPrice}</td>


                            <td style={{ width: "300px" }}>
                                <div className="d-flex gap-2" >
                                    <div>
                                    <Link className="btn btn-secondary btn-sm" to={"/firmaModül"}>Modüle Git</Link>

                                    </div>
                                    <div className="edit">
                                        <ModuleUpdateModal user={user} item={item} isChange={isChanges} />
                                    

                                       
                                    </div>
                                    <div className="remove">
                                         <DeleteModal id={item.id} isChange={isChanges} url={"/Module/DeleteModule?id="}/> 
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))
                    }
            </tbody>
        </table>
        </React.Fragment >
    )
}
