import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { APIClient } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export const ModuleUpdateModal = ({ user, isChange, item }) => {
    const [open, setOpen] = useState(false);
    const [authUser, setAuthUser] = useState(user);

    const api = new APIClient();

    useEffect(() => {
        setAuthUser(user);
    }, [user]);

    const initialValues = {
        moduleName: item.moduleName,
        demoTime: item.demoTime,
        salesPrice: item.salesPrice,
    };

    const validationSchema = Yup.object().shape({
        moduleName: Yup.string().required('Modül adı zorunludur'),
        demoTime: Yup.string().required('Demo süresi zorunludur'),
        salesPrice: Yup.string().required('Satış fiyatı zorunludur'),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        const updatedModule = {
            id: item.id,
            moduleName: values.moduleName,
            demoTime: values.demoTime,
            salesPrice: values.salesPrice,
            updatedUserId: authUser.Id,
            status: true,
        };

        api.create("/Module/UpdateModule", updatedModule)
            .then(res => {
                setOpen(false);
                isChange();
                toast.success("Başarıyla güncellendi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .catch(e => {
                console.log(e);
                toast.error("Güncellenemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <div className="d-flex gap-1">
                                <button
                                    className="btn btn-sm btn-warning edit-item-btn"
                                    data-bs-toggle="modal"
                                    onClick={() => setOpen(!open)}
                                    data-bs-target="#showModal"
                                >
                                    Düzenle
                                </button>
                            </div>

                            <Modal
                                isOpen={open}
                                toggle={() => setOpen(!open)}
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Ekle
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setOpen(false)}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div className="mb-3">
                                                    <label htmlFor="moduleName" className="col-form-label">
                                                        Modül Adı
                                                    </label>
                                                    <Field type="text" placeholder="Modül Adı Giriniz" name="moduleName" className="form-control" />
                                                    <ErrorMessage name="moduleName" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="demoTime" className="col-form-label">
                                                        Demo Süresi
                                                    </label>
                                                    <Field type="text" placeholder="Demo Süresi Seçiniz" name="demoTime" className="form-control" />
                                                    <ErrorMessage name="demoTime" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="salesPrice" className="col-form-label">
                                                        Satış Fiyatı
                                                    </label>
                                                    <Field type="text" placeholder="Satış Fiyatı Giriniz" name="salesPrice" className="form-control" />
                                                    <ErrorMessage name="salesPrice" component="div" className="text-danger" />
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-bs-dismiss="modal"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        Kapat
                                                    </button>
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                        Kaydet
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
{/*import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { APIClient } from '../../helpers/api_helper';
import { toast } from 'react-toastify';

export const ModuleUpdateModal = ({user,isChange,item}) => {

    const [open, setOpen] = useState(false);
    const [authUser,setAuthUser] = useState(user);

    function openModal() {
        setOpen(!open)
        console.log(authUser)

    }
    const api = new APIClient();
    const handleChange=()=>{
        isChange()
    }
   
    const [moduleName, setModuleName] = useState(item.moduleName);
    const [demoTime, setDemoTime] = useState(item.demoTime);
    const [salesPrice,setSalesPrice] = useState(item.salesPrice);
    useEffect(()=>{
        setAuthUser(user)
    },[user])
    const Module={
        id:item.id,
        moduleName:moduleName,
        demoTime:demoTime,
        salesPrice:salesPrice,
        //companyId: authUser.CompanyId ,
        //createdUserId: authUser.Id,
        updatedUserId: authUser.Id,
        status:true,
    }
    const handleSubmit = () => {
        console.log(Module)
        api.create("/Module/UpdateModule", Module).then(res => {
            
            openModal()
            handleChange()
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    }).catch(e => console.log(e))
    toast.error("Güncellenemedi", {
        position: "bottom-right",
        autoClose: 10000 
    });

    }

    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col>

                            <div className="d-flex gap-1">
                            <button className="btn btn-sm btn-warning edit-item-btn"
                                            data-bs-toggle="modal" onClick={() => {
                                                openModal();
                                            }}  data-bs-target="#showModal">Düzenle</button>
                                
                            </div>


                            <Modal
                                isOpen={open}
                                toggle={() => {
                                    openModal();
                                }}

                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                         Ekle
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                                Modül Adı 
                                            </label>
                                            <input className="form-control" type="text" placeholder='Modül Adı' defaultValue={item.moduleName} onChange={x => setModuleName(x.target.value)} />

                                        </div>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                               Demo Süresi
                                            </label>
                                            <input className="form-control" type="text" placeholder='Demo Süresi' defaultValue={item.demoTime} onChange={x => setDemoTime(x.target.value)} />

                                        </div>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                               Satış Fiyatı
                                            </label>
                                            <input className="form-control" type="text" placeholder='Satış Fiyatı' defaultValue={item.salesPrice} onChange={x => setSalesPrice(x.target.value)} />

                                        </div>

                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        Kapat
                                    </button>
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary">
                                        Kaydet
                                    </button>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}

*/}



