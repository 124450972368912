import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
const CashboxcollectionUpdatePage = () => {
    //gelen id parametresini al
    const api =new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    const navigate = useNavigate();
    const[id,setId]=useState("")
    const[document_Receipt_No,setDocument_Receipt_No]=useState("")
    const[cashbox_ID,setCashbox_ID]=useState("")
    const[date,setDate]=useState("")
    const[current_ID,setCurrent_ID]=useState("")
    const[explanation,setExplanation]=useState("")
    

    useEffect(()=>{
        api.get('/Cashboxcollection/GetCashboxcollectionById?id='+_id) 
        .then(res => {
        
           
            setId(res.id)
            setDocument_Receipt_No(res.document_Receipt_No)
            setCashbox_ID(res.cashbox_ID)
            setDate(res.date)
            setCurrent_ID(res.current_ID)
            setExplanation(res.explanation)

        })
        .catch(err => console.log(err))
        
    }, [])

  
    document.title = "KasaTahsilat Guncelle  | İlekaSoft";

  
    
    const handleSubmit = () => {
        api.create("/Cashboxcollection/UpdateCashboxcollection", Cashboxcollection)
            .then(res => {
                console.log("başarılı");
                navigate(`/KasaTahsilatlar`);
              //  window.location.href = `/KasaTahsilatlar`;
                toast.success("Başarıyla güncellendi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Güncellenemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        // name değerine göre ilgili state'i güncelle
        switch (name) {
            case "Document_Receipt_No":
                setDocument_Receipt_No(value);
                break;
            case "Cashbox_ID":
                setCashbox_ID(value);
                break;
            case "Date":
                setDate(value);
                break;
            case "Current_ID":
                setCurrent_ID(value);
                break;
            case "Explanation":
                setExplanation(value);
                break;
            default:
                break;
        }
    };
    


    
    const Cashboxcollection={
        id:id,
        document_Receipt_No:document_Receipt_No,
        cashbox_ID:cashbox_ID,
        date:date,
        current_ID:current_ID,
        explanation:explanation,
    }


    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="KasaTahsilatlar" breadcrumbItem="KasaTahsilat Guncelle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                   
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Document_Receipt_No    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Document_Receipt_No"
                                        value={Cashboxcollection.document_Receipt_No}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Cashbox_ID    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Cashbox_ID"
                                        value={Cashboxcollection.cashbox_ID}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Date      
                                            </label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="Date"
                                        value={Cashboxcollection.date}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Current_ID      
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Current_ID"
                                        value={Cashboxcollection.current_ID}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Explanation    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Explanation"
                                        value={Cashboxcollection.explanation}
                                        onChange={handleChange}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Guncelle
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CashboxcollectionUpdatePage;









