import React, { useState, useEffect } from "react";
import InvoiceCurrentSelect from "../../components/Invoice/InvoiceCurrentSelect";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import CalculativeEarchive from "../../components/Invoice/CalculativeEarchive";

const EArchiveAdd = () => {
    const api = new APIClient();
    const [user, setUser] = useState([]);
    const [label, setLabel] = useState([]);
    const [istisna, setIstisna] = useState([]);
    const [tevkifat, setTevkifat] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [currentID, setCurrentID] = useState(null);
    const authUser = getLoggedinUser();

    useEffect(() => {
        fetchData();
        ticketData();
        IstisnaData();
        TevkifatData();
        bankData();
    }, []); // useEffect sadece bileşenin ilk render edildiğinde çağrılacak

    const fetchData = () => {
        api.get("/CurrentAccount/GetEArchiveCurrent?id="+authUser.CompanyId)
            .then(res => {
                setUser(res);
            })
            .catch(er => console.log(er))
    }
    const ticketData = () => {
        api.get("/Label/GetAll?id=" + authUser.CompanyId)
            .then(res => {
                setLabel(res);
            })
            .catch(er => console.log(er))
    }
    const bankData =()=>[
        api.get("/Bank/GetAll?companyId="+authUser.CompanyId)
        .then(res => {
            setBankList(res);
        })
        .catch(er => console.log(er))
    ]
    const IstisnaData = () => {
        api.get("/İstisnalar/GetAll")
            .then(res => {
                setIstisna(res);
            })
            .catch(er => console.log(er))
    }
    const TevkifatData = () => {
        api.get("/Tevkifatlar/GetAll")
            .then(res => {
                setTevkifat(res);
            })
            .catch(er => console.log(er))
    }
 
    const handleSelectedUser = (selectedUser) => {
        setCurrentID(selectedUser.id);
    };

    return (
        <React.Fragment>
            <InvoiceCurrentSelect User={user} onSelectUser={handleSelectedUser} />
            {currentID == null ? "" : <CalculativeEarchive LabelData={label} IstisnaData={istisna} TevkifatData={tevkifat} CurrentID={currentID} BankData={bankList}/>}
        </React.Fragment>
    )
}

export default EArchiveAdd;
