import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Card, CardBody, Col, Container, Row  , Label , Select} from 'reactstrap';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import './styles.css'; // Import your CSS file here
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon';
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown';

const validationSchema = Yup.object().shape({
    currentType: Yup.string().required('Cari türü gereklidir.'),
    //currentName: Yup.string().required('Cari ünvanı gereklidir.'),
    //currentFirstName: Yup.string().required('Cari adı gereklidir.'),
    //currentLastName: Yup.string().required('Cari soyadı gereklidir.'),    

    currentFirstName: Yup.string().test(
      'currentFirstName',
      'Bu alan gereklidir',
      function (value) {
          const { currentType } = this.parent;
          if (currentType === '1') {
              return value !== undefined && value !== '';
          }
          return true;
      }
  ),
  currentLastName: Yup.string().test(
      'currentLastName',
      'Bu alan gereklidir',
      function (value) {
          const { currentType } = this.parent;
          if (currentType === '1') {
              return value !== undefined && value !== '';
          }
          return true;
      }
  ),
  currentName: Yup.string().test(
    'currentName',
    'Bu alan gereklidir',
    function (value) {
        const { currentType } = this.parent;
        if (currentType === '2') {
            return value !== undefined && value !== '';
        }
        return true;
    }
),
     currentCode: Yup.string().required('Cari kodu gereklidir.'),
     currentTCKN_VKN: Yup.string().required('TCKN/VKN numarası gereklidir.'),
     currentTaxAdministration: Yup.string().required('Vergi dairesi gereklidir.'),
    // province: Yup.number().required('İl gereklidir.'),
    // district: Yup.number().required('İlçe gereklidir.'),
     currentAddress: Yup.string().required('Adres gereklidir.'),
     currentWebsite: Yup.string().required('Web sitesi gereklidir.'),
     currentEmail: Yup.string().email('Geçerli bir email adresi giriniz.').required('Email adresi gereklidir.'),
     currentPhoneNumber: Yup.string().required('Telefon numarası gereklidir.'),
     currentGroup_ID: Yup.number().required('Cari grubu gereklidir.'),
});

const CurrentAccountAdd = () => {
  document.title = "Cari Ekle | İlekaSoft";
  const api = new APIClient();
  const navigate = useNavigate();
  const authUser = getLoggedinUser();
  const [userGrupInfo, setUserGrupInfo] = useState([]);

  const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    const [provinceId, setProvinceId] = useState(null); // Checkbox state
    const [districtId, setDistrictId] = useState(null); // Checkbox state

  useEffect(() => {
    fetchData();
  }, []);

  const companyId = authUser.CompanyId;

  const fetchData = () => {
    api.get(`/CurrentGroup/GetAll?companyId=${companyId}`)
      .then(res => {
        setUserGrupInfo(res);
      })
      .catch(er => console.log(er));
  };

  const handleProvinceChange = (selectedOption) => {
    setSelectedProvinceOption(selectedOption);
    setSelectedDistrictOption(null);
};

const handleDistrictChange = (selectedOption) => {
    setSelectedDistrictOption(selectedOption);
};

const [provinceError, setProvinceError] = useState(false);
const [districtError, setDistrictError] = useState(false);


  const formik = useFormik({
    initialValues: {
      currentType: '',
      currentName: '',
      currentFirstName: '',
      currentLastName: '',
      currentCode: '',
      currentTCKN_VKN: '',
      currentTaxAdministration: '',
      currentProvince_ID: '',
      currentDistrict_ID: '',
      currentAddress: '',
      currentWebsite: '',
      currentEmail: '',
      currentPhoneNumber: '',
      currentGroup_ID: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {

    values.currentType = parseInt(values.currentType);
    values.currentGroup_ID = parseInt(values.currentGroup_ID);
    values.currentProvince_ID = provinceId;
    values.currentDistrict_ID = districtId;
    values.alisPK = "default";

    if(values.currentProvince_ID == null)
    {
setProvinceError(true);

    }
    if(values.currentDistrict_ID == null)
    {
      setDistrictError(true);

    }

const model = 
 {
  "status": true,
  "companyId": parseInt(companyId),
  "createdUserId": parseInt(authUser.Id),
  "updatedUserId": parseInt(authUser.Id),
  "createdTime": new Date().toISOString(),
  "updatedTime": new Date().toISOString(),
  "currentType": values.currentType,
  "currentName": values.currentName,
  "currentFirstName": values.currentFirstName,
  "currentLastName": values.currentLastName,
  "currentCode": values.currentCode,
  "currentTCKN_VKN": values.currentTCKN_VKN,
  "currentTaxAdministration": values.currentTaxAdministration,
  "currentProvince_ID": provinceId,
  "currentDistrict_ID": districtId,
  "currentAddress": values.currentAddress,
  "currentWebsite": values.currentWebsite,
  "currentEmail": values.currentEmail,
  "currentPhoneNumber": values.currentPhoneNumber,
  "currentGroup_ID": values.currentGroup_ID
}

    console.log('carinin veirleri gelecek');
    console.log(values);
    console.log('model birazdan gösterilecek');
    console.log(model);
    api.create("/CurrentAccount/AddCurrentAccount", model)
      .then(res => {
        toast.success("Başarıyla kaydedildi", {
          position: "bottom-right",
          autoClose: 10000
        });
        navigate('/Cariler');
      })
      .catch(err => {
        toast.error("Kaydedilemedi", {
          position: "bottom-right",
          autoClose: 10000
        });
      });
  };

  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Cari Ekle" breadcrumbItem="Cariler" />
          <Row>
            <Col>
              <Card>
              <CardBody style={{ margin: "35px" }}>
  <form onSubmit={formik.handleSubmit}>
    <Row className="mb-3">
      <Col lg="6">
        <label htmlFor="currentType" className="col-form-label">Cari Türü</label>
        <div className="col-md-12">
          <select
            className="form-select"
            id="currentType"
            {...formik.getFieldProps('currentType')}
          >
            <option>Lütfen cari türü seçiniz</option>
            <option value="1">Bireysel</option>
            <option value="2">Kurumsal</option>
          </select>
          {formik.touched.currentType && formik.errors.currentType ? (
            <div className="error-message">{formik.errors.currentType}</div>
          ) : null}
        </div>
      </Col>
      <Col lg="6">
        <label htmlFor="currentCode" className="col-form-label">Cari Kodu</label>
        <div className="col-md-12">
          <input
            className="form-control"
            type="text"
            placeholder="Cari Kodu"
            id="currentCode"
            {...formik.getFieldProps('currentCode')}
          />
          {formik.touched.currentCode && formik.errors.currentCode ? (
            <div className="error-message">{formik.errors.currentCode}</div>
          ) : null}
        </div>
      </Col>
    </Row>

    {formik.values.currentType === '2' && (
      <Row className="mb-3">
        <Col lg="6">
          <label htmlFor="currentName" className="col-form-label">Cari Ünvanı</label>
          <div className="col-md-12">
            <input
              className="form-control"
              type="text"
              placeholder="Cari Ünvanı"
              id="currentName"
              {...formik.getFieldProps('currentName')}
            />
            {formik.touched.currentName && formik.errors.currentName ? (
              <div className="error-message">{formik.errors.currentName}</div>
            ) : null}
          </div>
        </Col>
        <Col lg="6"></Col> {/* Empty column to balance the row */}
      </Row>
    )}

    {formik.values.currentType === '1' && (
      <Row className="mb-3">
        <Col lg="6">
          <label htmlFor="currentFirstName" className="col-form-label">Cari Adı</label>
          <div className="col-md-12">
            <input
              className="form-control"
              type="text"
              placeholder="Cari Adı"
              id="currentFirstName"
              {...formik.getFieldProps('currentFirstName')}
            />
            {formik.touched.currentFirstName && formik.errors.currentFirstName ? (
              <div className="error-message">{formik.errors.currentFirstName}</div>
            ) : null}
          </div>
        </Col>
        <Col lg="6">
          <label htmlFor="currentLastName" className="col-form-label">Cari Soyadı</label>
          <div className="col-md-12">
            <input
              className="form-control"
              type="text"
              placeholder="Cari Soyadı"
              id="currentLastName"
              {...formik.getFieldProps('currentLastName')}
            />
            {formik.touched.currentLastName && formik.errors.currentLastName ? (
              <div className="error-message">{formik.errors.currentLastName}</div>
            ) : null}
          </div>
        </Col>
      </Row>
    )}

    <Row className="mb-3">
      <Col lg="6">
        <label htmlFor="currentTCKN_VKN" className="col-form-label">TCKN/VKN No</label>
        <div className="col-md-12">
          <input
            className="form-control"
            type="text"
            placeholder="TCKN/VKN No"
            id="currentTCKN_VKN"
            {...formik.getFieldProps('currentTCKN_VKN')}
          />
          {formik.touched.currentTCKN_VKN && formik.errors.currentTCKN_VKN ? (
            <div className="error-message">{formik.errors.currentTCKN_VKN}</div>
          ) : null}
        </div>
      </Col>
      <Col lg="6">
        <label htmlFor="currentTaxAdministration" className="col-form-label">Vergi Dairesi</label>
        <div className="col-md-12">
          <input
            className="form-control"
            type="text"
            placeholder="Vergi Dairesi"
            id="currentTaxAdministration"
            {...formik.getFieldProps('currentTaxAdministration')}
          />
          {formik.touched.currentTaxAdministration && formik.errors.currentTaxAdministration ? (
            <div className="error-message">{formik.errors.currentTaxAdministration}</div>
          ) : null}
        </div>
      </Col>
    </Row>

    <Row className="mb-3">
      <Col lg="6">
        <label htmlFor="currentAddress" className="col-form-label">Cari Adresi</label>
        <div className="col-md-12">
          <input
            className="form-control"
            type="text"
            placeholder="Cari Adresi"
            id="currentAddress"
            {...formik.getFieldProps('currentAddress')}
          />
          {formik.touched.currentAddress && formik.errors.currentAddress ? (
            <div className="error-message">{formik.errors.currentAddress}</div>
          ) : null}
        </div>
      </Col>
      <Col lg="6">
        <label htmlFor="currentEmail" className="col-form-label">Cari Email</label>
        <div className="col-md-12">
          <input
            className="form-control"
            type="text"
            placeholder="Cari Email"
            id="currentEmail"
            {...formik.getFieldProps('currentEmail')}
          />
          {formik.touched.currentEmail && formik.errors.currentEmail ? (
            <div className="error-message">{formik.errors.currentEmail}</div>
          ) : null}
        </div>
      </Col>
    </Row>

    <Row className="mb-3">
      <Col lg="6">
        <label htmlFor="currentPhoneNumber" className="col-form-label">Cari Telefon Numarası</label>
        <div className="col-md-12">
          <input
            className="form-control"
            type="text"
            placeholder="Cari Telefon Numarası"
            id="currentPhoneNumber"
            {...formik.getFieldProps('currentPhoneNumber')}
          />
          {formik.touched.currentPhoneNumber && formik.errors.currentPhoneNumber ? (
            <div className="error-message">{formik.errors.currentPhoneNumber}</div>
          ) : null}
        </div>
      </Col>
      <Col lg="6">
        <label htmlFor="currentWebsite" className="col-form-label">Cari Web Sitesi</label>
        <div className="col-md-12">
          <input
            className="form-control"
            type="text"
            placeholder="Cari Web Sitesi"
            id="currentWebsite"
            {...formik.getFieldProps('currentWebsite')}
          />
          {formik.touched.currentWebsite && formik.errors.currentWebsite ? (
            <div className="error-message">{formik.errors.currentWebsite}</div>
          ) : null}
        </div>
      </Col>
    </Row>

    <Row className="mb-3">
      <Col lg="6">
        <label htmlFor="currentGroup_ID" className="col-form-label">Cari Grup</label>
        <div className="col-md-12">
          <select
            className="form-select"
            id="currentGroup_ID"
            {...formik.getFieldProps('currentGroup_ID')}
          >
            <option>Lütfen cari grubu seçiniz</option>
            {userGrupInfo.map((userGrup, index) => (
              <option key={index} value={userGrup.id}>{userGrup.currentGroupName}</option>
            ))}
          </select>
          {formik.touched.currentGroup_ID && formik.errors.currentGroup_ID ? (
            <div className="error-message">{formik.errors.currentGroup_ID}</div>
          ) : null}
        </div>
      </Col>
      <Col lg="6"></Col> {/* Empty column to balance the row */}
    </Row>

    <Row className="mb-3">
      <Col md={6}>
        <label className="col-form-label">İl</label>
        <div className="col-md-12">
          <Province
            selectedOption={selectedProvinceOption}
            handleChange={(selectedOption) => {
              handleProvinceChange(selectedOption);
              setProvinceId(selectedOption.value);
              setProvinceError(false);

            }}
          />
            {provinceError && <div className="error-message">Lütfen il seçiniz</div>}

        </div>
      </Col>
      <Col md={6}>
        <label className="col-form-label">İlçe</label>
        <div className="col-md-12">
          <District
            province={selectedProvinceOption}
            selectedOption={selectedDistrictOption}
            handleChange={(selectedOption) => {
              handleDistrictChange(selectedOption);
              setDistrictId(selectedOption.value);
              setDistrictError(false);

            }}
            
          />
            {districtError && <div className="error-message">Lütfen ilçe seçiniz</div>}

        </div>
      </Col>
    </Row>

    <button className="btn btn-primary" type="submit">Güncelle</button>
  </form>
</CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CurrentAccountAdd;



