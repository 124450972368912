import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { APIClient } from '../../helpers/api_helper';

export const StockGroupAddModal = ({user,addChange}) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [authUser, setAuthUser] = useState(user);

    useEffect(() => {
        setAuthUser(user);
    }, [user]);

    const initialValues = {
        stockGroupName: '',
        stockGroupCode: '',
    };

    const validationSchema = Yup.object().shape({
        stockGroupName: Yup.string().required('Stok Grup Adı zorunludur.'),
        stockGroupCode: Yup.string().required('Stok Grup Kodu zorunludur.'),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        const UserGroup = {
            ...values,
            companyId: authUser.CompanyId,
            createdUserId: authUser.Id,
            updatedUserId: authUser.Id,
            status: true,
        };

        const api = new APIClient();
        api.create("/StockGroup/AddStockGroup", UserGroup)
            .then(res => {
                console.log("başarılı");
                window.location.href = `/stokGruplari`;

//                navigate('/stokGruplari');
                toast.success("Başarıyla kaydedildi", { position: "bottom-right", autoClose: 10000 });
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", { position: "bottom-right", autoClose: 10000 });
            })
            .finally(() => {
                setSubmitting(false);
                setOpen(false);
            });
    };

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col>
                        <div className="d-flex gap-1">
                            <Button color="success" className="add-btn" onClick={() => setOpen(!open)}>
                                <i className="ri-add-line align-bottom me-1"></i>Ekle
                            </Button>
                        </div>
                        <Modal isOpen={open} toggle={() => setOpen(!open)}>
                            <div className="modal-header">
                                <h5 className="modal-title">Stok Grubu Ekle</h5>
                                <button type="button" className="btn-close" onClick={() => setOpen(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="mb-3">
                                                <label htmlFor="stockGroupName" className="col-form-label">Stok Grubu Adı</label>
                                                <Field type="text" name="stockGroupName" className="form-control" placeholder="Stok Grup Adı Giriniz" />
                                                <ErrorMessage name="stockGroupName" component="div" className="text-danger" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="stockGroupCode" className="col-form-label">Stok Grup Kodu</label>
                                                <Field type="text" name="stockGroupCode" className="form-control" placeholder="Stok Grup Kodu Giriniz" />
                                                <ErrorMessage name="stockGroupCode" component="div" className="text-danger" />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" onClick={() => setOpen(false)}>Kapat</button>
                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Kaydet</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

