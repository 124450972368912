import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { APIClient } from '../../helpers/api_helper'
export const Province = ({ selectedOption, handleChange }) => {
  const api = new APIClient()

  const [provinceOption, setProvinceOptions] = useState([])
  useEffect(() => {


    api.get("/Province/GetAll").then(res => {
      const formattedOptions = res.map(c => ({
        value: c.id,
        label: c.name
      }))
      setProvinceOptions(formattedOptions)
    })
  },[])

  const turkishCharacterRegex = keyword => keyword
    .replace(/[ıİiI]/g, '[ıİiI]')
    .replace(/[şŞsS]/g, '[şŞsS]')
    .replace(/[çÇcC]/g, '[çÇcC]')
    .replace(/[ğĞgG]/g, '[ğĞgG]')
    .replace(/[öÖoO]/g, '[öÖoO]')
    .replace(/[üÜuU]/g, '[üÜuU]');


  const customFilter = (option, searchText) => ((turkishCharacterRegex(option.data.label)).toLowerCase().includes(turkishCharacterRegex(searchText).toLowerCase()));
  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={provinceOption}
      filterOption={customFilter}
      placeholder="İl Seç"

    />

  )

}
