import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export const CurrentGroupAddModal = ({ user, addChange }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [authUser, setAuthUser] = useState(user);

    function openModal() {
        setOpen(!open);
        console.log(authUser);
    }

    const api = new APIClient();

    useEffect(() => {
        setAuthUser(user);
    }, [user]);

    const validationSchema = Yup.object().shape({
        groupName: Yup.string().required('Cari Grup Adı gereklidir.'),
        groupCode: Yup.string().required('Cari Grup Kodu gereklidir.'),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        const userGroup = {
            currentGroupName: values.groupName,
            currentGroupCode: values.groupCode,
            companyId: authUser.CompanyId,
            createdUserId: authUser.Id,
            updatedUserId: authUser.Id,
            status: true,
        };

        api.create("/CurrentGroup/AddCurrentGroup", userGroup)
            .then(res => {
                console.log("başarılı");
                //navigate('/cariGruplari');
                window.location.href = `/cariGruplari`;
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .finally(() => {
                setSubmitting(false);
                setOpen(false);
            });
    };

    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <div className="d-flex gap-1">
                                <Button color="success" className="add-btn" onClick={openModal} id="create-btn">
                                    <i className="ri-add-line align-bottom me-1"></i>Ekle
                                </Button>
                            </div>

                            <Modal isOpen={open} toggle={openModal}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Ekle</h5>
                                    <button type="button" className="btn-close" onClick={openModal} aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <Formik
                                        initialValues={{ groupName: '', groupCode: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div className="mb-3">
                                                    <label htmlFor="groupName" className="col-form-label">Cari Grubu</label>
                                                    <Field type="text" name="groupName" className="form-control" placeholder="Cari Grup Adı Giriniz" />
                                                    <ErrorMessage name="groupName" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="groupCode" className="col-form-label">Cari Grup Kodu</label>
                                                    <Field type="text" name="groupCode" className="form-control" placeholder="Cari Grup Kodu Giriniz" />
                                                    <ErrorMessage name="groupCode" component="div" className="text-danger" />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" onClick={openModal}>Kapat</button>
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Kaydet</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
{/* import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

export const CurrentGroupAddModal = ({user,addChange}) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [authUser,setAuthUser] = useState(user);
    function openModal() {
        setOpen(!open)
        console.log(authUser)
    }
    const api = new APIClient();
    const handleChange=()=>{
        addChange()
    }
    const [groupName, setGroupName] = useState("");
    const [groupCode, setGroupCode] = useState("");

    useEffect(()=>{
        setAuthUser(user)
    },[user])
    const UserGroup={
        currentGroupName:groupName,
        currentGroupCode:groupCode,
        companyId: authUser.CompanyId ,
        createdUserId: authUser.Id,
        updatedUserId: authUser.Id,
        status:true,
    }
 

    const handleSubmit = () => {
        api.create("/CurrentGroup/AddCurrentGroup", UserGroup)
            .then(res => {
                console.log("başarılı");
                //window.location.href = `/cariGruplari`;
                navigate('/cariGruplari')
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }



    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col>

                            <div className="d-flex gap-1">
                                <Button color="success" className="add-btn" onClick={() => {
                                    openModal();
                                }} id="create-btn"><i className="ri-add-line align-bottom me-1"></i>Ekle</Button>
                               {/* <Button color="soft-danger"
                                // onClick="deleteMultiple()"
                            ><i className="ri-delete-bin-2-line"></i></Button>*/}


                            {/*
                            </div>


                            <Modal
                                isOpen={open}
                                toggle={() => {
                                    openModal();
                                }}

                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Ekle
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                                Cari Grubu 
                                            </label>
                                            <input className="form-control" type="text" placeholder='Cari Grup Adı' onChange={x => setGroupName(x.target.value)} />

                                        </div>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                                Cari Grup Kodu
                                            </label>
                                            <input className="form-control" type="text" placeholder='Cari Grup Kodu' onChange={x => setGroupCode(x.target.value)} />

                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        Kapat
                                    </button>
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary">
                                        Kaydet
                                    </button>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}


*/}

