import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { toast, ToastContainer } from 'react-toastify';
import { Navigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
const CashboxtransactionsUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    
    const navigate = useNavigate();

    const authUser = getLoggedinUser();

    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [current_ID, setCurrent_ID] = useState("");

    const [selectCashbox, setSelectCashbox] = useState(null);
    const [cashbox, setCashbox] = useState([]);
    const [cashbox_ID, setCashbox_ID] = useState("");
    
    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCurrent_ID(selectedOption ? selectedOption.value : '');
    };

    const handleCashbox = (selectedOption) => {
        setSelectCashbox(selectedOption);
        setCashbox_ID(selectedOption ? selectedOption.value : '');
    };

    const [initialValues, setInitialValues] = useState({
        id: "",
        paymentOrCollection: "",
        document_Receipt_No: "",
        cashbox_ID: "",
        date: "",
        current_ID: "",
        explanation: "",
        amount: ""
    });

    useEffect(() => {
        api.get('/Cashboxtransactions/GetCashboxtransactionsById?id=' + _id)
            .then(res => {
                setInitialValues({
                    id: res.id,
                    paymentOrCollection: res.paymentOrCollection,
                    document_Receipt_No: res.document_Receipt_No,
                    cashbox_ID: res.cashbox_ID,
                    date: res.date.split('T')[0],
                    current_ID: res.current_ID,
                    explanation: res.explanation,
                    amount: res.amount,
                });
                setSelectCurrentAccount({ label: res.current_Name, value: res.current_ID });
                setSelectCashbox({ label: res.cashbox_Name, value: res.cashbox_ID });
            })
            .catch(err => console.log(err));
    }, [_id]);
    
    useEffect(() => {
        var companyId = authUser.CompanyId;
        api.get("/CurrentAccount/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id
            }));
            setCurrentAccount(formattedOptions);
        });      
        api.get("/Cashbox/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id
            }));
            setCashbox(formattedOptions);
        });      
    }, []);

    document.title = "Kasa İşlemi Güncelle | İlekaSoft";

    const validationSchema = Yup.object({
        paymentOrCollection: Yup.string().required('Bu alan gereklidir'),
        document_Receipt_No: Yup.string().required('Bu alan gereklidir'),
        cashbox_ID: Yup.string().required('Bu alan gereklidir'),
        date: Yup.date().required('Bu alan gereklidir'),
        current_ID: Yup.string().required('Bu alan gereklidir'),
        explanation: Yup.string().required('Bu alan gereklidir'),
        amount: Yup.number().required('Bu alan gereklidir').positive('Pozitif bir değer olmak zorundadır')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const updatedValues = {
                ...values,
                current_ID: selectCurrentAccount.value,
                cashbox_ID: selectCashbox.value
            };

            const res = await api.create("/Cashboxtransactions/UpdateCashboxtransactions", updatedValues);
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000
            });
            navigate(`/KasaIslemleri`);
//            window.location.href = `/KasaIslemleri`;

        } catch (err) {
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
            setSubmitting(false);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Kasa İşlemi Güncelle" breadcrumbItem="Kasa İşlemleri" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <label htmlFor="paymentOrCollection" className="col-md-2 col-form-label">Ödeme / Tahsilat</label>
                                                    <Field
                                                        as="select"
                                                        name="paymentOrCollection"
                                                        className="form-control"
                                                    >
                                                        <option value="Kasa Ödeme">Kasa Ödeme</option>
                                                        <option value="Tahsilat">Tahsilat</option>
                                                    </Field>
                                                    <ErrorMessage name="paymentOrCollection" component="div" className="text-danger" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="document_Receipt_No" className="col-md-2 col-form-label">Belge / Makbuz No</label>
                                                    <Field
                                                        type="text"
                                                        name="document_Receipt_No"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="document_Receipt_No" component="div" className="text-danger" />
                                                </div>
                                         
                                                <div className="mb-3">
                                                    <label htmlFor="current_ID" className="form-label">Cari Adı</label>
                                                    <Select
                                                        value={selectCurrentAccount}
                                                        onChange={handleCurrentAccount}
                                                        options={currentAccount}
                                                        placeholder="Cari Seç"
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="date" className="col-md-2 col-form-label">Tarih</label>
                                                    <Field
                                                        type="date"
                                                        name="date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="date" component="div" className="text-danger" />
                                                </div>
                                              
                                                 <div className="mb-3">
                                                    <label htmlFor="cashbox_ID" className="form-label">Kasa Adı</label>
                                                    <Select
                                                        value={selectCashbox}
                                                        onChange={handleCashbox}
                                                        options={cashbox}
                                                        placeholder="Kasa Seç"
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="explanation" className="col-md-2 col-form-label">Açıklama</label>
                                                    <Field
                                                        type="text"
                                                        name="explanation"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="explanation" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="amount" className="col-md-2 col-form-label">Tutar</label>
                                                    <Field
                                                        type="number"
                                                        name="amount"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="amount" component="div" className="text-danger" />
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary waves-effect waves-light"
                                                    disabled={isSubmitting}
                                                >
                                                    Güncelle
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CashboxtransactionsUpdatePage;






{/*import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from "react-router-dom";

const CashboxtransactionsUpdatePage = () => {
    //gelen id parametresini al
    const api =new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    
    const[id,setId]=useState("")
    const[paymentOrCollection,setPaymentOrCollection]=useState("")
    const[document_Receipt_No,setDocument_Receipt_No]=useState("")
    const[cashbox_ID,setCashbox_ID]=useState("")
    const[date,setDate]=useState("")
    const[current_ID,setCurrent_ID]=useState("")
    const[explanation,setExplanation]=useState("")
    const[amount,setAmount]=useState("")


    useEffect(()=>{
        api.get('/Cashboxcollection/GetCashboxcollectionById?id='+_id) 
        .then(res => {
        
           
            setId(res.id)
            setPaymentOrCollection(res.paymentOrCollection)
            setDocument_Receipt_No(res.document_Receipt_No)
            setCashbox_ID(res.cashbox_ID)
            setDate(res.date)
            setCurrent_ID(res.current_ID)
            setExplanation(res.explanation)
            setAmount(res.amount)

        })
        .catch(err => console.log(err))
        
    }, [])

  
    document.title = "Kasa İşlemi Guncelle  | İlekaSoft";

  
    
    const handleSubmit = () => {
        api.create("/Cashboxtransactions/UpdateCashboxtransactions", Cashboxtransactions)
            .then(res => {
                console.log("başarılı");
                window.location.href = `/KasaIslemleri`;
                toast.success("Başarıyla güncellendi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Güncellenemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        // name değerine göre ilgili state'i güncelle
        switch (name) {
            case "PaymentOrCollection":
                setPaymentOrCollection(value);
                break;
            case "Document_Receipt_No":
                setDocument_Receipt_No(value);
                break;
            case "Cashbox_ID":
                setCashbox_ID(value);
                break;
            case "Date":
                setDate(value);
                break;
            case "Current_ID":
                setCurrent_ID(value);
                break;
            case "Explanation":
                setExplanation(value);
                break;
                case "Amount":
                    setAmount(value);
                    break;
            default:
                break;
        }
    };
    


    
    const Cashboxtransactions={
        id:id,
        paymentOrCollection:paymentOrCollection,
        document_Receipt_No:document_Receipt_No,
        cashbox_ID:cashbox_ID,
        date:date,
        current_ID:current_ID,
        explanation:explanation,
        amount:amount
    }


    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Kasa İşlemi Güncelle" breadcrumbItem="Kasa İşlemleri" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                   
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                PaymentOrCollection    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="PaymentOrCollection"
                                        value={Cashboxtransactions.paymentOrCollection}
                                        onChange={handleChange}
                                    />

                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Document_Receipt_No    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Document_Receipt_No"
                                        value={Cashboxtransactions.document_Receipt_No}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Cashbox_ID    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Cashbox_ID"
                                        value={Cashboxtransactions.cashbox_ID}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Date      
                                            </label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="Date"
                                        value={Cashboxtransactions.date}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Current_ID      
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Current_ID"
                                        value={Cashboxtransactions.current_ID}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Explanation    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Explanation"
                                        value={Cashboxtransactions.explanation}
                                        onChange={handleChange}
                                    />
                                     <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                     Amount    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Amount"
                                        value={Cashboxtransactions.amount}
                                        onChange={handleChange}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Guncelle
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CashboxtransactionsUpdatePage;
*/}
