

import React, { useState } from 'react'
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { APIClient } from '../../helpers/api_helper';

export const InvoiceSendingModal = ({isChange,id}) => {
    const [open, setOpen] = useState(false);
    function openModal() {
        setOpen(!open)
    }
    const api = new APIClient();
    const handleChange=()=>{
        isChange()
    }
    const handleSubmit = () => {
        api.create("/Invoices/DeleteInvoice?invoiceID="+ id ).then(res => {
            openModal()
            handleChange()
        }).catch(e => console.log(e))
    }
    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col>
                        <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => {
                                    openModal();
                                }} data-bs-toggle="modal" data-bs-target="#deleteRecordModal"><i className="fas fa-paper-plane"></i></button>
                           


                            <Modal
                                isOpen={open}
                                toggle={() => {
                                    openModal();
                                }}

                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                    E-Faturayı Gönder
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <input type='hidden' defaultValue={id}/>
                                    </form>
                                <p>Bu e-faturayı göndermek istediğinize emin misiniz?</p>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        Kapat
                                    </button>
                                    <button type="button" onClick={handleSubmit} className="btn btn-danger">
                                        E-Faturayı Gönder
                                    </button>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}