import React, { useEffect, useState } from 'react'
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon'
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown'
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'

const CompanyUserAddModal = ({ userInfo, lastcompany }) => {
    const api = new APIClient()
    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    const [firstName, setFisrtName] = useState("")
    const [userName, setUserName] = useState("")
    const [password, setpassword] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [telNo, setTelNo] = useState("")
    const [tcNo, setTcNo] = useState("")
    const [province, setProvince] = useState("")
    const [district, setDistrict] = useState("")
    const [userGroups, setUserGroups] = useState([])
    const [userGroup, setUserGroup] = useState("")
    const [selectUserGroup, setSelectUserGroup] = useState(null)

    const User = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        userName: userName,
        telNo: telNo,
        password: password,
        tcNo: tcNo,
        ilId: province,
        ilceId: district,
        companyId: lastcompany,
        createdUserId: userInfo.Id,
        updatedUserId: userInfo.Id,
        userGroupId: userGroup,
        status: true,
    }
    var companyId = authUser.CompanyId;

    useEffect(() => {
        api.get("/UserGroup/UserGroupGetAll?companyId="+companyId).then(res => {
            const formattedOptions = res
                .filter(item => item.groupName === "Admin")
                .map(item => ({
                    label: item.groupName,
                    value: item.id
                }));
            setUserGroups(formattedOptions);
        })
        console.log("deneme")
    }, [])

    const handleUserGroup = (selectedOption) => {
        setSelectUserGroup(selectedOption)
        console.log(selectedOption.value)
        setUserGroup(selectedOption == null ? selectedOption : selectedOption.value)
    }

    const handleProvinceChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption)
        setProvince(selectedOption == null ? selectedOption : selectedOption.value)
        setSelectedDistrictOption(null)
    }

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrictOption(selectedOption)
        setDistrict(selectedOption == null ? selectedOption : selectedOption.value)
    }

    const handleSubmit = () => {
        api.create("/Auth/Register", User).then(res => {
            console.log(res)
            if (res) {
                toast.success("Ekleme İşlemi Başarılı");
                setTimeout(() => {
                    navigate("/Kullanici");
                }, 1000);
            } else {
                toast.error("Ekleme İşlemi Başarısız");
            }
        })
    }

    return (
        <form autoComplete="off">
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        İsim
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setFisrtName(x.target.value)} placeholder="İsim" />
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Soyisim
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setLastName(x.target.value)} placeholder="Soyisim" />
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Email
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="email" onChange={x => setEmail(x.target.value)} placeholder="Email" autoComplete="new-password"/>
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Kullanıcı Adı
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setUserName(x.target.value)} placeholder="Kullanıcı Adı" />
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Şifre
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="password" onChange={x => setpassword(x.target.value)} placeholder="Şifre" autoComplete="new-password"/>
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Kullanıcı Grubu
                    </label>
                    <div className="col-md-9">
                        <Select
                            value={selectUserGroup}
                            onChange={handleUserGroup}
                            options={userGroups}
                            placeholder="Kullanıcı Seç"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label" >
                        Tc No
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setTcNo(x.target.value)} placeholder="Tc No" />
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label" >
                        Tel No
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="tel" onChange={x => setTelNo(x.target.value)} placeholder="Tel No" />
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <label className="col-md-3 col-form-label">İl</label>
                    <div className="col-md-9">
                        <Province selectedOption={selectedProvinceOption} handleChange={handleProvinceChange} />
                    </div>
                </Col>
                <Col md={6}>
                    <label className="col-md-3 col-form-label">İlçe</label>
                    <div className="col-md-9">
                        <District province={selectedProvinceOption} selectedOption={selectedDistrictOption} handleChange={handleDistrictChange} />
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <button
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                    >
                        Kaydet
                    </button>
                </Col>
            </Row>
        </form>
    )
}
export default CompanyUserAddModal;
