import React, { useEffect, useState } from 'react'
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon'
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown'
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { toast, ToastContainer } from 'react-toastify'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

export const UserAddModal = ({userInfo}) => {
    const api = new APIClient()
    const navigate = useNavigate()
    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null)
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null)
    const authUser = getLoggedinUser()
    const [userGroups, setUserGroups] = useState([])
    const [selectUserGroup, setSelectUserGroup] = useState(null)

    var companyId = authUser.CompanyId

    useEffect(() => {
        api.get("/UserGroup/UserGroupGetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.groupName,
                value: item.id
            }))
            setUserGroups(formattedOptions)
        })
    }, [])

    const handleUserGroup = (selectedOption) => {
        setSelectUserGroup(selectedOption)
    }

    const handleProvinceChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption)
        setSelectedDistrictOption(null)
    }

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrictOption(selectedOption)
    }

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        userName: '',
        password: '',
        tcNo: '',
        telNo: '',
        province: null,
        district: null,
        userGroup: null,
    }

    const validationSchema = Yup.object({
        firstName: Yup.string().required('İsim gerekli'),
        lastName: Yup.string().required('Soyisim gerekli'),
        email: Yup.string().email('Geçerli bir email girin').required('Email gerekli'),
        userName: Yup.string().required('Kullanıcı Adı gerekli'),
        password: Yup.string().required('Şifre gerekli'),
        tcNo: Yup.string().required('Tc No gerekli'),
        telNo: Yup.string().required('Tel No gerekli'),
        province: Yup.object().nullable().required('İl gerekli'),
        district: Yup.object().nullable().required('İlçe gerekli'),
        userGroup: Yup.object().nullable().required('Kullanıcı Grubu gerekli'),
    })

    const handleSubmit = (values, { setSubmitting }) => {
        const User = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            userName: values.userName,
            telNo: values.telNo,
            password: values.password,
            tcNo: values.tcNo,
            ilId: values.province.value,
            ilceId: values.district.value,
            companyId: userInfo.CompanyId,
            createdUserId: userInfo.Id,
            updatedUserId: userInfo.Id,
            userGroupId: values.userGroup.value,
            status: true,
        }
        api.create("/Auth/Register", User)
            .then(res => {
                navigate(`/Kullanici`)
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000
                })
            })
            .catch(err => {
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                })
            })
            .finally(() => setSubmitting(false))
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue }) => (
                <Form autoComplete="off">
                    <Row className="mb-3">
                        <Col lg="6">
                            <label htmlFor="firstName" className="col-md-3 col-form-label">
                                İsim
                            </label>
                            <div className="col-md-9">
                                <Field name="firstName" className="form-control" placeholder="İsim Giriniz" />
                                <ErrorMessage name="firstName" component="div" className="text-danger" />
                            </div>
                        </Col>
                        <Col lg="6">
                            <label htmlFor="lastName" className="col-md-3 col-form-label">
                                Soyisim
                            </label>
                            <div className="col-md-9">
                                <Field name="lastName" className="form-control" placeholder="Soyisim Giriniz" />
                                <ErrorMessage name="lastName" component="div" className="text-danger" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col lg="6">
                            <label htmlFor="email" className="col-md-3 col-form-label">
                                Email
                            </label>
                            <div className="col-md-9">
                                <Field name="email" type="email" className="form-control" placeholder="Email Giriniz" autoComplete="new-password" />
                                <ErrorMessage name="email" component="div" className="text-danger" />
                            </div>
                        </Col>
                        <Col lg="6">
                            <label htmlFor="userName" className="col-md-3 col-form-label">
                                Kullanıcı Adı
                            </label>
                            <div className="col-md-9">
                                <Field name="userName" className="form-control" placeholder="Kullanıcı Adı Giriniz" />
                                <ErrorMessage name="userName" component="div" className="text-danger" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col lg="6">
                            <label htmlFor="password" className="col-md-3 col-form-label">
                                Şifre
                            </label>
                            <div className="col-md-9">
                                <Field name="password" type="password" className="form-control" autoComplete="new-password" />
                                <ErrorMessage name="password" component="div" className="text-danger" />
                            </div>
                        </Col>
                        <Col lg="6">
                            <label htmlFor="userGroup" className="col-md-3 col-form-label">
                                Kullanıcı Grubu
                            </label>
                            <div className="col-md-9">
                                <Select
                                    name="userGroup"
                                    value={selectUserGroup}
                                    onChange={option => {
                                        setSelectUserGroup(option)
                                        setFieldValue('userGroup', option)
                                    }}
                                    options={userGroups}
                                    placeholder="Kullanıcı Seçiniz"
                                />
                                <ErrorMessage name="userGroup" component="div" className="text-danger" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col lg="6">
                            <label htmlFor="tcNo" className="col-md-3 col-form-label">
                                Tc No
                            </label>
                            <div className="col-md-9">
                                <Field name="tcNo" className="form-control" placeholder="Tc No Giriniz" />
                                <ErrorMessage name="tcNo" component="div" className="text-danger" />
                            </div>
                        </Col>
                        <Col lg="6">
                            <label htmlFor="telNo" className="col-md-3 col-form-label">
                                Tel No
                            </label>
                            <div className="col-md-9">
                                <Field name="telNo" className="form-control" placeholder="Tel No Giriniz" />
                                <ErrorMessage name="telNo" component="div" className="text-danger" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <label htmlFor="province" className="col-md-3 col-form-label">İl</label>
                            <div className="col-md-9">
                                <Province
                                    selectedOption={selectedProvinceOption}
                                    handleChange={option => {
                                        setFieldValue('province', option)
                                        handleProvinceChange(option)
                                    }}
                                />
                                <ErrorMessage name="province" component="div" className="text-danger" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <label htmlFor="district" className="col-md-3 col-form-label">İlçe</label>
                            <div className="col-md-9">
                                <District
                                    province={selectedProvinceOption}
                                    selectedOption={selectedDistrictOption}
                                    handleChange={option => {
                                        setFieldValue('district', option)
                                        handleDistrictChange(option)
                                    }}
                                />
                                <ErrorMessage name="district" component="div" className="text-danger" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <button
                                type="submit"
                                className="btn btn-primary w-md">
                                Kaydet
                            </button>
                        </Col>
                    </Row>
                    <ToastContainer />
                </Form>
            )}
        </Formik>
    )
}
