
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { APIClient } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export const CurrentGroupUpdateModal = ({ user, isChange, group }) => {
    const navigate = useNavigate();
    const api = new APIClient();

    const [open, setOpen] = useState(false);
    const [authUser, setAuthUser] = useState(user);

    const toggleModal = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setAuthUser(user);
    }, [user]);

    const validationSchema = Yup.object().shape({
        currentGroupName: Yup.string().required('Cari Grup Adı gereklidir'),
        currentGroupCode: Yup.string().required('Cari Grup Kodu gereklidir'),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        const UserGroup = {
            id: group.id,
            currentGroupName: values.currentGroupName,
            currentGroupCode: values.currentGroupCode,
            updatedUserId: authUser.Id,
            status: true,
        };

        api.create("/CurrentGroup/UpdateCurrentGroup", UserGroup)
            .then(res => {
                console.log("başarılı");
                //navigate('/cariGruplari');
                window.location.href = `/cariGruplari`;
                toast.success("Başarıyla güncellendi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Güncellenemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .finally(() => {
                setSubmitting(false);
                toggleModal();
            });
    };

    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <div className="d-flex gap-1">
                                <button
                                    className="btn btn-sm btn-warning edit-item-btn"
                                    onClick={toggleModal}
                                    data-bs-toggle="modal"
                                    data-bs-target="#showModal"
                                >
                                    Düzenle
                                </button>
                            </div>
                            <Modal isOpen={open} toggle={toggleModal}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Güncelle</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={toggleModal}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <Formik
                                    initialValues={{
                                        currentGroupName: group.currentGroupName,
                                        currentGroupCode: group.currentGroupCode,
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="modal-body">
                                                <div className="mb-3">
                                                    <label htmlFor="currentGroupName" className="col-form-label">
                                                        Cari Grubu
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="currentGroupName"
                                                        className="form-control"
                                                        placeholder="Cari Grup Adı Giriniz"
                                                    />
                                                    <ErrorMessage name="currentGroupName" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="currentGroupCode" className="col-form-label">
                                                        Cari Grup Kodu
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name="currentGroupCode"
                                                        className="form-control"
                                                        placeholder="Cari Grup Kodu Giriniz"
                                                    />
                                                    <ErrorMessage name="currentGroupCode" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    onClick={toggleModal}
                                                >
                                                    Kapat
                                                </button>
                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Kaydediliyor...' : 'Kaydet'}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
{/*import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { APIClient } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

export const CurrentGroupUpdateModal = ({user,isChange,group}) => {

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [authUser,setAuthUser] = useState(user);

    function openModal() {
        setOpen(!open)
        console.log(authUser)

    }
    const api = new APIClient();
    const handleChange=()=>{
        isChange()
    }
    useEffect(()=>{
        setAuthUser(user)
    },[user])
    const [groupName, setGroupName] = useState(group.currentGroupName);
    const [groupCode, setGroupCode] = useState(group.currentGroupCode);

    const UserGroup={
        id:group.id,
        currentGroupName:groupName,
        currentGroupCode:groupCode,
        //companyId: authUser.CompanyId ,
        //createdUserId: authUser.Id,
        updatedUserId: authUser.Id,
        status:true,
    }
   
    const handleSubmit = () => {
        api.create("/CurrentGroup/UpdateCurrentGroup", UserGroup)
            .then(res => {
                console.log("başarılı");
                //window.location.href = `/cariGruplari`;
                navigate('/cariGruplari')
                toast.success("Başarıyla güncellendi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Güncellenemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }


    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col>

                            <div className="d-flex gap-1">
                            <button className="btn btn-sm btn-warning edit-item-btn"
                                            data-bs-toggle="modal" onClick={() => {
                                                openModal();
                                            }}  data-bs-target="#showModal">Düzenle</button>
                                
                            </div>


                            <Modal
                                isOpen={open}
                                toggle={() => {
                                    openModal();
                                }}

                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Güncelle
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                                Cari Grubu 
                                            </label>
                                            <input className="form-control" type="text" placeholder='Cari Grup Adı' defaultValue={group.currentGroupName} onChange={x => setGroupName(x.target.value)} />
                                            {/* <input type='hidden' defaultValue={group.id}/> */}


                                           {/* 
                                        </div>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                                Cari Grup Kodu
                                            </label>
                                            <input className="form-control" type="text" placeholder='Cari Grup Kodu' defaultValue={group.currentGroupCode} onChange={x => setGroupCode(x.target.value)} />
                                            {/* <input type='hidden' defaultValue={group.id}/> */}
                 


                                            {/*
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        Kapat
                                    </button>
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary">
                                        Kaydet
                                    </button>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}


*/}
