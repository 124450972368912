import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon';
import { useParams } from 'react-router-dom';
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';


const validationSchema = Yup.object().shape({
     firstName: Yup.string().required('İsim alanı zorunludur'),
     lastName: Yup.string().required('Soyisim alanı zorunludur'),
     email: Yup.string().email('Geçersiz email adresi').required('Email alanı zorunludur'),
     userName: Yup.string().required('Kullanıcı adı alanı zorunludur'),
     telNo: Yup.string().required('Telefon numarası alanı zorunludur'),
     tcNo: Yup.string().required('TC kimlik numarası alanı zorunludur'),
     province: Yup.string().required('İl alanı zorunludur'),
     district: Yup.string().required('İlçe alanı zorunludur'),
     userGroup: Yup.string().required('Kullanıcı grubu alanı zorunludur') 
});



const UserUpdate = () => {
    const api = new APIClient();
    const [userInfo, setUserInfo] = useState({});
    const params = useParams();
    const authUser = getLoggedinUser();
    const [user, setUser] = useState({});
    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);
    const [userGroups, setUserGroups] = useState([]);
    const [selectUserGroup, setSelectUserGroup] = useState(null);

    var companyId = authUser.CompanyId;
    const navigate = useNavigate();
    
const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const [email, setEmail] = useState('');
const [userName, setUserName] = useState('');
const [tcNo, setTcNo] = useState('');
const [telNo, setTelNo] = useState('');
const [province, setProvince] = useState('');
const [district, setDistrict] = useState('');
const [userGroup, setUserGroup] = useState('');

    useEffect(() => {
        setUserInfo(authUser);
        api.get("/UserGroup/UserGroupGetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.groupName,
                value: item.id
            }));
            setUserGroups(formattedOptions);
        });
        api.get("/Users/GetUserDTOById?id=" + params.id).then(res => {
            setUser(res);
            setSelectedUser(res);
        });
    }, [params.id]);

    const setSelectedUser = (user) => {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);
        setUserName(user.username);
        setTcNo(user.tcNo);
        setTelNo(user.telNo);
        setProvince(user.ilId);
        setDistrict(user.ilceId);
        setUserGroup(user.userGroupId);
        const formattedOption = {
            value: user.userGroupId,
            label: user.groupName
        };
        setSelectUserGroup(formattedOption);
        api.get("/Province/GetById?id=" + user.ilId).then(res => {
            const formattedOptions = {
                value: res.id,
                label: res.name
            };
            setSelectedProvinceOption(formattedOptions);
        });
        api.get("/District/GetById?id=" + user.ilceId).then(res => {
            const formattedOption = {
                value: res.id,
                label: res.name
            };
            setSelectedDistrictOption(formattedOption);
        });
    };

    const handleSubmit = (values) => {
     
         alert(selectedProvinceOption.value);
         alert(selectedDistrictOption.value);
         alert(selectUserGroup.value);
        console.log('values doğru gösteriliyor mu ?');
        console.log(values);

        const User = {
            id: user.id,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            userName: values.userName,
            telNo: values.telNo,
            userGroupId: values.userGroup,
            tcNo: values.tcNo,
            ilId: selectedProvinceOption.value,
            ilceId: selectedDistrictOption.value,
            updatedUserId: userInfo.Id,
            userGroupId: selectUserGroup.value,
            groupName: selectUserGroup === null ? null : selectUserGroup.label,
            companyId:companyId
        };
        api.create("/Users/UpdateUserDTO", User).then(res => {
         navigate(`/Kullanici`);
           // window.location.href = `/Kullanici`;

            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000
            });
        }).catch(x => {
            console.log(x);
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
        });
    };

    // Province DropDown
    const handleProvinceChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption);

    };

    // District Dropdown
    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrictOption(selectedOption);


    };

    const handleUserGroup = (selectedOption) => {
        setSelectUserGroup(selectedOption);
    };


    
    useEffect(() => {
      
        console.log("veriler geliyor mu");
        console.log(firstName);
        console.log(lastName);
        console.log(email);
        console.log(userName);
        console.log(telNo);
        console.log(userGroup);
        console.log(tcNo);
        console.log(firstName);
        console.log(province);
        console.log(district);
        console.log(userGroup);
    });


    return (
        <React.Fragment>
            <div className="page-content">
                <ToastContainer />
                <Container fluid={true}>
                    <Breadcrumbs title="Kullanıcı Güncelle" breadcrumbItem="Kullanıcılar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                          enableReinitialize
                                          initialValues={{
                                              firstName: firstName,
                                              lastName: lastName,
                                              email: email,
                                              userName: userName,
                                              telNo: telNo,
                                              tcNo: tcNo,
                                              province: province,
                                              district: district,
                                              userGroup: userGroup
                                          }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ errors, touched }) => (
                                            <Form>
                                                <Row className="mb-3">
                                                    <Col lg="6">
                                                        <label htmlFor="firstName" className="col-md-3 col-form-label">
                                                            İsim
                                                        </label>
                                                        <div className="col-md-9">
                                                            <Field name="firstName" type="text" placeholder="İsim Giriniz" className={`form-control ${errors.firstName && touched.firstName ? 'is-invalid' : ''}`} />
                                                            <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <label htmlFor="lastName" className="col-md-3 col-form-label">
                                                            Soyisim
                                                        </label>
                                                        <div className="col-md-9">
                                                            <Field name="lastName" type="text" placeholder="Soyisim Giriniz"className={`form-control ${errors.lastName && touched.lastName ? 'is-invalid' : ''}`} />
                                                            <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col lg="6">
                                                        <label htmlFor="email" className="col-md-3 col-form-label">
                                                            Email
                                                        </label>
                                                        <div className="col-md-9">
                                                            <Field name="email" type="email" placeholder="Email Giriniz"className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`} />
                                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <label htmlFor="userName" className="col-md-3 col-form-label">
                                                            Kullanıcı Adı
                                                        </label>
                                                        <div className="col-md-9">
                                                            <Field name="userName" type="text" placeholder="Kullanıcı Adı" className={`form-control ${errors.userName && touched.userName ? 'is-invalid' : ''}`} />
                                                            <ErrorMessage name="userName" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col lg="6">
                                                        <label htmlFor="telNo" className="col-md-3 col-form-label">
                                                            Tel No
                                                        </label>
                                                        <div className="col-md-9">
                                                            <Field name="telNo" type="tel" placeholder="Tel No Giriniz" className={`form-control ${errors.telNo && touched.telNo ? 'is-invalid' : ''}`} />
                                                            <ErrorMessage name="telNo" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <label htmlFor="tcNo" className="col-md-3 col-form-label">
                                                            Tc No
                                                        </label>
                                                        <div className="col-md-9">
                                                            <Field name="tcNo" type="text" placeholder="Tc No Giriniz" className={`form-control ${errors.tcNo && touched.tcNo ? 'is-invalid' : ''}`} />
                                                            <ErrorMessage name="tcNo" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={6}>
                                                        <label className="col-md-3 col-form-label">İl</label>
                                                        <div className="col-md-9">
                                                            <Province selectedOption={selectedProvinceOption} handleChange={handleProvinceChange} />
                                                            <ErrorMessage name="province" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <label className="col-md-3 col-form-label">İlçe</label>
                                                        <div className="col-md-9">
                                                            <District province={selectedProvinceOption} selectedOption={selectedDistrictOption} handleChange={handleDistrictChange} />
                                                            <ErrorMessage name="district" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col lg="6">
                                                        <label htmlFor="userGroup" className="col-md-3 col-form-label">
                                                            Kullanıcı Grubu
                                                        </label>
                                                        <div className="col-md-9">
                                                            <Select
                                                                value={selectUserGroup}
                                                                onChange={handleUserGroup}
                                                                options={userGroups}
                                                                placeholder="Kullanıcı Seçiniz"
                                                            />
                                                            <ErrorMessage name="userGroup" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <button type="submit" className="btn btn-primary waves-effect waves-light">Güncelle</button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default UserUpdate;


{/*import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon'
import { useParams } from 'react-router-dom'
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select'

const UserUpdate = () => {
    const api = new APIClient()
    const [userInfo, setUserInfo] = useState({})
    const params = useParams();
    const authUser = getLoggedinUser();
    const [user, setUser] = useState({})
    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);
    const [firstName, setFisrtName] = useState("")
    const [userName, setUserName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [telNo, setTelNo] = useState("")
    const [tcNo, setTcNo] = useState("")
    const [province, setProvince] = useState("")
    const [district, setDistrict] = useState("")

    const [userGroups, setUserGroups] = useState([])
    const [userGroup,setUserGroup] =useState("")
    const [selectUserGroup,setSelectUserGroup] =useState(null)

    const User = {
        id: user.id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        userName: userName,
        telNo: telNo,
        userGroupId:userGroup,
        tcNo: tcNo,
        ilId: province,
        ilceId: district,
        updatedUserId: userInfo.Id,
       // companyId:userInfo.CompanyId,
        userGroupId :userGroup,
        groupName:selectUserGroup == null ? null : selectUserGroup.label,
        //status:true

    }

    var companyId = authUser.CompanyId;

    useEffect(() => {
        setUserInfo(authUser);
        api.get("/UserGroup/UserGroupGetAll?companyId="+companyId).then(res => { 
            const formattedOptions = res.map(item => ({
                label: item.groupName, // API'den gelen verilerin label ve value özelliklerine uygun bir şekilde dönüştürülmesi gerekiyor
                value: item.id
            }));
            setUserGroups(formattedOptions);
        })
        api.get("/Users/GetUserDTOById?id=" + params.id).then(res => {
            setUser(res);
            setFisrtName(res.firstName)
            setLastName(res.lastName)
            setEmail(res.email)
            setUserName(res.username)
            setTcNo(res.tcNo)
            setTelNo(res.telNo)
            setProvince(res.ilId)
            setDistrict(res.ilceId)
            setUserGroup(res.userGroupId)
            const formattedOption = {
                value: res.userGroupId,
                label: res.groupName
            }
            console.log(formattedOption)
            setSelectUserGroup(formattedOption)
            api.get("/Province/GetById?id=" + res.ilId).then(res => {
                const formattedOptions = {
                    value: res.id,
                    label: res.name
                }
                setSelectedProvinceOption(formattedOptions)
            })
            api.get("/District/GetById?id=" + res.ilceId).then(res => {
                const formattedOption = {
                    value: res.id,
                    label: res.name
                }
                console.log(formattedOption)
                setSelectedDistrictOption(formattedOption)
            })
            
        })

    }, [params.id])
    const handleUserGroup=(selectedOption)=>{
        setSelectUserGroup(selectedOption)
        console.log(selectedOption.value)
        setUserGroup(selectedOption == null ? selectedOption : selectedOption.value)
    }
    const handleSubmit = () => {
        console.log(User)
        api.create("/Users/UpdateUserDTO", User).then(res => {
            // window.location.reload();
            window.location.href = `/Kullanici`;

            toast.success("Başarıyla güncelendi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    }).catch(x=>console.log(x))
    toast.error("Güncellenemedi", {
        position: "bottom-right",
        autoClose: 10000 
    });

    }
    // Province DropDown
    const handleProvinceChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption)
        setProvince(selectedOption == null ? selectedOption : selectedOption.value)
        setSelectedDistrictOption(null)

    }
    // District Dropdown
    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrictOption(selectedOption)
        setDistrict(selectedOption == null ? selectedOption : selectedOption.value)


    }
    return (
        <React.Fragment>
            <div className="page-content">
            <ToastContainer/>

                <Container fluid={true}>
                    <Breadcrumbs title="Kullanıcı Güncelle" breadcrumbItem="Kullanıcılar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form>
                                        <Row className="mb-3">
                                            <Col lg="6">
                                                <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                                                    İsim
                                                </label>
                                                <div className="col-md-9">
                                                    <input className="form-control" type="text" onChange={x => setFisrtName(x.target.value)} defaultValue={firstName} />
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                                                    Soyisim
                                                </label>
                                                <div className="col-md-9">
                                                    <input className="form-control" type="text" onChange={x => setLastName(x.target.value)} defaultValue={lastName} />
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg="6">
                                                <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                                                    Email 
                                                </label>
                                                <div className="col-md-9">
                                                    <input className="form-control" type="email" onChange={x => setEmail(x.target.value)} defaultValue={email} />
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                                                    Kullanıcı Adı
                                                </label>
                                                <div className="col-md-9">
                                                    <input className="form-control" type="text" onChange={x => setUserName(x.target.value)} defaultValue={userName} />
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className="mb-3">
                                        <Col lg="6">
                                                <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                                                    Kullanıcı Grubu
                                                </label>
                                                <div className="col-md-9">
                                                    <Select
                                                        value={selectUserGroup}
                                                        onChange={handleUserGroup}
                                                        options={userGroups}
                                                        placeholder="Kullanıcı Seç"
                                                    />
                                                </div>

                                            </Col>


                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg="6">
                                                <label htmlFor="example-text-input" className="col-md-3 col-form-label" >
                                                    Tc No
                                                </label>
                                                <div className="col-md-9">
                                                    <input className="form-control" type="text" onChange={x => setTcNo(x.target.value)} defaultValue={tcNo} />
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <label htmlFor="example-text-input" className="col-md-3 col-form-label" >
                                                    Tel No
                                                </label>
                                                <div className="col-md-9">
                                                    <input className="form-control" type="tel" onChange={x => setTelNo(x.target.value)} defaultValue={telNo} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={6}>
                                                <label className="col-md-3 col-form-label">İl</label>
                                                <div className="col-md-9">
                                                    <Province selectedOption={selectedProvinceOption} handleChange={handleProvinceChange} />

                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <label className="col-md-3 col-form-label">İlçe</label>
                                                <div className="col-md-9">
                                                    <District province={selectedProvinceOption} selectedOption={selectedDistrictOption} handleChange={handleDistrictChange} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <button
                                                    onClick={handleSubmit}
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light"
                                                >
                                                    Kullanıcıyı Güncelle
                                                </button>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default UserUpdate;
*/}

