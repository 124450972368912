import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon'
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown'
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select'
import CompanyUserAddModal from '../../Pages/Company/CompanyUserAddModal' // Default import kullanıyoruz
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom'

const CompanyUserAdd = () => {
    const api = new APIClient()
    const params = useParams();
    const [userInfo, setUser] = useState({})
    const authUser = getLoggedinUser();
    useEffect(() => {
        setUser(authUser);
    }, [])

    const lastcompany = params.lastcompany;
    return (
        <React.Fragment>
            <div className="page-content">
                <ToastContainer />
                <Container fluid={true}>
                    <Breadcrumbs title="Firmaya Kullanıcı Ekle" breadcrumbItem="Firmalar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CompanyUserAddModal userInfo={userInfo} lastcompany={lastcompany} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default CompanyUserAdd;
