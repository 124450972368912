import { APIClient } from '../api_helper'

const api = new APIClient();
const authProcess = (data)=>{

    api.create("/Auth/Login",data).then(res=>{
        return new Promise((resolve, reject) => {
            setTimeout(() => {
              if (res.data.token !== null) {
                resolve([200, res.data.token]);
              } else {
                reject([
                  "Username and password are invalid. Please enter correct username and password",
                ]);
              }
            });
          });
    })
}

export default authProcess