import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Container, Input, Label, Modal, Row } from "reactstrap";
import Select from "react-select";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'


const CalculativeEarchive = ({LabelData,IstisnaData,TevkifatData,CurrentID,BankData}) => {
    const Etiketoptions = LabelData.map(item => ({
        value: item.id,
        label: item.label_Name,
    }));
     const Istisna =IstisnaData.map(item=>({
           id:item.id,
           istisnaKodu:item.code,
           istisnaAciklama:item.explanation

     }));
 
     const banksToAdd = BankData.map(item=>({
        id:item.id,
        BankaAdi:item.bank_Name,
        Sube:item.bank_Branch,
        ParaBirimi:item.currency,
        HesapNo:item.account_Number,
        Iban:item.iban
     }))
     const TevkifatOptions= TevkifatData.map(item =>({
         id:item.id,
         Tevkifat:item.explanation,
         TevkifatOrani:item.rate
     }))
     const FaturaTip = [
        { value: "1", label: "SATIŞ" },
        { value: "2", label: "İADE" },
        { value: "3", label: "TEVKİFAT" },
        { value: "4", label: "İSTİSNA" },
        { value: "5", label: "İADE-İSTİSNA" },
        { value: "6", label: "ÖZEL MATRAH" }
    ];
    const ParaBirimi = [
        { value: "1", label: "Türk Lirası" },
        { value: "2", label: "Amerikan Doları" },
        { value: "3", label: "Euro" },
        { value: "4", label: "İngiliz Sterlini" }
    ];
    const EArsivTipi =[
        { value: "1", label: "Normal" },
        { value: "2", label: "İnternet" },
    ]
    const GonderimTipi =[
        { value: "1", label: "Kağıt" },
        { value: "2", label: "Elektronik" },
    ]
    const OdemeSekli =[
        { value: "1", label: "Kredi Kartı / Banka Kartı" },
        { value: "2", label: "EFT / Havale" },
        { value: "3", label: "Kapıda Ödeme" },
        { value: "2", label: "Diğer" },

    ]
    const Tasiyan=[
        { value: "1", label: "Taşıyan" },
        { value: "2", label: "Tüzel" }
    ]
    const FaturaSenaryo = [
        { value: "3", label: "E-Arşiv Fatura" },
     
    ];
    const api = new APIClient();
    const authUser = getLoggedinUser();

    const [invoiceType, setInvoiceType] = useState({ value: 1 });
    const [paymentMethod, setPaymentMethod] = useState({ value: 1 });
    const [bearing, setBearing] = useState({ value: 1 });
    const [paymentDate, setPaymentDate]=useState("");
    const [internetSalesInfo,setInternetSalesInfo]=useState("");
    const [tool,setTool]=useState("");
    const [toolVknTckn,setToolVknTckn]=useState("");
    const [toolTitle,setToolTitle]=useState("");
    const [transferDate,setTransferDate]=useState("");
    const [selectedOptionMoney, setSelectedOptionMoney] = useState({ value: 1 });
    const [eArchiveType, setEArchiveType] = useState({ value: 1 });
    const [transferType, setETransferType] = useState({ value: 1 });
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [exchangeRate, setExchangeRate] = useState(0.0);
    const [invoiceDate, setInvoiceDate] = useState("");
    const [invoiceTime, setInvoiceTime] = useState("");
    const [ticket, setTicket] = useState({ value: 0 });
    const [invoiceScenario,setInvoiceScenario]= useState({ value:1});
    const [kdvIncluded, setKdvIncluded] = useState(false);
    const [epostaIncluded, setEpostaIncluded] = useState(false);

    const [bankAdded, setBankAdded] = useState(false);
    const [banks, setBanks] = useState([]);
    const [bankID, setBankID] = useState([{bankID:0}]);

    const [iadeRows, setIadeRows] = useState([{ id: 1 }]);
    const [GenelIskontorows, setGenelIskontorows] = useState([]);
    const [vergiMuafiyet, setVergiMuafiyet] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOptionIndirimTevkifat, setSelectedOptionIndirimTevkifat] = useState("");
    const [modalRowsID, setModalRowsID] = useState(0);
    const [productList, setProductList] = useState("");
    const [kdvList, setKdvList] = useState("");
    const [stockUnitList, setStockUnitList] = useState("");
    const [rowStates, setRowStates] = useState([{ id: 1, selectedProduct: null, selectedStockUnit: null, selectedStockKdv: null, discount: null, withholding: null , tevkifatOrani:null}]);
    const [invoiceStock, setInvoiceStock] = useState([{ stockID: 0, amount: 0.0, kdvid: 0, unitID: 0, unitPrice: 0.0 , discountedAmount:0.0, lineDiscount:0.0, withholdingID:0, invoiceBuySalesStatus:0,invoiceKind:0}]);
   const [generalDiscounts,setGeneralDiscounts]=useState([{cause:"",amount:0}])
   const [invoiceRebates,setInvoiceRebates]=useState([{rebateNumber:"",rebateDate:"2024-06-10T12:12:01.074Z"}])
   const [invoiceExceptional,setInvoiceExceptional]=useState({exceptionalId:0})
    const [quantityStates, setQuantityStates] = useState([{ id: 1, quantity: '' }]);
    const [unitPrices, setUnitPrices] = useState(Array(rowStates.length).fill(''));
    const [totalKDV, setTotalKDV] = useState(0);
    const [tevkifatTotal, setTevkifatTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [netTutar, setNetTutar] = useState(0);
    const [toplamIskonto, setToplamIskonto] = useState(0);
    const [genelToplam, setGenelToplam] = useState(0);
    const [vergiDahilOdenecekTutar, setVergiDahilOdenecekTutar] = useState(0);
    const [kdvState, setKdvState] = useState(false);
    const [explanation, setExplanation] = useState("");
    const [eArchiveTypeStatus,setEArchiveTypeStatus] = useState(false);
  
  
  
    useEffect(() => {
      
        VergiMuafiyet();
        productData();
        kdvData();
        stockUnitData();
        
    }, []);
    useEffect(() => {
        handleCalculate();
    }, [rowStates, quantityStates, unitPrices]);
    const VergiMuafiyet = () => {
        setVergiMuafiyet(Istisna);
    }
    const productData = () => {
        api.get("/Stock/GetAll?companyId=" + authUser.CompanyId ).then(res => {
            setProductList(res)
        }).catch(er => console.log(er))
    }
    const kdvData = () => {
        api.get("/KDV/GetAll").then(res => {
            setKdvList(res)
        }).catch(er => console.log(er))
    }
    const stockUnitData = () => {
        api.get("/StockUnit/GetAll").then(res => {
            setStockUnitList(res)
        }).catch(er => console.log(er))
    }
    const invoiceData = {
        currentID: parseInt(CurrentID) || 0,
        invoiceNumber: invoiceNumber || '',
        invoiceType: parseInt(invoiceType?.value) || 0,
        moneyUnit: parseInt(selectedOptionMoney?.value) || 0,
        exchangeRate: parseFloat(exchangeRate) || 0.0,
        invoiceDate: invoiceDate || '',
        time: invoiceTime || '',
        ticketID: ticket?.value ? parseInt(ticket.value) || 0 : 0,
        kdvState: kdvIncluded,
        subTotal: parseFloat(subTotal) || 0.0,
        totalDiscount: parseFloat(toplamIskonto) || 0.0,
        base: parseFloat(netTutar) || 0.0,
        calculatedKDV: parseFloat(totalKDV) || 0.0,
        calculatedWithholding: parseFloat(tevkifatTotal) || 0.0,
        taxesIncludedAmount: parseFloat(vergiDahilOdenecekTutar) || 0.0,
        generalTotal: parseFloat(genelToplam) || 0.0,
        explanation: explanation || '',
        guidID: '',
        invoiceScenario:3,
        paymentStatus: false,
        invoiceKind: 2,
        companyId: authUser.CompanyId ,
        createdUserId: authUser.Id,
        updatedUserId: authUser.Id,
        invoiceStocks: invoiceStock || [],
        generalDiscounts: generalDiscounts || [],
        invoiceBanks: bankID || [],
        invoiceRebates:invoiceRebates || [],
        invoiceExceptional: invoiceExceptional,
        currentAccountName:"",
        currentAccountTCKN_VKN:"",
        id:0,
        earchives: {
            "earchiveType": parseInt(eArchiveType.value),
            "shippingMethod": parseInt(transferType.value),
            "email": emailAddress,
            "paymentMethod": parseInt(paymentMethod.value),
            "agentInformation": tool,
            "paymentDate": paymentDate,
            "internetInfo": internetSalesInfo,
            "carrierType": parseInt(bearing.value),
            "carrierVKN_TCKNO": toolVknTckn,
            "carrierTitle": toolTitle,
            "sendingDate": transferDate
          }
    };
  
    const handleSubmit = async () => {
        console.log(invoiceData, "InfoiceInfoice")

        try {
            api.create("/Invoices/CreateInvoice", invoiceData).then(res => {
                console.log("Response:", res);
            }).catch(
            //    toast.success("Başarıyla kaydedildi", {
            //        position: "bottom-right",
            //        autoClose: 10000 
            //    })
            )
            // const response = await apii.post("/Invoices", invoiceData);
            // console.log("Response:", response.data);
            // toast.success("Başarıyla kaydedildi", {
            //     position: "bottom-right",
            //     autoClose: 10000
            // });
            // navigate("/cariler"); 
        } catch (error) {
            if (error.response) {
                // Sunucu yanıt verdi ve status code 2xx değil
                console.error("Response error:", error.response);
                console.error("Status code:", error.response.status);
                console.error("Data:", error.response.data);
            } else if (error.request) {
                // İstek yapıldı ama yanıt alınamadı
                console.error("Request error:", error.request);
            } else {
                // Başka bir hata oluştu
                console.error("Error:", error.message);
            }
            // toast.error("Kaydedilemedi", {
            //     position: "bottom-right",
            //     autoClose: 10000
            // });
        }
    }

    const handleCalculate = () => {
   
      if(kdvIncluded==false){
        let totalKDV = 0;
        let subTotal = 0;
        let tevkifatTotal = 0;
        let netTutar = 0;
        let ToplamIndirim = 0;
        let GenelToplam = 0;
        let VergiDahilTutar=0;
        let StockID =0;
        let KDVID=0;
        let UnitID=0;
        const updatedRows = rowStates.map((row, index) => {
            const unitPrice = parseFloat(unitPrices[index]) || 0;
            const quantity = parseFloat(quantityStates[index]?.quantity) || 0; // miktar
            const discount = parseFloat(row.discount) || 0;
            const tevkifat = parseFloat(row.tevkifatOrani) || 0;
            const kdvRate = parseFloat(row.selectedStockKdv?.kdvRate) || 0;
            const withholding = parseInt(row.withholding)|| 0;
            KDVID=parseInt(row.selectedStockKdv?.id) || 0;;
            StockID=parseInt(row.selectedProduct?.id)|| 0;
            UnitID =parseInt(row.selectedStockUnit?.id)|| 0;
            const totalPrice = unitPrice * quantity;
            const discountAmount = totalPrice * (discount / 100);
            const priceAfterDiscount = totalPrice - discountAmount;
            subTotal += totalPrice;
            ToplamIndirim += discountAmount;
          if(kdvRate===0){
            setKdvState(true)
          }
          else{
            setKdvState(false)
          }
            let toplm = priceAfterDiscount; 
            if (GenelIskontorows.length > 0) {
                GenelIskontorows.forEach((iskonto, index) => {
                    const oran = parseFloat(iskonto.oran) || 0;
                    const IskontoOran = toplm * (oran / 100);
                    ToplamIndirim += IskontoOran;
                    toplm -= IskontoOran; 
                  
                });
            }
            const kdvAmount = toplm * (kdvRate / 100);
            const tevkifatAmount = kdvAmount * (tevkifat / 100);
            totalKDV += kdvAmount;
            tevkifatTotal += tevkifatAmount;
            netTutar += toplm;
            const updatedRows = [...invoiceStock];
            updatedRows[index] = { ...updatedRows[index], stockID:StockID,amount:quantity,kdvid:KDVID,unitID:UnitID,unitPrice:unitPrice,discountedAmount:toplm,lineDiscount:ToplamIndirim,withholdingID:withholding,invoiceBuySalesStatus:parseInt(invoiceType.value),invoiceKind:1}; 
            setInvoiceStock(updatedRows)

            return {
                ...row,
                totalPrice: toplm,
                priceAfterDiscount: toplm,
                kdvRate: kdvAmount,
                tevkifat: tevkifatAmount,
                discountAmount: discountAmount
            };
        
        });
        VergiDahilTutar = netTutar + totalKDV;
        VergiDahilTutar = VergiDahilTutar.toFixed(2);

         GenelToplam = VergiDahilTutar - tevkifatTotal;
        GenelToplam = GenelToplam.toFixed(2);

        totalKDV =totalKDV.toFixed(2);
        subTotal =subTotal.toFixed(2);
        tevkifatTotal =tevkifatTotal.toFixed(2);
        netTutar =netTutar.toFixed(2);
        ToplamIndirim =ToplamIndirim.toFixed(2);
        setRowStates(updatedRows);
        setTotalKDV(totalKDV);
        setSubTotal(subTotal);
        setTevkifatTotal(tevkifatTotal);
        setNetTutar(netTutar);
        setToplamIskonto(ToplamIndirim);
        setVergiDahilOdenecekTutar(VergiDahilTutar);
        setGenelToplam(GenelToplam);
      }
      else{
        let Toplam=0;
        let AraToplam=0;
        let ToplamIskonto=0;
        let NetTutar=0;
        let HesaplananKDV=0;
        let HesaplananTevkifat=0;
        let VergilerDahilOdenecekTutar=0;
        let GenelToplam=0;
        let KDVID=0;
        let StockID=0;
        let UnitID=0;
        const updatedRows = rowStates.map((row, index) => {
            const ToplamFiyat = parseFloat(row.totalPrice) || 0;
             const Miktar = parseFloat(quantityStates[index]?.quantity) || 0; 
             const indirim = parseFloat(row.discount) || 0;
             const tevkifat = parseFloat(row.tevkifatOrani) || 0;
             const kdvOrani = parseFloat(row.selectedStockKdv?.kdvRate) || 0;
             const withholding = parseInt(row.withholding)|| 0;
             const kdvliBirimFiyat =ToplamFiyat/Miktar;
             const y=(1+(kdvOrani/100));
             const BirimFiyat=kdvliBirimFiyat/y;
             StockID=parseInt(row.selectedProduct?.id)|| 0;
             KDVID=parseInt(row.selectedStockKdv?.id) || 0;
             UnitID =parseInt(row.selectedStockUnit?.id)|| 0;
             Toplam=BirimFiyat*Miktar;
             AraToplam+=Toplam;
             if(kdvOrani===0){
                setKdvState(true)
              }
              else{
                setKdvState(false)
              }
             const IndirimOrani=Toplam*(indirim/100)
             ToplamIskonto+=IndirimOrani;
             Toplam-=IndirimOrani;

             if (GenelIskontorows.length > 0) {
                GenelIskontorows.forEach((iskonto, index) => {
                    const oran = parseFloat(iskonto.oran) || 0;
                    const IskontoOran = Toplam * (oran / 100);
                    ToplamIskonto += IskontoOran;
                    Toplam -= IskontoOran; // Subtract each discount from the total
           
                });
            }
            const kdvAmount = Toplam * (kdvOrani / 100);
            const tevkifatAmount = kdvAmount * (tevkifat / 100);
            HesaplananKDV+=kdvAmount;
            HesaplananTevkifat+=tevkifatAmount;
            
            NetTutar +=Toplam;
             unitPrices[index] = BirimFiyat;

             const updatedRows = [...invoiceStock];
             updatedRows[index] = { ...updatedRows[index], stockID:StockID,amount:Miktar,kdvid:KDVID,unitID:UnitID,unitPrice:BirimFiyat,discountedAmount:Toplam,lineDiscount:ToplamIskonto,withholdingID:withholding,invoiceBuySalesStatus:parseInt(invoiceType.value),invoiceKind:1}; 
             setInvoiceStock(updatedRows)
 
             return {
                ...row,
                 totalPrice: ToplamFiyat,
                priceAfterDiscount: Toplam,
                kdvRate: kdvAmount,
                tevkifat: tevkifatAmount,
                discountAmount: IndirimOrani,
            };
        
        });
        VergilerDahilOdenecekTutar = NetTutar + HesaplananKDV;
        VergilerDahilOdenecekTutar = VergilerDahilOdenecekTutar.toFixed(2);

         GenelToplam = VergilerDahilOdenecekTutar - HesaplananTevkifat;
        GenelToplam = GenelToplam.toFixed(2);

        HesaplananKDV =HesaplananKDV.toFixed(2);
        AraToplam =AraToplam.toFixed(2);
        HesaplananTevkifat =HesaplananTevkifat.toFixed(2);
        NetTutar =NetTutar.toFixed(2);
        ToplamIskonto =ToplamIskonto.toFixed(2);
        setRowStates(updatedRows);
        setTotalKDV(HesaplananKDV);
        setSubTotal(AraToplam);
        setTevkifatTotal(HesaplananTevkifat);
        setNetTutar(NetTutar);
        setToplamIskonto(ToplamIskonto);
        setVergiDahilOdenecekTutar(VergilerDahilOdenecekTutar);
        setGenelToplam(GenelToplam);
      }
      
      
    };
    
    const handleProductChange = (selectedOption, rowIndex) => {
        const product = productList.find(p => p.id === selectedOption.value);
        const updatedRowStates = [...rowStates];
        updatedRowStates[rowIndex] = {
            ...updatedRowStates[rowIndex],
            selectedProduct: product,
            selectedStockUnit: stockUnitList.find(unit => unit.id === product.stockUnit_ID),
            selectedStockKdv: kdvList.find(unit => unit.id === product.stockSalesKDV_ID)
        };
        setRowStates(updatedRowStates);

        // Seçilen ürünün birim fiyatını güncelle
        const updatedUnitPrices = [...unitPrices];
        updatedUnitPrices[rowIndex] = product.stockSalesPrice; // Varsayılan olarak birim fiyatını buradan alıyoruz
        setUnitPrices(updatedUnitPrices);
    };
 
    const handleUnitPriceChange = (e, index) => {
        const { value } = e.target;
        const updatedUnitPrices = [...unitPrices];
        updatedUnitPrices[index] = value;
    
       
        setUnitPrices(updatedUnitPrices);
    };
   
    const handleStockUnitChange = (selectedOption, rowIndex) => {
        const updatedRowStates = [...rowStates];
        updatedRowStates[rowIndex] = {
            ...updatedRowStates[rowIndex],
            selectedStockUnit: stockUnitList.find(unit => unit.id === selectedOption.value)
        };
        setRowStates(updatedRowStates);
    };
    const handleStockKDVChange = (selectedOption, rowIndex) => {
        const updatedRowStates = [...rowStates];
        updatedRowStates[rowIndex] = {
            ...updatedRowStates[rowIndex],
            selectedStockKdv: kdvList.find(unit => unit.id === selectedOption.value)
        };
        setRowStates(updatedRowStates);
    };
   
    const handleSelectIndirimTevkifatChange = (e) => {
        setSelectedOptionIndirimTevkifat(e.target.value);
    };

    const handleAdd = () => {
        const updatedRows = rowStates.map(row => {
            if (row.id === modalRowsID) {
                if (selectedOptionIndirimTevkifat === '1') {
                    return { ...row, discount: selectedOptionIndirimTevkifat };
                } else if (selectedOptionIndirimTevkifat === '2') {
                    return { ...row, withholding: selectedOptionIndirimTevkifat };
                }
            }
            return row;
        });
        setRowStates(updatedRows);
        closeModal();
    };

    const handleSelectChange = (selectedOption) => {
        setInvoiceType(selectedOption);
    };
    const handlePaymentMethod = (selectedOption) => {
        setPaymentMethod(selectedOption);
    };
    const handleBearingChange = (selectedOption) => {
        setBearing(selectedOption);
    };

    const handleSelectChange2 = (selectedOption) => {
        setSelectedOptionMoney(selectedOption);
    };
    const handleEarchiveChange =(e)=>{
        if(e.value==2){
            setETransferType({ value:2,label:"Elektronik"});
            setEpostaIncluded(true);
            setEArchiveTypeStatus(true);
        }
        else{
            setEArchiveTypeStatus(false); 
        }
        setEArchiveType(e)
    }
    const handleTransferType =(e)=>{
        if(eArchiveType.value!=2){
            setETransferType(e)
        }
        else{
            setETransferType({ value:2,label:"Elektronik"});
        }
        
    }
    const handleFaturaNoChange = (e) => {
        setInvoiceNumber(e.target.value);
    };
    const handleInternetSalesInfoChange= (e) => {
        setInternetSalesInfo(e.target.value);
    };
    const handleToolChange= (e) => {
        setTool(e.target.value);
    };
    const handleEmailAddressChange = (e) => {
        setEmailAddress(e.target.value);
    };
    const handleExchangeRateChange = (e) => {
        setExchangeRate(e.target.value);
    };
    const handleInvoiceDateChange = (e) => {
        setInvoiceDate(e.target.value);
    };
    const handlePaymentDateChange =(e) => {
        setPaymentDate(e.target.value);
    };
    const handleInvoiceTimeChange = (e) => {
        setInvoiceTime(e.target.value);
    };
    const handleTransferDateChange= (e) => {
        setTransferDate(e.target.value);
    };
    const handleToolTitleChange= (e) => {
        setToolTitle(e.target.value);
    };
    const handleToolVknTcknChange= (e) => {
        setToolVknTckn(e.target.value);
    };
    const handleTicketChange = (ticket) => {
        setTicket(ticket);
    };
    const handleInvoiceScenarioChange=(e) => {
        setInvoiceScenario(e);
    };
    const handleKdvIncludedChange = (e) => {
        setKdvIncluded(e.target.checked);
    };
    const handleEpostaIncludedChange =(e)=>{
        setEpostaIncluded(e.target.checked)
    }
    const handleBankAddedChange = (e) => {
        setBankAdded(e.target.checked);
        if (e.target.checked) {
            addBank(); // Checkbox işaretlendiğinde addBank fonksiyonunu çağır
        }
        else {
            setBanks([]);
        }
    };
    const handleBankDelete = (bankId) => {
        const updatedBanks = banks.filter(bank => bank.id !== bankId);
        setBanks(updatedBanks);
        
        const updatedRows = updatedBanks.map((item, index) => {
            return { ...bankID[index], bankID: parseInt(item.id) };
        });
    
        setBankID(updatedRows);
    };
    
    const addBank = () => {
        setBanks([...banks, ...banksToAdd]);
        
        const updatedRows = banksToAdd.map((item, index) => {
            return { ...bankID[index], bankID: parseInt(item.id) };
        });
    
        setBankID(updatedRows);
    };
    

    const addRow = () => {
        const newRow = { id: rowStates.length + 1, selectedProduct: null, selectedStockUnit: null, selectedStockKdv: null };
        setRowStates([...rowStates, newRow]);
        setQuantityStates([...quantityStates, { id: newRow.id, quantity: '' }]); 

    };


    const addIadeRow = () => {
        const newRow = { id: iadeRows.length + 1 };
        setIadeRows([...iadeRows, newRow]);
    }
    const openModal = (id) => {
        setIsModalOpen(true);
        setModalRowsID(id);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const removeRow = (index) => {
        if (rowStates.length === 1) {
            alert("Son satırı silemezsiniz!");
            return;
        }
        const updatedRows = [...rowStates];
        updatedRows.splice(index, 1);
        setRowStates(updatedRows);
        const updatedQuantityRows = [...quantityStates];
        updatedQuantityRows.splice(index, 1); 
        setQuantityStates(updatedQuantityRows);
    };

    const removeIadeRow = (index) => {
        if (iadeRows.length === 1) {
            alert("Son satırı silemezsiniz!");
            return;
        }
        const updatedRows = [...iadeRows];
        updatedRows.splice(index, 1);
        setIadeRows(updatedRows);
    }
    const addGenelIskontoRow = () => {
        const newRow = { id: GenelIskontorows.length + 1, sebep: null, oran: null };

        setGenelIskontorows([...GenelIskontorows, newRow]);
    };

    const removeGenelIskontoRow = (index) => {
        const updatedRows = [...GenelIskontorows];
        updatedRows.splice(index, 1);
        setGenelIskontorows(updatedRows);
    };
    const handleInputChange = (index, field, value) => {
        setGenelIskontorows(prevRows => {
            const updatedRows = [...prevRows];
    
            if (field === "" || field === "sebebi") {
                updatedRows[index] = { ...updatedRows[index], sebep: value };
                const updateIskonto = [...generalDiscounts];
                updateIskonto[index] = { ...updateIskonto[index], cause:value}; 

                setGeneralDiscounts(updateIskonto);
            }
    
            if (field === "indirim") {
                const floatOran = parseFloat(value); // String değeri float'a dönüştür
                updatedRows[index] = { ...updatedRows[index], oran: isNaN(floatOran) ? 0 : floatOran };
                const updateIskonto = [...generalDiscounts];
                updateIskonto[index] = { ...updateIskonto[index],amount:parseFloat(floatOran)}; 

                setGeneralDiscounts(updateIskonto);
            }
    
            return updatedRows;
        });
    };
    
    const handleExplanationInvoiceChange = (e) => {
        const updatedRows = { ...invoiceExceptional, exceptionalId: e.target.value };
        setInvoiceExceptional(updatedRows);
    };
    const handleTevkifatOraniChange = (selectedOption,index) => {
        const selectedTevkifat = TevkifatOptions.find(t => t.id === selectedOption.value);
        const updatedRows = [...rowStates];
        const tevkifatSelect = selectedOption.value;
        const tevkifatOrani = selectedTevkifat.TevkifatOrani;
        updatedRows[index] = { ...updatedRows[index], withholding: tevkifatSelect , tevkifatOrani: tevkifatOrani}; 
        setRowStates(updatedRows);
    };
    const handleDiscountChange = (e, index) => {
        const value = e.target.value;
        const updatedRows = [...rowStates];
        updatedRows[index] = { ...updatedRows[index], discount: value  }; 
        setRowStates(updatedRows);
    };
    

    const handleQuantityChange = (e, index) => {
        const { value } = e.target;
        const updatedQuantityStates = [...quantityStates];
        updatedQuantityStates[index] = { ...updatedQuantityStates[index], quantity: value };
        const updatedRows = [...rowStates];
        setRowStates(updatedRows);
        setQuantityStates(updatedQuantityStates);
    };
    
 const handleTotalChange =(e,index)=>{
    const { value } = e.target;
    const updatedRows = [...rowStates];
    updatedRows[index] = { ...updatedRows[index], totalPrice: value  }; 
    setRowStates(updatedRows);
 }
  const handleExplanationChange =(e)=>{
    setExplanation(e.target.value);
  }
  const handleIadeNumberChange =(index,value)=>{
    const updateIade = [...invoiceRebates];
    updateIade[index] = { ...updateIade[index], rebateNumber:value}; 

    setInvoiceRebates(updateIade);
  }
  const handleIadeDateChange=(index,value)=>{
    const updateIade = [...invoiceRebates];
    updateIade[index] = { ...updateIade[index], rebateDate:value}; 

    setInvoiceRebates(updateIade);
  }
    const currentDate = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    const formatDate = (date) => {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        const year = date.getFullYear();

        if (day < 10) {
            day = `0${day}`;
        }
        if (month < 10) {
            month = `0${month}`;
        }

        return `${year}-${month}-${day}`;
    };
   
      const customStyles = {
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 9999
        }),
      };
      
    return (
        <React.Fragment>
            <div className="page-content" style={{ marginTop: "-170px" }}>
                <Container fluid={true}>
                    <Row>
                        {/* Fatura numarasından etikete kadar olan bölüm burada */}
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="6">
                                            <div className="mb-3">
                                                <Label>Fatura No</Label>
                                                <Input
                                                    placeholder="Fatura No"
                                                    value={invoiceNumber}
                                                    onChange={handleFaturaNoChange}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Label>Fatura Tipi</Label>
                                                <Select
                                                    value={invoiceType}
                                                    onChange={handleSelectChange}
                                                    options={FaturaTip}
                                                    placeholder="Fatura Tipi"
                                                    required
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPosition="fixed"
                                                />
                                            </div>
                                           
                                           
                                            <div className="mb-3">
                                                <Label>E-Arşiv Tipi</Label>
                                                <Select
                                                    value={eArchiveType}
                                                    onChange={handleEarchiveChange}
                                                    options={EArsivTipi}
                                                    placeholder="E-Arşiv Tipi"
                                                    required
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPosition="fixed"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Label>Para Birimi</Label>
                                                <Select
                                                    value={selectedOptionMoney}
                                                    onChange={handleSelectChange2}
                                                    options={ParaBirimi}
                                                    placeholder="Para Birimi"
                                                    required
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPosition="fixed"
                                                />
                                            </div>
                                            {selectedOptionMoney && selectedOptionMoney.value !== "1" && (
                                                <div className="mb-3">
                                                    <Label>Döviz Kuru</Label>
                                                    <Input
                                                        placeholder="Döviz Kuru"
                                                        value={exchangeRate}
                                                        onChange={handleExchangeRateChange}
                                                    />
                                                </div>
                                            )}
                                            
                                        </Col>

                                        <Col lg="6">
                                            <div className="mb-3">
                                                <Label>Tarih</Label>
                                                <Input
                                                    placeholder="Tarih"
                                                    type="date"
                                                    value={invoiceDate}
                                                    onChange={handleInvoiceDateChange}
                                                    min={formatDate(sevenDaysAgo)}
                                                    max={formatDate(currentDate)}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Label>Saat</Label>
                                                <Input
                                                    placeholder="Tarih"
                                                    type="time"
                                                    value={invoiceTime}
                                                    onChange={handleInvoiceTimeChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Label>Gönderim Tipi</Label>
                                                <Select
                                                    value={transferType}
                                                    onChange={handleTransferType}
                                                    options={GonderimTipi}
                                                    placeholder="Gönderim Tipi"
                                                    required
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPosition="fixed"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Label>Etiket</Label>
                                                <Select
                                                    value={ticket}
                                                    onChange={handleTicketChange}
                                                    options={Etiketoptions}
                                                    placeholder="Etiketler"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPosition="fixed"
                                                />
                                            </div>
                                           
                                              
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                     
                        {/* Fatura numarasından etikete kadar olan bölüm burada */}
                        {
                            eArchiveTypeStatus && eArchiveTypeStatus===true?(
                                <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="6">
                                               <h4>Ödeme Bilgisi</h4>
                                                <div className="mb-3">
                                                    <Label>Ödeme Şekli</Label>
                                                    <Select
                                                        value={paymentMethod}
                                                        onChange={handlePaymentMethod}
                                                        options={OdemeSekli}
                                                        placeholder="Lütfen Ödeme Şekli Seçiniz"
                                                        required
                                                        styles={customStyles}
                                                        menuPortalTarget={document.body}
                                                        menuPosition="fixed"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Aracı</Label>
                                                    <Input
                                                        placeholder="Aracı"
                                                        value={tool}
                                                        onChange={handleToolChange}
                                                        required
                                                    />
                                                </div>
                                               
                                               
                                                <div className="mb-3">
                                                    <Label>Tarih</Label>
                                                    <Input
                                                        placeholder="Tarih"
                                                        type="date"
                                                        value={paymentDate}
                                                        onChange={handlePaymentDateChange}
                                                 
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>İnternet Satış Bilgisi</Label>
                                                
                                                      <Input
                                                        placeholder="İnternet Satış Bilgisi"
                                                        value={internetSalesInfo}
                                                        onChange={handleInternetSalesInfoChange}
                                                        
                                                    />
                                                </div>
                                               
                                            </Col>
    
                                            <Col lg="6">
                                              <h4>Gönderim Şekli</h4>
                                                <div className="mb-3">
                                                    <Label>Taşıyan Tipi</Label>
                                                    <Select
                                                        value={bearing}
                                                        onChange={handleBearingChange}
                                                        options={Tasiyan}
                                                        placeholder="Taşıyan"
                                                        required
                                                        styles={customStyles}
                                                        menuPortalTarget={document.body}
                                                        menuPosition="fixed"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Taşıyan VKNO / TC Kimlik No</Label>
                                                    <Input
                                                        placeholder="Taşıyan vergi no veya TC kimlik no"
                                                        type="text"
                                                        value={toolVknTckn}
                                                        onChange={handleToolVknTcknChange}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Taşıyan Unvan</Label>
                                                    <Input
                                                        placeholder="Taşıyan Unvan"
                                                        type="text"
                                                        value={toolTitle}
                                                        onChange={handleToolTitleChange}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Tarihi</Label>
                                                    <Input
                                                        placeholder="Tarihi"
                                                        type="date"
                                                        value={transferDate}
                                                        onChange={handleTransferDateChange}
                                                    />
                                                </div>
                                               
                                                  
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            ):null
                        }
                       
                        {/* KDV Dahil ve banka ekleme checkboxları */}
                        <Col lg="12" style={{ marginTop: "-15px" }}>
                            <Card>
                                <div className="row" style={{ padding: "15px" }}>
                                <div className="col">
                                        <div className="form-check form-check-inline" >
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="KdvCheckbox"
                                                checked={epostaIncluded}
                                                onChange={handleEpostaIncludedChange}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="KdvCheckbox"
                                            >
                                                E-Posta Olarak İletilsin
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline" >
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="KdvCheckbox"
                                                checked={kdvIncluded}
                                                onChange={handleKdvIncludedChange}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="KdvCheckbox"
                                            >
                                                KDV Dahil
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="BankAdd"
                                                checked={bankAdded}
                                                onChange={handleBankAddedChange}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="BankAdd"
                                            >
                                                Banka Ekle
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        {
                            epostaIncluded && epostaIncluded===true?(
                                <Col lg="12" style={{ marginTop: "-15px" }}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="12">
                                            <div >
                                                 <h4 style={{ marginRight: "auto" }}>E-Posta Bilgisi</h4>
                                                  <p style={{color:"green",fontSize:"12px"}}>Birden fazla e-posta adresini aralarına virgül (,) koyarak girebilirsiniz.</p>
                                             </div>
                                            <div className="mb-3">
                                                     <Input
                                                         placeholder="E-Posta Adresi"
                                                         value={emailAddress}
                                                         onChange={handleEmailAddressChange}
                                                         required
                                                     />
                                                 </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                             </Col>
                            ):null
                        }
                       
                        {
                            invoiceType && invoiceType.value === "2" || invoiceType.value === "5" ? (
                                <Col lg="12" style={{ marginTop: "-15px" }}>
                                    <Card style={{ padding: "20px" }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <h4 style={{ marginRight: "auto" }}>İade Fatura Bilgisi</h4>
                                            <div style={{ display: "flex" }}>
                                                <button className="btn btn-success" onClick={addIadeRow}>+Yeni Satır Ekle</button>
                                            </div>
                                        </div>
                                        <div className="table-responsive" style={{ marginTop: "20px" }}>
                                            <table className="table table-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Numarası</th>
                                                        <th>Tarih</th>
                                                        <th>İşlemler</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {iadeRows.map((row, index) => (
                                                        <tr key={index}>
                                                            <td><Input placeholder="Numarası"  onChange={(e) => handleIadeNumberChange(index, e.target.value)}/></td>
                                                            <td><Input placeholder="Tarih" type="date" onChange={(e) => handleIadeDateChange(index, e.target.value)}/></td>
                                                            <td>
                                                                <div style={{ display: "flex" }}>
                                                                    <button className="btn btn-danger" onClick={() => removeIadeRow(index)}>Sil</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card>
                                </Col>
                            ) : null
                        }

                        {/* KDV Dahil ve banka ekleme checkboxları */}
                        {/* Eklenen Banka Listeleri */}
                        {
                            bankAdded === true ?
                                (
                                    <Col lg="12" style={{ marginTop: "-15px" }}>
                                        <Card style={{ padding: "20px" }}>
                                            <h4>Banka Bilgileri</h4>
                                            <div className="table-responsive">
                                                <table className="table table-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Banka Adı</th>
                                                            <th>Şube</th>
                                                            <th>Para Birimi</th>
                                                            <th>Hesap No</th>
                                                            <th>IBAN</th>
                                                            <th>İşlemler</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            banks.map((item, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <th scope="row">{item.id}</th>
                                                                        <td>{item.BankaAdi}</td>
                                                                        <td>{item.Sube}</td>
                                                                        <td>{item.ParaBirimi}</td>
                                                                        <td>{item.HesapNo}</td>
                                                                        <td>{item.Iban}</td>
                                                                        <td>
                                                                            <button className="btn btn-danger" onClick={() => handleBankDelete(item.id)}>Sil</button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card>
                                    </Col>
                                ) : ""
                        }
                        {/* Eklenen Banka Listeleri */}
                        {/* Stok Bilgisi */}
                        <Col lg="12" style={{ marginTop: "-15px", height:"100%" }}>
                            <Card style={{ padding: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <h4 style={{ marginRight: "auto" }}>Stok Bilgileri</h4>
                                    <div style={{ display: "flex" }}>
                                        <button className="btn btn-info" onClick={addGenelIskontoRow} style={{ marginRight: '5px' }}>+Genel İskonto</button>
                                        <button className="btn btn-success" onClick={addRow}>+Yeni Satır Ekle</button>
                                    </div>
                                </div>
                                <div className="table-responsive" style={{ marginTop: "20px", height:"100%" }}>
                                    <table className="table table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th>Stok Adı</th>
                                                <th>Stok Kodu</th>
                                                <th>Barkod</th>
                                                <th>Birim</th>
                                                <th>Miktar</th>
                                                <th>Birim Fiyat
                                                    {selectedOptionMoney && (
                                                        <span>
                                                            {selectedOptionMoney.value === "1" && <span>(TL)</span>}
                                                            {selectedOptionMoney.value === "2" && <span>(USD)</span>}
                                                            {selectedOptionMoney.value === "3" && <span>(EUR)</span>}
                                                            {selectedOptionMoney.value === "4" && <span>(GBP)</span>}
                                                        </span>
                                                    )}
                                                </th>
                                                <th>KDV (%)</th>
                                                <th>Toplam</th>
                                                <th>İşlemler</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rowStates.map((row, index) => (
                                                <React.Fragment key={row.id}>
                                                    <tr >
                                                        <td >
                                                            <Select
                                                                options={productList.length > 0 ? productList.map(t => ({ value: t.id, label: t.stockName })) : []}
                                                                onChange={selectedOption => handleProductChange(selectedOption, index)}
                                                                placeholder="Stok Adı"
                                                                value={rowStates[index].selectedProduct} 
                                                                styles={customStyles}
                                                                menuPortalTarget={document.body}
                                                                menuPosition="fixed"
                                                            />
                                                        </td>
                                                        <td><Input placeholder="Stok Kodu"
                                                            value={row.selectedProduct ? row.selectedProduct.stockCode : ''}
                                                        /></td>
                                                        <td><Input placeholder="Barkodu" value={row.selectedProduct ? row.selectedProduct.stockBarcode : ''} />
                                                        </td>
                                                        <td>
                                                            <Select
                                                                options={stockUnitList.length > 0 ? stockUnitList.map(t => ({ value: t.id, label: t.stockUnitName })) : []}
                                                                placeholder="Birim"
                                                                value={row.selectedStockUnit ? { value: row.selectedStockUnit.id, label: row.selectedStockUnit.stockUnitName } : null}
                                                                onChange={selectedOption => handleStockUnitChange(selectedOption, index)}
                                                                styles={customStyles}
                                                                menuPortalTarget={document.body}
                                                                menuPosition="fixed"
                                                            /></td>
                                                        <td><Input
                                                            placeholder="Miktar"
                                                            value={quantityStates[index].quantity}
                                                            onChange={(e) => handleQuantityChange(e, index)}
                                                        /></td>
                                                        <td>
                                                            <Input
                                                                placeholder="Birim Fiyat"
                                                                value={unitPrices[index]}
                                                                onChange={(e) => handleUnitPriceChange(e, index)}
                                                                readOnly={kdvIncluded ? true : false}   
                                                            />
                                                        </td>
                                                        <td>
                                                            <Select
                                                                options={kdvList.length > 0 ? kdvList.map(t => ({ value: t.id, label: t.kdvRate })) : []}
                                                                placeholder="KDV"
                                                                value={row.selectedStockKdv ? { value: row.selectedStockKdv.id, label: row.selectedStockKdv.kdvRate } : null}
                                                                onChange={selectedOption => handleStockKDVChange(selectedOption, index)}
                                                                styles={customStyles}
                                                                menuPortalTarget={document.body}
                                                                menuPosition="fixed"
                                                            />
                                                         
                                                        </td>
                                                        <td> <Input 
                                                            type="number" 
                                                            value={row.totalPrice || 0} 
                                                            onChange={(e) => handleTotalChange(e, index)}
                                                             readOnly ={kdvIncluded ? false : true} 
                                                        /></td>
                                                        <td>
                                                            <div style={{ display: "flex" }}>
                                                                <button className="btn btn-success" onClick={() => openModal(row.id)} style={{ marginRight: '5px' }}>Ekle</button>
                                                                <button className="btn btn-danger" onClick={() => removeRow(index)}>Sil</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {row.withholding && (
                                                        <tr>
                                                            <td>
                                                                <Label>Tevkifat</Label>
                                                            </td>
                                                            <td colSpan="12">
                                                                <Select
                                                                    options={TevkifatOptions.map(t => ({ value: t.id, label: `${t.Tevkifat} -- Tevkifat Oranı:%${t.TevkifatOrani}` }))}
                                                                    placeholder="Tevkifat"
                                                                    onChange={(e)=>handleTevkifatOraniChange(e,index)}
                                                                    styles={customStyles}
                                                                    menuPortalTarget={document.body}
                                                                    menuPosition="fixed"
                                                                />
                                                            </td>
                                                          
                                                        </tr>
                                                    )}
                                                    {row.discount && (
                                                        <tr>
                                                            <td>
                                                                <Label>İndirim</Label>
                                                            </td>
                                                            <td colSpan="8">
                                                                <Input placeholder="İndirim" onChange={(e)=>handleDiscountChange(e, index)} />
                                                            </td>
                                                        </tr>
                                                    )}

                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {GenelIskontorows.length > 0 && (
                                    <>
                                        <h4 style={{marginTop:"20px"}}>Genel İskonto</h4>
                                        <div className="table-responsive" style={{ marginTop: "20px" }}>
                                    <table className="table table-bordered mb-0">
                                          <tbody>
                                          {GenelIskontorows.map((row, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Label>Sebebi</Label>
                                                </td>
                                                <td>
                                                    <select className="form-control" onChange={(e) => handleInputChange(index, 'sebebi', e.target.value)} >
                                                       <option value="">Lütfen bir tane seçiniz</option>
                                                        <option value="iskonto">İskonto</option>
                                                        <option value="nakitIskonto">Nakit İskonto</option>
                                                        <option value="diger">Diğer</option>
                                                    </select>
                                                  
                                                </td>
                                                <td>
                                                    <Label>Tutar</Label>
                                                </td>
                                                <td>
                                                    <Input
                                                        placeholder="İndirim (%)"
                                                        onChange={(e) => handleInputChange(index, 'indirim', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <button className="btn btn-danger" onClick={() => removeGenelIskontoRow(index)}>Sil</button>
                                                </td>
                                            </tr>
                                        ))}
                                          </tbody>
                                        </table>
                                        </div>
                                    </>
                                )}

                                {/* Hesaplanan değerlerin yazılacağı bölüm */}
                                <div className="table-responsive" style={{ marginTop: "40px" }}>
                                    <table className="table table-bordered mb-0">
                                        <tbody>
                                            <tr>
                                                <td>Ara Toplam</td>
                                                <td>{subTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>Toplam İskonto (İndirim)</td>
                                                <td>{toplamIskonto}</td>
                                            </tr>
                                            <tr>
                                                <td>Net Tutar (Matrah)</td>
                                                <td>{netTutar}</td>
                                            </tr>
                                            <tr>
                                                <td>Hesaplanan KDV</td>
                                                <td>{totalKDV}</td>
                                            </tr>
                                            <tr>
                                                <td>Hesaplanan KDV Tevkifat</td>
                                                <td>{tevkifatTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>Vergiler Dahil Ödenecek Tutar</td>
                                                <td>{vergiDahilOdenecekTutar}</td>
                                            </tr>
                                            <tr>
                                                <td>Genel Toplam</td>
                                                <td>{genelToplam}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {/* Hesaplanan değerlerin yazılacağı bölüm */}
                                {/* Vergi Muafiyet */}
                                {
                                    invoiceType && invoiceType.value === "4" || invoiceType.value === "5" || invoiceType.value === "6" || kdvState===true ? (
                                        <Col lg="12" style={{ marginTop: "25px" }}>
                                            <Card style={{ padding: "20px" }}>

                                                <h4 style={{ marginRight: "auto" }}>Vergi Muafiyet</h4>
                                                <p style={{ color: "red" }}>! İhracat faturasında 301 nolu kodu kullanız . .</p>
                                                <p style={{ color: "red" }}>! İhracat kayıtlı faturalarda 701 , 702 veya 703 nolu kodlar kullanız . .</p>
                                                <Label>İstisna Sebebi</Label>
                                                <select
                                                id="exceptional-select"
                                                className="form-control"
                                                onChange={(e) => handleExplanationInvoiceChange(e)}
                                            >
                                                <option>Lütfen bir tane istisna Sebebi seçiniz</option>
                                                {vergiMuafiyet.map((item, key) => (
                                                    <option key={key} value={item.id}>
                                                        {item.istisnaKodu} - {item.istisnaAciklama}
                                                    </option>
                                                ))}
                                            </select>

                                            </Card>
                                        </Col>
                                    ) : null
                                }
                                {/* Vergi Muafiyet */}
                                <div className="mb-3" style={{ marginTop: "40px" }}>
                                    <Label>Fatura Açıklama</Label>
                                    <textarea
                                        placeholder="Fatura Açıklama"
                                        value={explanation}
                                        onChange={handleExplanationChange}
                                        required
                                        style={{ width: '100%', height: '100px' }}
                                    ></textarea>
                                </div>
                                <Modal isOpen={isModalOpen} toggle={closeModal}>
                                    <div className="modal-header">
                                        <h5 className="modal-title">İşlem Seçiniz</h5>
                                        <button type="button" className="btn-close" onClick={closeModal}></button>
                                    </div>
                                    <div className="modal-body">
                                        <Label>İşlem Seçiniz</Label>
                                        <select className="form-control" onChange={handleSelectIndirimTevkifatChange} value={selectedOptionIndirimTevkifat}>
                                            <option>Lütfen bir tane işlem seçiniz</option>
                                            <option value="1">İndirim (%)</option>
                                            {invoiceType && invoiceType.value === "3" && <option value="2">Tevkifat</option>}
                                        </select>
                                    </div>
                                    <div className="modal-footer">
                                        <Button color="success" onClick={handleAdd}>Ekle</Button>
                                        <Button color="secondary" onClick={closeModal}>Kapat</Button>
                                    </div>
                                </Modal>
                                <div className="mb-3">
                                    <Button className="btn btn-success" style={{ width: '100%' }} onClick={handleSubmit}>Kaydet</Button>
                                </div>
                            </Card>
                        </Col>
                        {/* Stok Bilgisi */}

                    </Row>
                </Container>
            </div>


        </React.Fragment>
    )
}

export default CalculativeEarchive;
