import React, { useEffect, useState } from 'react'
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon'
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown'
import { APIClient } from '../../helpers/api_helper'
import {  Col, Row } from 'reactstrap'

import { toast } from 'react-toastify'
import Select from 'react-select'

export const ModuleUserAdd = ({ userInfo, handleSubmitBtn }) => {
    const api = new APIClient()
    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    const [firstName, setFisrtName] = useState("")
    const [userName, setUserName] = useState("")
    const [password, setpassword] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [telNo, setTelNo] = useState("")
    const [tcNo, setTcNo] = useState("")
    const [province, setProvince] = useState("")
    const [district, setDistrict] = useState("")
    const [userGroups, setUserGroups] = useState([])
    const [userGroup, setUserGroup] = useState("")
    const [groupName, setGroupName] = useState("")

    const [selectUserGroup, setSelectUserGroup] = useState(null)
    const [companyId,setCompanyId] = useState("")
    const [id,setId] = useState(0)
    const [updateBtn,setUpdateBtn] = useState(false)

    const User = {
        id:id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        userName: userName,
        telNo: telNo,
        password: password,
        tcNo: tcNo,
        ilId: province,
        ilceId: district,
        companyId: localStorage.getItem("companyId"),
        createdUserId: userInfo.Id,
        updatedUserId: userInfo.Id,
        userGroupId: userGroup,
        groupName:groupName,
        status:false
    }
  
    useEffect(() => {
        api.get("/UserGroup/UserGroupGetAll").then(res => {
            const formattedOptions = res.map(item => ({
                label: item.groupName, // API'den gelen verilerin label ve value özelliklerine uygun bir şekilde dönüştürülmesi gerekiyor
                value: item.id

            }));
            setUserGroups(formattedOptions);
        })
        setCompanyId(localStorage.getItem("companyId"))

    }, [companyId])

    const handleUserGroup = (selectedOption) => {
        setSelectUserGroup(selectedOption)
        console.log(selectedOption.value)
        setUserGroup(selectedOption == null ? selectedOption : selectedOption.value)
        setGroupName(selectedOption == null ? selectedOption : selectedOption.label)
    }
    // Province DropDown
    const handleProvinceChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption)
        setProvince(selectedOption == null ? selectedOption : selectedOption.value)
        setSelectedDistrictOption(null)

    }
    // District Dropdown
    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrictOption(selectedOption)
        setDistrict(selectedOption == null ? selectedOption : selectedOption.value)
    }

    const handleSubmit = () => {

        api.create("/Auth/Register", User).then(res => {
            if (res.data === false) {
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });

            } else {
                localStorage.setItem("userId", res.id)
                setId(res.id)
                setUpdateBtn(true)
                handleSubmitBtn()
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            }
        })
    }
    const handleSubmitUpdate = () => {
        console.log(User)
        api.create("/Users/UpdateUserDTO", User).then(res => {
            handleSubmitBtn()
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000 
            });
        setUpdateBtn(true)

        }).catch(e => console.log(e))
        toast.error("Kaydedilemedi", {
            position: "bottom-right",
            autoClose: 10000 
        });

    }
    return (
        <form>
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        İsim
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setFisrtName(x.target.value)} placeholder="İsim Giriniz" />
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Soyisim
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setLastName(x.target.value)} placeholder="Soyisim Giriniz" />
                    </div>
                </Col>

            </Row>
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Email
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="email" onChange={x => setEmail(x.target.value)} placeholder="Email Giriniz" />
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Kullanıcı Adı
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setUserName(x.target.value)} placeholder="Kullanıcı Adı Giriniz" />
                    </div>
                </Col>

            </Row>
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Şifre
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="password" onChange={x => setpassword(x.target.value)} placeholder="Şifre Giriniz" />
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Kullanıcı Grubu
                    </label>
                    <div className="col-md-9">
                        <Select
                            value={selectUserGroup}
                            onChange={handleUserGroup}
                            options={userGroups}
                            placeholder="Kullanıcı Seçiniz"
                        />
                    </div>

                </Col>

            </Row>
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label" >
                        Tc No
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setTcNo(x.target.value)} placeholder="Tc No Giriniz" />
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label" >
                        Tel No
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="tel" onChange={x => setTelNo(x.target.value)} placeholder="Tel No Giriniz" />
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <label className="col-md-3 col-form-label">İl</label>
                    <div className="col-md-9">
                        <Province selectedOption={selectedProvinceOption} handleChange={handleProvinceChange} />
                    </div>
                </Col>
                <Col md={6}>
                    <label className="col-md-3 col-form-label">İlçe</label>
                    <div className="col-md-9">
                        <District province={selectedProvinceOption} selectedOption={selectedDistrictOption} handleChange={handleDistrictChange} />
                    </div>
                </Col>
            </Row>
            <Row className="mb-3 mt-5">
                <Col className="d-flex justify-content-center">
                {updateBtn? <Col className="d-flex justify-content-center">
                    <button
                        onClick={handleSubmitUpdate}
                        type="button"
                        className="btn btn-warning waves-effect waves-light"
                    >
                        Güncelle
                    </button>
                </Col>:<Col className="d-flex justify-content-center">
                    <button
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                    >
                        Kaydet
                    </button>
                </Col>
}
                </Col>
            </Row>
        </form>
    )
}
