import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { APIClient } from '../../helpers/api_helper';

export const StockGroupUpdate = ({ user, isChange, group }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [authUser, setAuthUser] = useState(user);

    useEffect(() => {
        setAuthUser(user);
    }, [user]);

    const initialValues = {
        stockGroupName: group.stockGroupName,
        stockGroupCode: group.stockGroupCode,
    };

    const validationSchema = Yup.object().shape({
        stockGroupName: Yup.string().required('Stok Grup Adı zorunludur.'),
        stockGroupCode: Yup.string().required('Stok Grup Kodu zorunludur.'),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        const UserGroup = {
            ...values,
            id: group.id,
            companyId: authUser.CompanyId,
            createdUserId: authUser.Id,
            updatedUserId: authUser.Id,
            status: true,
        };

        const api = new APIClient();
        api.create("/StockGroup/UpdateStockGroup", UserGroup)
            .then(res => {
                console.log("başarılı");
                //navigate('/stokGruplari');
                window.location.href = `/stokGruplari`;

                toast.success("Başarıyla güncellendi", { position: "bottom-right", autoClose: 10000 });
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Güncellenemedi", { position: "bottom-right", autoClose: 10000 });
            })
            .finally(() => {
                setSubmitting(false);
                setOpen(false);
                isChange(); // Parent component'e değişikliği haber ver
            });
    };

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col>
                        <div className="d-flex gap-1">
                            <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => setOpen(!open)}>Düzenle</button>
                        </div>
                        <Modal isOpen={open} toggle={() => setOpen(!open)}>
                            <div className="modal-header">
                                <h5 className="modal-title">Stok Grubu Güncelle</h5>
                                <button type="button" className="btn-close" onClick={() => setOpen(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="mb-3">
                                                <label htmlFor="stockGroupName" className="col-form-label">Stok Grubu Adı</label>
                                                <Field type="text" name="stockGroupName" className="form-control" placeholder="Stok Grup Adı Giriniz" />
                                                <ErrorMessage name="stockGroupName" component="div" className="text-danger" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="stockGroupCode" className="col-form-label">Stok Grup Kodu</label>
                                                <Field type="text" name="stockGroupCode" className="form-control" placeholder="Stok Grup Kodu Giriniz" />
                                                <ErrorMessage name="stockGroupCode" component="div" className="text-danger" />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" onClick={() => setOpen(false)}>Kapat</button>
                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Kaydet</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

