import React, { useEffect, useState, useMemo } from 'react';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';

const SidebarData = () => {
  const api = new APIClient();
  const authUser = useMemo(() => getLoggedinUser(), []); // Bu kısmı useMemo ile sarmaladık
  const [sideBar, setSideBar] = useState([]);


  useEffect(() => {
    const fetchModuleSideBar = async (id) => {
      const res = await api.get(`/MainMenu/GetSideBarByModuleId?moduleId=${id}`);
      console.log("deneme", res);
      const menu = res.map(item => ({
        label: item.label,
        icon: item.icon,
        url: item.url,
        subItem: item.subMenus.map(sub => ({ sublabel: sub.subLabel, link: sub.link }))
      }));

      // Set state with new unique items
      setSideBar(prevSideBar => {
        const uniqueMenu = [...prevSideBar];
        menu.forEach(newItem => {
          const exists = uniqueMenu.some(existingItem => existingItem.label === newItem.label);
          if (!exists) {
            uniqueMenu.push(newItem);
          }
        });
        return uniqueMenu;
      });
    };

    if (authUser.Module) {
      if (typeof authUser.Module === "string") {
        fetchModuleSideBar(authUser.Module);
      } else if (Array.isArray(authUser.Module)) {
        authUser.Module.forEach(moduleId => fetchModuleSideBar(moduleId));
      }
    }
  }, [authUser.Module]);

  return [...sideBar];
};
export default SidebarData;
