import React, { useState, useEffect } from 'react';
import { CurrentGroupUpdateModal } from './CurrentGroupUpdateModal';
import { CurrentGroupDeleteModal } from './CurrentGroupDeleteModal';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';

export const CurrentGroupTableList = ({ user,isChange,userGrupInfo }) => {
    const [selectedItem, setSelectedItem] = useState();


  const [showDetail, setShowDetail] = useState(false);


const handleCloseDetail = () => setShowDetail(false);
    const handleShowDetail = (item) => {
        setShowDetail(true);
        setSelectedItem(item);
    };



    return (
        <React.Fragment>
            <table className="table align-middle table-nowrap" id="customerTable">
                <thead className="table-light">
                    <tr>
                        <th scope="col" style={{ width: "50px" }}>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                            </div>
                        </th>

                        <th className="sort" data-sort="id">No</th>
                        <th className="sort" data-sort="groupName">Cari Grup Adı</th>
                        <th className="sort" data-sort="groupName">Cari Grup Kodu</th>

                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody className="list form-check-all" >
                    {
                    userGrupInfo.map((item, index) => (
                        <tr key={item.id}>
                            <th scope="row">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                </div>
                            </th>

                            <td data-sort="groupName">{index + 1}</td>
                            <td data-sort="currentGroupName">{item.currentGroupName}</td>
                            <td data-sort="currentGroupCode">{item.currentGroupCode}</td>


                            <td style={{ width: "200px" }}>
                                <div className="d-flex gap-2" >
                                    <div className="edit">
                                       <CurrentGroupUpdateModal user={user} group={item} isChange={isChange}/>
                                    </div>
                                    <div className="remove">
                                        <CurrentGroupDeleteModal id={item.id} isChange={isChange}/>
                                    </div>
                                    <div className="editthree">
                                                                        <button className="btn btn-sm btn-info detail-item-btn" onClick={() => handleShowDetail(item)}>Detay</button>
                                                                    </div>

                                </div>
                            </td> 
                        </tr>
                    ))
                    }
            </tbody>
        </table>
        <Modal show={showDetail} onHide={handleCloseDetail} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header id="detayheader" className="modal-header" closeButton>
        <Modal.Title className="modal-title">Detaylar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="modal-content">
        <p className="modal-item"><strong>Cari Grubu:</strong> {selectedItem?.currentGroupName}</p>
        <p className="modal-item"><strong>Cari Grup Kodu:</strong> {selectedItem?.currentGroupCode}</p>

        </div>
    </Modal.Body>
</Modal>

<style jsx>{`
    .modal-content {
        padding: 20px;
    }
    .modal-item {
        margin-bottom: 15px;
        font-size: 16px;
    }
    .modal-title {
        font-size: 20px;
        font-weight: bold;
    }
        #detayheader{
                margin-left: 15px;
                margin-right: 15px;

        }
`}</style>


        </React.Fragment >
    )
}
