
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon';
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown';
import { APIClient } from '../../helpers/api_helper';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';

export const CompanyAddModule = ({ userInfo, handleSubmitBtn }) => {
    const api = new APIClient();

    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required('Firma adı zorunludur'),
        companySector: Yup.string().required('Firma sektörü zorunludur'),
        email: Yup.string().email('Geçerli bir email adresi giriniz').required('Email zorunludur'),
        taxOffice: Yup.string().required('Vergi dairesi zorunludur'),
        VKN_TCKN: Yup.string().required('VKN/TCKN zorunludur'),
        telNo: Yup.string().required('Telefon numarası zorunludur'),
        province: Yup.string().required('İl seçiniz'),
        district: Yup.string().required('İlçe seçiniz'),
        address: Yup.string().required('Adres zorunludur'),
    });

    const formik = useFormik({
        initialValues: {
            companyName: '',
            companySector: '',
            email: '',
            taxOffice: '',
            VKN_TCKN: '',
            telNo: '',
            province: '',
            district: '',
            address: '',
        },
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const handleProvinceChange = (selectedOption) => {
        formik.setFieldValue('province', selectedOption ? selectedOption.value : '');
        formik.setFieldValue('district', '');
    };

    const handleDistrictChange = (selectedOption) => {
        formik.setFieldValue('district', selectedOption ? selectedOption.value : '');
    };

    const handleSubmit = (values) => {
        const Company = {
            ...values,
            companyId: userInfo.CompanyId,
            createdUserId: userInfo.Id,
            updatedUserId: userInfo.Id,
            isDemo: false,
            status: false,
        };

        const endpoint = values.id ? '/Companies/UpdateCompany' : '/Companies/AddCompany';

        api.create(endpoint, Company)
            .then((res) => {
                localStorage.setItem('companyId', res.id);
                formik.resetForm();
                handleSubmitBtn();
                toast.success(values.id ? 'Başarıyla güncellendi' : 'Ekleme başarılı');
            })
            .catch((error) => {
                toast.error('Bir hata oluştu');
            });
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
            <Col lg="6">
    <label htmlFor="companyName" className="col-md-3 col-form-label">
        Firma Adı
    </label>
    <div className="col-md-9">
        <input
            id="companyName"
            name="companyName"
            className="form-control"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyName}
            placeholder="Firma Adı Giriniz"
        />
        {formik.touched.companyName && formik.errors.companyName ? (
            <div className="text-danger">{formik.errors.companyName}</div>
        ) : null}
    </div>
</Col>
<Col lg="6">
    <label htmlFor="companySector" className="col-md-3 col-form-label">
        Firma Sektör
    </label>
    <div className="col-md-9">
        <input
            id="companySector"
            name="companySector"
            className="form-control"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companySector}
            placeholder="Firma Sektörü Giriniz"
        />
        {formik.touched.companySector && formik.errors.companySector ? (
            <div className="text-danger">{formik.errors.companySector}</div>
        ) : null}
    </div>
</Col>
<Col lg="6">
    <label htmlFor="email" className="col-md-3 col-form-label">
        Email
    </label>
    <div className="col-md-9">
        <input
            id="email"
            name="email"
            className="form-control"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            placeholder="Email Giriniz"
        />
        {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
        ) : null}
    </div>
</Col>
<Col lg="6">
    <label htmlFor="taxOffice" className="col-md-3 col-form-label">
        Vergi Dairesi
    </label>
    <div className="col-md-9">
        <input
            id="taxOffice"
            name="taxOffice"
            className="form-control"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.taxOffice}
            placeholder="Vergi Dairesi Giriniz"
        />
        {formik.touched.taxOffice && formik.errors.taxOffice ? (
            <div className="text-danger">{formik.errors.taxOffice}</div>
        ) : null}
    </div>
</Col>
<Col lg="6">
    <label htmlFor="VKN_TCKN" className="col-md-3 col-form-label">
        VKN/TCKN
    </label>
    <div className="col-md-9">
        <input
            id="VKN_TCKN"
            name="VKN_TCKN"
            className="form-control"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.VKN_TCKN}
            placeholder="VKN/TCKN Giriniz"
        />
        {formik.touched.VKN_TCKN && formik.errors.VKN_TCKN ? (
            <div className="text-danger">{formik.errors.VKN_TCKN}</div>
        ) : null}
    </div>
</Col>
<Col lg="6">
    <label htmlFor="telNo" className="col-md-3 col-form-label">
        Tel No
    </label>
    <div className="col-md-9">
        <input
            id="telNo"
            name="telNo"
            className="form-control"
            type="tel"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.telNo}
            placeholder="Tel No Giriniz"
        />
        {formik.touched.telNo && formik.errors.telNo ? (
            <div className="text-danger">{formik.errors.telNo}</div>
        ) : null}
    </div>
</Col>
<Col md={6}>
    <label htmlFor="province" className="col-md-3 col-form-label">
        İl
    </label>
    <div className="col-md-9">
        <Province
            selectedOption={formik.values.province}
            handleChange={handleProvinceChange}
        />
        {formik.touched.province && formik.errors.province ? (
            <div className="text-danger">{formik.errors.province}</div>
        ) : null}
    </div>
</Col>
<Col md={6}>
    <label htmlFor="district" className="col-md-3 col-form-label">
        İlçe
    </label>
    <div className="col-md-9">
        <District
            province={formik.values.province}
            selectedOption={formik.values.district}
            handleChange={handleDistrictChange}
        />
        {formik.touched.district && formik.errors.district ? (
            <div className="text-danger">{formik.errors.district}</div>
        ) : null}
    </div>
</Col>
<Col lg="6">
    <label htmlFor="address" className="col-md-3 col-form-label">
        Adres
    </label>
    <div className="col-md-9">
        <textarea
            id="address"
            name="address"
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            placeholder="Adres Giriniz"
        />
        {formik.touched.address && formik.errors.address ? (
            <div className="text-danger">{formik.errors.address}</div>
        ) : null}
    </div>
</Col>
            </Row>
            <Row className="mb-3 mt-5">
                <Col className="d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary waves-effect waves-light">
                        Kaydet
                    </button>
                </Col>
            </Row>
        </form>
    );
};
{/*import React, { useEffect, useState } from 'react'
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon'
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown'
import { APIClient } from '../../helpers/api_helper'
import { Col, Row } from 'reactstrap'

import {  toast } from 'react-toastify'
export const CompanyAddModule = ({ userInfo, handleSubmitBtn }) => {
    const api = new APIClient()
    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    const [companyName, setCompanyName] = useState("")
    const [isDemo, setIsDemo] = useState("")
    const [address, setAddress] = useState("")
    const [email, setEmail] = useState("")
    const [taxOffice, setTaxOffice] = useState("")
    const [companySector, setCompanySector] = useState("")
    const [telNo, setTelNo] = useState("")
    const [VKN_TCKN, setVKN_TCKN] = useState("")
    const [province, setProvince] = useState("")
    const [district, setDistrict] = useState("")
    const [updateBtn, setUpdateBtn] = useState(false)
    const [id, setId] = useState(0)
    const Company = {
        id: id,
        companyName: companyName,
        companySector: companySector,
        email: email,
        telNo: telNo,
        address: address,
        taxOffice: taxOffice,
        VKN_TCKN: VKN_TCKN,
        provinceId: province,
        districtId: district,
        companyId: userInfo.CompanyId,
        createdUserId: userInfo.Id,
        updatedUserId: userInfo.Id,
        isDemo: false,
        status: false
    }
    useEffect(() => {

    }, [])

    // Province DropDown
    const handleProvinceChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption)
        setProvince(selectedOption == null ? selectedOption : selectedOption.value)
        setSelectedDistrictOption(null)

    }
    // District Dropdown
    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrictOption(selectedOption)
        setDistrict(selectedOption == null ? selectedOption : selectedOption.value)
    }

    const handleSubmit = () => {

        api.create("/Companies/AddCompany", Company).then(res => {
            localStorage.setItem("companyId", res.id);
            setId(res.id)
            handleSubmitBtn()
            toast.success("Ekleme Başarılı");
            setUpdateBtn(true)

        }).catch(e => console.log(e))
    }
    const handleSubmitUpdate = () => {

        api.create("/Companies/UpdateCompany", Company).then(res => {
            handleSubmitBtn()
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000 
            });
        setUpdateBtn(true)

        }).catch(e => console.log(e))
        toast.error("Kaydedilemedi", {
            position: "bottom-right",
            autoClose: 10000 
        });

    }
    return (
        <form>
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Firma Adı
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setCompanyName(x.target.value)} placeholder="Firma Adı" />
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Firma Sektör
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setCompanySector(x.target.value)} placeholder="Firma Sektör" />
                    </div>
                </Col>

            </Row>
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Email
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="email" onChange={x => setEmail(x.target.value)} placeholder="Email" />
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Vergi Dairesi
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setTaxOffice(x.target.value)} placeholder="Vergi Dairesi" />
                    </div>
                </Col>

            </Row>

            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        VKN/TCKN
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" onChange={x => setVKN_TCKN(x.target.value)} placeholder="VKN/TCKN" />
                    </div>
                </Col>
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label" >
                        Tel No
                    </label>
                    <div className="col-md-9">
                        <input className="form-control" type="tel" onChange={x => setTelNo(x.target.value)} placeholder="Tel No" />
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <label className="col-md-3 col-form-label">İl</label>
                    <div className="col-md-9">
                        <Province selectedOption={selectedProvinceOption} handleChange={handleProvinceChange} />
                    </div>
                </Col>
                <Col md={6}>
                    <label className="col-md-3 col-form-label">İlçe</label>
                    <div className="col-md-9">
                        <District province={selectedProvinceOption} selectedOption={selectedDistrictOption} handleChange={handleDistrictChange} />
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg="6">
                    <label htmlFor="example-text-input" className="col-md-3 col-form-label">
                        Adres
                    </label>
                    <div className="col-md-9">
                        <textarea className="form-control" onChange={x => setAddress(x.target.value)} placeholder="Adres"></textarea>
                    </div>
                </Col>
            </Row>
            <Row className="mb-3 mt-5">
                {updateBtn ? <Col className="d-flex justify-content-center">
                    <button
                        onClick={handleSubmitUpdate}
                        type="button"
                        className="btn btn-warning waves-effect waves-light"
                    >
                        Güncelle
                    </button>
                </Col> : <Col className="d-flex justify-content-center">
                    <button
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                    >
                        Kaydet
                    </button>
                </Col>
                }


            </Row>
        </form>
    )
}
*/}