import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import BankTransferAdd from '../../../Pages/BankTransfer/BankTransferAdd';
import BankTransferDelete from '../../../Pages/BankTransfer/DeleteBankTransfer';
import { APIClient, getLoggedinUser } from '../../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';
//const TableListModal = () => {
    const TableListModal = (props) => {        

        const navigate = useNavigate();
        
        const [showDetail, setShowDetail] = useState(false);

        const handleCloseDetail = () => setShowDetail(false);
    const handleShowDetail = (item) => {
        setShowDetail(true);
        setSelectedItem(item);
    };



    const [filter, setFilter] = useState('tumu');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [bankTransfers, setBankTransfers] = useState([]);

    const api = new APIClient();
    const authUser = getLoggedinUser();
    const companyId = authUser.CompanyId;

   {/* useEffect(() => {
        fetch(`https://localhost:7199/api/BankTransfer/GetAll?companyId=${companyId}`)
            .then(response => response.json())
            .then(data => {
                console.log(data, "Data")
                setBankTransfers(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []); */}

    useEffect(() => {
        setBankTransfers([...props.companyArray]);
    }, [props.companyArray]);


    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowDeleteModal = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const columnsToShow = {
        tumu: [
            { key: 'heOrBvOrBkt', name: 'Transfer Türü' },
            { key: 'bankIdName', name: 'Banka Hesabı' },
            { key: 'currentAccountIdName', name: 'Cari Adı' },
            { key: 'sourceBankIdName', name: 'Kaynak Banka Hesabı' },
            { key: 'targetBankIdName', name: 'Hedef Banka Hesabı' },
            { key: 'targetCashboxIdName', name: 'Hedef Kasa Hesabı' },
            { key: 'date', name: 'Tarih' },
            { key: 'amount', name: 'Tutar' },
            { key: 'operationType', name: 'İşlem Tipi' },
            { key: 'explanation', name: 'Açıklama' },
            { key: 'document_Receipt_No', name: 'Belge / Mabuz No' }
        ],
        havaleeft: [
            { key: 'heOrBvOrBkt', name: 'Transfer Türü' },
            { key: 'bankIdName', name: 'Banka Hesabı' },
            { key: 'currentAccountIdName', name: 'Cari Adı' },
            { key: 'date', name: 'Tarih' },
            { key: 'amount', name: 'Tutar' },
            { key: 'operationType', name: 'İşlem Tipi' },
            { key: 'explanation', name: 'Açıklama' },
            { key: 'document_Receipt_No', name: 'Belge / Mabuz No' }
        ],
        bankavirman: [
            { key: 'heOrBvOrBkt', name: 'Transfer Türü' },
            { key: 'sourceBankIdName', name: 'Kaynak Banka Hesabı' },
            { key: 'targetBankIdName', name: 'Hedef Banka Hesabı' },
            { key: 'date', name: 'Tarih' },
            { key: 'amount', name: 'Tutar' },
            { key: 'explanation', name: 'Açıklama' },
            { key: 'document_Receipt_No', name: 'Belge / Mabuz No' }
        ],
        bankadankasayatransfer: [
            { key: 'heOrBvOrBkt', name: 'Transfer Türü' },
            { key: 'sourceBankIdName', name: 'Kaynak Banka Hesabı' },
            { key: 'targetCashboxIdName', name: 'Hedef Kasa Hesabı' },
            { key: 'date', name: 'Tarih' },
            { key: 'amount', name: 'Tutar' },
            { key: 'explanation', name: 'Açıklama' },
            { key: 'document_Receipt_No', name: 'Belge / Mabuz No' }
        ]
    };

    const filteredBankTransfers = () => {
        if (filter === 'havaleeft') {
            return bankTransfers.filter(item => item.heOrBvOrBkt === 'Havale / Eft');
        } else if (filter === 'bankavirman') {
            return bankTransfers.filter(item => item.heOrBvOrBkt === 'Banka Virman');
        } else if (filter === 'bankadankasayatransfer') {
            return bankTransfers.filter(item => item.heOrBvOrBkt === 'Bankadan Kasaya Transfer');
        } else {
            return bankTransfers;
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <div id="customerList">
                                <Row className="g-4 mb-3">
                                    <Col className="col-sm-auto">
                                       {/* <Button color="success" className="add-btn" onClick={handleShowAddModal}>
                                            <i className="ri-add-line align-bottom me-1"></i> Ekle
                                        </Button>*/}
                                     <Button color="success" className="add-btn" onClick={() => {
                                               // window.location.href = `/bankaTransferEkle`;
                                                navigate('/bankaTransferEkle');
                                            }}>
                                                <i className="ri-add-line align-bottom me-1"></i> Ekle
                                            </Button>
                                    </Col>
                                    <Col className="col-sm-auto">
                                        <div className="d-flex gap-1">
                                            <select className="form-control" value={filter} onChange={(e) => setFilter(e.target.value)}>
                                                <option value="tumu">Tümü</option>
                                                <option value="havaleeft">Havale/Eft</option>
                                                <option value="bankavirman">Banka Virman</option>
                                                <option value="bankadankasayatransfer">Bankadan Kasaya Transfer</option>
                                            </select>
                                        </div>
                                    </Col>                              
                                </Row>
                                <div className="table-responsive table-card mt-3 mb-1">
                                    <table className="table align-middle table-nowrap" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                {columnsToShow[filter].map((column, index) => (
                                                    <th key={index}>{column.name}</th>
                                                ))}
                                                <th style={{ width: '200px' }}>İşlemler</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {filteredBankTransfers().map((item, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {columnsToShow[filter].map((column, colIndex) => (
                                                        <td key={colIndex}>{item[column.key]}</td>
                                                    ))}
                                                    <td style={{ width: '200px' }}>
                                                        <div className="d-flex gap-2">
                                                            <div className="edit">
                                                                <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => navigate(`/bankaTransferGuncelleSayfa?parametre=${item.id}`)}>Düzenle</button>
                                                                {/*<button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/bankaTransferGuncelleSayfa?parametre=${item.id}`}>Düzenle</button>*/}

                                                            </div>
                                                            <div className="edittwo">
                                                                <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShowDeleteModal(item)}>Sil</button>
                                                            </div>
                                                            <div className="editthree">
                                                                        <button className="btn btn-sm btn-info detail-item-btn" onClick={() => handleShowDetail(item)}>Detay</button>
                                                                    </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal show={showAddModal} onHide={handleCloseAddModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferAdd handleClose={handleCloseAddModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferDelete selectedItem={selectedItem} handleClose={handleCloseDeleteModal} />
                </Modal.Body>
            </Modal>
            <Modal show={showDetail} onHide={handleCloseDetail} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header id="detayheader" className="modal-header" closeButton>
        <Modal.Title className="modal-title">Detaylar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="modal-content">
        <p className="modal-item"><strong>Transfer Türü:</strong> {selectedItem?.heOrBvOrBkt} </p>
        <p className="modal-item"><strong>Banka Hesabı:</strong> {selectedItem?.bankId} </p>
        <p className="modal-item"><strong>Kaynak Banka Hesabı:</strong> {selectedItem?.sourceBankId}</p>
        <p className="modal-item"><strong>Hedef Banka Hesabı:</strong> {selectedItem?.targetBankId}</p>
        <p className="modal-item"><strong>Hedef Kasa Adı:</strong> {selectedItem?.targetCashboxId}</p>
        <p className="modal-item"><strong>Cari Adı:</strong> {selectedItem?.currentAccountId}</p>
        <p className="modal-item"><strong>Tarih:</strong> {selectedItem?.date}</p>
        <p className="modal-item"><strong>Tutar:</strong> {selectedItem?.amount}</p>
        <p className="modal-item"><strong>İşlem Tipi:</strong> {selectedItem?.operationType}</p>
        <p className="modal-item"><strong>Açıklama:</strong> {selectedItem?.explanation}</p>
        <p className="modal-item"><strong>Belge / Makbuz No:</strong> {selectedItem?.document_Receipt_No}</p>

        
        
        
        
        
        
        
        
        
        
        
        </div>
    </Modal.Body>
</Modal>

<style jsx>{`
    .modal-content {
        padding: 20px;
    }
    .modal-item {
        margin-bottom: 15px;
        font-size: 16px;
    }
    .modal-title {
        font-size: 20px;
        font-weight: bold;
    }
        #detayheader{
                margin-left: 15px;
                margin-right: 15px;

        }
`}</style>


        </Container>
    );
}

export default TableListModal;

{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import BankTransferAdd from '../../../Pages/BankTransfer/BankTransferAdd';
import BankTransferDelete from '../../../Pages/BankTransfer/DeleteBankTransfer';
import { APIClient, getLoggedinUser } from '../../../helpers/api_helper'

const TableListModal = () => {
    const [filter, setFilter] = useState('tumu');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [bankTransfers, setBankTransfers] = useState([]);

    const api = new APIClient();
    const authUser = getLoggedinUser();
    const companyId = authUser.CompanyId;

    useEffect(() => {
        fetch(`https://localhost:7199/api/BankTransfer/GetAll?companyId=${companyId}`)
      //  fetch('https://localhost:7199/api/BankTransfer/GetAll?companyId=1')
            .then(response => response.json())
            .then(data => {
                console.log(data,"Data")
                setBankTransfers(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowDeleteModal = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => setShowDeleteModal(false);

        const columnsToShow = {
        tumu: ['heOrBvOrBkt', 'bankIdName', 'currentAccountIdName', 'sourceBankIdName', 'targetBankIdName', 'targetCashboxIdName', 'date', 'amount', 'operationType', 'explanation', 'document_Receipt_No'],
        havaleeft: ['heOrBvOrBkt', 'bankIdName', 'currentAccountIdName', 'date', 'amount', 'operationType', 'explanation', 'document_Receipt_No'],
        bankavirman: ['heOrBvOrBkt', 'sourceBankIdName', 'targetBankIdName', 'date', 'amount', 'explanation', 'document_Receipt_No'],
        bankadankasayatransfer: ['heOrBvOrBkt', 'sourceBankIdName', 'targetCashboxIdName', 'date', 'amount', 'explanation', 'document_Receipt_No']
    };

    const filteredBankTransfers = () => {
        if (filter === 'havaleeft') {
            return bankTransfers.filter(item => item.heOrBvOrBkt === 'Havale / Eft');
        } else if (filter === 'bankavirman') {
            return bankTransfers.filter(item => item.heOrBvOrBkt === 'Banka Virman');
        } else if (filter === 'bankadankasayatransfer') {
            return bankTransfers.filter(item => item.heOrBvOrBkt === 'Bankadan Kasaya Transfer');
        } else {
            return bankTransfers;
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <div id="customerList">
                                <Row className="g-4 mb-3">
                                    <Col className="col-sm-auto">
                                 
                                         <Button color="success" className="add-btn" onClick={() => {
                                                window.location.href = `/bankaTransferEkle`;
                                            }}>
                                                <i className="ri-add-line align-bottom me-1"></i> Ekle
                                            </Button>
                                    </Col>
                                    <Col className="col-sm-auto">
                                        <div className="d-flex gap-1">
                                            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                                                <option value="tumu">Tümü</option>
                                                <option value="havaleeft">Havale/Eft</option>
                                                <option value="bankavirman">Banka Virman</option>
                                                <option value="bankadankasayatransfer">Bankadan Kasaya Transfer</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="table-responsive table-card mt-3 mb-1">
                                    <table className="table align-middle table-nowrap" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                {columnsToShow[filter].map((column, index) => (
                                                    <th key={index}>{column}</th>
                                                ))}
                                                <th style={{ width: '200px' }}>İşlemler</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {filteredBankTransfers().map((item, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {columnsToShow[filter].map((column, colIndex) => (
                                                        <td key={colIndex}>{item[column]}</td>
                                                    ))}
                                                    <td style={{ width: '200px' }}>
                                                        <div className="d-flex gap-2">
                                                            <div className="edit">
                                                            <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/bankaTransferGuncelleSayfa?parametre=${item.id}`}>Düzenle</button>
                                                            

                                                            </div>
                                                            <div className="edittwo">
                                                            <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShowDeleteModal(item)}>Sil</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        
            <Modal show={showAddModal} onHide={handleCloseAddModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferAdd handleClose={handleCloseAddModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferDelete selectedItem={selectedItem} handleClose={handleCloseDeleteModal} />
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default TableListModal;
*/}



{/*
import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import BankTransferAdd from '../../../Pages/BankTransfer/BankTransferAdd';
import BankTransferDelete from '../../../Pages/BankTransfer/DeleteBankTransfer';

const TableListModal = () => {
    const [filter, setFilter] = useState('tumu');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    // Statik olarak örnek veri
    const table = [
        {
            id: 1,
            HeOrBvOrBkt: 'Havale / Eft',
            BankIdName: 'Bank 1',
            CurrentAccountIdName: 'Hesap 1',
            SourceBankIdName: 'Kaynak Banka 1',
            TargetBankIdName: 'Hedef Banka 1',
            TargetCashboxIdName: 'Hedef Kasa 1',
            Date: '2024-06-13',
            Amount: 1000,
            OperationType: 'Transfer',
            Explanation: 'Açıklama 1',
            Document_Receipt_No: '123ABC'
        },
        {
            id: 1,
            HeOrBvOrBkt: 'Banka Virman',
            BankIdName: 'Bank 1',
            CurrentAccountIdName: 'Hesap 1',
            SourceBankIdName: 'Kaynak Banka 1',
            TargetBankIdName: 'Hedef Banka 1',
            TargetCashboxIdName: 'Hedef Kasa 1',
            Date: '2024-06-13',
            Amount: 1000,
            OperationType: 'Transfer',
            Explanation: 'Açıklama 1',
            Document_Receipt_No: '123ABC'
        },
        {
            id: 1,
            HeOrBvOrBkt: 'Bankadan Kasaya Transfer',
            BankIdName: 'Bank 1',
            CurrentAccountIdName: 'Hesap 1',
            SourceBankIdName: 'Kaynak Banka 1',
            TargetBankIdName: 'Hedef Banka 1',
            TargetCashboxIdName: 'Hedef Kasa 1',
            Date: '2024-06-13',
            Amount: 1000,
            OperationType: 'Transfer',
            Explanation: 'Açıklama 1',
            Document_Receipt_No: '123ABC'
        }
        // Diğer örnek veriler...
    ];

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowDeleteModal = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const columnsToShow = {
        tumu: ['HeOrBvOrBkt', 'BankIdName', 'CurrentAccountIdName', 'SourceBankIdName', 'TargetBankIdName', 'TargetCashboxIdName', 'Date', 'Amount', 'OperationType', 'Explanation', 'Document_Receipt_No'],
        havaleeft: ['HeOrBvOrBkt', 'BankIdName', 'CurrentAccountIdName', 'Date', 'Amount', 'OperationType', 'Explanation', 'Document_Receipt_No'],
        bankavirman: ['HeOrBvOrBkt', 'SourceBankIdName', 'TargetBankIdName', 'Date', 'Amount', 'Explanation', 'Document_Receipt_No'],
        bankadankasayatransfer: ['HeOrBvOrBkt', 'SourceBankIdName', 'TargetCashboxIdName', 'Date', 'Amount', 'Explanation', 'Document_Receipt_No']
    };

    const filteredBankTransfers = () => {
        if (filter === 'havaleeft') {
            return table.filter(item => item.HeOrBvOrBkt === 'Havale / Eft');
        } else if (filter === 'bankavirman') {
            return table.filter(item => item.HeOrBvOrBkt === 'Banka Virman');
        } else if (filter === 'bankadankasayatransfer') {
            return table.filter(item => item.HeOrBvOrBkt === 'Bankadan Kasaya Transfer');
        } else {
            return table;
        }
    };

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <Button color="success" className="add-btn" onClick={handleShowAddModal}>
                                                <i className="ri-add-line align-bottom me-1"></i> Ekle
                                            </Button>
                                        </Col>
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                                                    <option value="tumu">Tümü</option>
                                                    <option value="havaleeft">Havale/Eft</option>
                                                    <option value="bankavirman">Banka Virman</option>
                                                    <option value="bankadankasayatransfer">Bankadan Kasaya Transfer</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    {columnsToShow[filter].map((column, index) => (
                                                        <th key={index}>{column}</th>
                                                    ))}
                                                    <th style={{ width: '200px' }}>İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {filteredBankTransfers().map((item, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {columnsToShow[filter].map((column, colIndex) => (
                                                            <td key={column}>{item[column]}</td>
                                                        ))}
                                                        <td style={{ width: '200px' }}>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/bankaTransferGuncelleSayfa?parametre=${item.id}`}>Düzenle</button>
                                                                </div>
                                                                <div className="edittwo">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShowDeleteModal(item)}>Sil</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={showAddModal} onHide={handleCloseAddModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferAdd handleClose={handleCloseAddModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferDelete selectedItem={selectedItem} handleClose={handleCloseDeleteModal} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default TableListModal;
*/}


{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import BankTransferAdd from '../../../Pages/BankTransfer/BankTransferAdd';
import BankTransferDelete from '../../../Pages/BankTransfer/DeleteBankTransfer';

const TableListModal = (props) => {
    const [filter, setFilter] = useState('tumu');
    const [table, setTable] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        setTable([...props.companyArray]);
    }, [props.companyArray]);

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowDeleteModal = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const columnsToShow = {
        tumu: ['HeOrBvOrBkt', 'BankIdName', 'CurrentAccountIdName', 'SourceBankIdName', 'TargetBankIdName', 'TargetCashboxIdName', 'Date', 'Amount', 'OperationType', 'Explanation', 'Document_Receipt_No'],
        havaleeft: ['HeOrBvOrBkt', 'BankIdName', 'CurrentAccountIdName', 'Date', 'Amount', 'OperationType', 'Explanation', 'Document_Receipt_No'],
        bankavirman: ['HeOrBvOrBkt', 'SourceBankIdName', 'TargetBankIdName', 'Date', 'Amount', 'Explanation', 'Document_Receipt_No'],
        bankadankasayatransfer: ['HeOrBvOrBkt', 'SourceBankIdName', 'TargetCashboxIdName', 'Date', 'Amount', 'Explanation', 'Document_Receipt_No']
    };

    const filteredBankTransfers = () => {
        if (filter === 'havaleeft') {
            return table.filter(item => item.HeOrBvOrBkt === 'Havale / Eft');
        } else if (filter === 'bankavirman') {
            return table.filter(item => item.HeOrBvOrBkt === 'Banka Virman');
        } else if (filter === 'bankadankasayatransfer') {
            return table.filter(item => item.HeOrBvOrBkt === 'Bankadan Kasaya Transfer');
        } else {
            return table;
        }
    };

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <Button color="success" className="add-btn" onClick={handleShowAddModal}>
                                                <i className="ri-add-line align-bottom me-1"></i> Ekle
                                            </Button>
                                        </Col>
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                                                    <option value="tumu">Tümü</option>
                                                    <option value="havaleeft">Havale/Eft</option>
                                                    <option value="bankavirman">Banka Virman</option>
                                                    <option value="bankadankasayatransfer">Bankadan Kasaya Transfer</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    {columnsToShow[filter].map((column, index) => (
                                                        <th key={index}>{column}</th>
                                                    ))}
                                                    <th style={{ width: '200px' }}>İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {filteredBankTransfers().map((item, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {columnsToShow[filter].map((column, colIndex) => (
                                                            <td key={column}>{item[column]}</td>
                                                        ))}
                                                        <td style={{ width: '200px' }}>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/bankaTransferGuncelleSayfa?parametre=${item.id}`}>Düzenle</button>
                                                                </div>
                                                                <div className="edittwo">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShowDeleteModal(item)}>Sil</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={showAddModal} onHide={handleCloseAddModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferAdd handleClose={handleCloseAddModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferDelete selectedItem={selectedItem} handleClose={handleCloseDeleteModal} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default TableListModal;
*/}





{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import BankTransferAdd from '../../../Pages/BankTransfer/BankTransferAdd';
import BankTransferUpdate from '../../../Pages/BankTransfer/BankTransferUpdate';
import BankTransferDelete from '../../../Pages/BankTransfer/DeleteBankTransfer';

const TableListModal = (props) => {
    const [filter, setFilter] = useState('tumu'); // default value is 'tumu', can be 'havaleeft', 'bankavirman', or 'bankadankasayatransfer'
    const [table, setTable] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);

    useEffect(() => {
        setTable([...props.companyArray]);
    }, [props.companyArray]);

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowDeleteModal = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    // Columns to display based on filter selection
    const columnsToShow = {
        tumu: ['HeOrBvOrBkt', 'BankIdName', 'CurrentAccountIdName', 'SourceBankIdName', 'TargetBankIdName', 'TargetCashboxIdName', 'Date', 'Amount', 'OperationType', 'Explanation', 'Document_Receipt_No'],
        havaleeft: ['HeOrBvOrBkt', 'BankIdName', 'CurrentAccountIdName', 'Date', 'Amount', 'OperationType', 'Explanation', 'Document_Receipt_No'],
        bankavirman: ['HeOrBvOrBkt', 'SourceBankIdName', 'TargetBankIdName', 'Date', 'Amount', 'Explanation', 'Document_Receipt_No'],
        bankadankasayatransfer: ['HeOrBvOrBkt', 'SourceBankIdName', 'TargetCashboxIdName', 'Date', 'Amount', 'Explanation', 'Document_Receipt_No']
    };

    // Filter function to apply based on filter selection
    const filteredBankTransfers = () => {
        if (filter === 'havaleeft') {
            return table.filter(item => item.HeOrBvOrBkt === 'Havale / Eft');
        } else if (filter === 'bankavirman') {
            return table.filter(item => item.HeOrBvOrBkt === 'Banka Virman');
        } else if (filter === 'bankadankasayatransfer') {
            return table.filter(item => item.HeOrBvOrBkt === 'Bankadan Kasaya Transfer');
        } else {
            return table;
        }
    };

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <Button color="success" className="add-btn" onClick={handleShowAddModal}>
                                                <i className="ri-add-line align-bottom me-1"></i> Ekle
                                            </Button>
                                        </Col>
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                                                    <option value="tumu">Tümü</option>
                                                    <option value="havaleeft">Havale/Eft</option>
                                                    <option value="bankavirman">Banka Virman</option>
                                                    <option value="bankadankasayatransfer">Bankadan Kasaya Transfer</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    {columnsToShow[filter].map((column, index) => (
                                                        <th key={index}>{column}</th>
                                                    ))}
                                                    <th style={{ width: '200px' }}>İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {filteredBankTransfers().map(item => (
                                                    <tr key={item.id}>
                                                        {columnsToShow[filter].map((column, index) => (
                                                            <td key={index}>{item[column]}</td>
                                                        ))}
                                                        <td style={{ width: '200px' }}>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/bankaTransferGuncelleSayfa?parametre=${item.id}`}>Düzenle</button>
                                                                </div>
                                                                <div className="edittwo">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShowDeleteModal(item)}>Sil</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={showAddModal} onHide={handleCloseAddModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferAdd handleClose={handleCloseAddModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferDelete selectedItem={selectedItem} handleClose={handleCloseDeleteModal} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default TableListModal;
*/}





{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import BankTransferAdd from '../../../Pages/BankTransfer/BankTransferAdd';
import BankTransferUpdate from '../../../Pages/BankTransfer/BankTransferUpdate';
import BankTransferDelete from '../../../Pages/BankTransfer/DeleteBankTransfer';



const TableListModal = (props) => {
    const [filter, setFilter] = useState('Tumu'); // default value is 'all', can be 'girls', 'boys', or 'all'

    const [table, setTable] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);


    useEffect(() => {
        setTable([...props.companyArray]);

         // Sample student data (static)
  const banktransfers = [
    { id: props.companyArray.id, heOrBvOrBkt: props.companyArray.heOrBvOrBkt , bankIdName: props.companyArray.bankIdName , currentAccountIdName:  props.companyArray.currentAccountIdName, sourceBankIdName:  props.companyArray.sourceBankIdName ,  targetBankIdName:  props.companyArray.targetBankIdName , targetCashboxIdName:  props.companyArray.targetCashboxIdName , date:  props.companyArray.date , amount:  props.companyArray.amount , operationType:  props.companyArray.operationType  explanation: props.companyArray.explanation  , document_Receipt_No:  props.companyArray.document_Receipt_No},
    // Add more student data as needed
  ];


    }, [props.companyArray]);
   

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowDeleteModal = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);



 

  // Columns to display based on filter selection
  const columnsToShow = {
    tumu: ['HeOrBvOrBkt', 'BankId', 'CurrentAccountId', 'SourceBankId' , 'TargetBankId' , 'TargetCashboxId' , 'Date' ,  'Amount' , 'OperationType' , 'Explanation' , 'Document_Receipt_No'  ],
    havaleeft: ['HeOrBvOrBkt', 'BankId', 'CurrentAccountId', 'Date', 'Amount', 'OperationType' , 'Explanation' , 'Document_Receipt_No '],
    bankavirman: ['HeOrBvOrBkt', 'SourceBankId', 'TargetBankId', 'Date', 'Amount', 'Explanation' , 'Document_Receipt_No'],
    bankadankasayatransfer: ['HeOrBvOrBkt', 'SourceBankId', 'TargetCashboxId', 'Date', 'Amount', 'Explanation' , 'Document_Receipt_No'],

  };

  // Filter function to apply based on filter selection
  const filteredBankTransfers = () => {
    if (filter === 'havaleeft') {
      return students.filter(student => student.gender === 'Havale / Eft');
    } else if (filter === 'bankavirman') {
      return students.filter(student => student.gender === 'Banka Virman');
    } else if (filter === 'bankadankasayatransfer') {
        return students.filter(student => student.gender === 'Bankadan Kasaya Transfer');
      }else {
      return students;
    }
  };

  return (
    <React.Fragment>
    <Container fluid>
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <div id="customerList">
                            <Row className="g-4 mb-3">
                                <Col className="col-sm-auto">
                                <Button color="success" className="add-btn" onClick={() => {
                                        window.location.href = `/bankaTransferEkle`;
                                    }}>
                                        <i className="ri-add-line align-bottom me-1"></i> Ekle
                                    </Button>
                                </Col>
                                <Col className="col-sm-auto">
                                    <div className="d-flex gap-1">
                                    <select value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="tumu">All</option>
          <option value="havaleeft">havaleeft</option>
          <option value="bankavirman">bankavirman</option>
          <option value="bankadankasayatransfer">bankadankasayatransfer</option>
        </select>
                                    </div>
                                </Col>
                            </Row>


    

   
      
                            <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                {columnsToShow[filter].map((column, index) => (
              <th key={index}>{column}</th>
            ))}
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {{filteredBankTransfers().map(student => (
            <tr key={banktransfer.id}>
              {columnsToShow[filter].map((column, index) => (
                <td key={index}>{banktransfer[column.toLowerCase()]}</td>
              ))}
                                                        <td style={{ width: '200px' }}>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/bankaTransferGuncelleSayfa?parametre=${item.id}`}> Düzenle</button>
                                                                </div>
                                                                <div className="edittwo">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShowDeleteModal(item)}> Sil</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
        
                    <Modal show={showAddModal} onHide={handleCloseAddModal} size="md" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Ekle</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <BankTransferAdd handleClose={handleCloseAddModal} />
                        </Modal.Body>
                    </Modal>
        
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} size="md" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Sil</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <BankTransferDelete selectedItem={selectedItem} handleClose={handleCloseDeleteModal} />
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
            );
        }

export default TableListModal;
*/}





{/*import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import BankTransferAdd from '../../../Pages/BankTransfer/BankTransferAdd';
import BankTransferUpdate from '../../../Pages/BankTransfer/BankTransferUpdate';
import BankTransferDelete from '../../../Pages/BankTransfer/DeleteBankTransfer';

const TableListModal = (props) => {
    const [table, setTable] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);

    useEffect(() => {
        setTable([...props.companyArray]);
        setTableHeaders([
            { key: 'HeOrBvOrBkt', label: 'HeOrBvOrBkt' },
            { key: 'BankIdName', label: 'BankIdName', visible: false },
            { key: 'CurrentAccountIdName', label: 'CurrentAccountIdName', visible: false },
            { key: 'SourceBankIdName', label: 'SourceBankIdName', visible: false },
            { key: 'TargetBankIdName', label: 'TargetBankIdName', visible: false },
            { key: 'TargetCashboxIdName', label: 'TargetCashboxIdName', visible: false },
            { key: 'Date', label: 'Date' },
            { key: 'Amount', label: 'Amount' },
            { key: 'OperationType', label: 'OperationType', visible: false },
            { key: 'Explanation', label: 'Explanation' },
            { key: 'Document_Receipt_No', label: 'Document_Receipt_No' },
            { key: 'İşlemler', label: 'İşlemler' }
        ]);
    }, [props.companyArray]);
   
    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowDeleteModal = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const handleFilter = (filterType) => {
        let updatedHeaders = [...tableHeaders];
        updatedHeaders = updatedHeaders.map(header => {
            switch (filterType) {
                case 'Havale / Eft':
                    header.visible = ['HeOrBvOrBkt', 'BankIdName', 'CurrentAccountIdName', 'Date', 'Amount', 'OperationType', 'Explanation', 'Document_Receipt_No'].includes(header.label);
                    break;
                case 'Banka Virman':
                    header.visible = ['HeOrBvOrBkt', 'SourceBankIdName', 'TargetBankIdName', 'Date', 'Amount', 'Explanation', 'Document_Receipt_No'].includes(header.label);
                    break;
                case 'Bankadan Kasaya Transfer':
                    header.visible = ['HeOrBvOrBkt', 'SourceBankIdName', 'Date', 'Amount', 'Explanation', 'Document_Receipt_No'].includes(header.label);
                    break;
                default:
                    header.visible = true;
            }
            return header;
        });
        setTableHeaders(updatedHeaders);
    };
    
    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                        <Button color="success" className="add-btn" onClick={() => {
                                                window.location.href = `/bankaTransferEkle`;
                                            }}>
                                                <i className="ri-add-line align-bottom me-1"></i> Ekle
                                            </Button>
                                        </Col>
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <select className="form-control" onChange={(e) => handleFilter(e.target.value)}>
                                                    <option value="">Tümü</option>
                                                    <option value="Havale / Eft">Havale / Eft</option>
                                                    <option value="Banka Virman">Banka Virman</option>
                                                    <option value="Bankadan Kasaya Transfer">Bankadan Kasaya Transfer</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    {tableHeaders.map(header => (
                                                        header.visible && <th key={header.key} scope="col">{header.label}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {table.map((item) => (
                                                    <tr key={item.id}>
                                                        {tableHeaders.map(header => (
                                                            header.visible && <td key={header.key} data-sort={header.key}>{item[header.key]}</td>
                                                        ))}
                                                        <td style={{ width: '200px' }}>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/bankaTransferGuncelleSayfa?parametre=${item.id}`}> Düzenle</button>
                                                                </div>
                                                                <div className="edittwo">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShowDeleteModal(item)}> Sil</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={showAddModal} onHide={handleCloseAddModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferAdd handleClose={handleCloseAddModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferDelete selectedItem={selectedItem} handleClose={handleCloseDeleteModal} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default TableListModal;
*/}

{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import BankTransferAdd from '../../../Pages/BankTransfer/BankTransferAdd';
import BankTransferUpdate from '../../../Pages/BankTransfer/BankTransferUpdate';
import BankTransferDelete from '../../../Pages/BankTransfer/DeleteBankTransfer';

const TableListModal = (props) => {
    const [table, setTable] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        setTable([...props.companyArray]);
    }, [props.companyArray]);
   
    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowDeleteModal = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const handleFilter = (filterType) => {
        if (filterType === '') {
            setTable([...props.companyArray]);
        } else {
            const filteredTable = props.companyArray.filter(item => item.heOrBvOrBkt === filterType);
            setTable(filteredTable.map(item => {
                switch (filterType) {
                    case 'Havale / Eft':
                        return {
                            ...item,
                            SourceBankId: '',
                            TargetBankId: '',
                            TargetCashboxId: '',
                            OperationType: '',
                        };
                    case 'Banka Virman':
                        return {
                            ...item,
                            BankId: '',
                            CurrentAccountId: '',
                            TargetCashboxId: '',
                            OperationType: '',
                        };
                    case 'Bankadan Kasaya Transfer':
                        return {
                            ...item,
                            BankId: '',
                            CurrentAccountId: '',
                            TargetBankId: '',
                            OperationType: '',
                        };
                    default:
                        return item;
                }
            }));
        }
    };
    
    // Dinamik olarak tablo başlıklarını oluşturmak için bir dizi tanımlayın
    const tableHeaders = [
        { key: 'Transfer Türü', label: 'Transfer Türü' },
        { key: 'Banka Adı', label: 'Banka Adı' },
        { key: 'Cari adı soyadı eya unvan', label: 'Cari adı soyadı eya unvan' },
        { key: 'Kaynak Banka', label: 'Kaynak Banka' },
        { key: 'Hedef Banka', label: 'Hedef Banka' },
        { key: 'Hedef Kasa', label: 'Hedef Kasa' },
        { key: 'Tarih', label: 'Tarih' },
        { key: 'Tutar', label: 'Tutar' },
        { key: 'Transfer Türü', label: 'Transfer Türü' },
        { key: 'Açıklama', label: 'Açıklama' },
        { key: 'Belge / Makbuz No', label: 'Belge / Makbuz No' },
        { key: 'İşlemler', label: 'İşlemler' }
    ];

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                        <Button color="success" className="add-btn" onClick={() => {
                                                window.location.href = `/bankaTransferEkle`;
                                            }}>
                                                <i className="ri-add-line align-bottom me-1"></i> Ekle
                                            </Button>
                                        </Col>
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <select className="form-control" onChange={(e) => handleFilter(e.target.value)}>
                                                    <option value="">Tümü</option>
                                                    <option value="Havale / Eft">Havale / Eft</option>
                                                    <option value="Banka Virman">Banka Virman</option>
                                                    <option value="Bankadan Kasaya Transfer">Bankadan Kasaya Transfer</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    {tableHeaders.map(header => (
                                                        <th key={header.key} scope="col">{header.label}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {table.map((item) => (
                                                    <tr key={item.id}>
                                                        {tableHeaders.map(header => (
                                                            <td key={header.key} data-sort={header.key}>{item[header.key]}</td>
                                                        ))}
                                                        <td style={{ width: '200px' }}>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/bankaTransferGuncelleSayfa?parametre=${item.id}`}> Düzenle</button>
                                                                </div>
                                                                <div className="edittwo">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShowDeleteModal(item)}> Sil</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={showAddModal} onHide={handleCloseAddModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferAdd handleClose={handleCloseAddModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferDelete selectedItem={selectedItem} handleClose={handleCloseDeleteModal} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default TableListModal;
*/}




{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import BankTransferAdd from '../../../Pages/BankTransfer/BankTransferAdd';
import BankTransferUpdate from '../../../Pages/BankTransfer/BankTransferUpdate';
import BankTransferDelete from '../../../Pages/BankTransfer/DeleteBankTransfer';

const TableListModal = (props) => {
    const [table, setTable] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        setTable([...props.companyArray]);
    }, [props.companyArray]);
   
    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowDeleteModal = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const handleFilter = (filterType) => {
        if (filterType === '') {
            setTable([...props.companyArray]);
        } else {
            const filteredTable = props.companyArray.filter(item => item.heOrBvOrBkt === filterType);
            setTable(filteredTable.map(item => {
                switch (filterType) {
                    case 'Havale / Eft':
                        return {
                            ...item,
                            SourceBankId: '',
                            TargetBankId: '',
                            TargetCashboxId: '',
                            OperationType: '',
                        };
                    case 'Banka Virman':
                        return {
                            ...item,
                            BankId: '',
                            CurrentAccountId: '',
                            TargetCashboxId: '',
                            OperationType: '',
                        };
                    case 'Bankadan Kasaya Transfer':
                        return {
                            ...item,
                            BankId: '',
                            CurrentAccountId: '',
                            TargetBankId: '',
                            OperationType: '',
                        };
                    default:
                        return item;
                }
            }));
        }
    };
    

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                        <Button color="success" className="add-btn" onClick={() => {
                                                window.location.href = `/bankaTransferEkle`;
                                            }}>
                                                <i className="ri-add-line align-bottom me-1"></i> Ekle
                                            </Button>
                                        </Col>
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <select className="form-control" onChange={(e) => handleFilter(e.target.value)}>
                                                    <option value="">Tümü</option>
                                                    <option value="Havale / Eft">Havale / Eft</option>
                                                    <option value="Banka Virman">Banka Virman</option>
                                                    <option value="Bankadan Kasaya Transfer">Bankadan Kasaya Transfer</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col" style={{ width: "50px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                        </div>
                                                    </th>
                                                    <th scope="col">Transfer Türü</th>
                                                    <th scope="col">Banka Adı</th>
                                                    <th scope="col">Cari adı soyadı eya unvan</th>
                                                    <th scope="col">Kaynak Banka</th>
                                                    <th scope="col">Hedef Banka</th>
                                                    <th scope="col">Hedef Kasa</th>
                                                    <th scope="col">Tarih</th>
                                                    <th scope="col">Tutar</th>
                                                    <th scope="col">Transfer Türü</th>
                                                    <th scope="col">Açıklama</th>
                                                    <th scope="col">Belge / Makbuz No </th>
                                                    <th>İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {table.map((item) => (
                                                    <tr key={item.id}>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                                            </div>
                                                        </th>
                                                        {Object.keys(item).map((key) => (
                                                            <React.Fragment key={key}>
                                                                {key !== 'id' && <td data-sort={key}>{item[key]}</td>}
                                                            </React.Fragment>
                                                        ))}
                                                        <td style={{ width: '200px' }}>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/bankaTransferGuncelleSayfa?parametre=${item.id}`}> Düzenle</button>
                                                                </div>
                                                                <div className="edittwo">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShowDeleteModal(item)}> Sil</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={showAddModal} onHide={handleCloseAddModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferAdd handleClose={handleCloseAddModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferDelete selectedItem={selectedItem} handleClose={handleCloseDeleteModal} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default TableListModal;
*/}



{/*import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import BankTransferAdd from '../../../Pages/BankTransfer/BankTransferAdd';
import BankTransferUpdate from '../../../Pages/BankTransfer/BankTransferUpdate';
import BankTransferDelete from '../../../Pages/BankTransfer/DeleteBankTransfer';

const TableListModal = (props) => {
    const [table, setTable] = useState([]);
    const [filterType, setFilterType] = useState('');
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTable([...props.companyArray]);
    }, [props.companyArray]);

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedItem, setSelectedItem] = useState();

    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = (selectedItem) => {
        setShow2(true);
        setSelectedItem(selectedItem);
    };

    const handleClose3 = () => setShow3(false);
    const handleShow3 = (selectedItem) => {
        setShow3(true);
        setSelectedItem(selectedItem);
    };

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <Button color="success" className="add-btn" onClick={() => {
                                                window.location.href = `/bankaTransferEkle`;
                                            }}>
                                                <i className="ri-add-line align-bottom me-1"></i> Ekle
                                            </Button>
                                        </Col>
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <select className="form-control" onChange={handleFilterChange}>
                                                    <option value="">Tümü</option>
                                                    <option value="Havale / Eft">Havale / Eft</option>
                                                    <option value="Banka Virman">Banka Virman</option>
                                                    <option value="Bankadan Kasaya Transfer">Bankadan Kasaya Transfer</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col" style={{ width: "50px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                        </div>
                                                    </th>
                                                    <th scope="col">HeOrBvOrBkt</th>
                                                    {filterType !== 'Banka Virman' ||  'Bankadan Kasaya Transfer' && <th scope="col">BankIdName</th>}
                                                    {filterType !== 'Banka Virman' ||  'Bankadan Kasaya Transfer' && <th scope="col">CurrentAccountIdName</th>}
                                                    {filterType !== 'Havale / Eft'  && <th scope="col">SourceBankIdName</th>}
                                                    {filterType !== 'Havale / Eft' ||  'Bankadan Kasaya Transfer' && <th scope="col">TargetBankIdName</th>}
                                                    {filterType !== 'Havale / Eft' ||  'Banka Virman' && <th scope="col">TargetCashboxIdName</th>}
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Amount</th>
                                                    {filterType !== 'Banka Virman' ||  'Bankadan Kasaya Transfer' && <th scope="col">OperationType</th>}
                                                    <th scope="col">Explanation</th>
                                                    {filterType !== 'Banka Virman' ||  'Bankadan Kasaya Transfer' && <th scope="col">Document_Receipt_No</th>}
                                                    <th>İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {table.map((item) => {
                                                    if (filterType === '' || item.heOrBvOrBkt === filterType) {
                                                        return (
                                                            <tr key={item.id}>
                                                                <th scope="row">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                                                    </div>
                                                                </th>
                                                                {Object.keys(item).map((key) => {
                                                                    if (
                                                                        key === 'heOrBvOrBkt' ||
                                                                        (key === 'bankIdName' && filterType !== 'Banka Virman' || 'Bankadan Kasaya Transfer') ||
                                                                        (key === 'currentAccountIdName' && filterType !== 'Banka Virman' || 'Bankadan Kasaya Transfer') ||
                                                                        (key === 'sourceBankIdName' && filterType !== 'Havale / Eft') ||
                                                                        (key === 'targetBankIdName' && filterType !== 'Havale / Eft' || 'Bankadan Kasaya Transfer') ||
                                                                        (key === 'targetCashboxIdName' && filterType !== 'Havale / Eft' || 'Banka Virman') ||
                                                                        key === 'date' ||
                                                                        key === 'amount' ||
                                                                        (key === 'operationType' && filterType !== 'Banka Virman' || 'Bankadan Kasaya Transfer') ||
                                                                        key === 'explanation' ||
                                                                        (key === 'document_Receipt_No' && filterType !== 'Banka Virman' || 'Bankadan Kasaya Transfer') 
                                                                    ) {
                                                                        return <td key={key} data-sort={key}>{item[key]}</td>;
                                                                    }
                                                                    return null;
                                                                })}
                                                                <td style={{ width: '200px' }}>
                                                                    <div className="d-flex gap-2">
                                                                        <div className="edit">
                                                                            <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/bankaTransferGuncelleSayfa?parametre=${item.id}`}> Düzenle</button>
                                                                        </div>
                                                                        <div className="edittwo">
                                                                            <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShow3(item)}>  Sil</button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedItem ? 'Düzenle' : 'Ekle'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedItem ? (
                        <BankTransferUpdate selectedItem={selectedItem} handleClose={handleClose} />
                    ) : (
                        <BankTransferAdd handleClose={handleClose} />
                    )}
                </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={handleClose3} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title> Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankTransferDelete selectedItem={selectedItem} handleClose2={() => handleClose3} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default TableListModal;
*/}