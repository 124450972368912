import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const CashboxtypeAdd = () => {
    const api =new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const [cashboxtype, setCashboxtype] = useState({
        Cashbox_Payment: '',
        Cashbox_Collection: '',
        Cashbox_Transfer: '',
        CompanyId: authUser.CompanyId,
        CreatedUserId: authUser.Id,
        status:true

    });

    document.title = "KasaTur Ekle  | İlekaSoft";



    const handleSubmit = () => {
        api.create("/Cashboxtype/AddCashboxtype", cashboxtype)
            .then(res => {
                console.log("başarılı");
                navigate(`/KasaTurler`);
                //window.location.href = `/KasaTurler`;
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setCashboxtype({ ...cashboxtype, [name]: value });
    };

    return (
        <React.Fragment>
           {/* <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="KasaTurler" breadcrumbItem="KasaTur Ekle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {/* Your form inputs */}
                                    {/* Assuming you have input fields and a submit button */}
                                    {/* Example for Account Number input */}
                                   <div style={{  padding: '10px' }}>
                                   <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Kasa Ödeme    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Cashbox_Payment"
                                        value={cashboxtype.Cashbox_Payment}
                                        onChange={handleChange}
                                    />
                                     </div> 
                                     <div style={{  padding: '10px' }}>

                                     
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Kasa Tahsilat    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Cashbox_Collection"
                                        value={cashboxtype.Cashbox_Collection}
                                        onChange={handleChange}
                                    />
                                    </div>
                                    <div style={{  padding: '10px' }}>
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Kasa Devir      
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Cashbox_Transfer"
                                        value={cashboxtype.Cashbox_Transfer}
                                        onChange={handleChange}
                                    />
                                 </div>
                                 <div style={{  padding: '10px' }}>
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Kaydet
                                    </button>
                                    </div>
                              {/*  </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
    </div>*/}
        </React.Fragment>
    );
};

export default CashboxtypeAdd;

