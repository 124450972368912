import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import * as yup from 'yup';

const CostUpdatePage = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const location = useLocation();

    const [mainCosts, setMainCosts] = useState([]);
    const [subCosts, setSubCosts] = useState([]);
    const [cashboxes, setCashboxes] = useState([]);
    const [banks, setBanks] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState(null);

    const searchParams = new URLSearchParams(location.search);
    const costId = searchParams.get("parametre");

    const validationSchema = yup.object().shape({
        mainCostId: yup.string().required('Bu alan gereklidir'),
        subCostId: yup.string().required('Bu alan gereklidir'),
        date: yup.string().required('Bu alan gereklidir'),
        paymentType: yup.string().required('Bu alan gereklidir'),
        cashboxId: yup.string().required('Bu alan gereklidir'),
        bankId: yup.string().required('Bu alan gereklidir'),
        amount: yup.number().required('Bu alan gereklidir'),
        explanation: yup.string().required('Bu alan gereklidir'),
    });


    useEffect(() => {
        const companyId = authUser.CompanyId;

        api.get("/Cost/GetAllMainCosts").then(res => {
            const formattedOptions = res.map(item => ({
                label: item.name,
                value: item.id
            }));
            setMainCosts(formattedOptions);
        });

        api.get(`/Cost/GetCostById?id=${costId}`).then(res => {
            formik.setValues({
                date: res.date.split('T')[0],
                paymentType: res.paymentType,
                amount: res.amount,
                explanation: res.explanation,
                id: res.id,
                status: true,
                companyId: res.companyId,
                createdUserId: res.createdUserId,
                updatedUserId: res.updatedUserId,
                createdTime: res.createdTime,
                updatedTime: res.updatedTime,
                mainCostId: res.mainCostId,
                subCostId: res.subCostId,
                cashboxId: res.cashboxId ? res.cashboxId : '',
                bankId: res.bankId ? res.bankId : '',
            });
            setSelectedPaymentType({ label: res.paymentType === 'Kasa' ? 'Kasa' : 'Banka', value: res.paymentType });
  
            if(res.paymentType =="Kasa")
            {
                formik.setFieldValue('bankId', 0);

            }
            else if (res.paymentType =="Banka")
            {
                formik.setFieldValue('cashboxId', 0);

            }



            api.get(`/Cost/GetAllSubCosts?mainCostId=${res.mainCostId}`).then(subRes => {
                const formattedOptions = subRes.map(item => ({
                    label: item.name,
                    value: item.id
                }));
                setSubCosts(formattedOptions);
            });

            api.get("/Cashbox/GetAll?companyId=" + companyId).then(cashboxRes => {
                const formattedCashboxes = cashboxRes.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id
                }));
                setCashboxes(formattedCashboxes);
            });

            api.get("/Bank/GetAll?companyId=" + companyId).then(bankRes => {
                const formattedBanks = bankRes.map(item => ({
                    label: item.bank_Name,
                    value: item.id
                }));
                setBanks(formattedBanks);
            });
        });
    }, [authUser.CompanyId, costId]);

    const formik = useFormik({
        initialValues: {
            id: costId,
            mainCostId: '',
            subCostId: '',
            date: '',
            paymentType: '',
            cashboxId: '',
            bankId: '',
            amount: '',
            explanation: '',
            companyId: parseInt(authUser.CompanyId),
            createdUserId: parseInt(authUser.Id),
            status: true,
        },
        validationSchema,

        onSubmit: (values) => {
            const data = {
                ...values,
                cashboxId: values.cashboxId === '' ? null : values.cashboxId,
                bankId: values.bankId === '' ? null : values.bankId
            };

            console.log(values);
            api.create("/Cost/UpdateCost", data)
                .then(res => {
                    navigate('/giderler');
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    const handleMainCostChange = (selectedOption) => {
        formik.setFieldValue('mainCostId', selectedOption ? selectedOption.value : '');
        if (selectedOption) {
            api.get(`/Cost/GetAllSubCosts?mainCostId=${selectedOption.value}`).then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.name,
                    value: item.id
                }));
                setSubCosts(formattedOptions);
            });
        } else {
            setSubCosts([]);
            formik.setFieldValue('subCostId', '');
        }
    };

    const handlePaymentTypeChange = (selectedOption) => {
        formik.setFieldValue('paymentType', selectedOption ? selectedOption.value : '');
        setSelectedPaymentType(selectedOption);

        if(selectedOption.value == "Banka")
            {
              
                formik.setFieldValue('cashboxId', 0);
                formik.setFieldValue('bankId', null);
    
            }
if(selectedOption.value =="Kasa")
{
    formik.setFieldValue('cashboxId', null);
    formik.setFieldValue('bankId', 0);
}


    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Gider Güncelle" breadcrumbItem="Giderler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="mainCostId" className="form-label">Gider Türü</label>
                                            <Select
                                                name="mainCostId"
                                                options={mainCosts}
                                                onChange={handleMainCostChange}
                                                value={mainCosts.find(option => option.value === formik.values.mainCostId)}
                                            />
                                               {formik.errors.mainCostId && formik.touched.mainCostId ? (
                                                <div className="text-danger">{formik.errors.mainCostId}</div>
                                            ) : null}
                                        </div>

                                        {formik.values.mainCostId && (
                                            <div className="mb-3">
                                                <label htmlFor="subCostId" className="form-label">Gider Alt Türü</label>
                                                <Select
                                                    name="subCostId"
                                                    options={subCosts}
                                                    onChange={(option) => formik.setFieldValue('subCostId', option ? option.value : '')}
                                                    value={subCosts.find(option => option.value === formik.values.subCostId)}
                                                />
                                                    {formik.errors.subCostId && formik.touched.subCostId ? (
                                                <div className="text-danger">{formik.errors.subCostId}</div>
                                            ) : null}

                                            </div>
                                            
                                        )}

                                        {formik.values.subCostId && (
                                            <>
                                                <div className="mb-3">
                                                    <label htmlFor="date" className="form-label">Tarih</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="date"
                                                        value={formik.values.date}
                                                        onChange={formik.handleChange}
                                                    />
                                                      {formik.errors.date && formik.touched.date ? (
                                                <div className="text-danger">{formik.errors.date}</div>
                                            ) : null}

                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="paymentType" className="form-label">Ödeme Türü</label>
                                                    <Select
                                                        name="paymentType"
                                                        options={[
                                                            { label: 'Kasa', value: 'Kasa' },
                                                            { label: 'Banka', value: 'Banka' }
                                                        ]}
                                                        onChange={handlePaymentTypeChange}
                                                        value={selectedPaymentType}
                                                    />
                                                       {formik.errors.paymentType && formik.touched.paymentType ? (
                                                <div className="text-danger">{formik.errors.paymentType}</div>
                                            ) : null}
                                                </div>

                                                {formik.values.paymentType === 'Kasa' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="cashboxId" className="form-label">Kasa</label>
                                                        <Select
                                                            name="cashboxId"
                                                            options={cashboxes}
                                                            onChange={(option) => formik.setFieldValue('cashboxId', option ? option.value : '')}
                                                            value={cashboxes.find(option => option.value === formik.values.cashboxId)}
                                                        />
  {formik.errors.cashboxId && formik.touched.cashboxId ? (
                                                    <div className="text-danger">{formik.errors.cashboxId}</div>
                                                ) : null}
                                                    </div>
                                                )}

                                                {formik.values.paymentType === 'Banka' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="bankId" className="form-label">Banka</label>
                                                        <Select
                                                            name="bankId"
                                                            options={banks}
                                                            onChange={(option) => formik.setFieldValue('bankId', option ? option.value : '')}
                                                            value={banks.find(option => option.value === formik.values.bankId)}
                                                        />
                                                         {formik.errors.bankId && formik.touched.bankId ? (
                                                    <div className="text-danger">{formik.errors.bankId}</div>
                                                ) : null}
                                                    </div>
                                                )}

                                                <div className="mb-3">
                                                    <label htmlFor="amount" className="form-label">Tutar</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="amount"
                                                        value={formik.values.amount}
                                                        onChange={formik.handleChange}
                                                    />
                                                              {formik.errors.amount && formik.touched.amount ? (
                                                <div className="text-danger">{formik.errors.amount}</div>
                                            ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="explanation" className="form-label">Açıklama</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="explanation"
                                                        value={formik.values.explanation}
                                                        onChange={formik.handleChange}
                                                    />
                                                     {formik.errors.explanation && formik.touched.explanation ? (
                                                <div className="text-danger">{formik.errors.explanation}</div>
                                            ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <button className="btn btn-primary" type="submit">Güncelle</button>
                                                </div>
                                            </>
                                        )}
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CostUpdatePage;


{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const CostUpdatePage = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const location = useLocation();

    const [mainCosts, setMainCosts] = useState([]);
    const [subCosts, setSubCosts] = useState([]);
    const [cashboxes, setCashboxes] = useState([]);
    const [banks, setBanks] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState(null);

    const searchParams = new URLSearchParams(location.search);
    const costId = searchParams.get("parametre");

    useEffect(() => {
        const companyId = authUser.CompanyId;

        api.get("/Cost/GetAllMainCosts").then(res => {
            const formattedOptions = res.map(item => ({
                label: item.name,
                value: item.id.toString()
            }));
            setMainCosts(formattedOptions);
        });

        // Fetch cost details based on costId and set form values
        api.get(`/Cost/GetCostById?id=${costId}`).then(res => {
            formik.setValues({
                Date: res.date.split('T')[0],
                PaymentType: res.paymentType,
                Amount: res.amount,
                Explanation: res.explanation,
                Id:res.id,
                Status: true,
                CompanyId: res.companyId,
                CreatedUserId: res.createdUserId,
                UpdatedUserId: res.updatedUserId,
                CreatedTime: res.createdTime,
                UpdatedTime: res.updatedTime,
                MainCostId: res.mainCostId.toString(),
                SubCostId: res.subCostId.toString(),
                CashboxId: res.cashboxId ? res.cashboxId.toString() : '',
                BankId: res.bankId ? res.bankId.toString() : '',



            });
            setSelectedPaymentType({ label: res.paymentType === 'kasa' ? 'Kasa' : 'Banka', value: res.paymentType });
            // Fetch sub costs based on main cost ID
            api.get(`/Cost/GetAllSubCosts?mainCostId=${res.mainCostId}`).then(subRes => {
                const formattedOptions = subRes.map(item => ({
                    label: item.name,
                    value: item.id.toString()
                }));
                setSubCosts(formattedOptions);
            });

            // Fetch cashboxes and banks
            api.get("/Cashbox/GetAll?companyId=" + companyId).then(cashboxRes => {
                const formattedCashboxes = cashboxRes.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id.toString()
                }));
                setCashboxes(formattedCashboxes);
            });

            api.get("/Bank/GetAll?companyId=" + companyId).then(bankRes => {
                const formattedBanks = bankRes.map(item => ({
                    label: item.bank_Name,
                    value: item.id.toString()
                }));
                setBanks(formattedBanks);
            });
        });
    }, [authUser.CompanyId, costId]);

    const formik = useFormik({
        initialValues: {
            id:costId,
            MainCostId: '',
            SubCostId: '',
            Date: '',
            PaymentType: '',
            CashboxId: '',
            BankId: '1',
            Amount: '',
            Explanation: '',
            CompanyId: parseInt(authUser.CompanyId),
            CreatedUserId: parseInt(authUser.Id),
            status: true,
        },
        onSubmit: (values) => {
            alert("values geliyor");
            console.log(values);
    
            api.update("/Cost/UpdateCost", values)//(`/Cost/UpdateCost?id=${costId}`, values)
                .then(res => {
                    navigate('/giderler');
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    const handleMainCostChange = (selectedOption) => {
        formik.setFieldValue('MainCostId', selectedOption ? selectedOption.value : '');
        if (selectedOption) {
            api.get(`/Cost/GetAllSubCosts?mainCostId=${selectedOption.value}`).then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.name,
                    value: item.id.toString()
                }));
                setSubCosts(formattedOptions);
            });
        } else {
            setSubCosts([]);
            formik.setFieldValue('SubCostId', '');
        }
    };

    const handlePaymentTypeChange = (selectedOption) => {
        formik.setFieldValue('PaymentType', selectedOption ? selectedOption.value : '');
        setSelectedPaymentType(selectedOption);

        formik.setFieldValue('CashboxId', '');
        formik.setFieldValue('BankId', '');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Gider Güncelle" breadcrumbItem="Giderler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="MainCostId" className="form-label">Gider Türü</label>
                                            <Select
                                                name="MainCostId"
                                                options={mainCosts}
                                                onChange={handleMainCostChange}
                                                value={mainCosts.find(option => option.value === formik.values.MainCostId)}
                                            />
                                        </div>

                                        {formik.values.MainCostId && (
                                            <div className="mb-3">
                                                <label htmlFor="SubCostId" className="form-label">Gider Alt Türü</label>
                                                <Select
                                                    name="SubCostId"
                                                    options={subCosts}
                                                    onChange={(option) => formik.setFieldValue('SubCostId', option ? option.value : '')}
                                                    value={subCosts.find(option => option.value === formik.values.SubCostId)}
                                                />
                                            </div>
                                        )}

                                        {formik.values.SubCostId && (
                                            <>
                                                <div className="mb-3">
                                                    <label htmlFor="Date" className="form-label">Tarih</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="Date"
                                                        value={formik.values.Date}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="PaymentType" className="form-label">Ödeme Türü</label>
                                                    <Select
                                                        name="PaymentType"
                                                        options={[
                                                            { label: 'Kasa', value: 'kasa' },
                                                            { label: 'Banka', value: 'banka' }
                                                        ]}
                                                        onChange={handlePaymentTypeChange}
                                                        value={selectedPaymentType}
                                                    />
                                                </div>

                                                {formik.values.PaymentType === 'kasa' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="CashboxId" className="form-label">Kasa</label>
                                                        <Select
                                                            name="CashboxId"
                                                            options={cashboxes}
                                                            onChange={(option) => formik.setFieldValue('CashboxId', option ? option.value : '')}
                                                            value={cashboxes.find(option => option.value === formik.values.CashboxId)}
                                                        />
                                                    </div>
                                                )}

                                                {formik.values.PaymentType === 'banka' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="BankId" className="form-label">Banka</label>
                                                        <Select
                                                            name="BankId"
                                                            options={banks}
                                                            onChange={(option) => formik.setFieldValue('BankId', option ? option.value : '')}
                                                            value={banks.find(option => option.value === formik.values.BankId)}
                                                        />
                                                    </div>
                                                )}

                                                <div className="mb-3">
                                                    <label htmlFor="Amount" className="form-label">Tutar</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="Amount"
                                                        value={formik.values.Amount}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="Explanation" className="form-label">Açıklama</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="Explanation"
                                                        value={formik.values.Explanation}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <button type="submit" className="btn btn-primary">Güncelle</button>
                                            </>
                                        )}
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CostUpdatePage;
*/}

{/*import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const CostUpdatePage = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const location = useLocation();

    const [mainCosts, setMainCosts] = useState([]);
    const [subCosts, setSubCosts] = useState([]);
    const [cashboxes, setCashboxes] = useState([]);
    const [banks, setBanks] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState(null);

    const searchParams = new URLSearchParams(location.search);
    const costId = searchParams.get("parametre");

    useEffect(() => {
        const companyId = authUser.CompanyId;

        api.get("/Cost/GetAllMainCosts").then(res => {
            const formattedOptions = res.map(item => ({
                label: item.name,
                value: item.id.toString()
            }));
            setMainCosts(formattedOptions);
        });

        // Fetch cost details based on costId and set form values
        api.get(`/Cost/GetCostById?id=${costId}`).then(res => {
            formik.setValues({
                Date: res.date.split('T')[0],
                PaymentType: res.paymentType,
                Amount: res.amount,
                Explanation: res.explanation,
                Id:res.id,
                Status: true,
                CompanyId: res.companyId,
                CreatedUserId: res.createdUserId,
                UpdatedUserId: res.updatedUserId,
                CreatedTime: res.createdTime,
                UpdatedTime: res.updatedTime,
                MainCostId: res.mainCostId.toString(),
                SubCostId: res.subCostId.toString(),
                CashboxId: res.cashboxId ? res.cashboxId.toString() : '',
                BankId: res.bankId ? res.bankId.toString() : '',



            });
            setSelectedPaymentType({ label: res.paymentType === 'kasa' ? 'Kasa' : 'Banka', value: res.paymentType });
            // Fetch sub costs based on main cost ID
            api.get(`/Cost/GetAllSubCosts?mainCostId=${res.mainCostId}`).then(subRes => {
                const formattedOptions = subRes.map(item => ({
                    label: item.name,
                    value: item.id.toString()
                }));
                setSubCosts(formattedOptions);
            });

            // Fetch cashboxes and banks
            api.get("/Cashbox/GetAll?companyId=" + companyId).then(cashboxRes => {
                const formattedCashboxes = cashboxRes.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id.toString()
                }));
                setCashboxes(formattedCashboxes);
            });

            api.get("/Bank/GetAll?companyId=" + companyId).then(bankRes => {
                const formattedBanks = bankRes.map(item => ({
                    label: item.bank_Name,
                    value: item.id.toString()
                }));
                setBanks(formattedBanks);
            });
        });
    }, [authUser.CompanyId, costId]);

    const formik = useFormik({
        initialValues: {
            id:costId,
            MainCostId: '',
            SubCostId: '',
            Date: '',
            PaymentType: '',
            CashboxId: '',
            BankId: '1',
            Amount: '',
            Explanation: '',
            CompanyId: parseInt(authUser.CompanyId),
            CreatedUserId: parseInt(authUser.Id),
            status: true,
        },
        onSubmit: (values) => {
            alert("values geliyor");
            console.log(values);
    
            api.update("/Cost/UpdateCost", values)//(`/Cost/UpdateCost?id=${costId}`, values)
                .then(res => {
                    navigate('/giderler');
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    const handleMainCostChange = (selectedOption) => {
        formik.setFieldValue('MainCostId', selectedOption ? selectedOption.value : '');
        if (selectedOption) {
            api.get(`/Cost/GetAllSubCosts?mainCostId=${selectedOption.value}`).then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.name,
                    value: item.id.toString()
                }));
                setSubCosts(formattedOptions);
            });
        } else {
            setSubCosts([]);
            formik.setFieldValue('SubCostId', '');
        }
    };

    const handlePaymentTypeChange = (selectedOption) => {
        formik.setFieldValue('PaymentType', selectedOption ? selectedOption.value : '');
        setSelectedPaymentType(selectedOption);

        formik.setFieldValue('CashboxId', '');
        formik.setFieldValue('BankId', '');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Gider Güncelle" breadcrumbItem="Giderler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="MainCostId" className="form-label">Gider Türü</label>
                                            <Select
                                                name="MainCostId"
                                                options={mainCosts}
                                                onChange={handleMainCostChange}
                                                value={mainCosts.find(option => option.value === formik.values.MainCostId)}
                                            />
                                        </div>

                                        {formik.values.MainCostId && (
                                            <div className="mb-3">
                                                <label htmlFor="SubCostId" className="form-label">Gider Alt Türü</label>
                                                <Select
                                                    name="SubCostId"
                                                    options={subCosts}
                                                    onChange={(option) => formik.setFieldValue('SubCostId', option ? option.value : '')}
                                                    value={subCosts.find(option => option.value === formik.values.SubCostId)}
                                                />
                                            </div>
                                        )}

                                        {formik.values.SubCostId && (
                                            <>
                                                <div className="mb-3">
                                                    <label htmlFor="Date" className="form-label">Tarih</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="Date"
                                                        value={formik.values.Date}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="PaymentType" className="form-label">Ödeme Türü</label>
                                                    <Select
                                                        name="PaymentType"
                                                        options={[
                                                            { label: 'Kasa', value: 'kasa' },
                                                            { label: 'Banka', value: 'banka' }
                                                        ]}
                                                        onChange={handlePaymentTypeChange}
                                                        value={selectedPaymentType}
                                                    />
                                                </div>

                                                {formik.values.PaymentType === 'kasa' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="CashboxId" className="form-label">Kasa</label>
                                                        <Select
                                                            name="CashboxId"
                                                            options={cashboxes}
                                                            onChange={(option) => formik.setFieldValue('CashboxId', option ? option.value : '')}
                                                            value={cashboxes.find(option => option.value === formik.values.CashboxId)}
                                                        />
                                                    </div>
                                                )}

                                                {formik.values.PaymentType === 'banka' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="BankId" className="form-label">Banka</label>
                                                        <Select
                                                            name="BankId"
                                                            options={banks}
                                                            onChange={(option) => formik.setFieldValue('BankId', option ? option.value : '')}
                                                            value={banks.find(option => option.value === formik.values.BankId)}
                                                        />
                                                    </div>
                                                )}

                                                <div className="mb-3">
                                                    <label htmlFor="Amount" className="form-label">Tutar</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="Amount"
                                                        value={formik.values.Amount}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="Explanation" className="form-label">Açıklama</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="Explanation"
                                                        value={formik.values.Explanation}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <button type="submit" className="btn btn-primary">Güncelle</button>
                                            </>
                                        )}
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CostUpdatePage;
*/}


{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const CostUpdatePage = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const location = useLocation();

    const [mainCosts, setMainCosts] = useState([]);
    const [subCosts, setSubCosts] = useState([]);
    const [cashboxes, setCashboxes] = useState([]);
    const [banks, setBanks] = useState([]);

    const [selectedPaymentType, setSelectedPaymentType] = useState('');

    const searchParams = new URLSearchParams(location.search);
    const costId = searchParams.get("parametre");

    useEffect(() => {
        const companyId = authUser.CompanyId;

        api.get("/Cost/GetAllMainCosts").then(res => {
            const formattedOptions = res.map(item => ({
                label: item.name,
                value: item.id.toString() 
            }));
            setMainCosts(formattedOptions);
        });

        // Fetch cost details based on costId and set form values
        api.get(`/Cost/GetCostById?id=${costId}`).then(res => {
            formik.setValues({
                MainCostId: res.mainCostId.toString(),
                SubCostId: res.subCostId.toString(),
                Date: res.date,
                PaymentType: res.paymentType,
                CashboxId: res.cashboxId ? res.cashboxId.toString() : '',
                BankId: res.bankId ? res.bankId.toString() : '',
                Amount: res.amount,
                Explanation: res.explanation,
                CompanyId: authUser.CompanyId,
                CreatedUserId: authUser.Id,
                status: true,
            });
            setSelectedPaymentType(res.paymentType);
            // Fetch sub costs based on main cost ID
            api.get(`/Cost/GetAllSubCosts?mainCostId=${res.mainCostId}`).then(subRes => {
                const formattedOptions = subRes.map(item => ({
                    label: item.name,
                    value: item.id.toString() 
                }));
                setSubCosts(formattedOptions);
            });

            // Fetch cashboxes and banks
            api.get("/Cashbox/GetAll?companyId=" + companyId).then(cashboxRes => {
                const formattedCashboxes = cashboxRes.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id.toString() 
                }));
                setCashboxes(formattedCashboxes);
            });

            api.get("/Bank/GetAll?companyId=" + companyId).then(bankRes => {
                const formattedBanks = bankRes.map(item => ({
                    label: item.bank_Name,
                    value: item.id.toString() 
                }));
                setBanks(formattedBanks);
            });
        });
    }, [authUser.CompanyId, costId]);

    const formik = useFormik({
        initialValues: {
            MainCostId: '',
            SubCostId: '',
            Date: '',
            PaymentType: '',
            CashboxId: '',
            BankId: '',
            Amount: '',
            Explanation: '',
            CompanyId: authUser.CompanyId,
            CreatedUserId: authUser.Id,
            status: true,
        },
        onSubmit: (values) => {
            api.update(`/Cost/UpdateCost?costId=${costId}`, values)
                .then(res => {
                    navigate('/giderler');
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    const handleMainCostChange = (selectedOption) => {
        formik.setFieldValue('MainCostId', selectedOption ? selectedOption.value : '');
        if (selectedOption) {
            api.get(`/Cost/GetAllSubCosts?mainCostId=${selectedOption.value}`).then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.name,
                    value: item.id.toString() 
                }));
                setSubCosts(formattedOptions);
            });
        } else {
            setSubCosts([]);
            formik.setFieldValue('SubCostId', '');
        }
    };

    const handlePaymentTypeChange = (selectedOption) => {
        formik.setFieldValue('PaymentType', selectedOption ? selectedOption.value : '');
        setSelectedPaymentType(selectedOption ? selectedOption.value : '');

        formik.setFieldValue('CashboxId', '');
        formik.setFieldValue('BankId', '');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Gider Güncelle" breadcrumbItem="Giderler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="MainCostId" className="form-label">Gider Türü</label>
                                            <Select
                                                name="MainCostId"
                                                options={mainCosts}
                                                onChange={handleMainCostChange}
                                                value={mainCosts.find(option => option.value === formik.values.MainCostId)}
                                            />
                                        </div>

                                        {formik.values.MainCostId && (
                                            <div className="mb-3">
                                                <label htmlFor="SubCostId" className="form-label">Gider Alt Türü</label>
                                                <Select
                                                    name="SubCostId"
                                                    options={subCosts}
                                                    onChange={(option) => formik.setFieldValue('SubCostId', option ? option.value : '')}
                                                    value={subCosts.find(option => option.value === formik.values.SubCostId)}
                                                />
                                            </div>
                                        )}

                                        {formik.values.SubCostId && (
                                            <>
                                                <div className="mb-3">
                                                    <label htmlFor="Date" className="form-label">Tarih</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="Date"
                                                        value={formik.values.Date}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="PaymentType" className="form-label">Ödeme Türü</label>
                                                    <Select
                                                        name="PaymentType"
                                                        options={[
                                                            { label: 'Kasa', value: 'kasa' },
                                                            { label: 'Banka', value: 'banka' }
                                                        ]}
                                                        onChange={handlePaymentTypeChange}
                                                        value={selectedPaymentType}
                                                        //value={formik.values.PaymentType}
                                                    />
                                                </div>

                                                {formik.values.PaymentType === 'kasa' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="CashboxId" className="form-label">Kasa</label>
                                                        <Select
                                                            name="CashboxId"
                                                            options={cashboxes}
                                                            onChange={(option) => formik.setFieldValue('CashboxId', option ? option.value : '')}
                                                            value={cashboxes.find(option => option.value === formik.values.CashboxId)}
                                                        />
                                                    </div>
                                                )}

                                                {formik.values.PaymentType === 'banka' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="BankId" className="form-label">Banka</label>
                                                        <Select
                                                            name="BankId"
                                                            options={banks}
                                                            onChange={(option) => formik.setFieldValue('BankId', option ? option.value : '')}
                                                            value={banks.find(option => option.value === formik.values.BankId)}
                                                        />
                                                    </div>
                                                )}

                                                <div className="mb-3">
                                                    <label htmlFor="Amount" className="form-label">Tutar</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="Amount"
                                                        value={formik.values.Amount}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="Explanation" className="form-label">Açıklama</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="Explanation"
                                                        value={formik.values.Explanation}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <button type="submit" className="btn btn-primary">Güncelle</button>
                                            </>
                                        )}
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CostUpdatePage;
*/}


{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate, useLocation } from 'react-router-dom';

const validationSchema = yup.object().shape({
    MainCostId: yup.string().required('Gider Türü gerekli'),
    SubCostId: yup.string().required('Gider Alt Türü gerekli'),
    Date: yup.string().required('Tarih gerekli'),
    PaymentType: yup.string().required('Ödeme Türü gerekli'),
    CashboxId: yup.string().required('Kasa gerekli'),
    BankId: yup.string().required('Banka gerekli'),
    Amount: yup.number().required('Tutar gerekli'),
    Explanation: yup.string().required('Açıklama gerekli'),
});

const CostUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    const [mainCosts, setMainCosts] = useState([]);
    const [subCosts, setSubCosts] = useState([]);
    const [cashboxes, setCashboxes] = useState([]);
    const [banks, setBanks] = useState([]);
    const [selectedCostOption, setSelectedCostOption] = useState(null);

    useEffect(() => {
        const companyId = authUser.CompanyId;

        api.get("/Cost/GetAllMainCosts").then(res => {
            const formattedOptions = res.map(item => ({
                label: item.name,
                value: item.id
            }));
            setMainCosts(formattedOptions);
        });

        api.get("/Cashbox/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id
            }));
            setCashboxes(formattedOptions);
        });

        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id
            }));
            setBanks(formattedOptions);
        });
    }, [authUser.CompanyId]);

    useEffect(() => {
        api.get('/Cost/GetCostById?id=' + _id)
            .then(res => {
                formik.setValues({
                    MainCostId: res.mainCostId,
                    SubCostId: res.subCostId,
                    Date: res.date,
                    PaymentType: res.paymentType,
                    CashboxId: res.cashboxId,
                    BankId: res.bankId,
                    Amount: res.amount,
                    Explanation: res.explanation,
                    CompanyId: authUser.CompanyId,
                    UpdatedUserId: authUser.Id,
                });
                setSelectedCostOption({ label: res.mainCostName, value: res.mainCostId });
                handleMainCostChange({ label: res.mainCostName, value: res.mainCostId });
            })
            .catch(err => console.log(err));
    }, [_id]);

    const formik = useFormik({
        initialValues: {
            MainCostId: '',
            SubCostId: '',
            Date: '',
            PaymentType: '',
            CashboxId: '',
            BankId: '',
            Amount: '',
            Explanation: '',
            CompanyId: authUser.CompanyId,
            UpdatedUserId: authUser.Id,
        },
        validationSchema,
        onSubmit: (values) => {
            api.create('/Cost/UpdateCost', values)
                .then(res => {
                    navigate('/giderTürler');
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    const handleMainCostChange = (selectedOption) => {
        formik.setFieldValue('MainCostId', selectedOption ? selectedOption.value : '');
        if (selectedOption) {
            api.get(`/Cost/GetAllSubCosts?mainCostId=${selectedOption.value}`).then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.name,
                    value: item.id
                }));
                setSubCosts(formattedOptions);
            });
        } else {
            setSubCosts([]);
            formik.setFieldValue('SubCostId', '');
        }
    };

    const handlePaymentTypeChange = (selectedOption) => {
        formik.setFieldValue('PaymentType', selectedOption ? selectedOption.value : '');
        formik.setFieldValue('CashboxId', '');
        formik.setFieldValue('BankId', '');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Gider Güncelle" breadcrumbItem="Giderler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="MainCostId" className="form-label">Gider Türü</label>
                                            <Select
                                                name="MainCostId"
                                                options={mainCosts}
                                                onChange={handleMainCostChange}
                                                value={mainCosts.find(option => option.value === formik.values.MainCostId)}
                                            />
                                        </div>

                                        {formik.values.MainCostId && (
                                            <div className="mb-3">
                                                <label htmlFor="SubCostId" className="form-label">Gider Alt Türü</label>
                                                <Select
                                                    name="SubCostId"
                                                    options={subCosts}
                                                    onChange={(option) => formik.setFieldValue('SubCostId', option ? option.value : '')}
                                                    value={subCosts.find(option => option.value === formik.values.SubCostId)}
                                                />
                                            </div>
                                        )}

                                        {formik.values.SubCostId && (
                                            <>
                                                <div className="mb-3">
                                                    <label htmlFor="Date" className="form-label">Tarih</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="Date"
                                                        value={formik.values.Date}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="PaymentType" className="form-label">Ödeme Türü</label>
                                                    <Select
                                                        name="PaymentType"
                                                        options={[
                                                            { label: 'Kasa', value: 'kasa' },
                                                            { label: 'Banka', value: 'banka' }
                                                        ]}
                                                        onChange={handlePaymentTypeChange}
                                                        value={formik.values.PaymentType}
                                                    />
                                                </div>

                                                {formik.values.PaymentType === 'kasa' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="CashboxId" className="form-label">Kasa</label>
                                                        <Select
                                                            name="CashboxId"
                                                            options={cashboxes}
                                                            onChange={(option) => formik.setFieldValue('CashboxId', option ? option.value : '')}
                                                            value={cashboxes.find(option => option.value === formik.values.CashboxId)}
                                                        />
                                                    </div>
                                                )}

                                                {formik.values.PaymentType === 'banka' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="BankId" className="form-label">Banka</label>
                                                        <Select
                                                            name="BankId"
                                                            options={banks}
                                                            onChange={(option) => formik.setFieldValue('BankId', option ? option.value : '')}
                                                            value={banks.find(option => option.value === formik.values.BankId)}
                                                        />
                                                    </div>
                                                )}

                                                <div className="mb-3">
                                                    <label htmlFor="Amount" className="form-label">Tutar</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="Amount"
                                                        value={formik.values.Amount}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="Explanation" className="form-label">Açıklama</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="Explanation"
                                                        value={formik.values.Explanation}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <button type="submit" className="btn btn-primary">Güncelle</button>
                                            </>
                                        )}
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CostUpdatePage;
*/}






{/*
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from "react-router-dom";


import { useNavigate  } from 'react-router-dom';

const validationSchema = yup.object().shape({
    MainCostId: yup.string().required('MainCostId gerekli'),
    SubCostId: yup.string().required('SubCostId gerekli'),
    Date: yup.string().required('Date gerekli'),
    PaymentType: yup.string().required('PaymentType gerekli'),
    CashboxId: yup.string().required('CashboxId gerekli'),
    BankId: yup.string().required('BankId gerekli'),
    Amount: yup.number().required('Amount gerekli'),
    Explanation: yup.string().required('Explanation gerekli'),
});

const CostUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [bankOptions, setBankOptions] = useState([]);
    const [selectedCostOption, setSelectedCostOption] = useState(null);

    useEffect(() => {
        setCurrencyOptions([
            { id: 1, currencyName: 'TRY' },
            { id: 2, currencyName: 'EUR' },
            { id: 3, currencyName: 'USD' }
        ]);
        const companyId = authUser.CompanyId;

        api.get("/Banklist/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.bank_Name
            }));
            setBankOptions(formattedOptions);
        });
    }, []);

    useEffect(() => {
        api.get('/Bank/GetCostById?id=' + _id)
            .then(res => {
                formik.setValues({
                    id: res.id,
                    mainCostId: res.mainCostId,
                    subCostId: res.subCostId,
                    date: res.date,
                    paymentType: res.paymentType,
                    cashboxId: res.cashboxId,
                    bankId: res.bankId,
                    amount: res.amount,
                    explanation: res.explanation,
                  
                    updatedUserId: authUser.Id,
                });
                //setSelectedBankOption({ label: res.bank_Name, value: res.bank_Name });
            })
            .catch(err => console.log(err));
    }, [_id]);

    document.title = "Gider Güncelle | İlekaSoft";

    const formik = useFormik({
        initialValues: {
            mainCostId: '',
            subCostId: '',
            date: '',
            paymentType: '',
            cashboxId: '',
            bankId: '',
            amount: '',
            explanation: '',
            updatedUserId: authUser.Id,
        },
        validationSchema,
        onSubmit: (values) => {
            api.create("/Cost/UpdateCost", values)
                .then(res => {
                    console.log("başarılı");
                    //window.location.href = `/Bankalar`;
                    navigate('/Giderler')
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    const handleCostSelectChange = (selectedOption) => {
        setSelectedCostOption(selectedOption);
        formik.setFieldValue('MainCostId', selectedOption ? selectedOption.value : '');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Gider Güncelle" breadcrumbItem="Giderler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="mainCostId" className="form-label">MainCostId</label>
                                            <input
                                                className={`form-control ${formik.touched.mainCostId && formik.errors.mainCostId ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="mainCostId"
                                                value={formik.values.mainCostId}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.mainCostId && formik.errors.mainCostId ? (
                                                <div className="invalid-feedback">{formik.errors.mainCostId}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="subCostId" className="form-label">subCostId</label>
                                            <input
                                                className={`form-control ${formik.touched.subCostId && formik.errors.subCostId ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="subCostId"
                                                value={formik.values.subCostId}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.subCostId && formik.errors.subCostId ? (
                                                <div className="invalid-feedback">{formik.errors.subCostId}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="date" className="form-label">date</label>
                                            <input
                                                className={`form-control ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="date"
                                                value={formik.values.date}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.date && formik.errors.date ? (
                                                <div className="invalid-feedback">{formik.errors.date}</div>
                                            ) : null}
                                        </div>

           <div className="mb-3">
                                            <label htmlFor="paymentType" className="form-label">paymentType</label>
                                            <input
                                                className={`form-control ${formik.touched.paymentType && formik.errors.paymentType ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="paymentType"
                                                value={formik.values.paymentType}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.paymentType && formik.errors.paymentType ? (
                                                <div className="invalid-feedback">{formik.errors.paymentType}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="cashboxId" className="form-label">cashboxId</label>
                                            <input
                                                className={`form-control ${formik.touched.cashboxId && formik.errors.cashboxId ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="cashboxId"
                                                value={formik.values.cashboxId}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.cashboxId && formik.errors.cashboxId ? (
                                                <div className="invalid-feedback">{formik.errors.cashboxId}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="bankId" className="form-label">bankId</label>
                                            <input
                                                className={`form-control ${formik.touched.bankId && formik.errors.bankId ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="bankId"
                                                value={formik.values.bankId}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.bankId && formik.errors.bankId ? (
                                                <div className="invalid-feedback">{formik.errors.bankId}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="amount" className="form-label">amount</label>
                                            <input
                                                className={`form-control ${formik.touched.amount && formik.errors.amount ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="amount"
                                                value={formik.values.amount}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.amount && formik.errors.amount ? (
                                                <div className="invalid-feedback">{formik.errors.amount}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="explanation" className="form-label">explanation</label>
                                            <input
                                                className={`form-control ${formik.touched.explanation && formik.errors.explanation ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="explanation"
                                                value={formik.values.explanation}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.explanation && formik.errors.explanation ? (
                                                <div className="invalid-feedback">{formik.errors.explanation}</div>
                                            ) : null}
                                        </div>
                                        
                                        <div>
                                            <button className="btn btn-primary" type="submit">Güncelle</button>
                                        </div>
                                    </form>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CostUpdatePage;
*/}
