import React, { useEffect, useRef, useState } from 'react'

export const PaginationComp = ({itemnumber,data,handledatachild}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const dataPerPage = itemnumber;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const npage = Math.ceil(data.length / dataPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1)
    const dataArray = data.slice(firstIndex, lastIndex);

    const handleData =()=>{
        handledatachild(dataArray)
    }
    useEffect(()=>{
        handleData()
       
       
        
    },[currentPage,data])
    
  return (
    <div className="d-flex justify-content-end">
    <nav>
        <ul className='pagination'>
            <li className='page-item'>
                <a href='#' className='page-link' onClick={prevPage}>Geri</a>
            </li>
            {
                numbers.map((n, i) => (
                    <li className={`page-item ${currentPage === n ? "active" : ""}`} key={i}>
                        <a href='#' className='page-link' onClick={() => changePage(n)}>{n}</a>
                    </li>
                ))
            }
            <li className='page-item'>
                <a href='#' className='page-link' onClick={nextPage}>İleri</a>
            </li>
        </ul>
    </nav>
</div>
  )
  function prevPage() {
    if(currentPage !== 1){
        setCurrentPage(currentPage-1)
    }
}
function changePage(n) {
    setCurrentPage(n)
}
function nextPage() {
    if(currentPage !== npage){
        setCurrentPage(currentPage+1)
    }
}
}
