
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const CashboxtypeUpdatePage = () => {
    //gelen id parametresini al
    const navigate = useNavigate();

    const api =new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    
    const[cashbox_Payment,setCashbox_Payment]=useState("")
    const[cashbox_Collection,setCashbox_Collection]=useState("")
    const[cashbox_Transfer,setCashbox_Transfer]=useState("")
      

    useEffect(()=>{
        api.get('Cashboxtype/GetCashboxtypeById?id='+_id) 
        .then(res => {
        
           
            setCashbox_Payment(res.cashbox_Payment)
            setCashbox_Collection(res.cashbox_Collection)
            setCashbox_Transfer(res.cashbox_Transfer)
            
        })
        .catch(err => console.log(err))
        
    }, [])

  
    document.title = "KasaTur Guncelle  | İlekaSoft";

  


    const handleSubmit = () => {
        api.create("/Cashboxtype/UpdateCashboxtype", Cashboxtype)
            .then(res => {
                console.log("başarılı");
                navigate(`/KasaTurler`);
                //window.location.href = `/KasaTurler`;
                toast.success("Başarıyla güncellendi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Güncellenemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }


    

    const handleChange = (e) => {
        const { name, value } = e.target;
        // name değerine göre ilgili state'i güncelle
        switch (name) {
            case "Cashbox_Payment":
                setCashbox_Payment(value);
                break;
            case "Cashbox_Collection":
                setCashbox_Collection(value);
                break;
            case "Cashbox_Transfer":
                setCashbox_Transfer(value);
                break;
            default:
                break;
        }
    };
    


    
    const Cashboxtype={
        cashbox_Payment:cashbox_Payment,
        cashbox_Collection:cashbox_Collection,
        cashbox_Transfer:cashbox_Transfer,
        

    }


    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="KasaTurler" breadcrumbItem="KasaTur Guncelle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                   
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Cashbox_Payment    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Cashbox_Payment"
                                        value={Cashboxtype.cashbox_Payment}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Cashbox_Collection    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Cashbox_Collection"
                                        value={Cashboxtype.cashbox_Collection}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Cashbox_Transfer      
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Cashbox_Transfer"
                                        value={Cashboxtype.cashbox_Transfer}
                                        onChange={handleChange}
                                    />
                                    
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Guncelle
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CashboxtypeUpdatePage;




