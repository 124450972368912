import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate  } from 'react-router-dom';

const CostTypeUpdate = (props) => {
    const api =new APIClient();
    const [main_Cost_Type, setMain_Cost_Type] = useState(props.selectedItem.main_Cost_Type);
    const [fixed_Costs, setFixed_Costs] = useState(props.selectedItem.fixed_Costs);
    const [general_Costs, setGeneral_Costs] = useState(props.selectedItem.general_Costs);
    const [transport_Costs, setTransport_Costs] = useState(props.selectedItem.transport_Costs);
    const [personnel_Costs, setPersonnel_Costs] = useState(props.selectedItem.personnel_Costs);
    const [tax_Insurance_and_Similar_Costs, setTax_Insurance_and_Similar_Costs] = useState(props.selectedItem.tax_Insurance_and_Similar_Costs);
    const [communication_Costs, setCommunication_Costs] = useState(props.selectedItem.communication_Costs);


    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedCostType = {
            id: props.selectedItem.id,
            main_Cost_Type: main_Cost_Type,
            fixed_Costs: fixed_Costs,
            general_Costs: general_Costs,
            transport_Costs: transport_Costs,
            personnel_Costs: personnel_Costs,
            tax_Insurance_and_Similar_Costs: tax_Insurance_and_Similar_Costs,
            communication_Costs: communication_Costs,
            status:true

          

        };

        try {
           //await axios.post("/Bank/UpdateBank", updatedBank);
           await api.create("/CostType/UpdateCostType", updatedCostType);
            props.handleClose2(); // Modalı kapatıyoruz
            //window.location.href = `/GiderTürler`;
            navigate('/GiderTürler')

        } catch (error) {
            console.error("Error updating current group:", error);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Main_Cost_Type</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Main_Cost_Type"
                    name="main_Cost_Type"
                    value={main_Cost_Type}
                    onChange={(e) => setMain_Cost_Type(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Fixed_Costs</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Fixed_Costs"
                    name="fixed_Costs"
                    value={fixed_Costs}
                    onChange={(e) => setFixed_Costs(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>General_Costs</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="General_Costs"
                    name="general_Costs"
                    value={general_Costs}
                    onChange={(e) => setGeneral_Costs(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Transport_Costs</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Transport_Costs"
                    name="transport_Costs"
                    value={transport_Costs}
                    onChange={(e) => setTransport_Costs(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Personnel_Costs</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Personnel_Costs"
                    name="personnel_Costs"
                    value={personnel_Costs}
                    onChange={(e) => setPersonnel_Costs(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Tax_Insurance_and_Similar_Costs</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Tax_Insurance_and_Similar_Costs"
                    name="tax_Insurance_and_Similar_Costs"
                    value={tax_Insurance_and_Similar_Costs}
                    onChange={(e) => setTax_Insurance_and_Similar_Costs(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Communication_Costs</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Communication_Costs"
                    name="communication_Costs"
                    value={communication_Costs}
                    onChange={(e) => setCommunication_Costs(e.target.value)}
                    required
                />
            </Form.Group>


            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
            GiderTür Düzenle
            </Button>
        </Form>
    );
};

export default CostTypeUpdate;
