import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import BankAdd from '../../../Pages/Bank/BankAdd';
import BankUpdate from '../../../Pages/Bank/BankUpdate';
import BankDelete from '../../../Pages/Bank/DeleteBank';

const TableListModal = (props) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState();

    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = (item) => {
        setShow2(true);
        setSelectedItem(item);
    };

    const handleClose3 = () => setShow3(false);
    const handleShow3 = (item) => {
        setShow3(true);
        setSelectedItem(item);
    };

    const handleCloseDetail = () => setShowDetail(false);
    const handleShowDetail = (item) => {
        setShowDetail(true);
        setSelectedItem(item);
    };

    const [table, setTable] = useState([]);

    useEffect(() => {
        setTable([...props.companyArray]);
        setShow2(false);
    }, [props.companyArray]);

    useEffect(() => {
        setTable([...props.companyArray]);
        setShow3(false);
    }, [props.companyArray]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <Button color="success" className="add-btn" onClick={() => navigate('/BankaEkle')}>
                                                    <i className="ri-add-line align-bottom me-1"></i> Ekle
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <div className="search-box ms-2">
                                                    <input type="text" className="form-control search" placeholder="Search..." />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col" style={{ width: "50px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                        </div>
                                                    </th>
                                                    {
                                                        props.tableHeadArray.map((element, index) => (
                                                            <th key={index} className="sort" data-sort={element}>{element}</th>
                                                        ))
                                                    }
                                                    <th>İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {
                                                    props.companyArray.map((item) => (
                                                        <tr key={item.id}>
                                                            <th scope="row">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                                                </div>
                                                            </th>
                                                            {Object.keys(item).map((key) => {
                                                                if ([
                                                                    'account_Number', 'iban', 'currency', 'account_Starting_Balance', 
                                                                    'account_Name', 'bank_Name', 'bank_Branch', 'branch_Phone_Number', 
                                                                    'branch_Representative', 'branch_Representative_eMail', 
                                                                    'branch_Address', 'isInvoiceable', 'isPosDefined', 'isMailOrderDefined'
                                                                ].includes(key)) {
                                                                    return (
                                                                        <td data-sort={key} key={key}>
                                                                            {['isInvoiceable', 'isPosDefined', 'isMailOrderDefined'].includes(key) ? (
                                                                                <input type="checkbox" checked={item[key]} readOnly />
                                                                            ) : (
                                                                                item[key]
                                                                            )}
                                                                        </td>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                            <td style={{ width: "200px" }}>
                                                                <div className="d-flex gap-2">
                                                                    <div className="edit">
                                                                        <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/bankaGuncelleSayfa?parametre=${item.id}`}>Düzenle</button>
                                                                    </div>
                                                                    <div className="edittwo">
                                                                        <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShow3(item)}>Sil</button>
                                                                    </div>
                                                                    <div className="editthree">
                                                                        <button className="btn btn-sm btn-info detail-item-btn" onClick={() => handleShowDetail(item)}>Detay</button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <div className="noresult" style={{ display: "none" }}>
                                            <div className="text-center">
                                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                    colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                </lord-icon>
                                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                                <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any orders for you search.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title>Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankAdd />
                </Modal.Body>
            </Modal>
            <Modal show={show2} onHide={handleClose2} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title>Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankUpdate selectedItem={selectedItem} handleClose2={handleClose2} />
                </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={handleClose3} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title>Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankDelete selectedItem={selectedItem} handleClose3={handleClose3} />
                </Modal.Body>
            </Modal>
            <Modal show={showDetail} onHide={handleCloseDetail} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header id="detayheader" className="modal-header" closeButton>
        <Modal.Title className="modal-title">Detaylar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="modal-content">
            <p className="modal-item"><strong>Hesap Numarası:</strong> {selectedItem?.account_Number}</p>
            <p className="modal-item"><strong>IBAN:</strong> {selectedItem?.iban}</p>
            <p className="modal-item"><strong>Para Birimi:</strong> {selectedItem?.currency}</p>
            <p className="modal-item"><strong>Hesap Başlangıç Bakiyesi:</strong> {selectedItem?.account_Starting_Balance}</p>
            <p className="modal-item"><strong>Hesap Adı:</strong> {selectedItem?.account_Name}</p>
            <p className="modal-item"><strong>Banka Adı:</strong> {selectedItem?.bank_Name}</p>
            <p className="modal-item"><strong>Banka Şubesi:</strong> {selectedItem?.bank_Branch}</p>
            <p className="modal-item"><strong>Şube Telefon No:</strong> {selectedItem?.branch_Phone_Number}</p>
            <p className="modal-item"><strong>Şube Temsilcisi:</strong> {selectedItem?.branch_Representative}</p>
            <p className="modal-item"><strong>Şube Temsilcisi e-Posta:</strong> {selectedItem?.branch_Representative_eMail}</p>
            <p className="modal-item"><strong>Şube Adres:</strong> {selectedItem?.branch_Address}</p>
            <p className="modal-item"><strong>Demo mu ?:</strong> {selectedItem?.isInvoiceable ? <>&#10004; demodur</> : <>&#10060; demo değildir</>}</p>
            <p className="modal-item"><strong>Pos Tanımlı mı ?:</strong> {selectedItem?.isPosDefined ? <>&#10004; tanımlıdır</> : <>&#10060; tanımlı değildir</>}</p>
            <p className="modal-item"><strong>Mail Order Tanımlı mı ?:</strong> {selectedItem?.isMailOrderDefined ? <>&#10004; tanımlıdır</> : <>&#10060; tanımlı değildir</>}</p>
        </div>
    </Modal.Body>
</Modal>

<style jsx>{`
    .modal-content {
        padding: 20px;
    }
    .modal-item {
        margin-bottom: 15px;
        font-size: 16px;
    }
    .modal-title {
        font-size: 20px;
        font-weight: bold;
    }
        #detayheader{
                margin-left: 15px;
                margin-right: 15px;

        }
`}</style>

        </React.Fragment>
    );
}

export default TableListModal;
