import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
const Promissory_NotePortfolioUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    const navigate = useNavigate();
    const authUser = getLoggedinUser();
    const companyId = authUser.CompanyId;
    
    const [selectedMovementType, setSelectedMovementType] = useState('');
    const [debtor, setDebtor] = useState('');
    const [currentAccountName, setCurrentAccountName] = useState('');
    const [companyName, setCompanyName] = useState(""); 
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [current_ID, setCurrent_ID] = useState("");
    const [initialValues, setInitialValues] = useState({
        id: "",
        movement_Type: "",
        current_ID: "",
        debtor: "",
        registration_Date: "",
        explanation: "",
        portfolio_No: "",
        serial_Number: "",
        expiry_Date: "",
        amount: "",
        updatedUserId: authUser.Id,
        status: true,
    });

    useEffect(() => {
        api.get("/Company/GetCompanyById?id=" + companyId)
            .then(res => {
                setCompanyName(res.companyName);
            })
            .catch(error => {
                console.error("Error fetching company data:", error);
            });
    }, [companyId]);

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCurrentAccountName(selectedOption.label);
        setCurrent_ID(selectedOption ? selectedOption.value : '');
        const debtorValue = selectedMovementType === "Alınan Çek" ? selectedOption.label : companyName;
        setDebtor(debtorValue);
    };


    useEffect(() => {
        api.get('/Promissory_NotePortfolio/GetPromissory_NotePortfolioById?id=' + _id)
            .then(res => {
                setInitialValues({
                    id: res.id,
                    movement_Type: res.movement_Type,
                    current_ID: res.current_ID,
                    debtor: res.movement_Type === "Alınan Çek" ? currentAccountName : companyName,
                    registration_Date: res.registration_Date.split('T')[0],
                    explanation: res.explanation,
                    portfolio_No: res.portfolio_No,
                    serial_Number: res.serial_Number,
                    expiry_Date: res.expiry_Date.split('T')[0],
                    amount: res.amount,
                    companyId: res.companyId
                });
                setSelectCurrentAccount({ label: res.current_Name, value: res.current_ID });
                setSelectedMovementType(res.movement_Type);
                setCurrentAccountName(res.current_Name); 
            })
            .catch(err => console.log(err));
    }, [_id]);

    useEffect(() => {
        api.get("/CurrentAccount/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id
            }));
            setCurrentAccount(formattedOptions);
        });        
    }, [companyId]);

    useEffect(() => {
        updateDebtorValue(selectedMovementType);
    }, [selectedMovementType]);

    const updateDebtorValue = (selectedMovementType) => {
        const debtorValue = selectedMovementType === "Alınan Çek" ? currentAccountName : companyName; 
        setDebtor(debtorValue);
    };

    document.title = "Çek Portföy Güncelle | İlekaSoft";

    const validationSchema = Yup.object({
        movement_Type: Yup.string().required('Bu alan gereklidir'),
        current_ID: Yup.string().required('Bu alan gereklidir'),
        registration_Date: Yup.string().required('Bu alan gereklidir'),
        explanation: Yup.string().required('Bu alan gereklidir'),  
        portfolio_No: Yup.string().required('Bu alan gereklidir'),
        serial_Number: Yup.string().required('Bu alan gereklidir'),
        expiry_Date: Yup.string().required('Bu alan gereklidir'),
        amount: Yup.number().required('Bu alan gereklidir'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const updatedValues = {
                ...values,
                current_ID: selectCurrentAccount?.value || values.current_ID,
                debtor: debtor, 
            };

            const res = await api.create("/Promissory_NotePortfolio/UpdatePromissory_NotePortfolio", updatedValues);
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000
            });
            navigate(`/SenetPortfoyler`);
            //window.location.href = `/SenetPortfoyler`;
        } catch (err) {
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
            setSubmitting(false);
        }
    };

  

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Senet Portföy Güncelle" breadcrumbItem="Senet Portföyler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting, setFieldValue }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <label htmlFor="movement_Type" className="col-md-2 col-form-label">Hareket Tipi</label>
                                                    <select
                                                        name="movement_Type"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setFieldValue("movement_Type", e.target.value);
                                                            setSelectedMovementType(e.target.value);
                                                        }}
                                                        value={selectedMovementType}
                                                    >
                                                        <option value="Alınan Çek">Alınan Çek</option>
                                                        <option value="Verilen Çek">Verilen Çek</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="current_ID" className="form-label">Cari</label>
                                                    <Select
                                                        value={selectCurrentAccount}
                                                        onChange={handleCurrentAccount}
                                                        options={currentAccount}
                                                        placeholder="Select Current Account"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="debtor" className="col-md-2 col-form-label">Borçlu</label>
                                                    <input
                                                        type="text"
                                                        //placeholder="Borçlu Giriniz"
                                                        name="debtor"
                                                        className="form-control"
                                                        value={debtor}
                                                        readOnly
                                                    />
                                                    <ErrorMessage name="debtor" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="registration_Date" className="col-md-2 col-form-label">Kayıt Tarihi</label>
                                                    <Field
                                                        type="date"
                                                        name="registration_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="registration_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="explanation" className="col-md-2 col-form-label">Açıklama</label>
                                                    <Field
                                                        type="text"
                                                        placeholder="Açıklama Giriniz"
                                                        name="explanation"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="explanation" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="portfolio_No" className="col-md-2 col-form-label">Portföy No</label>
                                                    <Field
                                                        type="text"
                                                        placeholder="Portföy No Giriniz"
                                                        name="portfolio_No"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="portfolio_No" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="serial_Number" className="col-md-2 col-form-label">Seri No</label>
                                                    <Field
                                                        type="text"
                                                        placeholder="Seri No"
                                                        name="serial_Number"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="serial_Number" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="expiry_Date" className="col-md-2 col-form-label">Vade Tarihi</label>
                                                    <Field
                                                        type="date"
                                                        name="expiry_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="expiry_Date" component="div" className="text-danger" />
                                                </div>
                                                 <div className="form-group">
                                                    <label htmlFor="amount" className="col-md-2 col-form-label">Tutar</label>
                                                    <Field
                                                        type="number"
                                                        placeholder="Tutar Giriniz"
                                                        name="amount"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="amount" component="div" className="text-danger" />
                                                </div>
                                               
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        Kaydet
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Promissory_NotePortfolioUpdatePage;
{/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Promissory_NotePortfolioUpdatePage = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const [movementTypeInfo, setMovementTypeInfo] = useState([]);
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [updatedUserId, setUpdatedUserId] = useState(authUser.Id);

    useEffect(() => {
        // Eski kodlar burada
    }, []);

    useEffect(() => {
        // Eski kodlar burada
    }, [movementTypeInfo, currentAccount, _id]);

    // Yup doğrulama şeması oluşturalım
    const validationSchema = Yup.object().shape({
        movement_Type: Yup.string().required('Hareket tipi zorunludur'),
        current_ID: Yup.string().required('Cari hesap seçimi zorunludur'),
        registration_Date: Yup.date().required('Kayıt tarihi zorunludur'),
        explanation: Yup.string(),
        portfolio_No: Yup.string(),
        serial_Number: Yup.string(),
        debtor: Yup.string(),
        expiry_Date: Yup.date().required('Vade tarihi zorunludur'),
        amount: Yup.number().required('Tutar zorunludur'),
    });

    const handleSubmit = async (values) => {
        const Promissory_NotePortfolio = {
            id: values.id,
            movement_Type: values.movement_Type,
            current_ID: values.current_ID,
            registration_Date: values.registration_Date,
            explanation: values.explanation,
            portfolio_No: values.portfolio_No,
            serial_Number: values.serial_Number,
            debtor: values.debtor,
            expiry_Date: values.expiry_Date,
            amount: values.amount,
            updatedUserId: updatedUserId,
        };

        try {
            const response = await axios.post('https://localhost:7199/api/Promissory_NotePortfolio/UpdatePromissory_NotePortfolio', Promissory_NotePortfolio);
            console.log(response.data); 
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000 
            });
            navigate('/SenetPortfoyler');
        } catch (error) {
            console.error('Error updating promissory note:', error);
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Senet Portföy Güncelle" breadcrumbItem="Senet Portföy Guncelle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={{
                                            id: '',
                                            movement_Type: '',
                                            current_ID: '',
                                            registration_Date: '',
                                            explanation: '',
                                            portfolio_No: '',
                                            serial_Number: '',
                                            debtor: '',
                                            expiry_Date: '',
                                            amount: '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {formik => (
                                            <Form>
                                                <div className="mb-3">
                                                    <label htmlFor="movement_Type" className="form-label">Hareket Tipi</label>
                                                    <Field as="select" className="form-control" name="movement_Type">
                                                        <option value="">Hareket Türü Seçin</option>
                                                        {movementTypeInfo.map((item) => (
                                                            <option value={item.id} key={item.id}>{item.movementTypeName}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage name="movement_Type" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="current_ID" className="form-label">Cari Adı</label>
                                                    <Field as={Select}
                                                        name="current_ID"
                                                        value={selectCurrentAccount}
                                                        onChange={setSelectCurrentAccount}
                                                        options={currentAccount}
                                                        placeholder="Cari Hesap Seç"
                                                    />
                                                    <ErrorMessage name="current_ID" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
    <label htmlFor="registration_Date" className="form-label">Kayıt Tarihi</label>
    <Field
        type="date"
        className="form-control"
        name="registration_Date"
    />
    <ErrorMessage name="registration_Date" component="div" className="text-danger" />
</div>
<div className="mb-3">
    <label htmlFor="explanation" className="form-label">Açıklama</label>
    <Field
        type="text"
        className="form-control"
        name="explanation"
    />
    <ErrorMessage name="explanation" component="div" className="text-danger" />
</div>
<div className="mb-3">
    <label htmlFor="portfolio_No" className="form-label">Portföy No</label>
    <Field
        type="text"
        className="form-control"
        name="portfolio_No"
    />
    <ErrorMessage name="portfolio_No" component="div" className="text-danger" />
</div>
<div className="mb-3">
    <label htmlFor="serial_Number" className="form-label">Seri No</label>
    <Field
        type="text"
        className="form-control"
        name="serial_Number"
    />
    <ErrorMessage name="serial_Number" component="div" className="text-danger" />
</div>
<div className="mb-3">
    <label htmlFor="debtor" className="form-label">Borçlu</label>
    <Field
        type="text"
        className="form-control"
        name="debtor"
    />
    <ErrorMessage name="debtor" component="div" className="text-danger" />
</div>
<div className="mb-3">
    <label htmlFor="expiry_Date" className="form-label">Vade Tarihi</label>
    <Field
        type="date"
        className="form-control"
        name="expiry_Date"
    />
    <ErrorMessage name="expiry_Date" component="div" className="text-danger" />
</div>
<div className="mb-3">
    <label htmlFor="amount" className="form-label">Tutar</label>
    <Field
        type="text"
        className="form-control"
        name="amount"
    />
    <ErrorMessage name="amount" component="div" className="text-danger" />
</div>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary waves-effect waves-light"
                                                    disabled={formik.isSubmitting}
                                                >
                                                    Senet Portföyü Güncelle
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Promissory_NotePortfolioUpdatePage;
{/*import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

const Promissory_NoteUpdatePage = () => {
    const api = new APIClient();

    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const [id, setId] = useState("");
    const [movement_Type, setMovement_Type] = useState("");
    const [current_ID, setCurrent_ID] = useState("");
    const [registration_Date, setRegistration_Date] = useState("");
    const [explanation, setExplanation] = useState("");
    const [portfolio_No, setPortfolio_No] = useState("");
    const [serial_Number, setSerial_Number] = useState("");
    const [debtor, setDebtor] = useState("");
    const [expiry_Date, setExpiry_Date] = useState("");
    const [amount, setAmount] = useState("");

    const [movementTypeInfo, setMovementTypeInfo] = useState([]);
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [updatedUserId, setUpdatedUserId] = useState(authUser.Id);

    useEffect(() => {
        const movementTypeData = [
            { id: 1, movementTypeName: 'İlk Tür' },
            { id: 2, movementTypeName: 'İkinci Tür' },
            { id: 3, movementTypeName: 'Üçüncü Tür' }
        ];
        var companyId = authUser.CompanyId;
        setMovementTypeInfo(movementTypeData); 
        api.get("/CurrentAccount/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id.toString()
            }));
            setCurrentAccount(formattedOptions);
        });
    }, []);

    useEffect(() => {
        api.get('/Promissory_Note/GetPromissory_NoteById?id=' + _id) 
            .then(res => {
                setId(res.id);
                setMovement_Type(res.movement_Type);
                setCurrent_ID(res.current_ID);
                setRegistration_Date(res.registration_Date.split('T')[0]); 
                setExplanation(res.explanation);
                setPortfolio_No(res.portfolio_No);
                setSerial_Number(res.serial_Number);
                setExpiry_Date(res.expiry_Date.split('T')[0]); 
                setDebtor(res.debtor);
                setAmount(res.amount);

                // Hareket Tipi ve Cari Adı Seçeneklerini Ayarla
                const selectedMovementType = movementTypeInfo.find(type => type.id === res.movement_Type);
                if (selectedMovementType) setMovement_Type(selectedMovementType.id);

                const selectedAccount = currentAccount.find(account => account.value === res.current_ID.toString());
                if (selectedAccount) setSelectCurrentAccount(selectedAccount);
            })
            .catch(err => console.log(err));
    }, [movementTypeInfo, currentAccount, _id]);

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCurrent_ID(selectedOption ? selectedOption.value : '');
    };

    document.title = "Senet Guncelle  | İlekaSoft";

    const handleSubmit = async () => {
        const Promissory_Note = {
            id: id,
            movement_Type: movement_Type,
            current_ID: current_ID,
            registration_Date: registration_Date,
            explanation: explanation,
            portfolio_No: portfolio_No,
            serial_Number: serial_Number,
            debtor: debtor,
            expiry_Date: expiry_Date,
            amount: amount,
            updatedUserId: updatedUserId,
            //status:true


        };

        try {
            const response = await axios.post('https://localhost:7199/api/Promissory_Note/UpdatePromissory_Note', Promissory_Note);
            console.log(response.data); 
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000 
            });
      //  window.location.href = `/Senetler`;
        navigate('/Senetler')
        } catch (error) {
            console.error('Error updating promissory note:', error);
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    }
    };

    const handleChangeRegistrationDate = (event) => {
        const { value } = event.target;
        setRegistration_Date(value);
    };

    const handleChangeExpireDate = (event) => {
        const { value } = event.target;
        setExpiry_Date(value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "Movement_Type":
                setMovement_Type(value);
                break;
            case "Registration_Date":
                setRegistration_Date(value);
                break;
            case "Explanation":
                setExplanation(value);
                break;
            case "Portfolio_No":
                setPortfolio_No(value);
                break;
            case "Serial_Number":
                setSerial_Number(value);
                break;
            case "Debtor":
                setDebtor(value);
                break;
            case "Expiry_Date":
                setExpiry_Date(value);
                break;
            case "Amount":
                setAmount(value);
                break;
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Senet Güncelle" breadcrumbItem="Senet Guncelle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <label htmlFor="Movement_Type" className="form-label">Hareket Tipi</label>
                                        <select className="form-control" name="Movement_Type" value={movement_Type} onChange={handleChange} required>
                                            <option value="">Hareket Türü</option>
                                            {movementTypeInfo.map((item) => (
                                                <option value={item.id} key={item.id}>{item.movementTypeName}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="Current_ID" className="form-label">Cari Adı</label>
                                        <Select
                                            value={selectCurrentAccount}
                                            onChange={handleCurrentAccount}
                                            options={currentAccount}
                                            placeholder="Cari Hesap Seç"
                                        />
                                    </div>
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Kayıt Tarihi</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="Registration_Date"
                                        value={registration_Date}
                                        onChange={handleChangeRegistrationDate}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Açıklama</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Explanation"
                                        value={explanation}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Portföy No</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Portfolio_No"
                                        value={portfolio_No}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Seri No</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Serial_Number"
                                        value={serial_Number}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Borçlu</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Debtor"
                                        value={debtor}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Vade Tarihi</label>
                                 
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="Expiry_Date"
                                        value={expiry_Date}
                                        onChange={handleChangeExpireDate}
                                    />


                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Tutar</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Amount"
                                        value={amount}
                                        onChange={handleChange}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Senedi Güncelle
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Promissory_NoteUpdatePage;
*/}

