import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { Province } from '../Dropdown_Il_Ilce/Provincedropdwon';
import { District } from '../Dropdown_Il_Ilce/DistrictDropDown';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    warehouseName: Yup.string().required('Depo Adı gerekli'),
    address: Yup.string().required('Adres gerekli'),
    currentProvince_ID: Yup.number().required('İl gerekli'),
    currentDistrict_ID: Yup.number().required('İlçe gerekli'),
});

export const WarehousesAddModal = ({ user, addChange }) => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [authUser, setAuthUser] = useState(user);
    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    useEffect(() => {
        setAuthUser(user);
    }, [user]);

    const initialValues = {
        warehouseName: '',
        address: '',
        currentProvince_ID: '',
        currentDistrict_ID: ''
    };

    const api = new APIClient();

    const handleSubmit = (values, { setSubmitting }) => {
        const UserGroup = {
            ...values,
            companyId: authUser.CompanyId,
            createdUserId: authUser.Id,
            updatedUserId: authUser.Id,
            defaultStatus: false,
            status: true,
        };

        api.create("/Warehouses/AddWarehouses", UserGroup)
            .then(res => {
                console.log("başarılı");
                window.location.href = `/Depolar`;
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleProvinceChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption);
        setSelectedDistrictOption(null);
    };

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrictOption(selectedOption);
    };

    const openModal = () => {
        setOpen(!open);
    };

    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <div className="d-flex gap-1">
                                <Button color="success" className="add-btn" onClick={openModal} id="create-btn">
                                    <i className="ri-add-line align-bottom me-1"></i> Ekle
                                </Button>
                            </div>

                            <Modal isOpen={open} toggle={openModal}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Ekle</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setOpen(false)}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ setFieldValue, isSubmitting }) => (
                                            <Form>
                                                <div className="mb-3">
                                                    <label htmlFor="warehouseName" className="col-form-label">
                                                        Depo Adı
                                                    </label>
                                                    <Field
                                                        name="warehouseName"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder='Depo Adı Giriniz'
                                                    />
                                                    <ErrorMessage name="warehouseName" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="address" className="col-form-label">
                                                        Adres
                                                    </label>
                                                    <Field
                                                        name="address"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder='Adres Giriniz'
                                                    />
                                                    <ErrorMessage name="address" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="currentProvince_ID" className="col-form-label">
                                                        İl
                                                    </label>
                                                    <Province
                                                        selectedOption={selectedProvinceOption}
                                                        handleChange={option => {
                                                            setFieldValue("currentProvince_ID", option ? option.value : "");
                                                            handleProvinceChange(option);
                                                        }}
                                                    />
                                                    <ErrorMessage name="currentProvince_ID" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="currentDistrict_ID" className="col-form-label">
                                                        İlçe
                                                    </label>
                                                    <District
                                                        province={selectedProvinceOption}
                                                        selectedOption={selectedDistrictOption}
                                                        handleChange={option => {
                                                            setFieldValue("currentDistrict_ID", option ? option.value : "");
                                                            handleDistrictChange(option);
                                                        }}
                                                    />
                                                    <ErrorMessage name="currentDistrict_ID" component="div" className="text-danger" />
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        Kapat
                                                    </button>
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                        Kaydet
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
