import React from 'react'
import { APIClient } from '../../helpers/api_helper'
import { Col } from 'reactstrap'
import { toast } from 'react-toastify'

export const PaymentComponent = ({userInfo,handlePayment}) => {
    const api = new APIClient()

    const Payment ={
        userId:localStorage.getItem("userId"),
        companyId:localStorage.getItem("companyId"),
        moduleCompanyGuidId:localStorage.getItem("guidId"),
        
    }
    const handleSubmit = () => {
        console.log(Payment)
        api.create("/Payments/DoPayment", Payment).then(res => {
            toast.success("Ödeme Başarılı");
            console.log(res)
            handlePayment()
        }).catch(e => console.log(e))
    }
  return (
    <form className='mt-5'>
            <Col className="d-flex justify-content-center">
                    <button
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                    >
                        Ödeme Yap
                    </button>
                </Col>
    </form>
  )
}
