import React from "react";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../api/axios";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';

// Validation schema
const validationSchema = yup.object().shape({
    cashbox_Name: yup.string().required("Kasa Adı gerekli"),
    cashbox_Code: yup.string().required("Kasa Kodu gerekli"),
    cashbox_Starting_Balance: yup.number().required("Başlangıç Bakiyesi gerekli").positive("Başlangıç Bakiyesi pozitif olmalı"),
    explanation: yup.string().required("Açıklama gerekli"),
});

const CashboxUpdate = (props) => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    // Formik setup
    const formik = useFormik({
        initialValues: {
            id: props.selectedItem.id,
            cashbox_Name: props.selectedItem.cashbox_Name,
            cashbox_Code: props.selectedItem.cashbox_Code,
            cashbox_Starting_Balance: props.selectedItem.cashbox_Starting_Balance,
            explanation: props.selectedItem.explanation,
            updatedUserId: authUser.Id,
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                await api.create("/Cashbox/UpdateCashbox", { ...values, updatedUserId: authUser.Id });
                props.handleClose2();
                window.location.href = `/Kasalar`;

               // navigate('/Kasalar');
            } catch (error) {
                console.error("Error updating cashbox:", error);
            }
        },
    });

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Kasa Adı</Form.Label>
                <Form.Control
                    type="text"
                    name="cashbox_Name"
                    value={formik.values.cashbox_Name}
                    placeholder="Kasa Adını Giriniz"
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.cashbox_Name && formik.errors.cashbox_Name}
                />
                <Form.Control.Feedback type="invalid">
                    {formik.errors.cashbox_Name}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Kasa Kodu</Form.Label>
                <Form.Control
                    type="text"
                    name="cashbox_Code"
                    placeholder="Kasa Kodu Giriniz"
                    value={formik.values.cashbox_Code}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.cashbox_Code && formik.errors.cashbox_Code}
                />
                <Form.Control.Feedback type="invalid">
                    {formik.errors.cashbox_Code}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Başlangıç Bakiyesi</Form.Label>
                <Form.Control
                    type="number"
                    name="cashbox_Starting_Balance"
                    placeholder="Başlangıç Bakiyesi Giriniz"
                    value={formik.values.cashbox_Starting_Balance}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.cashbox_Starting_Balance && formik.errors.cashbox_Starting_Balance}
                />
                <Form.Control.Feedback type="invalid">
                    {formik.errors.cashbox_Starting_Balance}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Açıklama</Form.Label>
                <Form.Control
                    type="text"
                    name="explanation"
                    placeholder="Açıklama Giriniz"
                    value={formik.values.explanation}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.explanation && formik.errors.explanation}
                />
                <Form.Control.Feedback type="invalid">
                    {formik.errors.explanation}
                </Form.Control.Feedback>
            </Form.Group>

            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
                 Güncelle
            </Button>
        </Form>
    );
};

export default CashboxUpdate;

{/*import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../api/axios";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate  } from 'react-router-dom';

const validationSchema = yup.object().shape({
    cashbox_Name: yup.string().required("Kasa Adı gerekli"),
    cashbox_Code: yup.string().required("Kasa Kodu gerekli"),
    cashbox_Starting_Balance: yup.number().required("Başlangıç Bakiyesi gerekli").positive("Başlangıç Bakiyesi pozitif olmalı"),
    explanation: yup.string().required("Açıklama gerekli"),
});

const CashboxUpdate = (props) => {
    const api = new APIClient();
    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            id: props.selectedItem.id,
            cashbox_Name: props.selectedItem.cashbox_Name,
            cashbox_Code: props.selectedItem.cashbox_Code,
            cashbox_Starting_Balance: props.selectedItem.cashbox_Starting_Balance,
            explanation: props.selectedItem.explanation,
            updatedUserId:authUser.Id
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                await api.create("/Cashbox/UpdateCashbox", { ...values, updatedUserId: authUser.Id });
                props.handleClose2();
                //window.location.href = `/Kasalar`;
                navigate('/Kasalar')
            } catch (error) {
                console.error("Error updating current group:", error);
            }
        },
    });

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Kasa Adı</Form.Label>
                <Form.Control
                    type="text"
                    //placeholder="Cashbox_Name"
                    name="cashbox_Name"
                    value={formik.values.cashbox_Name}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.cashbox_Name && formik.errors.cashbox_Name}
                />
                <Form.Control.Feedback type="invalid">
                    {formik.errors.cashbox_Name}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Kasa Kodu</Form.Label>
                <Form.Control
                    type="text"
                  //  placeholder="Cashbox_Code"
                    name="cashbox_Code"
                    value={formik.values.cashbox_Code}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.cashbox_Code && formik.errors.cashbox_Code}
                />
                <Form.Control.Feedback type="invalid">
                    {formik.errors.cashbox_Code}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Başlangıç Bakiyesi</Form.Label>
                <Form.Control
                    type="number"
                    //placeholder="Cashbox_Starting_Balance"
                    name="cashbox_Starting_Balance"
                    value={formik.values.cashbox_Starting_Balance}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.cashbox_Starting_Balance && formik.errors.cashbox_Starting_Balance}
                />
                <Form.Control.Feedback type="invalid">
                    {formik.errors.cashbox_Starting_Balance}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Açıklama</Form.Label>
                <Form.Control
                    type="text"
                   // placeholder="Explanation"
                    name="explanation"
                    value={formik.values.explanation}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.explanation && formik.errors.explanation}
                />
                <Form.Control.Feedback type="invalid">
                    {formik.errors.explanation}
                </Form.Control.Feedback>
            </Form.Group>

            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
                Kasayı Güncelle
            </Button>
        </Form>
    );
};

export default CashboxUpdate;
*/}
