import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { ModuleList } from '../../components/Module/ModuleList';
import { ModuleAddModal } from '../../components/Module/ModuleAddModal';
import { PaginationComp } from '../../components/Common/pagination';
import { ModuleCompanyList } from '../../components/ModuleCompany/ModuleCompanyList';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const ModuleCompany = () => {
    document.title = "Firma Modülleri | İlekaSoft"

    const [moduleCompanies, setModuleCompanies] = useState([])
    const [user, setUser] = useState({})
    const [dataArray, setDataArray] = useState([])

    const authUser = getLoggedinUser();
    const api = new APIClient();
    useEffect(() => {
        console.log("render")
        setUser(authUser);
        fetchData()
    }, [])
    const fetchData = () => {
        api.get("/ModuleCompany/ModuleCompanyDtoGetAll").then(res => {
            console.log(res)
            setModuleCompanies(res)

        }).catch(er => console.log(er))
    }
    const handleChange = () => {
        fetchData();
    };
    const handledatachild = (dataSlice) => {
        setDataArray(dataSlice)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Firma Modülleri" breadcrumbItem="Firma Modülleri" />
                    <ToastContainer/>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">

                                            <div className="d-flex gap-1">

                                            </div>
                                        </Col>
                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <div className="search-box ms-2">
                                                    <input type="text" className="form-control search" placeholder="Search..." />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <ModuleCompanyList user={user} moduleCompany={dataArray} isChanges={handleChange} />
                                    </div>
                                    <PaginationComp itemnumber={5} data={moduleCompanies} handledatachild={handledatachild} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default ModuleCompany;