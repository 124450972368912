
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CashboxUpdatePage = () => {
    //gelen id parametresini al

    const navigate = useNavigate();

    
    const api =new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    
    const[id,setId]=useState("")
    const[cashbox_Name,setCashbox_Name]=useState("")
    const[cashbox_Code,setCashbox_Code]=useState("")
    const[cashbox_Starting_Balance,setCashbox_Starting_Balance]=useState("")
    const[explanation,setExplanation]=useState("")

    useEffect(()=>{
        api.get('/Cashbox/GetCashboxById?id='+_id) 
        .then(res => {
        
           
            setId(res.id)
            setCashbox_Name(res.cashbox_Name)
            setCashbox_Code(res.cashbox_Code)
            setCashbox_Starting_Balance(res.cashbox_Starting_Balance)
            setExplanation(res.explanation)
           

        })
        .catch(err => console.log(err))
        
    }, [])

  
    document.title = "Kasa Guncelle  | İlekaSoft";

  
   
    const handleSubmit = () => {
        api.create("/Cashbox/UpdateCashbox", Cashbox)
            .then(res => {
                console.log("başarılı");
                navigate(`/Kasalar`);
                // window.location.href = `/Kasalar`;
                toast.success("Başarıyla guncelledi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Güncellenemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }



    const handleChange = (e) => {
        const { name, value } = e.target;
        // name değerine göre ilgili state'i güncelle
        switch (name) {
            case "Cashbox_Name":
                setCashbox_Name(value);
                break;
            case "Cashbox_Code":
                setCashbox_Code(value);
                break;
            case "Cashbox_Starting_Balance":
                setCashbox_Starting_Balance(value);
                break;
            case "Explanation":
                setExplanation(value);
                break;
            default:
                break;
        }
    };
    

    
    const Cashbox={
        id:id,
        cashbox_Name:cashbox_Name,
        cashbox_Code:cashbox_Code,
        cashbox_Starting_Balance:cashbox_Starting_Balance,
        explanation:explanation,
        status:true


    }

    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Kasalar" breadcrumbItem="Kasa Guncelle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                   
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Kasa Adı    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Cashbox_Name"
                                        placeholder="Kasa Adı Giriniz"
                                        value={Cashbox.cashbox_Name}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Kasa Kodu    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Cashbox_Code"
                                        placeholder="Kasa Kodu Giriniz"
                                        value={Cashbox.cashbox_Code}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Kasa Başlangıç Bakiyesi      
                                            </label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="Cashbox_Starting_Balance"
                                        placeholder="Başlangıç Bakiyesi Giriniz"
                                        value={Cashbox.cashbox_Starting_Balance}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Açıklama   
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Explanation"
                                        placeholder="Açıklama Giriniz"
                                        value={Cashbox.explanation}
                                        onChange={handleChange}
                                    />
                                 
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Güncelle
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CashboxUpdatePage;





