import React, { useState, useEffect } from 'react';
import { WarehousesDeleteModal } from './WarehousesDeleteModal';
import { WarehousesUpdate } from './WarehousesUpdate';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
export const WarehousesTableList = ({ user, isChange, warehouses }) => {

    const [selectedItem, setSelectedItem] = useState();




  const [showDetail, setShowDetail] = useState(false);


const handleCloseDetail = () => setShowDetail(false);
    const handleShowDetail = (item) => {
        setShowDetail(true);
        setSelectedItem(item);
    };



    return (
        <React.Fragment>
            <table className="table align-middle table-nowrap" id="customerTable">
                <thead className="table-light">
                    <tr>
                        <th scope="col" style={{ width: "50px" }}>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                            </div>
                        </th>
                        <th className="sort" data-sort="id">No</th>
                        <th className="sort" data-sort="warehouseName">Depo Adı</th>
                        <th className="sort" data-sort="address">Depo Adresi</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody className="list form-check-all">
                    {warehouses.map((item, index) => (
                        <tr key={item.id}>
                            <th scope="row">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                </div>
                            </th>
                            <td data-sort="id">{index + 1}</td>
                            <td data-sort="warehouseName">{item.warehouseName}</td>
                            <td data-sort="address">{item.address}</td>
                            <td style={{ width: "200px" }}>
                                <div className="d-flex gap-2">
                                    <div className="edit">
                                        <WarehousesUpdate user={user} group={item} isChange={isChange} />
                                    </div>
                                    <div className="remove">
                                        <WarehousesDeleteModal id={item.id} isChange={isChange} />
                                    </div>
                                    <div className="editthree">
                                                                        <button className="btn btn-sm btn-info detail-item-btn" onClick={() => handleShowDetail(item)}>Detay</button>
                                                                    </div>

                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal show={showDetail} onHide={handleCloseDetail} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header id="detayheader" className="modal-header" closeButton>
        <Modal.Title className="modal-title">Detaylar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="modal-content">
            <p className="modal-item"><strong>Depo Adı:</strong> {selectedItem?.warehouseName}</p>
            <p className="modal-item"><strong>Depo Adresi:</strong> {selectedItem?.address}</p>
            <p className="modal-item"><strong>İl:</strong> {selectedItem?.currentProvince_ID}</p>
            <p className="modal-item"><strong>İlçe:</strong> {selectedItem?.currentDistrict_ID}</p>

        </div>
    </Modal.Body>
</Modal>

<style jsx>{`
    .modal-content {
        padding: 20px;
    }
    .modal-item {
        margin-bottom: 15px;
        font-size: 16px;
    }
    .modal-title {
        font-size: 20px;
        font-weight: bold;
    }
        #detayheader{
                margin-left: 15px;
                margin-right: 15px;

        }
`}</style>

        </React.Fragment>
    );
};
