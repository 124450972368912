import React, { useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import ExampleLogo from "../../assets/images/Invoice/TurkbelgeLogo.jpg"
import Breadcrumbs from "../../components/Common/Breadcrumb";

const InvoiceCurrentSelect = ({ User,onSelectUser  }) => {
    const [selectedId, setSelectedId] = useState(null); //Carinin idsini aldım
    const [selectedAddress,setSelectedAddress]= useState(null); // Carinin adresini bu usestate e attım

    function handleSelectGroup(selectedGroup) {
        setSelectedAddress(selectedGroup.address);
        setSelectedId(selectedGroup.value);

        // onSelectUser prop'u aracılığıyla seçilen kullanıcı bilgilerini iletebiliriz
        onSelectUser({
            id: selectedGroup.value,
            address: selectedGroup.address
        });
    }
    

   
     const options = User.map(item => ({
        value: item.id,
        label: `${item.currentTCKN_VKN} - ${item.currentName}`,
        address: item.currentAddress
    }));

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Faturalar" breadcrumbItem="Fatura Oluştur" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <form>
                                        <Row>
                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label>Cari TCKN/VKN Veya Cari Adı </Label>
                                                    <Select
                                                        className="form-control"
                                                        id="Current"
                                                        options={options}
                                                        placeholder="Lütfen cariyi seçiniz"
                                                        isSearchable={true}
                                                        noOptionsMessage={() => "Sonuç bulunamadı"}
                                                        onChange={handleSelectGroup} // onChange işleyicisi eklendi
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="control-label">
                                                        Adres
                                                    </label>
                                                    <textarea className="form-control" style={{ background: "#e9eef7" }} value={selectedAddress==null?"":selectedAddress} disabled></textarea>
                                                </div>
                                            </Col>

                                            <Col lg="6">
                                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                                    <Card style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}}>
                                                        <div style={{ display: 'flex', height: "170px" }}>
                                                            <div style={{ flex: 1, marginTop: "50px" }}>
                                                                <img src={ExampleLogo} />
                                                            </div>
                                                            <div style={{ flex: 1, marginTop: "50px" }}>
                                                                <div>
                                                                    <h4>İleka Akademi.</h4>
                                                                    <p>
                                                                        İsmet Kaptan, Şair Eşref Blv. 31/A, 35210 Konak/İzmir
                                                                        Konak V.D. 4840847211
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default InvoiceCurrentSelect;
