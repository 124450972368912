import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate  } from 'react-router-dom';

const validationSchema = yup.object().shape({
    Cashbox_Name: yup.string().required('Kasa Adı gerekli'),
    Cashbox_Code: yup.string().required('Kasa Kodu gerekli'),
    Cashbox_Starting_Balance: yup.number().required('Başlangıç Bakiyesi gerekli').positive('Başlangıç Bakiyesi pozitif olmalı'),
    Explanation: yup.string().required('Açıklama gerekli'),
});

const CashboxAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            Cashbox_Name: '',
            Cashbox_Code: '',
            Cashbox_Starting_Balance: '',
            Explanation: '',
            CompanyId: authUser.CompanyId,
            CreatedUserId: authUser.Id,
            status: true,
        },
        validationSchema,
        onSubmit: (values) => {
            api.create('/Cashbox/AddCashbox', values)
                .then(res => {
                    console.log("başarılı");
                    window.location.href = `/Kasalar`;
                    //navigate('/Kasalar')
                    toast.success("Başarıyla kaydedildi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Kaydedilemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    return (
        <React.Fragment>
             
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="Cashbox_Name" className="form-label">Kasa Adı</label>
                                            <input
                                                className={`form-control ${formik.touched.Cashbox_Name && formik.errors.Cashbox_Name ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Cashbox_Name"
                                                value={formik.values.Cashbox_Name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Cashbox_Name && formik.errors.Cashbox_Name ? (
                                                <div className="invalid-feedback">{formik.errors.Cashbox_Name}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Cashbox_Code" className="form-label">Kasa Kodu</label>
                                            <input
                                                className={`form-control ${formik.touched.Cashbox_Code && formik.errors.Cashbox_Code ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Cashbox_Code"
                                                value={formik.values.Cashbox_Code}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Cashbox_Code && formik.errors.Cashbox_Code ? (
                                                <div className="invalid-feedback">{formik.errors.Cashbox_Code}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Cashbox_Starting_Balance" className="form-label">Başlangıç Bakiyesi</label>
                                            <input
                                                className={`form-control ${formik.touched.Cashbox_Starting_Balance && formik.errors.Cashbox_Starting_Balance ? 'is-invalid' : ''}`}
                                                type="number"
                                                name="Cashbox_Starting_Balance"
                                                value={formik.values.Cashbox_Starting_Balance}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Cashbox_Starting_Balance && formik.errors.Cashbox_Starting_Balance ? (
                                                <div className="invalid-feedback">{formik.errors.Cashbox_Starting_Balance}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Explanation" className="form-label">Açıklama</label>
                                            <input
                                                className={`form-control ${formik.touched.Explanation && formik.errors.Explanation ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Explanation"
                                                value={formik.values.Explanation}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Explanation && formik.errors.Explanation ? (
                                                <div className="invalid-feedback">{formik.errors.Explanation}</div>
                                            ) : null}
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Kaydet
                                        </button>
                                    </form>
                                
            <ToastContainer />
        </React.Fragment>
    );
};

export default CashboxAdd;{/*
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation, useNavigate } from 'react-router-dom';

const validationSchema = yup.object().shape({
    heOrBvOrBkt: yup.string().required('HeOrBvOrBkt gerekli'),
    bankName: yup.string().required('Bank Name gerekli'),
    cANameandSurnameOrTitle: yup.string().required('CA Name and Surname or Title gerekli'),
    date: yup.date().required('Date gerekli'),
    amount: yup.number().required('Amount gerekli').positive('Amount pozitif olmalı'),
    operationType: yup.string().required('Operation Type gerekli'),
    explanation: yup.string().required('Explanation gerekli'),
    document_Receipt_No: yup.string().required('Document/Receipt No gerekli'),
    sourceBankId: yup.string().required('Source Bank ID gerekli'),
    targetBankId: yup.string().required('Target Bank ID gerekli'),
});

const BankTransferUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        heOrBvOrBkt: "",
        bankName: "",
        cANameandSurnameOrTitle: "",
        date: "",
        amount: "",
        operationType: "",
        explanation: "",
        document_Receipt_No: "",
        sourceBankId: "",
        targetBankId: "",
        updatedUserId: authUser.Id,
    });

    useEffect(() => {
        api.get('/BankTransfer/GetBankTransferById?id=' + _id)
            .then(res => {
                setInitialValues({
                    id: res.id,
                    heOrBvOrBkt: res.heOrBvOrBkt,
                    bankName: res.bankName,
                    cANameandSurnameOrTitle: res.cANameandSurnameOrTitle,
                    date: res.date,
                    amount: res.amount,
                    operationType: res.operationType,
                    explanation: res.explanation,
                    document_Receipt_No: res.document_Receipt_No,
                    sourceBankId: res.sourceBankId,
                    targetBankId: res.targetBankId,
                    updatedUserId: authUser.Id,
                });
            })
            .catch(err => console.log(err));
    }, [_id]);

    document.title = "Banka Transferi Güncelle | İlekaSoft";

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values) => {
            api.create('/BankTransfer/UpdateBankTransfer', values)
                .then(res => {
                    console.log("başarılı");
                    navigate('/BankaTransferler');
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Banka Transfer Güncelle" breadcrumbItem="Banka Transferler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="heOrBvOrBkt" className="form-label">HeOrBvOrBkt DÜZELTİLECEK</label>
                                            <input
                                                className={`form-control ${formik.touched.heOrBvOrBkt && formik.errors.heOrBvOrBkt ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="heOrBvOrBkt"
                                                value={formik.values.heOrBvOrBkt}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.heOrBvOrBkt && formik.errors.heOrBvOrBkt ? (
                                                <div className="invalid-feedback">{formik.errors.heOrBvOrBkt}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="bankName" className="form-label">Banka Adı</label>
                                            <input
                                                className={`form-control ${formik.touched.bankName && formik.errors.bankName ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="bankName"
                                                placeholder="Banka Adı Giriniz"
                                                value={formik.values.bankName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.bankName && formik.errors.bankName ? (
                                                <div className="invalid-feedback">{formik.errors.bankName}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="cANameandSurnameOrTitle" className="form-label">Cari Adı Soyadı veya Unvanı</label>
                                            <input
                                                className={`form-control ${formik.touched.cANameandSurnameOrTitle && formik.errors.cANameandSurnameOrTitle ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="cANameandSurnameOrTitle"
                                                placeholder="Cari Adı Soyadını veya Unvanını Giriniz"
                                                value={formik.values.cANameandSurnameOrTitle}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.cANameandSurnameOrTitle && formik.errors.cANameandSurnameOrTitle ? (
                                                <div className="invalid-feedback">{formik.errors.cANameandSurnameOrTitle}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="date" className="form-label">Tarih</label>
                                            <input
                                                className={`form-control ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                                                type="date"
                                                name="date"
                                                value={formik.values.date}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.date && formik.errors.date ? (
                                                <div className="invalid-feedback">{formik.errors.date}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="amount" className="form-label">Tutar</label>
                                            <input
                                                className={`form-control ${formik.touched.amount && formik.errors.amount ? 'is-invalid' : ''}`}
                                                type="number"
                                                name="amount"
                                                placeholder="Tutar Giriniz"
                                                value={formik.values.amount}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.amount && formik.errors.amount ? (
                                                <div className="invalid-feedback">{formik.errors.amount}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="operationType" className="form-label">Operasyon Türü</label>
                                            <input
                                                className={`form-control ${formik.touched.operationType && formik.errors.operationType ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="operationType"
                                                value={formik.values.operationType}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.operationType && formik.errors.operationType ? (
                                                <div className="invalid-feedback">{formik.errors.operationType}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="explanation" className="form-label">Açıklama</label>
                                            <input
                                                className={`form-control ${formik.touched.explanation && formik.errors.explanation ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="explanation"
                                                placeholder="Açıklama Giriniz"
                                                value={formik.values.explanation}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.explanation && formik.errors.explanation ? (
                                                <div className="invalid-feedback">{formik.errors.explanation}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="document_Receipt_No" className="form-label">Belge / Makbuz No</label>
                                            <input
                                                className={`form-control ${formik.touched.document_Receipt_No && formik.errors.document_Receipt_No ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="document_Receipt_No"
                                                placeholder='Belge / Makbuz No Giriniz'
                                                value={formik.values.document_Receipt_No}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.document_Receipt_No && formik.errors.document_Receipt_No ? (
                                                <div className="invalid-feedback">{formik.errors.document_Receipt_No}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="sourceBankId" className="form-label">Kaynak Banka</label>
                                            <input
                                                className={`form-control ${formik.touched.sourceBankId && formik.errors.sourceBankId ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="sourceBankId"
                                                placeholder="Kaynak Banka Seçiniz"
                                                value={formik.values.sourceBankId}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.sourceBankId && formik.errors.sourceBankId ? (
                                                <div className="invalid-feedback">{formik.errors.sourceBankId}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="targetBankId" className="form-label">Hedef Banka</label>
                                            <input
                                                className={`form-control ${formik.touched.targetBankId && formik.errors.targetBankId ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="targetBankId"
                                                placeholder="Hedef Banka Seçiniz"
                                                value={formik.values.targetBankId}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.targetBankId && formik.errors.targetBankId ? (
                                                <div className="invalid-feedback">{formik.errors.targetBankId}</div>
                                            ) : null}
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Güncelle
                                        </button>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default BankTransferUpdatePage;
*/}




{/*
*/}

