
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from "react-router-dom";

const BanklistUpdatePage = () => {
    const api =new APIClient();
    //gelen id parametresini al
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    
    const[id,setId]=useState("")
    const[bank_Name,setBank_Name]=useState("")
   
    

    useEffect(()=>{
        api.create('/Banklist/GetBanklistById?id='+_id) 
        .then(res => {
        
           
            setId(res.id)
            setBank_Name(res.bank_Name)
            

        })
        .catch(err => console.log(err))
        
    }, [])

  
    document.title = "Banka Adı Guncelle  | İlekaSoft";

  
    

    const handleSubmit = () => {
        api.create("/Banklist/UpdateBanklist", Banklist)
            .then(res => {
                console.log("başarılı");
                window.location.href = `/BankaListeler`;
                toast.success("Başarıyla güncellendi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Güncellenemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        // name değerine göre ilgili state'i güncelle
        switch (name) {
            case "Bank_Name":
                setBank_Name(value);
                break;
            default:
                break;
        }
    };
    

    
    const Banklist={
        id:id,
        bank_Name:bank_Name,
       

    }

    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Bankalisteler" breadcrumbItem="Bankaliste Guncelle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                   
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Banka Adı    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Bank_Name"
                                        value={Banklist.bank_Name}
                                        onChange={handleChange}
                                    />                                  
                                  
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Banka Adını Güncelle
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BanklistUpdatePage;





