import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableList from '../../components/TableComponent/TableList';
import axios from '../../api/axios';
import TableListModal from '../../components/TableComponent/Cashboxtype/TableListModal';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';

const CashboxtypeList = () => {
    const api = new APIClient();
    document.title = "KasaTurler  | İlekaSoft";
    const [cashboxtypeInfo, setCashboxtypeInfo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const authUser = getLoggedinUser();
    var companyId = authUser.CompanyId;

    const tableHead = ["Kasa Ödeme", "Kasa Tahsilat", "Kasa Devir"];
    useEffect(() => {
        api.get("/Cashboxtype/GetAll?companyId="+companyId)
            .then(response => {
                setCashboxtypeInfo(response);
            })
            .catch(error => {
                console.error("Error fetching current group data:", error);
            });
    }, []);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = cashboxtypeInfo.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < Math.ceil(cashboxtypeInfo.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPages = Math.ceil(cashboxtypeInfo.length / itemsPerPage);

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <li key={i} className="page-item">
                        <Button className={`page-link ${currentPage === i ? 'active' : ''}`} onClick={() => paginate(i)}>
                            {i}
                        </Button>
                    </li>
                );
            }
        } else {
            if (currentPage > 2) {
                pageNumbers.push(
                    <li key={1} className="page-item">
                        <Button className="page-link" onClick={() => paginate(1)}>
                            1
                        </Button>
                    </li>
                );
                if (currentPage > 3) {
                    pageNumbers.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
                }
            }

            const startPage = currentPage === 1 ? currentPage : currentPage - 1;
            const endPage = currentPage === totalPages ? currentPage : currentPage + 1;

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <li key={i} className="page-item">
                        <Button className={`page-link ${currentPage === i ? 'active' : ''}`} onClick={() => paginate(i)}>
                            {i}
                        </Button>
                    </li>
                );
            }

            if (currentPage < totalPages - 1) {
                if (currentPage < totalPages - 2) {
                    pageNumbers.push(<li key="ellipsis2" className="page-item disabled"><span className="page-link">...</span></li>);
                }
                pageNumbers.push(
                    <li key={totalPages} className="page-item">
                        <Button className="page-link" onClick={() => paginate(totalPages)}>
                            {totalPages}
                        </Button>
                    </li>
                );
            }
        }

        return pageNumbers;
    }
  return (
    <div>
       <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Kasa Türler " breadcrumbItem="Kasa Türler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                {cashboxtypeInfo.length === 0 ? (
                                            <div className="text-center">
                                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                    colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                </lord-icon>
                                                <h5 className="mt-2">Veri Bulunamadı</h5>
                                                <p className="text-muted mb-0">Sistemde kayıtlı kasa tür bulunmamaktadır.</p>
                                            </div>
                                        ) : (
                                            <>
                                                <TableListModal tableHeadArray={tableHead} companyArray={currentItems} />
                                                <div className="d-flex justify-content-end">
                                                    <div className="pagination-wrap hstack gap-2">
                                                        <Button className="page-item pagination-prev" onClick={prevPage} disabled={currentPage === 1}>
                                                        Önceki
                                                        </Button>
                                                        <ul className="pagination listjs-pagination mb-0">
                                                            {renderPageNumbers()}
                                                        </ul>
                                                        <Button className="page-item pagination-next" onClick={nextPage} disabled={currentPage === Math.ceil(cashboxtypeInfo.length / itemsPerPage)}>
                                                            Sonraki
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <style jsx>{`
                                            .pagination .page-link {
                                                color: grey; /* Tüm sayfa numaraları için gri renk */
                                            }
                                            .pagination .page-link.active {
                                                color: white; /* Aktif sayfa numarası için beyaz renk */
                                            }
                                        `}</style>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    </div>
  )
}

export default CashboxtypeList


