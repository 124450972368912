import React from 'react'
import { DeleteModal } from '../Common/DeleteModal'
import { Link } from 'react-router-dom'

export const ModuleCompanyList = ({user,moduleCompany,isChanges}) => {
    return (
        <React.Fragment>
            <table className="table align-middle table-nowrap" id="customerTable">
                <thead className="table-light">
                    <tr>
                        <th scope="col" style={{ width: "50px" }}>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                            </div>
                        </th>

                        <th className="sort" data-sort="id">Id</th>
                        <th className="sort" data-sort="firstName">Firma Adı</th>
                        <th className="sort" data-sort="lastName">Bitiş Süresi</th>
                     


                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody className="list form-check-all" >
                    {
                    moduleCompany.map((item, index) => (
                        <tr key={item.id}>
                            <th scope="row">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                </div>
                            </th>

                            <td data-sort="id">{item.id}</td>
                            <td data-sort="firstName">{item.companyName}</td>
                            <td data-sort="lastName">{item.moduleFinishTime.split("T")[0]}</td>


                            <td style={{ width: "200px" }}>
                                <div className="d-flex gap-2" >
                                    <div className="edit">
                                        <Link className="btn btn-secondary btn-sm" to={"/firmaModül"}> Düzenle</Link>
                                        {/* <ModuleUpdateModal user={user} item={item} isChange={isChanges} /> */}
                                    

                                       
                                    </div>
                                    <div className="remove">
                                         <DeleteModal id={item.id} isChange={isChanges} url={"/ModuleCompany/DeleteModuleCompany?id="}/> 
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))
                    }
            </tbody>
        </table>
        </React.Fragment >
    )
}
