import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon'
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown'
import Select from 'react-select'
import { UserAddModal } from '../../components/User/UserAddModal'
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

const UserAdd = () => {
    const api = new APIClient()
    const [userInfo, setUser] = useState({})
    const authUser = getLoggedinUser();
    useEffect(()=>{
        setUser(authUser);

    },[])
  
  {/*  const searchParams = new URLSearchParams(useLocation().search);
    const lastcompany = searchParams.get("lastcompany");
    alert('lastcompany UserAdd sayfasına geldi mi '+ lastcompany); // Display success message
*/}
//alert('lastcompany UserAdd sayfasına geldi mi '+ params.lastcompany); // Display success message

    return (
        <React.Fragment>
            <div className="page-content">
                <ToastContainer />

                <Container fluid={true}>
                    <Breadcrumbs title="Kullanıcı Ekle" breadcrumbItem="Kullanıcılar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                <UserAddModal userInfo={userInfo}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default UserAdd;



