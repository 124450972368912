import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';
const CompanyUpdate = (props) => {
    const api =new APIClient();

    const navigate = useNavigate();


    const [companyName, setCompanyName] = useState(props.selectedItem.companyName);
    const [address, setAddress] = useState(props.selectedItem.address);
    const [companySector, setCompanySector] = useState(props.selectedItem.companySector);
    const [districtId, setDistrictId] = useState(props.selectedItem.districtId);
    const [email, setEmail] = useState(props.selectedItem.email);
    const [provinceId, setProvinceId] = useState(props.selectedItem.provinceId);
    const [taxOffice, setTaxOffice] = useState(props.selectedItem.taxOffice);
    const [telNo, setTelNo] = useState(props.selectedItem.telNo);
    const [identityNo, setIdentityNo] = useState(props.selectedItem.identityNo);
    const [ustFirmaId, setUstFirmaId] = useState(props.selectedItem.ustFirmaId);
    const [isDemo, setIsDemo] = useState(props.selectedItem.isDemo);
    const [streetName, setStreetName] = useState(props.selectedItem.address);
    const [website, setWebsite] = useState(props.selectedItem.address);
    const [postalCode , setPostalCode] = useState(props.selectedItem.address);



    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedCompany = {
            id: props.selectedItem.id,
            companyName: companyName,
            address: address,
            companySector: companySector,
            districtId: districtId,
            email: email,
            provinceId: provinceId,
            taxOffice: taxOffice,
            telNo: telNo,
            identityNo: identityNo,
            ustFirmaId: ustFirmaId,
            isDemo: isDemo,
            streetName:'', 
            website:'', 
            postalCode:''
     


        };

        try {
           //await axios.post("/Bank/UpdateBank", updatedBank);
           await api.create("/Company/UpdateCompany", updatedCompany);
            props.handleClose2(); // Modalı kapatıyoruz
            navigate(`/Firmalar`);
//            window.location.href = `/Firmalar`;


        } catch (error) {
            console.error("Error updating current group:", error);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>CompanyName</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="CompanyName"
                    name="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Address</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Address"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>CompanySector</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="CompanySector"
                    name="companySector"
                    value={companySector}
                    onChange={(e) => setCompanySector(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>DistrictId</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="DistrictId"
                    name="districtId"
                    value={districtId}
                    onChange={(e) => setDistrictId(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>ProvinceId</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="ProvinceId"
                    name="provinceId"
                    value={provinceId}
                    onChange={(e) => setProvinceId(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>TaxOffice</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="TaxOffice"
                    name="taxOffice"
                    value={taxOffice}
                    onChange={(e) => setTaxOffice(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>TelNo</Form.Label>
                <Form.Control
                    type="tel"
                    placeholder="TelNo"
                    name="telNo"
                    value={telNo}
                    onChange={(e) => setTelNo(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>VergiKimlikNumarası_TcKimlikNumarası</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="IdentityNo"
                    name="identityNo"
                    value={identityNo}
                    onChange={(e) => setIdentityNo(e.target.value)}
                    required
                />
            </Form.Group>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>UstFirmaId</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="UstFirmaId"
                    name="ustFirmaId"
                    value={ustFirmaId}
                    onChange={(e) => setUstFirmaId(e.target.value)}
                    required
                />
            </Form.Group>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>IsDemo</Form.Label>
                <Form.Control
                    type="isDemo"
                    placeholder="IsDemo"
                    name="isDemo"
                    value={isDemo}
                    onChange={(e) => setIsDemo(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Sokak Adı</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="StreetName"
                    name="streetName"
                    value={streetName}
                    onChange={(e) => setStreetName(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Web Sitesi</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Website"
                    name="website"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Posta Kodu</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="PostalCode"
                    name="postalCode"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    required
                />
            </Form.Group>


            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
            Firma Düzenle
            </Button>
        </Form>
    );
};

export default CompanyUpdate;
