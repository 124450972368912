import React from 'react'
import { Link } from 'react-router-dom'
import { DeleteModal } from '../Common/DeleteModal'
import { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';

export const UserTableList = ({ isChange,userData }) => {
    
    const [showDetail, setShowDetail] = useState(false);

    const [selectedItem, setSelectedItem] = useState();
    const handleCloseDetail = () => setShowDetail(false);
        const handleShowDetail = (item) => {
            setShowDetail(true);
            setSelectedItem(item);
        };

        
    return (
        <React.Fragment>
            <table className="table align-middle table-nowrap" id="customerTable">
                <thead className="table-light">
                    <tr>
                        <th scope="col" style={{ width: "50px" }}>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                            </div>
                        </th>

                        <th className="sort" data-sort="id">Id</th>
                        <th className="sort" data-sort="firstName">İsim</th>
                        <th className="sort" data-sort="lastName">Soyisim</th>
                        <th className="sort" data-sort="telNo">Tel No</th>
                     


                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody className="list form-check-all" >
                    {
                    userData.map((item, index) => (
                        <tr key={item.id}>
                            <th scope="row">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                </div>
                            </th>

                            <td data-sort="id">{item.id}</td>
                            <td data-sort="firstName">{item.firstName}</td>
                            <td data-sort="lastName">{item.lastName}</td>
                            <td data-sort="telNo">{item.telNo}</td>


                            <td style={{ width: "200px" }}>
                                <div className="d-flex gap-2" >
                                    <div className="edit">
                                        
                                        <Link color="warning" className="btn btn-sm btn-warning edit-item-btn" to={"/Kullanici/KullaniciGuncelle/"+item.id}>
                                        Düzenle
                                        </Link>
                                    

                                       {/* <UserUpdate user={item} /> */}
                                    </div>
                                    <div className="remove">
                                         <DeleteModal id={item.id} isChange={isChange} url={"/Users/DeleteUser?id="}/> 
                                    </div>
                                    <div className="editthree">
                                                                        <button className="btn btn-sm btn-info detail-item-btn" onClick={() => handleShowDetail(item)}>Detay</button>
                                                                    </div>

                                </div>
                            </td>
                        </tr>
                    ))
                    }
            </tbody>
        </table>
        <Modal show={showDetail} onHide={handleCloseDetail} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header id="detayheader" className="modal-header" closeButton>
        <Modal.Title className="modal-title">Detaylar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="modal-content">
        <p className="modal-item"><strong>İsim:</strong> {selectedItem?.firstName}</p>
        <p className="modal-item"><strong>Soyisim:</strong> {selectedItem?.lastName}</p>
        <p className="modal-item"><strong>Email:</strong> {selectedItem?.email}</p>
        <p className="modal-item"><strong>Kullanıcı Adı:</strong> {selectedItem?.username}</p>
        <p className="modal-item"><strong>Kullanıcı Grubu:</strong> {selectedItem?.username}</p>
        <p className="modal-item"><strong>Tc No:</strong> {selectedItem?.tcNo}</p>
        <p className="modal-item"><strong>Tel No:</strong> {selectedItem?.telNo}</p>
        <p className="modal-item"><strong>İl:</strong> {selectedItem?.ilId}</p>
        <p className="modal-item"><strong>İlçe:</strong> {selectedItem?.ilceId}</p>
     

        </div>
    </Modal.Body>
</Modal>

<style jsx>{`
    .modal-content {
        padding: 20px;
    }
    .modal-item {
        margin-bottom: 15px;
        font-size: 16px;
    }
    .modal-title {
        font-size: 20px;
        font-weight: bold;
    }
        #detayheader{
                margin-left: 15px;
                margin-right: 15px;

        }
`}</style>


        </React.Fragment >
    )
}
