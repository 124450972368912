import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object().shape({
    kvOrKbt: yup.string().required('Transfer türü gereklidir'),
    document_Receipt_No: yup.string().required('Belge / Makbuz No gereklidir'),
    sourceCashbox: yup.string().required('Kaynak Kasa gereklidir'),
    targetCashbox: yup.string().required('Hedef Kasa gereklidir'),
targetBank: yup.string().required('Hedef Banka gereklidir'),
    date: yup.string().required('Tarih gereklidir'),
    amount: yup.number().required('Tutar gereklidir'),
    explanation: yup.string().required('Açıklama gereklidir'),
});

const CashboxTransfersUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const [sourcecashboxOptions, setSourceCashboxOptions] = useState([]);
    const [selectedSourceCashboxOption, setSelectedSourceCashboxOption] = useState(null);

    const [targetcashboxOptions, setTargetCashboxOptions] = useState([]);
    const [selectedTargetCashboxOption, setSelectedTargetCashboxOption] = useState(null);

    const [targetbankOptions, setTargetBankOptions] = useState([]);
    const [selectedTargetBankOption, setSelectedTargetBankOption] = useState(null);

    const [kvOrKbtOptions, setKvOrKbtOptions] = useState([]);
    const [selectedKvOrKbtOption, setSelectedKvOrKbtOption] = useState(null);

    const [selectedTransferType, setSelectedTransferType] = useState('');

    useEffect(() => {
        const companyId = authUser.CompanyId;

        // Transfer türü seçenekleri
        const options = [
            { label: "Kasa Virman", value: "Kasa Virman" },
            { label: "Kasadan Bankaya Tahsilat", value: "Kasadan Bankaya Tahsilat" }
        ];
        setKvOrKbtOptions(options);

        // Kaynak kasa seçeneklerini getir
        api.get("/Cashbox/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id.toString()
            }));
            setSourceCashboxOptions(formattedOptions);
        });

        // Hedef kasa seçeneklerini getir
        api.get("/Cashbox/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id
            }));
            setTargetCashboxOptions(formattedOptions);
        });

        // Hedef banka seçeneklerini getir
        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id
            }));
            setTargetBankOptions(formattedOptions);
        });
    }, []);

    useEffect(() => {
        api.get('/CashboxTransfers/GetCashboxTransfersById?id=' + _id)
            .then(res => {
                const selectedTransferType = res.kvOrKbt;
                setSelectedTransferType(selectedTransferType);

                formik.setValues({
                    id: res.id,
                    kvOrKbt: res.kvOrKbt,
                    document_Receipt_No: res.document_Receipt_No,
                    sourceCashbox: res.sourceCashbox,
                    targetCashbox: res.targetCashbox,
                    targetBank: res.targetBank,
                    date: res.date.split('T')[0],
                    amount: res.amount,
                    explanation: res.explanation,
                    updatedUserId: authUser.Id,
                });

                setSelectedSourceCashboxOption({ label: res.sourceCashboxName, value: res.sourceCashbox });
                setSelectedTargetCashboxOption({ label: res.targetCashboxName, value: res.targetCashbox });
                setSelectedTargetBankOption({ label: res.targetBankName, value: res.targetBank });
                setSelectedKvOrKbtOption({ label: res.kvOrKbt, value: res.kvOrKbt });

                
                if(res.kvOrKbt == "Kasa Virman")
                    {
                        formik.setFieldValue('targetBank', 0);
                        
                    }
                   else if(res.kvOrKbt == "Kasadan Bankaya Tahsilat")
                   {
                    formik.setFieldValue('targetCashbox', 0);
                   
    
                   }
                
            })
            .catch(err => console.log(err));
    }, [_id]);

    const handleSourceCashboxSelectChange = (selectedOption) => {
        setSelectedSourceCashboxOption(selectedOption);
        formik.setFieldValue('sourceCashbox', selectedOption ? selectedOption.value : '');
    };

    const handleTargetCashboxSelectChange = (selectedOption) => {
        setSelectedTargetCashboxOption(selectedOption);
        formik.setFieldValue('targetCashbox', selectedOption ? selectedOption.value : '');
    };

    const handleTargetBankSelectChange = (selectedOption) => {
        setSelectedTargetBankOption(selectedOption);
        formik.setFieldValue('targetBank', selectedOption ? selectedOption.value : '');
    };

    const handleKvOrKbtSelectChange = (selectedOption) => {
        setSelectedKvOrKbtOption(selectedOption);
        formik.setFieldValue('kvOrKbt', selectedOption ? selectedOption.value : '');
        setSelectedTransferType(selectedOption.value);


        if(selectedOption.value == "Kasa Virman")
            {
              
               formik.setFieldValue('targetCashbox', null);
           
    
            }
            if(selectedOption.value == "Kasadan Bankaya Tahsilat")
            {
              
                formik.setFieldValue('targetBank', null);

               
    
            }
            
    };

    const formik = useFormik({
        initialValues: {
            document_Receipt_No: '',
            sourceCashbox: '',
            targetCashbox: '',
            targetBank: '',
            date: '',
            amount: '',
            explanation: '',
            updatedUserId: authUser.Id,
        },
        validationSchema,
        onSubmit: (values) => {
            api.create("/CashboxTransfers/UpdateCashboxTransfers", values)
                .then(res => {
                    console.log("başarılı");
                    navigate('/KasaTransferler')
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Kasa Transferi Güncelle" breadcrumbItem="Kasa Transferleri" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="kvOrKbt" className="form-label">Transfer Türü</label>
                                            <Select
                                                value={selectedKvOrKbtOption}
                                                onChange={handleKvOrKbtSelectChange}
                                                options={kvOrKbtOptions}
                                                placeholder="KvOrKbt Seç"
                                                className={`react-select ${formik.touched.kvOrKbt && formik.errors.kvOrKbt ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.kvOrKbt && formik.errors.kvOrKbt ? (
                                                <div className="invalid-feedback d-block">{formik.errors.kvOrKbt}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="document_Receipt_No" className="form-label">Belge / Makbuz No</label>
                                            <input
                                                className={`form-control ${formik.touched.document_Receipt_No && formik.errors.document_Receipt_No ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="document_Receipt_No"
                                                value={formik.values.document_Receipt_No}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.document_Receipt_No && formik.errors.document_Receipt_No ? (
                                                <div className="invalid-feedback">{formik.errors.document_Receipt_No}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="sourceCashbox" className="form-label">Kaynak Kasa</label>
                                            <Select
                                                value={selectedSourceCashboxOption}
                                                onChange={handleSourceCashboxSelectChange}
                                                options={sourcecashboxOptions}
                                                placeholder="Kaynak Kasa Seç"
                                                className={`react-select ${formik.touched.sourceCashbox && formik.errors.sourceCashbox ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.sourceCashbox && formik.errors.sourceCashbox ? (
                                                <div className="invalid-feedback d-block">{formik.errors.sourceCashbox}</div>
                                            ) : null}
                                        </div>

                                        {selectedTransferType === 'Kasa Virman' && (
                                            <div className="mb-3">
                                                <label htmlFor="targetCashbox" className="form-label">Hedef Kasa</label>
                                                <Select
                                                    value={selectedTargetCashboxOption}
                                                    onChange={handleTargetCashboxSelectChange}
                                                    options={targetcashboxOptions}
                                                    placeholder="Hedef Kasa Seç"
                                                    className={`react-select ${formik.touched.targetCashbox && formik.errors.targetCashbox ? 'is-invalid' : ''}`}
                                                />
                                                {formik.touched.targetCashbox && formik.errors.targetCashbox ? (
                                                    <div className="invalid-feedback d-block">{formik.errors.targetCashbox}</div>
                                                ) : null}
                                            </div>
                                        )}

                                        {selectedTransferType === 'Kasadan Bankaya Tahsilat' && (
                                            <div className="mb-3">
                                                <label htmlFor="targetBank" className="form-label">Hedef Banka</label>
                                                <Select
                                                    value={selectedTargetBankOption}
                                                    onChange={handleTargetBankSelectChange}
                                                    options={targetbankOptions}
                                                    placeholder="Hedef Banka Seç"
                                                    className={`react-select ${formik.touched.targetBank && formik.errors.targetBank ? 'is-invalid' : ''}`}
                                                />
                                                {formik.touched.targetBank && formik.errors.targetBank ? (
                                                    <div className="invalid-feedback d-block">{formik.errors.targetBank}</div>
                                                ) : null}
                                            </div>
                                        )}

                                        <div className="mb-3">
                                            <label htmlFor="date" className="form-label">Tarih</label>
                                            <input
                                                className={`form-control ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                                                type="date"
                                                name="date"
                                                value={formik.values.date}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.date && formik.errors.date ? (
                                                <div className="invalid-feedback">{formik.errors.date}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="amount" className="form-label">Tutar</label>
                                            <input
                                                className={`form-control ${formik.touched.amount && formik.errors.amount ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="amount"
                                                value={formik.values.amount}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.amount && formik.errors.amount ? (
                                                <div className="invalid-feedback">{formik.errors.amount}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="explanation" className="form-label">Açıklama</label>
                                            <input
                                                className={`form-control ${formik.touched.explanation && formik.errors.explanation ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="explanation"
                                                value={formik.values.explanation}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.explanation && formik.errors.explanation ? (
                                                <div className="invalid-feedback">{formik.errors.explanation}</div>
                                            ) : null}
                                        </div>

                                        <div>
                                            <button className="btn btn-primary" type="submit">Güncelle</button>
                                        </div>
                                    </form>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CashboxTransfersUpdatePage;
