import React, { useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
export const UserGroupAdd = () => {
    document.title = "Kullanıcı Grubu Ekle | İlekaSoft";
    const api = new APIClient();

    const [groupName,setGroupName] = useState("");

    const navigate = useNavigate();



    const handleSubmit = () => {
        api.create("/UserGroup/AddUserGroup",{groupName:groupName})
            .then(res => {
                console.log("başarılı");
                navigate(`/KullaniciGrup`);
                //window.location.href = `/KullaniciGrup`;
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }


  return (
    <React.Fragment>
    <div className="page-content">
        <Container fluid={true}>
            <Breadcrumbs title="Kullanııcı Grubu Ekle" breadcrumbItem="Kullanııcı Grupları" />
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row className="mb-3">
                                <Col lg="6">
                                    <label htmlFor="example-text-input" className="col-md-6 col-form-label">
                                        Kullanıcı Grup Adı
                                    </label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="text" placeholder='Kullanıcı Grup Adı Giriniz' onChange={x=>setGroupName(x.target.value)} />
                                    </div>
                                </Col>
                               

                            </Row>
                            
                            <Row className="mb-3">
                                <Col>
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                         Güncelle
                                    </button>
                                    </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </Container>
    </div>
</React.Fragment>
  )
}



