import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';
const Promissory_NotePortfolioUpdate = (props) => {
    const api =new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const [movement_Type, setMovement_Type] = useState(props.selectedItem.account_Number);
    const [current_ID, setCurrent_ID] = useState(props.selectedItem.account_Number);
    const [registration_Date, setRegistration_Date] = useState(props.selectedItem.account_Number);
    const [explanation, setExplanation] = useState(props.selectedItem.account_Number);
    const [portfolio_No, setPortfolio_No] = useState(props.selectedItem.account_Number);
    const [serial_Number, setSerial_Number] = useState(props.selectedItem.account_Number);
    const [debtor, setDebtor] = useState(props.selectedItem.account_Number);
    const [expiry_Date, setExpiry_Date] = useState(props.selectedItem.expiry_Date);
    const [amount, setAmount] = useState(props.selectedItem.account_Number);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedPromissory_NotePortfolio = {
            id: props.selectedItem.id,
            movement_Type: movement_Type,
            current_ID: current_ID,
            registration_Date: registration_Date,
            explanation: explanation,
            portfolio_No: portfolio_No,
            serial_Number: serial_Number,
            debtor: debtor,
            expiry_Date: expiry_Date,
            amount: amount,
            UpdatedUserId:authUser.Id,

        };

        try {
           //await axios.post("/Bank/UpdateBank", updatedBank);
           await api.create("/Promissory_NotePortfolio/UpdatePromissory_NotePortfolio", updatedPromissory_NotePortfolio);
            props.handleClose2(); // Modalı kapatıyoruz
            navigate(`/SenetPortfoyler`);
            //window.location.href = `/SenetPortfoyler`;

        } catch (error) {
            console.error("Error updating current group:", error);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Hareket Tipi</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Movement_Type"
                    name="movement_Type"
                    value={movement_Type}
                    onChange={(e) => setMovement_Type(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Cari</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Current_ID"
                    name="current_ID"
                    value={current_ID}
                    onChange={(e) => setCurrent_ID(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Kayıt Tarihi</Form.Label>
                <Form.Control
                    type="date"
                    placeholder="Registration_Date"
                    name="registration_Date"
                    value={registration_Date}
                    onChange={(e) => setRegistration_Date(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Açıklama</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Explanation "
                    name="explanation "
                    value={explanation }
                    onChange={(e) => setExplanation(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Portföy No</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Portfolio_No"
                    name="portfolio_No"
                    value={portfolio_No}
                    onChange={(e) => setPortfolio_No(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Banka</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Serial_Number"
                    name="serial_Number"
                    value={serial_Number}
                    onChange={(e) => setSerial_Number(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Borçlu</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Debtor"
                    name="debtor"
                    value={debtor}
                    onChange={(e) => setDebtor(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Vade Tarihi</Form.Label>
                <Form.Control
                    type="date"
                    placeholder="Expiry_Date"
                    name="expiry_Date"
                    value={expiry_Date}
                    onChange={(e) => setExpiry_Date(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Tutar </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Amount"
                    name="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                />
            </Form.Group>

            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
            Senet Portföy Düzenle
            </Button>
        </Form>
    );
};

export default Promissory_NotePortfolioUpdate;
