import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

export const Forbiden = () => {
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid={true}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div>Yasak lan çık !!!!</div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
