

import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate,getCurrencySymbol  } from './Util';
import { EArchiveDeleteModal } from './EArchiveDeleteModal';

const EArchiveTableList = ({ user, isChange, userGrupInfo }) => {
  return (
    <React.Fragment>
      <table className="table align-middle table-nowrap" id="customerTable">
        <thead className="table-light">
          <tr>
            <th scope="col" style={{ width: "50px" }}>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
              </div>
            </th>
            <th className="sort" data-sort="id">#</th>
            <th className="sort" data-sort="groupName">Fatura No</th>
            <th className="sort" data-sort="groupName">Cari VKN/TC No</th>
            <th className="sort" data-sort="groupName">Senaryo</th>
            <th className="sort" data-sort="groupName">Tarih-Saat</th>
            <th className="sort" data-sort="groupName">Genel Toplam</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody className="list form-check-all">
          {
            userGrupInfo.map((item, index) => (
              <tr key={item.id}>
                <th scope="row">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                  </div>
                </th>
                <td data-sort="groupName">{index + 1}</td>
                <td data-sort="invoiceNumber">{item.invoiceNumber}</td>
                <td data-sort="currentTCKN_VKN">{item.currentAccountTCKN_VKN}</td>
                <td data-sort="currentEmail">E-Arşiv</td>
                <td data-sort="invoiceDate">{formatDate(item.invoiceDate)} {item.time}</td>
                <td data-sort="generalTotal">
                  {item.generalTotal} {getCurrencySymbol(item.moneyUnit)}
                </td>
                 <td style={{ width: "200px" }}>
                  <div className="d-flex gap-2">
                    {/* <div className="edit">
                      <Link color="warning"  className="btn btn-sm btn-warning edit-item-btn" to={"/EArsiv/EArsivGuncelle/"+item.id}>
                      <i className="fa-solid fa-pen"></i>
                      </Link>
                    </div> */}
                    <div className="remove">
                      <EArchiveDeleteModal id={item.id} isChange={isChange}/>
                    </div>
                    <div className="info">
                      <Link color="info" className="btn btn-sm btn-info edit-item-btn" to={"/EArsiv/EArsivGonder/"+item.id}>
                      <i className="fas fa-paper-plane"></i>
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default EArchiveTableList;
