import React, { useState, useEffect } from 'react';
// import { CurrentGroupUpdateModal } from './CurrentGroupUpdateModal';
// import { CurrentGroupDeleteModal } from './CurrentGroupDeleteModal';
import { StockDeleteModal } from './StockDeleteModal';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';

export const StockTableList = ({ user,isChange,userGrupInfo }) => {
    console.log(userGrupInfo,"userGrupInfo");

    const [selectedItem, setSelectedItem] = useState();




    const [showDetail, setShowDetail] = useState(false);
  
  
  const handleCloseDetail = () => setShowDetail(false);
      const handleShowDetail = (item) => {
          setShowDetail(true);
          setSelectedItem(item);
      };

      
    return (
        <React.Fragment>
            <table className="table align-middle table-nowrap" id="customerTable">
                <thead className="table-light">
                    <tr>
                        <th scope="col" style={{ width: "50px" }}>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                            </div>
                        </th>

                        <th className="sort" data-sort="id">No</th>
                        <th className="sort" data-sort="groupName">Stok Adı</th>
                        <th className="sort" data-sort="groupName">Stok Kodu</th>
                        <th className="sort" data-sort="groupName">Barkodu</th>
                        <th className="sort" data-sort="groupName">Alış Fiyatı</th>
                        <th className="sort" data-sort="groupName">Satış Fiyatı</th>

                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody className="list form-check-all" >
                    {
                    userGrupInfo.map((item, index) => (
                        <tr key={item.id}>
                            <th scope="row">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                </div>
                            </th>

                            <td data-sort="groupName">{index + 1}</td>
                            <td data-sort="stockName">{item.stockName}</td>
                            <td data-sort="stockCode">{item.stockCode}</td>
                            <td data-sort="stockBarcode">{item.stockBarcode}</td>
                            <td data-sort="stockPurchasePrice">{item.stockPurchasePrice}</td>
                            <td data-sort="stockSalesPrice">{item.stockSalesPrice}</td>
                            <td style={{ width: "200px" }}>
                                <div className="d-flex gap-2" >
                                    <div className="edit">
                                    <Link color="warning" className="btn btn-sm btn-warning edit-item-btn" to={"/Stok/StokGuncelle/"+item.id}>
                                         Düzenle
                                        </Link>
                                        
                                    
                                       {/* <CurrentGroupUpdateModal user={user} group={item} isChange={isChange}/> */}
                                    </div>
                                    <div className="remove">
                                        <StockDeleteModal id={item.id} isChange={isChange}/>
                                    </div>
                                    <div className="editthree">
                                                                        <button className="btn btn-sm btn-info detail-item-btn" onClick={() => handleShowDetail(item)}>Detay</button>
                                                                    </div>

                                </div>
                            </td>  
                        </tr>
                    ))
                    }
            </tbody>
        </table>
        <Modal show={showDetail} onHide={handleCloseDetail} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header id="detayheader" className="modal-header" closeButton>
        <Modal.Title className="modal-title">Detaylar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="modal-content">
            <p className="modal-item"><strong> Ürün Veya Hizmet Adı:</strong> {selectedItem?.stockName}</p>
            <p className="modal-item"><strong> Ürün / Stok Kodu:</strong> {selectedItem?.stockCode}</p>
            <p className="modal-item"><strong>Barkod Numarası:</strong> {selectedItem?.stockBarcode}</p>
            <p className="modal-item"><strong>Ürün/Stok Grup Kodu:</strong> {selectedItem?.stockGroup_ID}</p>
            <p className="modal-item"><strong>Ürün Resmi:</strong> {selectedItem?.picture}</p>
            <p className="modal-item"><strong>Stok / Ürün Birimi:</strong> {selectedItem?.stockUnit_ID}</p>
            <p className="modal-item"><strong>GTIP Kodu:</strong> {selectedItem?.gtipkodu}</p>
            <p className="modal-item"><strong>Stok Takibi:</strong> {selectedItem?.stokTakibi}</p>
            <p className="modal-item"><strong>Toplam ürün miktarı için belirle:</strong> {selectedItem?.toplamUrunMiktari}</p>
            <p className="modal-item"><strong>Depo bazında ürün miktarları için belirle:</strong> {selectedItem?.depoUrunMiktari}</p>
            <p className="modal-item"><strong>Kritik miktarın altına düşmesin:</strong> {selectedItem?.kritikstokalertlimit}</p>
            <p className="modal-item"><strong>Alış KDV::</strong> {selectedItem?.stockPurchaseKDV_ID}</p>
            <p className="modal-item"><strong>Alış Fiyatı:</strong> {selectedItem?.stockPurchasePrice}</p>
            <p className="modal-item"><strong>Satış KDV:</strong> {selectedItem?.stockSalesKDV_ID}</p>
            <p className="modal-item"><strong>Satış Fiyatı::</strong> {selectedItem?.stockSalesPrice}</p>
            <p className="modal-item"><strong>Stok Açıklama:</strong> {selectedItem?.stockDescription}</p>
        </div>
    </Modal.Body>
</Modal>

<style jsx>{`
    .modal-content {
        padding: 20px;
    }
    .modal-item {
        margin-bottom: 15px;
        font-size: 16px;
    }
    .modal-title {
        font-size: 20px;
        font-weight: bold;
    }
        #detayheader{
                margin-left: 15px;
                margin-right: 15px;

        }
`}</style>

        </React.Fragment >
    )
}
