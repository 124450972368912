import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { Link, useNavigate } from 'react-router-dom';
// import { GroupAddModal } from '../../components/UserGroup/GroupAddModal';
import { CurrentGroupAddModal } from '../../components/CurrentGroup/CurrentGroupAddModal';
import {CurrentGroupTableList} from '../../components/CurrentGroup/CurrentGroupTableList';

const CurrentGroupList = () => {
    document.title = "Cari Grubu | İlekaSoft";
    const navigate = useNavigate();
    const [isAdded,setIsAdded] = useState()
    const [user,setUser] = useState({})
      const api = new APIClient();

      const [userGrupInfo, setUserGrupInfo] = useState([])
      const [currentPage, setCurrentPage] = useState(1);
      const itemsPerPage = 5
  
      const authUser = getLoggedinUser();
      var companyId = authUser.CompanyId;
  
      useEffect(() => {
        setUser(authUser);
        fetchData();
      }, [])
      const fetchData=()=>{
            api.get("/CurrentGroup/GetAll?companyId="+companyId).then(res => {
            setUserGrupInfo(res)}).catch(er=>console.log(er))
      }
      const handleChange = () => {
        fetchData();
      };


      const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = userGrupInfo.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < Math.ceil(userGrupInfo.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPages = Math.ceil(userGrupInfo.length / itemsPerPage);

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <li key={i} className="page-item">
                        <Button className={`page-link ${currentPage === i ? 'active' : ''}`} onClick={() => paginate(i)}>
                            {i}
                        </Button>
                    </li>
                );
            }
        } else {
            if (currentPage > 2) {
                pageNumbers.push(
                    <li key={1} className="page-item">
                        <Button className="page-link" onClick={() => paginate(1)}>
                            1
                        </Button>
                    </li>
                );
                if (currentPage > 3) {
                    pageNumbers.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
                }
            }

            const startPage = currentPage === 1 ? currentPage : currentPage - 1;
            const endPage = currentPage === totalPages ? currentPage : currentPage + 1;

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <li key={i} className="page-item">
                        <Button className={`page-link ${currentPage === i ? 'active' : ''}`} onClick={() => paginate(i)}>
                            {i}
                        </Button>
                    </li>
                );
            }

            if (currentPage < totalPages - 1) {
                if (currentPage < totalPages - 2) {
                    pageNumbers.push(<li key="ellipsis2" className="page-item disabled"><span className="page-link">...</span></li>);
                }
                pageNumbers.push(
                    <li key={totalPages} className="page-item">
                        <Button className="page-link" onClick={() => paginate(totalPages)}>
                            {totalPages}
                        </Button>
                    </li>
                );
            }
        }

        return pageNumbers;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Cari Grupları" breadcrumbItem="Cari Grupları" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>

                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">

                                                <CurrentGroupAddModal user={user} addChange={handleChange}/>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Search..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        {userGrupInfo.length === 0 ? (
                                        <div className="text-center">
                                            <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                            </lord-icon>
                                            <h5 className="mt-2">Veri Bulunamadı</h5>
                                            <p className="text-muted mb-0">Sistemde kayıtlı cari bulunmamaktadır.</p>
                                        </div>
                                    ) : (
                                        <>
                                            <CurrentGroupTableList user={user} isChange={handleChange}  userGrupInfo={userGrupInfo} />
                                                
                                            

                                                <div className="d-flex justify-content-end">
                                         <div className="pagination-wrap hstack gap-2">
                                             <Button className="page-item pagination-prev" onClick={prevPage} disabled={currentPage === 1}>
                                             Önceki
                                             </Button>
                                             <ul className="pagination listjs-pagination mb-0">
                                                 {renderPageNumbers()}
                                             </ul>
                                             <Button className="page-item pagination-next" onClick={nextPage} disabled={currentPage === Math.ceil(userGrupInfo.length / itemsPerPage)}>
                                                 Sonraki
                                             </Button>
                                         </div>
                                     </div>
                                        </>
                                    )}

                                    <style jsx>{`
                                        .pagination .page-link {
                                            color: grey;
                                        }
                                        .pagination .page-link.active {
                                            color: white;
                                        }
                                    `}</style>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CurrentGroupList;

