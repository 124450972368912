import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const CashboxTransfersAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const [cashboxes, setCashboxes] = useState([]);
    const [banks, setBanks] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const companyId = authUser.CompanyId;

    useEffect(() => {
        fetchCashboxes();
        fetchBanks();
    }, [companyId]);

    const fetchCashboxes = () => {
        api.get(`/Cashbox/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id
                }));
                setCashboxes(formattedOptions);
                setShowForm(true);
            })
            .catch(error => console.error("Error fetching cashboxes:", error));
    };

    const fetchBanks = () => {
        api.get(`/Bank/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.bank_Name,
                    value: item.id
                }));
                setBanks(formattedOptions);
            })
            .catch(error => console.error("Error fetching banks:", error));
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        if(values.TargetBank == '')
            {
                values.TargetBank = 0;
            }
           else if(values.SourceCashbox == '')
                {
                    values.SourceCashbox = 0;
                }
              else  if(values.TargetCashbox == '')
                    {
                        values.TargetCashbox = 0;
                    }
            values.CompanyId = companyId;
        try {
            console.log("merhaba");
            console.log(values);
            await api.create("/CashboxTransfers/AddCashboxTransfers", values);
            toast.success("Başarıyla kaydedildi", { position: "bottom-right", autoClose: 10000 });
            setTimeout(() => {
                navigate(`/KasaTransferler`);
                //window.location.href = `/KasaTransferler`;
            }, 1500);
        } catch (error) {
            console.error("Error:", error);
            toast.error("Kaydedilemedi", { position: "bottom-right", autoClose: 10000 });
        } finally {
            setSubmitting(false);
        }
    };

    const validationSchema = Yup.object().shape({
        KvOrKbt: Yup.string().required('Bu alan gereklidir'),
        Document_Receipt_No: Yup.string().required('Bu alan gereklidir'),
        SourceCashbox: Yup.string().required('Bu alan gereklidir'),
        Date: Yup.string().required('Bu alan gereklidir'),
        Amount: Yup.number()
            .typeError('Bu alan gereklidir')
            .required('Bu alan gereklidir')
            .positive('Pozitif bir değer olması gerekmektedir'),
        Explanation: Yup.string().required('Bu alan gereklidir'),
        TargetCashbox: Yup.string().test(
            'TargetCashbox',
            'Bu alan gereklidir',
            function (value) {
                const { KvOrKbt } = this.parent;
                if (KvOrKbt === '1') {
                    return value !== undefined && value !== '';
                }
                return true;
            }
        ),
        TargetBank: Yup.string().test(
            'TargetBank',
            'Bu alan gereklidir',
            function (value) {
                const { KvOrKbt } = this.parent;
                if (KvOrKbt === '0') {
                    return value !== undefined && value !== '';
                }
                return true;
            }
        )
    });
    
    

    
    


    const initialValues = {
        KvOrKbt: '',
        Document_Receipt_No: '',
        SourceCashbox: '',
        TargetCashbox: '',
        TargetBank: '',
        Date: '',
        Amount: '',
        Explanation: '',
        CompanyId: authUser.CompanyId,
        CreatedUserId: authUser.Id,
        Status: true
    };

    const kvOrKbtOptions = [
        { label: 'Kasa Virman', value: '1' },
        { label: 'Kasadan Bankaya Tahsilat', value: '0' }
    ];

    return (
        <React.Fragment>
            <div className="page-content" style={{ maxHeight: 'calc(150vh - 200px)', overflowY: 'auto' }}>
                <Container fluid={true}>
                    <Breadcrumbs title="Kasa Transfer Ekle" breadcrumbItem="Kasa Transferler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting, setFieldValue, values }) => (
                                            <Form>
                                                <FormGroup>
                                                    <Label for="KvOrKbt">Transfer Türü</Label>
                                                    <Select
                                                        name="KvOrKbt"
                                                        value={kvOrKbtOptions.find(option => option.value === values.KvOrKbt)}
                                                        onChange={option => setFieldValue('KvOrKbt', option.value)}
                                                        options={kvOrKbtOptions}
                                                        placeholder="Transfer Türü Seçiniz"
                                                    />
                                                    <ErrorMessage name="KvOrKbt" component="div" className="text-danger" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="Document_Receipt_No">Belge / Makbuz Numarası</Label>
                                                    <Field
                                                        type="text"
                                                        name="Document_Receipt_No"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Document_Receipt_No" component="div" className="text-danger" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="SourceCashbox">Kaynak Kasa</Label>
                                                    <Select
                                                        name="SourceCashbox"
                                                        value={cashboxes.find(option => option.value === values.SourceCashbox)}
                                                        onChange={option => setFieldValue('SourceCashbox', option.value)}
                                                        options={cashboxes}
                                                        placeholder="Kaynak Kasa Seçiniz"
                                                    />
                                                    <ErrorMessage name="SourceCashbox" component="div" className="text-danger" />
                                                </FormGroup>

                                                {values.KvOrKbt === '1' ? (
                            <FormGroup>
                                <Label for="TargetCashbox">Hedef Kasa</Label>
                                <Select
                                    name="TargetCashbox"
                                    value={cashboxes.find(option => option.value === values.TargetCashbox) || null}
                                    onChange={option => setFieldValue('TargetCashbox', option ? option.value : '')}
                                    options={cashboxes}
                                    placeholder="Hedef Kasa Seçiniz"
                                />
                                <ErrorMessage name="TargetCashbox" component="div" className="text-danger" />
                            </FormGroup>
                        ) : (
                            <FormGroup>
                                <Label for="TargetBank">Hedef Banka</Label>
                                <Select
                                    name="TargetBank"
                                    value={banks.find(option => option.value === values.TargetBank) || null}
                                    onChange={option => setFieldValue('TargetBank', option ? option.value : '')}
                                    options={banks}
                                    placeholder="Hedef Banka Seçiniz"
                                />
                                <ErrorMessage name="TargetBank" component="div" className="text-danger" />
                            </FormGroup>
                        )}

                                                <FormGroup>
                                                    <Label for="Date">Tarih</Label>
                                                    <Field
                                                        type="date"
                                                        name="Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Date" component="div" className="text-danger" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="Amount">Tutar</Label>
                                                    <Field
                                                        type="number"
                                                        name="Amount"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Amount" component="div" className="text-danger" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="Explanation">Açıklama</Label>
                                                    <Field
                                                        type="textarea"
                                                        name="Explanation"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Explanation" component="div" className="text-danger" />
                                                </FormGroup>

                                                <Button color="primary" type="submit" disabled={isSubmitting}>Kaydet</Button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CashboxTransfersAdd;








{/*import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
const CashboxTransfersAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const [cashboxes, setCashboxes] = useState([]);
    const [banks, setBanks] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const companyId = authUser.CompanyId;

    useEffect(() => {
        fetchCashboxes();
        fetchBanks();
    }, [companyId]);

    const fetchCashboxes = () => {
        api.get(`/Cashbox/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id
                }));
                setCashboxes(formattedOptions);
                setShowForm(true);
            })
            .catch(error => console.error("Error fetching cashboxes:", error));
    };

    const fetchBanks = () => {
        api.get(`/Bank/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.bank_Name,
                    value: item.id
                }));
                setBanks(formattedOptions);
            })
            .catch(error => console.error("Error fetching banks:", error));
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        if(values.TargetBank == '')
            {
                values.TargetBank = 0;
            }
           else if(values.SourceCashbox == '')
                {
                    values.SourceCashbox = 0;
                }
              else  if(values.TargetCashbox == '')
                    {
                        values.TargetCashbox = 0;
                    }
            values.CompanyId = companyId;
        try {
            console.log("merhaba");
            console.log(values);
            await api.create("/CashboxTransfers/AddCashboxTransfers", values);
            toast.success("Başarıyla kaydedildi", { position: "bottom-right", autoClose: 10000 });
            setTimeout(() => {
                navigate(`/KasaTransferler`);
                //window.location.href = `/KasaTransferler`;
            }, 1500);
        } catch (error) {
            console.error("Error:", error);
            toast.error("Kaydedilemedi", { position: "bottom-right", autoClose: 10000 });
        } finally {
            setSubmitting(false);
        }
    };

    const initialValues = {
        KvOrKbt: '',
        Document_Receipt_No: '',
        SourceCashbox: '',
        TargetCashbox: '',
        TargetBank: '',
        Date: '',
        Amount: '',
        Explanation: '',
        CompanyId: authUser.CompanyId,
        CreatedUserId: authUser.Id,
        Status: true
    };

    const kvOrKbtOptions = [
        { label: 'Kasa Virman', value: '1' },
        { label: 'Kasadan Bankaya Tahsilat', value: '0' }
    ];

    return (
        <React.Fragment>
            <div className="page-content" style={{ maxHeight: 'calc(150vh - 200px)', overflowY: 'auto' }}>
                <Container fluid={true}>
                    <Breadcrumbs title="Kasa Transfer Ekle" breadcrumbItem="Kasa Transferler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting, setFieldValue, values }) => (
                                            <Form>
                                                <FormGroup>
                                                    <Label for="KvOrKbt">Transfer Türü</Label>
                                                    <Select
                                                        name="KvOrKbt"
                                                        value={kvOrKbtOptions.find(option => option.value === values.KvOrKbt)}
                                                        onChange={option => setFieldValue('KvOrKbt', option.value)}
                                                        options={kvOrKbtOptions}
                                                        placeholder="Transfer Türü Seçiniz"
                                                    />
                                                    <ErrorMessage name="KvOrKbt" component="div" className="text-danger" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="Document_Receipt_No">Belge / Makbuz Numarası</Label>
                                                    <Field
                                                        type="text"
                                                        name="Document_Receipt_No"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Document_Receipt_No" component="div" className="text-danger" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="SourceCashbox">Kaynak Kasa</Label>
                                                    <Select
                                                        name="SourceCashbox"
                                                        value={cashboxes.find(option => option.value === values.SourceCashbox)}
                                                        onChange={option => setFieldValue('SourceCashbox', option.value)}
                                                        options={cashboxes}
                                                        placeholder="Kaynak Kasa Seçiniz"
                                                    />
                                                    <ErrorMessage name="SourceCashbox" component="div" className="text-danger" />
                                                </FormGroup>

                                                {values.KvOrKbt === '1' ? (
                                                    <FormGroup>
                                                        <Label for="TargetCashbox">Hedef Kasa</Label>
                                                        <Select
                                                            name="TargetCashbox"
                                                            value={cashboxes.find(option => option.value === values.TargetCashbox)}
                                                            onChange={option => setFieldValue('TargetCashbox', option.value)}
                                                            options={cashboxes}
                                                            placeholder="Hedef Kasa Seçiniz"
                                                        />
                                                        <ErrorMessage name="TargetCashbox" component="div" className="text-danger" />
                                                    </FormGroup>
                                                ) : (
                                                    <FormGroup>
                                                        <Label for="TargetBank">Hedef Banka</Label>
                                                        <Select
                                                            name="TargetBank"
                                                            value={banks.find(option => option.value === values.TargetBank)}
                                                            onChange={option => setFieldValue('TargetBank', option.value)}
                                                            options={banks}
                                                            placeholder="Hedef Banka Seçiniz"
                                                        />
                                                        <ErrorMessage name="TargetBank" component="div" className="text-danger" />
                                                    </FormGroup>
                                                )}

                                                <FormGroup>
                                                    <Label for="Date">Tarih</Label>
                                                    <Field
                                                        type="date"
                                                        name="Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Date" component="div" className="text-danger" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="Amount">Tutar</Label>
                                                    <Field
                                                        type="number"
                                                        name="Amount"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Amount" component="div" className="text-danger" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="Explanation">Açıklama</Label>
                                                    <Field
                                                        type="textarea"
                                                        name="Explanation"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Explanation" component="div" className="text-danger" />
                                                </FormGroup>

                                                <Button color="primary" type="submit" disabled={isSubmitting}>Kaydet</Button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CashboxTransfersAdd;
*/}







{/*import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CashboxTransfersAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();

    const [cashboxsource, setCashboxsource] = useState([]);
    const [cashboxtarget, setCashboxtarget] = useState([]);
    const [banktarget, setBanktarget] = useState([]);

    const [showForm, setShowForm] = useState(false);
    const companyId = authUser.CompanyId;

    useEffect(() => {
        api.get("/Cashbox/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id.toString()
            }));
            setCashboxsource(formattedOptions);
            setCashboxtarget(formattedOptions);
        });
        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const bankformattedOptions = res.map(item => ({
                label: item.Bank_Name,
                value: item.id.toString()
            }));
            setBanktarget(bankformattedOptions);
        });
    }, [companyId]);

    const validationSchema = Yup.object().shape({
        KvOrKbt: Yup.number().required('Required'),
        Document_Receipt_No: Yup.string().required('Required'),
        SourceCashbox: Yup.string().required('Required'),
        TargetCashbox: Yup.string().required('Required'),
        TargetBank: Yup.string().required('Required'),     
        Date: Yup.date().required('Required'),
        Amount: Yup.number().required('Required').positive('Must be positive'),
        Explanation: Yup.string().required('Required')
    });

    const initialValues = {
        KvOrKbt: 0,
        Document_Receipt_No: '',
        SourceCashbox: '',
        TargetCashbox: '',
        TargetBank: '',
        Date: '',
        Amount: 0,
        Explanation: '',
        CompanyId: authUser.CompanyId,
        CreatedUserId: authUser.Id,
        status: true
    };

    const handleSubmit = (values, { setSubmitting }) => {
        api.create("/CashboxTransfers/AddCashboxTransfers", values)
            .then(res => {
                console.log("başarılı");
                window.location.href = `/KasaTransferler`;
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .finally(() => setSubmitting(false));
    };

    const handleKvOrKbtChange = (option, setFieldValue) => {
        setFieldValue('KvOrKbt', option.value);
        setShowForm(true);
    };

    const kvOrKbtOptions = [
        { label: 'Kasa Virman', value: 1 },
        { label: 'Kasadan Bankaya Tahsilat', value: 0 }
    ];

    return (
        <React.Fragment>
            <div className="page-content" style={{ maxHeight: 'calc(150vh - 200px)', overflowY: 'auto' }}>
                <Container fluid={true}>
                    <Breadcrumbs title="Kasa Transfer Ekle" breadcrumbItem="Kasa Transferler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting, setFieldValue, values }) => (
                                        <Form>
                                            <FormGroup>
                                                <Label for="KvOrKbt">KvOrKbt</Label>
                                                <Select
                                                    name="KvOrKbt"
                                                    value={kvOrKbtOptions.find(option => option.value === values.KvOrKbt)}
                                                    onChange={option => handleKvOrKbtChange(option, setFieldValue)}
                                                    options={kvOrKbtOptions}
                                                    placeholder="Select KvOrKbt"
                                                />
                                                <ErrorMessage name="KvOrKbt" component="div" className="text-danger" />
                                            </FormGroup>

                                            {showForm && (
                                                <>
                                                    <FormGroup>
                                                        <Label for="Document_Receipt_No">Document Receipt No</Label>
                                                        <Field
                                                            type="text"
                                                            name="Document_Receipt_No"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage name="Document_Receipt_No" component="div" className="text-danger" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="SourceCashbox">Source Cashbox</Label>
                                                        <Select
                                                            name="SourceCashbox"
                                                            value={cashboxsource.find(option => option.value === values.SourceCashbox)}
                                                            onChange={option => setFieldValue('SourceCashbox', option ? option.value : '')}
                                                            options={cashboxsource}
                                                            placeholder="Select Source Cashbox"
                                                        />
                                                        <ErrorMessage name="SourceCashbox" component="div" className="text-danger" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="TargetCashbox">Target Cashbox</Label>
                                                        <Select
                                                            name="TargetCashbox"
                                                            value={cashboxtarget.find(option => option.value === values.TargetCashbox)}
                                                            onChange={option => setFieldValue('TargetCashbox', option ? option.value : '')}
                                                            options={cashboxtarget}
                                                            placeholder="Select Target Cashbox"
                                                        />
                                                        <ErrorMessage name="TargetCashbox" component="div" className="text-danger" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="TargetBank">TargetBank</Label>
                                                        <Select
                                                            name="TargetBank"
                                                            value={banktarget.find(option => option.value === values.TargetBank)}
                                                            onChange={option => setFieldValue('TargetBank', option ? option.value : '')}
                                                            options={banktarget}
                                                            placeholder="Select Target Cashbox"
                                                        />
                                                        <ErrorMessage name="TargetCashbox" component="div" className="text-danger" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="Date">Date</Label>
                                                        <Field
                                                            type="date"
                                                            name="Date"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage name="Date" component="div" className="text-danger" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="Amount">Amount</Label>
                                                        <Field
                                                            type="number"
                                                            name="Amount"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage name="Amount" component="div" className="text-danger" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="Explanation">Explanation</Label>
                                                        <Field
                                                            type="textarea"
                                                            name="Explanation"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage name="Explanation" component="div" className="text-danger" />
                                                    </FormGroup>

                                                    <Button color="primary" type="submit" disabled={isSubmitting}>Kaydet</Button>
                                                </>
                                            )}
                                        </Form>
                                    )}

                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};
export default CashboxTransfersAdd;*/}