import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import * as yup from 'yup';

const CostAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    const [mainCosts, setMainCosts] = useState([]);
    const [subCosts, setSubCosts] = useState([]);
    const [cashboxes, setCashboxes] = useState([]);
    const [banks, setBanks] = useState([]);


    
 const validationSchema = yup.object().shape({
    MainCostId: yup.string().required('Bu alan gereklidir'),
    SubCostId: yup.string().required('Bu alan gereklidir'),
    Date: yup.string().required('Bu alan gereklidir'),
    PaymentType: yup.string().required('Bu alan gereklidir'),
    CashboxId: yup.string().required('Bu alan gereklidir'),
    BankId: yup.string().required('Bu alan gereklidir'),
    Amount: yup.number().required('Bu alan gereklidir'),
    Explanation: yup.string().required('Bu alan gereklidir'),
});



    useEffect(() => {
        const companyId = authUser.CompanyId;

        api.get("/Cost/GetAllMainCosts").then(res => {
            const formattedOptions = res.map(item => ({
                label: item.name,
                value: item.id
            }));
            setMainCosts(formattedOptions);
        });

        api.get("/Cashbox/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id
            }));
            setCashboxes(formattedOptions);
        });

        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id
            }));
            setBanks(formattedOptions);
        });
    }, [authUser.CompanyId]);

    const formik = useFormik({
        initialValues: {
            MainCostId: '', //1
            SubCostId: '', //1
            Date: '',   //2024-06-24T00:00:00    
            PaymentType: '', //Kasa
            CashboxId: '', //1
            BankId: '', //1
            Amount: '', //100
            Explanation: '', //string
            CompanyId: parseInt(authUser.CompanyId),
            CreatedUserId: parseInt(authUser.Id),
            status: true,
        },
        validationSchema,
        onSubmit: (values) => {
console.log("geliyor");
            console.log(values);

            if(values.CashboxId != "")
                {
                    values.BankId = 0;
                    alert("selam");
                }
                else if(values.BankId != "")
                    {
                        values.CashboxId = 0;
                        alert("selam");

                    }
            api.create('/Cost/AddCost', values)
                .then(res => {
                    navigate('/giderler');
                    toast.success("Başarıyla kaydedildi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    toast.error("Kaydedilemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    const handleMainCostChange = (selectedOption) => {
        formik.setFieldValue('MainCostId', selectedOption ? selectedOption.value : '');
        if (selectedOption) {
            api.get(`/Cost/GetAllSubCosts?mainCostId=${selectedOption.value}`).then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.name,
                    value: item.id
                }));
                setSubCosts(formattedOptions);
            });
        } else {
            setSubCosts([]);
            formik.setFieldValue('SubCostId', '');
        }
    };

    const handlePaymentTypeChange = (selectedOption) => {
        formik.setFieldValue('PaymentType', selectedOption ? selectedOption.value : '');
      

        if(selectedOption.value =="Kasa")
        {
            formik.setFieldValue('CashboxId', '');
            formik.setFieldValue('BankId', 0);
        }
        else if (selectedOption.value =="Banka")
        {
            formik.setFieldValue('CashboxId', 0);
            formik.setFieldValue('BankId', '');
        }
        const cardBodyElement = document.getElementById('thecardbody');
        if (cardBodyElement) {
            cardBodyElement.style.height = '660px';
        }

    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Gider Ekle" breadcrumbItem="Giderler" />
                    <Row>
                        <Col>
                            <Card>
                            <CardBody id="thecardbody" style={{ height: '570px' }}>
                                        <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="MainCostId" className="form-label">Gider Türü</label>
                                            <Select
                                                name="MainCostId"
                                                options={mainCosts}
                                                onChange={handleMainCostChange}
                                                value={mainCosts.find(option => option.value === formik.values.MainCostId)}
                                            />
                                           {formik.errors.MainCostId && formik.touched.MainCostId ? (
                                                <div className="text-danger">{formik.errors.MainCostId}</div>
                                            ) : null}


                                        </div>

                                            <div className="mb-3">
                                                <label htmlFor="SubCostId" className="form-label">Gider Alt Türü</label>
                                                <Select
                                                    name="SubCostId"
                                                    options={subCosts}
                                                    onChange={(option) => formik.setFieldValue('SubCostId', option ? option.value : '')}
                                                    value={subCosts.find(option => option.value === formik.values.SubCostId)}
                                                />
                                                  {formik.errors.SubCostId && formik.touched.SubCostId ? (
                                                <div className="text-danger">{formik.errors.SubCostId}</div>
                                            ) : null}




                                            </div>

                                            <>
                                                <div className="mb-3">
                                                    <label htmlFor="Date" className="form-label">Tarih</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="Date"
                                                        value={formik.values.Date}
                                                        onChange={formik.handleChange}
                                                    />
                                                    
    {formik.errors.Date && formik.touched.Date ? (
                                                <div className="text-danger">{formik.errors.Date}</div>
                                            ) : null}

                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="PaymentType" className="form-label">Ödeme Türü</label>
                                                    <Select
                                                        name="PaymentType"
                                                        options={[
                                                            { label: 'Kasa', value: 'Kasa' },
                                                            { label: 'Banka', value: 'Banka' }
                                                        ]}
                                                        onChange={handlePaymentTypeChange}
                                                        value={{ label: formik.values.PaymentType, value: formik.values.PaymentType }}
                                                    />
                                                    
    {formik.errors.PaymentType && formik.touched.PaymentType ? (
                                                <div className="text-danger">{formik.errors.PaymentType}</div>
                                            ) : null}


                                                </div>

                                                {formik.values.PaymentType === 'Kasa' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="CashboxId" className="form-label">Kasa</label>
                                                        <Select
                                                            name="CashboxId"
                                                            options={cashboxes}
                                                            onChange={(option) => formik.setFieldValue('CashboxId', option ? option.value : 0)}
                                                            value={cashboxes.find(option => option.value === formik.values.CashboxId)}
                                                        />
                                                        
     {formik.errors.CashboxId && formik.touched.CashboxId ? (
                                                    <div className="text-danger">{formik.errors.CashboxId}</div>
                                                ) : null}


                                                    </div>
                                                )}

                                                {formik.values.PaymentType === 'Banka' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="BankId" className="form-label">Banka</label>
                                                        <Select
                                                            name="BankId"
                                                            options={banks}
                                                            onChange={(option) => formik.setFieldValue('BankId', option ? option.value : 0)}
                                                            value={banks.find(option => option.value === formik.values.BankId)}
                                                        />
                                                        
   {formik.errors.BankId && formik.touched.BankId ? (
                                                    <div className="text-danger">{formik.errors.BankId}</div>
                                                ) : null}


                                                    </div>
                                                )}

                                                <div className="mb-3">
                                                    <label htmlFor="Amount" className="form-label">Tutar</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="Amount"
                                                        value={formik.values.Amount}
                                                        onChange={formik.handleChange}
                                                    />
                                                      {formik.errors.Amount && formik.touched.Amount ? (
                                                <div className="text-danger">{formik.errors.Amount}</div>
                                            ) : null}

                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="Explanation" className="form-label">Açıklama</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="Explanation"
                                                        value={formik.values.Explanation}
                                                        onChange={formik.handleChange}
                                                        
                                                    />
                                                    
  {formik.errors.Explanation && formik.touched.Explanation ? (
                                                <div className="text-danger">{formik.errors.Explanation}</div>
                                            ) : null}

                                                </div>

                                                <button type="submit" className="btn btn-primary">Kaydet</button>
                                            </>
                                        
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CostAdd;
{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const CostAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    const [mainCosts, setMainCosts] = useState([]);
    const [subCosts, setSubCosts] = useState([]);
    const [cashboxes, setCashboxes] = useState([]);
    const [banks, setBanks] = useState([]);

    useEffect(() => {
        const companyId = authUser.CompanyId;

        api.get("/Cost/GetAllMainCosts").then(res => {
            const formattedOptions = res.map(item => ({
                label: item.name,
                value: item.id.toString() 
            }));
            setMainCosts(formattedOptions);
        });

        api.get("/Cashbox/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id.toString() 
            }));
            setCashboxes(formattedOptions);
        });

        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id.toString() 
            }));
            setBanks(formattedOptions);
        });
    }, [authUser.CompanyId]);

    const formik = useFormik({
        initialValues: {
        MainCostId: '', //1
        SubCostId: '', //1
        Date: '',   //2024-06-24T00:00:00	
        PaymentType: '', //Kasa
        CashboxId: '', //1
        BankId: '', //1
        Amount: '', //100
        Explanation: '', //string
        CompanyId: authUser.CompanyId,
        CreatedUserId: authUser.Id,
        status: true,
        },
        onSubmit: (values) => {
            alert('submit')
            console.log(values);
            api.create('/Cost/AddCost', values)
                .then(res => {
                    navigate('/giderler');
                    toast.success("Başarıyla kaydedildi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    toast.error("Kaydedilemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    const handleMainCostChange = (selectedOption) => {
        formik.setFieldValue('MainCostId', selectedOption ? selectedOption.value : '');
        if (selectedOption) {
            api.get(`/Cost/GetAllSubCosts?mainCostId=${selectedOption.value}`).then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.name,
                    value: item.id.toString() 
                }));
                setSubCosts(formattedOptions);
            });
        } else {
            setSubCosts([]);
            formik.setFieldValue('SubCostId', '');
        }
    };

    const handlePaymentTypeChange = (selectedOption) => {
        formik.setFieldValue('PaymentType', selectedOption ? selectedOption.value : '');
        formik.setFieldValue('CashboxId', '');
        formik.setFieldValue('BankId', '');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Gider Ekle" breadcrumbItem="Giderler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="MainCostId" className="form-label">Gider Türü</label>
                                            <Select
                                                name="MainCostId"
                                                options={mainCosts}
                                                onChange={handleMainCostChange}
                                                value={mainCosts.find(option => option.value === formik.values.MainCostId)}
                                            />
                                        </div>

                                        {formik.values.MainCostId && (
                                            <div className="mb-3">
                                                <label htmlFor="SubCostId" className="form-label">Gider Alt Türü</label>
                                                <Select
                                                    name="SubCostId"
                                                    options={subCosts}
                                                    onChange={(option) => formik.setFieldValue('SubCostId', option ? option.value : '')}
                                                    value={subCosts.find(option => option.value === formik.values.SubCostId)}
                                                />
                                            </div>
                                        )}

                                        {formik.values.SubCostId && (
                                            <>
                                                <div className="mb-3">
                                                    <label htmlFor="Date" className="form-label">Tarih</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="Date"
                                                        value={formik.values.Date}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="PaymentType" className="form-label">Ödeme Türü</label>
                                                    <Select
                                                        name="PaymentType"
                                                        options={[
                                                            { label: 'Kasa', value: 'kasa' },
                                                            { label: 'Banka', value: 'banka' }
                                                        ]}
                                                        onChange={handlePaymentTypeChange}
                                                        value={formik.values.PaymentType}
                                                    />
                                                </div>

                                                {formik.values.PaymentType === 'kasa' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="CashboxId" className="form-label">Kasa</label>
                                                        <Select
                                                            name="CashboxId"
                                                            options={cashboxes}
                                                            onChange={(option) => formik.setFieldValue('CashboxId', option ? option.value : '')}
                                                            value={cashboxes.find(option => option.value === formik.values.CashboxId)}
                                                        />
                                                    </div>
                                                )}

                                                {formik.values.PaymentType === 'banka' && (
                                                    <div className="mb-3">
                                                        <label htmlFor="BankId" className="form-label">Banka</label>
                                                        <Select
                                                            name="BankId"
                                                            options={banks}
                                                            onChange={(option) => formik.setFieldValue('BankId', option ? option.value : '')}
                                                            value={banks.find(option => option.value === formik.values.BankId)}
                                                        />
                                                    </div>
                                                )}

                                                <div className="mb-3">
                                                    <label htmlFor="Amount" className="form-label">Tutar</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="Amount"
                                                        value={formik.values.Amount}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="Explanation" className="form-label">Açıklama</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="Explanation"
                                                        value={formik.values.Explanation}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>

                                                <button type="submit" className="btn btn-primary">Kaydet</button>
                                            </>
                                        )}
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CostAdd;
*/}








{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';

const CostAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

 

    const [formValues, setFormValues] = useState({
        MainCostId: '', //1
        SubCostId: '', //1
        Date: '',   //2024-06-24T00:00:00	
        PaymentType: '', //Kasa
        CashboxId: '', //1
        BankId: '', //1
        Amount: '', //100
        Explanation: '', //string
        CompanyId: authUser.CompanyId,
        CreatedUserId: authUser.Id,
        status: true,
    });
    const [formErrors, setFormErrors] = useState({});


    const validate = () => {
        let errors = {}; // errors değişkenini burada tanımlıyoruz
    
        if (!formValues.MainCostId) {
            errors.MainCostId = 'MainCostId gerekli';
        }
        if (!formValues.SubCostId) {
            errors.SubCostId = 'SubCostId gerekli';
        }
        if (!formValues.Date) {
            errors.Date = 'Date gerekli';
        }
        if (!formValues.PaymentType) {
            errors.PaymentType = 'PaymentType gerekli';
        }
        if (!formValues.CashboxId) {
            errors.CashboxId = 'CashboxId gerekli';
        }
        if (!formValues.BankId) {
            errors.BankId = 'BankId gerekli';
        }
        if (!formValues.Amount) {
            errors.Amount = 'Amount gerekli';
        }
        if (!formValues.Explanation) {
            errors.Explanation = 'Explanation gerekli';
        }
           
        return errors;
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validate();
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            api.create('/Cost/AddCost', formValues)
                .then(res => {
                    navigate('/Giderler');
                    toast.success("Başarıyla kaydedildi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Kaydedilemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        }
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Gider Ekle" breadcrumbItem="Giderler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={handleSubmit}>
                                    

                                      
                                        <div className="mb-3">
                                            <label htmlFor="MainCostId" className="form-label">MainCostId</label>
                                            <input
                                                className={`form-control ${formErrors.MainCostId ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="MainCostId"
                                                value={formValues.MainCostId}
                                                onChange={handleChange}
                                            />
                                            {formErrors.MainCostId && (
                                                <div className="invalid-feedback">{formErrors.MainCostId}</div>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="SubCostId" className="form-label">SubCostId</label>
                                            <input
                                                className={`form-control ${formErrors.SubCostId ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="SubCostId"
                                                value={formValues.SubCostId}
                                                onChange={handleChange}
                                            />
                                            {formErrors.SubCostId && (
                                                <div className="invalid-feedback">{formErrors.SubCostId}</div>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Date" className="form-label">Date</label>
                                            <input
                                                className={`form-control ${formErrors.Date ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Date"
                                                value={formValues.Date}
                                                onChange={handleChange}
                                            />
                                            {formErrors.Date && (
                                                <div className="invalid-feedback">{formErrors.Date}</div>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="PaymentType" className="form-label">PaymentType</label>
                                            <input
                                                className={`form-control ${formErrors.PaymentType ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="PaymentType"
                                                value={formValues.PaymentType}
                                                onChange={handleChange}
                                            />
                                            {formErrors.PaymentType && (
                                                <div className="invalid-feedback">{formErrors.PaymentType}</div>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="CashboxId" className="form-label">CashboxId</label>
                                            <input
                                                className={`form-control ${formErrors.CashboxId ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="CashboxId"
                                                value={formValues.CashboxId}
                                                onChange={handleChange}
                                            />
                                            {formErrors.CashboxId && (
                                                <div className="invalid-feedback">{formErrors.CashboxId}</div>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="BankId" className="form-label">BankId</label>
                                            <input
                                                className={`form-control ${formErrors.BankId ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="BankId"
                                                value={formValues.BankId}
                                                onChange={handleChange}
                                            />
                                            {formErrors.BankId && (
                                                <div className="invalid-feedback">{formErrors.BankId}</div>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Amount" className="form-label">Amount</label>
                                            <input
                                                className={`form-control ${formErrors.Amount ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Amount"
                                                value={formValues.Amount}
                                                onChange={handleChange}
                                            />
                                            {formErrors.Amount && (
                                                <div className="invalid-feedback">{formErrors.Amount}</div>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Explanation" className="form-label">Explanation</label>
                                            <input
                                                className={`form-control ${formErrors.Explanation ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Explanation"
                                                value={formValues.Explanation}
                                                onChange={handleChange}
                                            />
                                            {formErrors.Explanation && (
                                                <div className="invalid-feedback">{formErrors.Explanation}</div>
                                            )}
                                        </div>


                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Kaydet
                                        </button>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CostAdd;
*/}

