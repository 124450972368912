import axios from "axios";

// default
 axios.defaults.baseURL = "https://portalapi.ilekasoft.com/api";

// axios.defaults.baseURL = "https://localhost:7199/api";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
// intercepting to capture errors

axios.interceptors.response.use(

  function (response) {

    return response.data ? response.data : response;
  },
  function (error) {

    // Any status codes that falls outside the range of 2xx cause this function to trigger

    let message;
    switch (error.response.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "/login";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      case 403:
        message = "/yasak";
        break;
      default:

        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
axios.interceptors.request.use(
  config => {

      // Tüm cookie stringini al
      const cookies = document.cookie;

      // Cookie stringini ';' karakterine göre böler ve her bir cookie parçasını içeren bir dizi elde ederiz
      const cookieArray = cookies.split(';');

      // Token adını arar ve bulursa değerini alır
      let token;
      cookieArray.forEach(cookie => {
          const [name, value] = cookie.trim().split('=');
          if (name === 'token') {
              token = value;
          }
      });
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
   console.log("deneme"+error)
  }
);

const setAuthorization = (token) => {
  document.cookie = `token=${token}`;
  // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
 
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
