import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify'

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
} from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getLoggedinUser } from "../../helpers/api_helper";
import { ModuleUserAdd } from "../../components/ModuleCompany/ModuleUserAdd";
import { CompanyAddModule } from "../../components/ModuleCompany/CompanyAddModule";
import { ModuleCompanyAdd } from "../../components/ModuleCompany/ModuleCompanyAdd";
import { PaymentComponent } from "../../components/Payment/PaymentComponent";

const ModuleCompanyBuy = () => {


  
  document.title = "Modül Satın Al | İlekaSoft";
  const [activeTab, setactiveTab] = useState(1);

  const [passedSteps, setPassedSteps] = useState([1]);
  const [userInfo, setUserInfo] = useState({})
  const [user, setUser] = useState({})
  const [activeBtn,setActiveBtn] =useState(false)
  const [paymentSuccess,setPaymentSuccess] = useState(false)
    const authUser = getLoggedinUser();
    useEffect(()=>{
        setUserInfo(authUser)
        console.log(paymentSuccess)
    },[])

  function toggleTab(tab) {

    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  const handleSubmitBtn=()=>{
    setActiveBtn(true)
  }

  const handlePayment=()=>{
    setPaymentSuccess(true)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Modül" breadcrumbItem="Modül Al" />
    <ToastContainer/>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <NavItem className={classnames({ active: activeTab === 1 })}>
                        <NavLink
                          data-toggle="tab"
                          className={classnames({ active: activeTab === 1 })}
                          onClick={() => {
                            setactiveTab(1);
                          }}
                        >
                          <span className="step-number">01</span>
                          <span className="step-title" style={{ paddingLeft: "10px" }}>Şirket Kayıt</span>
                        </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ active: activeTab === 2 })}>
                        <NavLink
                          data-toggle="tab"
                          className={classnames({ active: activeTab === 2 })}
                          onClick={() => {
                            setactiveTab(2);
                          }}
                        >
                          <span className="step-number">02</span>
                          <span className="step-title" style={{ paddingLeft: "10px" }}>Kullanıcı Kayıt</span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={classnames({ active: activeTab === 3 })}>
                        <NavLink
                          data-toggle="tab"
                          className={classnames({ active: activeTab === 3 })}
                          onClick={() => {
                            setactiveTab(3);
                          }}

                        >

                          <span className="step-number">03</span>
                          <span className="step-title" style={{ paddingLeft: "10px" }}>Modül Kayıt</span>
                        </NavLink>
                      </NavItem>
                      <NavItem className={classnames({ active: activeTab === 4 })}>
                        <NavLink
                          data-toggle="tab"
                          className={classnames({ active: activeTab === 4 })}
                          onClick={() => {
                            setactiveTab(4);
                          }}
                        >
                          <span className="step-number">04</span>
                          <span className="step-title" style={{ paddingLeft: "10px" }}>Ödeme İşlemleri</span>
                        </NavLink>
                      </NavItem>
                    </ul>

                    <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-content">
                      <TabPane tabId={1}>
                        <CompanyAddModule userInfo={userInfo} handleSubmitBtn={handleSubmitBtn} />
                      </TabPane>
                      <TabPane tabId={2}>
                        <div>
                        <ModuleUserAdd userInfo={userInfo} handleSubmitBtn={handleSubmitBtn} />
                    
                        </div>
                      </TabPane>
                      <TabPane tabId={3}>
                        <div className="p-3">
                         <ModuleCompanyAdd userInfo={userInfo} handleSubmitBtn={handleSubmitBtn} />
                        </div>
                      </TabPane>
                      <TabPane tabId={4}>
                        <div className="row justify-content-center">
                            {!paymentSuccess?<PaymentComponent userInfo={userInfo} handlePayment={handlePayment} />:
                        
                          <Col lg="6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>Ödeme Başarılı</h5>
                                {/* <p className="text-muted">
                                  If several languages coalesce, the grammar
                                  of the resulting
                                </p> */}
                              </div>
                            </div>
                          </Col>
                          }
                        </div>
                      </TabPane>
                    </TabContent>
                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li
                        className={
                          activeTab === 1
                            ? "previous disabled me-2"
                            : "previous me-2"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab - 1);
                            setActiveBtn(true)

                          }}
                        >
                          Geri
                        </Link>
                      </li>
                        {(activeBtn ===true) ?<li
                        className={(activeTab === 4 )  ? "next disabled" : "next"}
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab + 1);
                          }}
                        >
                          İleri
                        </Link>
                      </li>:<li
                        className={ "next disabled" }
                      >
                        <Link
                          to="#"
                          
                        >
                          İleri
                        </Link>
                      </li>}
                     
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
            </Row>
            </Container>
            </div>
            </React.Fragment>
  )
}
export default ModuleCompanyBuy;