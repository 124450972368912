import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from '../../api/axios';
import TableListModal_E_Archive from '../../components/TableComponent/Invoice_Serial_Management/TableListModal_E_Archive';
import TableListModal_E_Invoice from '../../components/TableComponent/Invoice_Serial_Management/TableListModal_E_Invoice';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';

const InvoiceSerialManagementList = () => {
    const api = new APIClient();
    document.title = "Kasalar | İlekaSoft";

    const [invoiceData, setInvoiceData] = useState([]);
    const [archiveData, setArchiveData] = useState([]);
    const [invoiceCurrentPage, setInvoiceCurrentPage] = useState(1);
    const [archiveCurrentPage, setArchiveCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const authUser = getLoggedinUser();
    var companyId = authUser.CompanyId;

    const tableHeadE_Invoice = ["E Fatura Seri Ön Eki", "E Fatura Sıra No", "E Fatura Seri Yılı", "Varsayılan"];
    const tableHeadE_Archive = ["E Arşiv Seri Ön Eki", "E Arşiv Sıra No", "E Arşiv Seri Yılı", "Varsayılan"];

    useEffect(() => {
        api.get("/Invoice_Serial_Management/GetAllE_Invoice?companyId="+companyId)
            .then(response => setInvoiceData(response))
            .catch(error => console.error("Error fetching invoice data:", error));
    }, []);

    useEffect(() => {
        api.get("/Invoice_Serial_Management/GetAllE_Archive?companyId="+companyId)
            .then(response => setArchiveData(response))
            .catch(error => console.error("Error fetching archive data:", error));
    }, []);

    const paginate = (setPage, currentPage, totalPages) => {
        return {
            nextPage: () => {
                if (currentPage < totalPages) {
                    setPage(currentPage + 1);
                }
            },
            prevPage: () => {
                if (currentPage > 1) {
                    setPage(currentPage - 1);
                }
            },
            renderPageNumbers: () => {
                const pageNumbers = [];
                if (totalPages <= 5) {
                    for (let i = 1; i <= totalPages; i++) {
                        pageNumbers.push(
                            <li key={i} className="page-item">
                                <Button className={`page-link ${currentPage === i ? 'active' : ''}`} onClick={() => setPage(i)}>
                                    {i}
                                </Button>
                            </li>
                        );
                    }
                } else {
                    if (currentPage > 2) {
                        pageNumbers.push(
                            <li key={1} className="page-item">
                                <Button className="page-link" onClick={() => setPage(1)}>
                                    1
                                </Button>
                            </li>
                        );
                        if (currentPage > 3) {
                            pageNumbers.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
                        }
                    }

                    const startPage = currentPage === 1 ? currentPage : currentPage - 1;
                    const endPage = currentPage === totalPages ? currentPage : currentPage + 1;

                    for (let i = startPage; i <= endPage; i++) {
                        pageNumbers.push(
                            <li key={i} className="page-item">
                                <Button className={`page-link ${currentPage === i ? 'active' : ''}`} onClick={() => setPage(i)}>
                                    {i}
                                </Button>
                            </li>
                        );
                    }

                    if (currentPage < totalPages - 1) {
                        if (currentPage < totalPages - 2) {
                            pageNumbers.push(<li key="ellipsis2" className="page-item disabled"><span className="page-link">...</span></li>);
                        }
                        pageNumbers.push(
                            <li key={totalPages} className="page-item">
                                <Button className="page-link" onClick={() => setPage(totalPages)}>
                                    {totalPages}
                                </Button>
                            </li>
                        );
                    }
                }
                return pageNumbers;
            }
        };
    };

    const invoicePagination = paginate(setInvoiceCurrentPage, invoiceCurrentPage, Math.ceil(invoiceData.length / itemsPerPage));
    const archivePagination = paginate(setArchiveCurrentPage, archiveCurrentPage, Math.ceil(archiveData.length / itemsPerPage));

    const currentItems = (data, currentPage) => data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div className="page-content">
            <Container fluid={true}>
                <Breadcrumbs title="Seri Yönetimler" breadcrumbItem="Seri Yönetimler" />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <TableListModal_E_Invoice tableHeadArray={tableHeadE_Invoice} companyArray={currentItems(invoiceData, invoiceCurrentPage)} />
                                    <div className="d-flex justify-content-end">
                                        <div className="pagination-wrap hstack gap-2">
                                            <Button className="page-item pagination-prev" onClick={invoicePagination.prevPage} disabled={invoiceCurrentPage === 1}>
                                            Önceki
                                            </Button>
                                            <ul className="pagination listjs-pagination mb-0">
                                                {invoicePagination.renderPageNumbers()}
                                            </ul>
                                            <Button className="page-item pagination-next" onClick={invoicePagination.nextPage} disabled={invoiceCurrentPage === Math.ceil(invoiceData.length / itemsPerPage)}>
                                                Sonraki
                                            </Button>
                                        </div>
                                    </div>
                                    <TableListModal_E_Archive tableHeadArray={tableHeadE_Archive} companyArray={currentItems(archiveData, archiveCurrentPage)} />
                                    <div className="d-flex justify-content-end">
                                        <div className="pagination-wrap hstack gap-2">
                                            <Button className="page-item pagination-prev" onClick={archivePagination.prevPage} disabled={archiveCurrentPage === 1}>
                                            Önceki
                                            </Button>
                                            <ul className="pagination listjs-pagination mb-0">
                                                {archivePagination.renderPageNumbers()}
                                            </ul>
                                            <Button className="page-item pagination-next" onClick={archivePagination.nextPage} disabled={archiveCurrentPage === Math.ceil(archiveData.length / itemsPerPage)}>
                                                Sonraki
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <style jsx>{`
                .pagination .page-link {
                    color: grey; /* Tüm sayfa numaraları için gri renk */
                }
                .pagination .page-link.active {
                    color: white; /* Aktif sayfa numarası için beyaz renk */
                }
            `}</style>
        </div>
    );
};

export default InvoiceSerialManagementList;


