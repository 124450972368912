import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const CheckPortfolioUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    
    const navigate = useNavigate();

    const authUser = getLoggedinUser();
    const companyId = authUser.CompanyId;
    
    const [selectedMovementType, setSelectedMovementType] = useState('');
    const [debtor, setDebtor] = useState('');
    const [currentAccountName, setCurrentAccountName] = useState('');
    const [companyName, setCompanyName] = useState(""); 
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [current_ID, setCurrent_ID] = useState("");
    const [selectBank, setSelectBank] = useState(null);
    const [bank, setBank] = useState([]);
    const [bank_ID, setBank_ID] = useState("");
    const [check_Picture, setCheck_Picture] = useState("");
    const [initialValues, setInitialValues] = useState({
        id: "",
        movement_Type: "",
        current_ID: "",
        debtor: "",
        registration_Date: "",
        explanation: "",
        portfolio_No: "",
        serial_Number: "",
        expiry_Date: "",
        bank_Id: "",
        bank_Branch: "",
        amount: "",
        check_Picture: "",
        updatedUserId: authUser.Id,
        status: true,
    });

    useEffect(() => {
        api.get("/Company/GetCompanyById?id=" + companyId)
            .then(res => {
                setCompanyName(res.companyName);
            })
            .catch(error => {
                console.error("Error fetching company data:", error);
            });
    }, [companyId]);

    const [showPreviousImage, setShowPreviousImage] = useState(true);

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCurrentAccountName(selectedOption.label);
        setCurrent_ID(selectedOption ? selectedOption.value : '');
        const debtorValue = selectedMovementType === "Alınan Çek" ? selectedOption.label : companyName;
        setDebtor(debtorValue);
    };

    const handleBank = (selectedOption) => {
        setSelectBank(selectedOption);
        setBank_ID(selectedOption ? selectedOption.value : '');
    };

    useEffect(() => {
        api.get('/CheckPortfolio/GetCheckPortfolioById?id=' + _id)
            .then(res => {
                setInitialValues({
                    id: res.id,
                    movement_Type: res.movement_Type,
                    current_ID: res.current_ID,
                    debtor: res.movement_Type === "Alınan Çek" ? currentAccountName : companyName,
                    registration_Date: res.registration_Date.split('T')[0],
                    explanation: res.explanation,
                    portfolio_No: res.portfolio_No,
                    serial_Number: res.serial_Number,
                    expiry_Date: res.expiry_Date.split('T')[0],
                    bank_Id: res.bank_Id,
                    bank_Branch: res.bank_Branch,
                    amount: res.amount,
                    check_Picture: res.check_Picture,
                    companyId: res.companyId
                });
                setSelectCurrentAccount({ label: res.current_Name, value: res.current_ID });
                setSelectBank({ label: res.bank_Name, value: res.bank_ID });
                setSelectedMovementType(res.movement_Type);
                setCurrentAccountName(res.current_Name); 
            })
            .catch(err => console.log(err));
    }, [_id]);

    useEffect(() => {
        api.get("/CurrentAccount/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id
            }));
            setCurrentAccount(formattedOptions);
        });      
        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id
            }));
            setBank(formattedOptions);
        });      
    }, [companyId]);

    useEffect(() => {
        updateDebtorValue(selectedMovementType);
    }, [selectedMovementType]);

    const updateDebtorValue = (selectedMovementType) => {
        const debtorValue = selectedMovementType === "Alınan Çek" ? currentAccountName : companyName; 
        setDebtor(debtorValue);
    };

    document.title = "Çek Portföy Güncelle | İlekaSoft";

    const validationSchema = Yup.object({
        movement_Type: Yup.string().required('Bu alan gereklidir'),
        current_ID: Yup.string().required('Bu alan gereklidir'),
        registration_Date: Yup.string().required('Bu alan gereklidir'),
        explanation: Yup.string().required('Bu alan gereklidir'),  
        portfolio_No: Yup.string().required('Bu alan gereklidir'),
        serial_Number: Yup.string().required('Bu alan gereklidir'),
        expiry_Date: Yup.string().required('Bu alan gereklidir'),
        bank_Id: Yup.string().required('Bu alan gereklidir'),
        bank_Branch: Yup.string().required('Bu alan gereklidir'),
        amount: Yup.number().required('Bu alan gereklidir'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        console.log(values);
        try {
            const updatedValues = {
                ...values,
                current_ID: selectCurrentAccount?.value || values.current_ID,
                bank_Id: selectBank?.value || values.bank_Id,
                debtor: debtor, // Ensure the updated debtor value is included
            };

            const res = await api.create("/CheckPortfolio/UpdateCheckPortfolio", updatedValues);
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000
            });
            navigate(`/ÇekPortfoyler`);
//            window.location.href = `/ÇekPortfoyler`;

        } catch (err) {
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
            setSubmitting(false);
        }
    };

   {/* const handleFileChange = (e) => {
        const element = document.querySelector('.FormerPicture');
        if (element) {
            element.style.display = 'none';
        }
    
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setInitialValues(prevState => ({
                    ...prevState,
                    check_Picture: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };*/}
    const handleFileChange = (e, setFieldValue) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFieldValue('check_Picture', reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Çek Portföy Güncelle" breadcrumbItem="Çek Portföyler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                         initialValues={initialValues}
                                         enableReinitialize
                                         validationSchema={validationSchema}
                                         onSubmit={handleSubmit}
                                     >
                                         {({ isSubmitting, setFieldValue }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <label htmlFor="movement_Type" className="col-md-2 col-form-label">Hareket Tipi</label>
                                                    <select
                                                        name="movement_Type"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setFieldValue("movement_Type", e.target.value);
                                                            setSelectedMovementType(e.target.value);
                                                        }}
                                                        value={selectedMovementType}
                                                    >
                                                        <option value="Alınan Çek">Alınan Çek</option>
                                                        <option value="Verilen Çek">Verilen Çek</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="current_ID" className="form-label">Cari</label>
                                                    <Select
                                                        value={selectCurrentAccount}
                                                        onChange={handleCurrentAccount}
                                                        options={currentAccount}
                                                        placeholder="Select Current Account"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="debtor" className="col-md-2 col-form-label">Borçlu</label>
                                                    <input
                                                        type="text"
                                                        name="debtor"
                                                        className="form-control"
                                                        value={debtor}
                                                        readOnly
                                                    />
                                                    <ErrorMessage name="debtor" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="registration_Date" className="col-md-2 col-form-label">Kayıt Tarihi</label>
                                                    <Field
                                                        type="date"
                                                        name="registration_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="registration_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="explanation" className="col-md-2 col-form-label">Açıklama</label>
                                                    <Field
                                                        type="text"
                                                        name="explanation"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="explanation" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="portfolio_No" className="col-md-2 col-form-label">Portföy Numarası</label>
                                                    <Field
                                                        type="text"
                                                        name="portfolio_No"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="portfolio_No" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="serial_Number" className="col-md-2 col-form-label">Seri Numarası</label>
                                                    <Field
                                                        type="text"
                                                        name="serial_Number"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="serial_Number" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="expiry_Date" className="col-md-2 col-form-label">Vade Tarihi</label>
                                                    <Field
                                                        type="date"
                                                        name="expiry_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="expiry_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="bank_Id" className="form-label">Banka</label>
                                                    <Select
                                                        value={selectBank}
                                                        onChange={handleBank}
                                                        options={bank}
                                                        placeholder="Select Bank"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="bank_Branch" className="col-md-2 col-form-label">Banka Şubesi</label>
                                                    <Field
                                                        type="text"
                                                        name="bank_Branch"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="bank_Branch" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="amount" className="col-md-2 col-form-label">Miktar</label>
                                                    <Field
                                                        type="number"
                                                        name="amount"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="amount" component="div" className="text-danger" />
                                                </div>
                                              {/*  <div className="form-group">
                                                    <label htmlFor="check_Picture" className="col-md-2 col-form-label">Çek Resmi</label>
                                                    <input
                                                        type="file"
                                                        name="check_Picture"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            handleFileChange(e);
                                                            setFieldValue("check_Picture", e.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    {initialValues.check_Picture && (
                                                        <div style={{ marginTop: "10px" }} className="FormerPicture">
                                                                    <img src={`data:image/jpeg;base64, ${initialValues.check_Picture}`} alt="Check Picture" style={{ maxWidth: "100px" }} />
                                                        </div>
                                                    )}
                                                </div>
                                                */}
   <div className="form-group row" style={{ marginTop: "20px" }}>
            <div className="col-md-6">
                <label htmlFor="check_Picture" className="col-md-2 col-form-label">Çek Resmi</label>
                <input
                id="check_Picture"
                name="check_Picture"
                type="file"
                className="form-control"
                onChange={(e) => handleFileChange(e, setFieldValue)}
            />
            </div>
            {showPreviousImage && initialValues.check_Picture && (
                <div id="Formerpic" className="col-md-6" style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: "10px" }}>Önceki Resim:</div>
                    <img
                        src={`data:image/jpeg;base64, ${initialValues.check_Picture}`}
                        alt="Önceki Çek Resmi"
                        style={{ maxWidth: "100px" }}
                    />
                </div>
            )}
        </div>



                                                <div style={{ marginRight: "850px" ,  marginTop: "20px"  }} className="d-flex justify-content-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        Kaydet
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CheckPortfolioUpdatePage;




{/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CheckPortfolioUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    
    const authUser = getLoggedinUser();
    const companyId = authUser.CompanyId;
    
    const [selectedMovementType, setSelectedMovementType] = useState('');
    const [debtor, setDebtor] = useState('');
    const [currentAccountName, setCurrentAccountName] = useState('');
    const [companyName, setCompanyName] = useState(""); 
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [current_ID, setCurrent_ID] = useState("");
    const [selectBank, setSelectBank] = useState(null);
    const [bank, setBank] = useState([]);
    const [bank_ID, setBank_ID] = useState("");
    const [check_Picture, setCheck_Picture] = useState("");
    const [initialValues, setInitialValues] = useState({
        id: "",
        movement_Type: "",
        current_ID: "",
        debtor: "",
        registration_Date: "",
        explanation: "",
        portfolio_No: "",
        serial_Number: "",
        expiry_Date: "",
        bank_Id: "",
        bank_Branch: "",
        amount: "",
        check_Picture: "",
        updatedUserId: authUser.Id,
        status: true,
    });

    useEffect(() => {
        api.get("/Company/GetCompanyById?id=" + companyId)
            .then(res => {
                setCompanyName(res.companyName);
            })
            .catch(error => {
                console.error("Error fetching company data:", error);
            });
    }, [companyId]);

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCurrentAccountName(selectedOption.label);
        setCurrent_ID(selectedOption ? selectedOption.value : '');
        const debtorValue = selectedMovementType === "Alınan Çek" ? selectedOption.label : companyName;
        setDebtor(debtorValue);
    };

    const handleBank = (selectedOption) => {
        setSelectBank(selectedOption);
        setBank_ID(selectedOption ? selectedOption.value : '');
    };

    useEffect(() => {
        api.get('/CheckPortfolio/GetCheckPortfolioById?id=' + _id)
            .then(res => {
                setInitialValues({
                    id: res.id,
                    movement_Type: res.movement_Type,
                    current_ID: res.current_ID,
                    debtor: res.movement_Type === "Alınan Çek" ? currentAccountName : companyName,
                    registration_Date: res.registration_Date.split('T')[0],
                    explanation: res.explanation,
                    portfolio_No: res.portfolio_No,
                    serial_Number: res.serial_Number,
                    expiry_Date: res.expiry_Date.split('T')[0],
                    bank_Id: res.bank_Id,
                    bank_Branch: res.bank_Branch,
                    amount: res.amount,
                    check_Picture: res.check_Picture,
                    companyId: res.companyId
                });
                setSelectCurrentAccount({ label: res.current_Name, value: res.current_ID });
                setSelectBank({ label: res.bank_Name, value: res.bank_ID });
                setSelectedMovementType(res.movement_Type);
                setCurrentAccountName(res.current_Name); 
            })
            .catch(err => console.log(err));
    }, [_id]);

    useEffect(() => {
        api.get("/CurrentAccount/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id.toString()
            }));
            setCurrentAccount(formattedOptions);
        });      
        api.get("/Bank/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id.toString()
            }));
            setBank(formattedOptions);
        });      
    }, [companyId]);

    useEffect(() => {
        updateDebtorValue(selectedMovementType);
    }, [selectedMovementType]);

    const updateDebtorValue = (selectedMovementType) => {
        const debtorValue = selectedMovementType === "Alınan Çek" ? currentAccountName : companyName; 
        setDebtor(debtorValue);
    };

    document.title = "Çek Portföy Güncelle | İlekaSoft";

    const validationSchema = Yup.object({
        movement_Type: Yup.string().required('This field is required'),
        current_ID: Yup.string().required('This field is required'),
      //  debtor: Yup.string().required('This field is required'),
        registration_Date: Yup.string().required('This field is required'),
        explanation: Yup.string().required('This field is required'),  
        portfolio_No: Yup.string().required('This field is required'),
        serial_Number: Yup.string().required('This field is required'),
        expiry_Date: Yup.string().required('This field is required'),
        bank_Id: Yup.string().required('This field is required'),
        bank_Branch: Yup.string().required('This field is required'),
        amount: Yup.number().required('This field is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const updatedValues = {
                ...values,
                current_ID: selectCurrentAccount.value,
                bank_ID: selectBank.value
            };

            const res = await api.create("/CheckPortfolio/UpdateCheckPortfolio", updatedValues);
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000
            });
            window.location.href = `/ÇekPortfoyler`;
        } catch (err) {
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
            setSubmitting(false);
        }
    };

    const handleFileChange = (e) => {
        const element = document.querySelector('.FormerPicture');
        if (element) {
            element.style.display = 'none';
        }
    
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setInitialValues(prevState => ({
                    ...prevState,
                    check_Picture: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Çek Portföy Güncelle" breadcrumbItem="Çek Portföyler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <label htmlFor="movement_Type" className="col-md-2 col-form-label">Movement Type</label>
                                                    <select
                                                        name="movement_Type"
                                                        className="form-control"
                                                        onChange={(e) => setSelectedMovementType(e.target.value)}
                                                        value={selectedMovementType}
                                                    >
                                                        <option value="Alınan Çek">Alınan Çek</option>
                                                        <option value="Verilen Çek">Verilen Çek</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="current_ID" className="form-label">Current Account</label>
                                                    <Select
                                                        value={selectCurrentAccount}
                                                        onChange={handleCurrentAccount}
                                                        options={currentAccount}
                                                        placeholder="Select Current Account"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="debtor" className="col-md-2 col-form-label">Debtor</label>
                                                    <input
                                                        type="text"
                                                        name="debtor"
                                                        className="form-control"
                                                        value={debtor}
                                                        readOnly
                                                    />
                                                    <ErrorMessage name="debtor" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="registration_Date" className="col-md-2 col-form-label">Registration Date</label>
                                                    <Field
                                                        type="date"
                                                        name="registration_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="registration_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="explanation" className="col-md-2 col-form-label">Explanation</label>
                                                    <Field
                                                        type="text"
                                                        name="explanation"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="explanation" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="portfolio_No" className="col-md-2 col-form-label">Portfolio No</label>
                                                    <Field
                                                        type="text"
                                                        name="portfolio_No"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="portfolio_No" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="serial_Number" className="col-md-2 col-form-label">Serial Number</label>
                                                    <Field
                                                        type="text"
                                                        name="serial_Number"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="serial_Number" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="expiry_Date" className="col-md-2 col-form-label">Expiry Date</label>
                                                    <Field
                                                        type="date"
                                                        name="expiry_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="expiry_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="bank_Id" className="col-md-2 col-form-label">Bank</label>
                                                    <Select
                                                        value={selectBank}
                                                        onChange={handleBank}
                                                        options={bank}
                                                        placeholder="Select Bank"
                                                    />
                                                    <ErrorMessage name="bank_Id" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="bank_Branch" className="col-md-2 col-form-label">Bank Branch</label>
                                                    <Field
                                                        type="text"
                                                        name="bank_Branch"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="bank_Branch" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="amount" className="col-md-2 col-form-label">Amount</label>
                                                    <Field
                                                        type="number"
                                                        name="amount"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="amount" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="check_Picture" className="col-md-2 col-form-label">Check Picture</label>
                                                    <input
                                                        type="file"
                                                        name="check_Picture"
                                                        className="form-control"
                                                        onChange={handleFileChange}
                                                    />
                                                    <div className="FormerPicture">
                                                        <img src={initialValues.check_Picture} alt="Check" style={{ width: '100px', height: '100px' }} />
                                                    </div>
                                                    <ErrorMessage name="check_Picture" component="div" className="text-danger" />
                                                </div>
                                                <div className="text-center mt-4">
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Update</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CheckPortfolioUpdatePage;



{/*
const CheckPortfolioUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    
    const authUser = getLoggedinUser();
    const companyId = authUser.CompanyId;
    
    const [selectedMovementType, setSelectedMovementType] = useState('');
    const [debtor, setDebtor] = useState('');
    const [currentAccountName, setCurrentAccountName] = useState('');
    const [companyName, setCompanyName] = useState(""); 
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [current_ID, setCurrent_ID] = useState("");
    const [selectBank, setSelectBank] = useState(null);
    const [bank, setBank] = useState([]);
    const [bank_ID, setBank_ID] = useState("");
    const [check_Picture, setCheck_Picture] = useState("");
    const [initialValues, setInitialValues] = useState({
        id: "",
        movement_Type: "",
        current_ID: "",
        debtor: "",
        registration_Date: "",
        explanation: "",
        portfolio_No: "",
        serial_Number: "",
        expiry_Date: "",
        bank_Id: "",
        bank_Branch: "",
        amount: "",
        check_Picture: "",
        updatedUserId: authUser.Id,
        status: true,
    });

    useEffect(() => {
        api.get("/Company/GetCompanyById?id=" + companyId)
            .then(res => {
                setCompanyName(res.companyName);
            })
            .catch(error => {
                console.error("Error fetching company data:", error);
            });
    }, [companyId]);

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCurrentAccountName(selectedOption.label);
        setCurrent_ID(selectedOption ? selectedOption.value : '');
        const debtorValue = selectedMovementType === "Alınan Çek" ? selectedOption.label : companyName;
        setDebtor(debtorValue);
    };

    const handleBank = (selectedOption) => {
        setSelectBank(selectedOption);
        setBank_ID(selectedOption ? selectedOption.value : '');
    };

    useEffect(() => {
        api.get('/CheckPortfolio/GetCheckPortfolioById?id=' + _id)
            .then(res => {
                setInitialValues({
                    id: res.id,
                    movement_Type: res.movement_Type,
                    current_ID: res.current_ID,
                    debtor: res.movement_Type === "Alınan Çek" ? currentAccountName : companyName,
                    registration_Date: res.registration_Date.split('T')[0],
                    explanation: res.explanation,
                    portfolio_No: res.portfolio_No,
                    serial_Number: res.serial_Number,
                    expiry_Date: res.expiry_Date.split('T')[0],
                    bank_Id: res.bank_Id,
                    bank_Branch: res.bank_Branch,
                    amount: res.amount,
                    check_Picture: res.check_Picture,
                    companyId: res.companyId
                });
                setSelectCurrentAccount({ label: res.current_Name, value: res.current_ID });
                setSelectBank({ label: res.bank_Name, value: res.bank_ID });
                setSelectedMovementType(res.movement_Type);
                setCurrentAccountName(res.current_Name); 
            })
            .catch(err => console.log(err));
    }, [_id]);

    useEffect(() => {
        api.get("/CurrentAccount/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id.toString()
            }));
            setCurrentAccount(formattedOptions);
        });      
        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id.toString()
            }));
            setBank(formattedOptions);
        });      
    }, [companyId]);

    useEffect(() => {
        updateDebtorValue(selectedMovementType);
    }, [selectedMovementType]);

    const updateDebtorValue = (selectedMovementType) => {
        const debtorValue = selectedMovementType === "Alınan Çek" ? currentAccountName : companyName; 
        setDebtor(debtorValue);
    };

    document.title = "Çek Portföy Güncelle | İlekaSoft";

    const validationSchema = Yup.object({
        movement_Type: Yup.string().required('This field is required'),
        current_ID: Yup.string().required('This field is required'),
        registration_Date: Yup.string().required('This field is required'),
        explanation: Yup.string().required('This field is required'),  
        portfolio_No: Yup.string().required('This field is required'),
        serial_Number: Yup.string().required('This field is required'),
        expiry_Date: Yup.string().required('This field is required'),
        bank_Id: Yup.string().required('This field is required'),
        bank_Branch: Yup.string().required('This field is required'),
        amount: Yup.number().required('This field is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const updatedValues = {
                ...values,
                current_ID: selectCurrentAccount?.value || values.current_ID,
                bank_Id: selectBank?.value || values.bank_Id,
                debtor: debtor, // Ensure the updated debtor value is included
            };

            const res = await api.create("/CheckPortfolio/UpdateCheckPortfolio", updatedValues);
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000
            });
            window.location.href = `/ÇekPortfoyler`;
        } catch (err) {
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
            setSubmitting(false);
        }
    };

    const handleFileChange = (e) => {
        const element = document.querySelector('.FormerPicture');
        if (element) {
            element.style.display = 'none';
        }
    
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setInitialValues(prevState => ({
                    ...prevState,
                    check_Picture: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Çek Portföy Güncelle" breadcrumbItem="Çek Portföyler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting, setFieldValue }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <label htmlFor="movement_Type" className="col-md-2 col-form-label">Movement Type</label>
                                                    <select
                                                        name="movement_Type"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setFieldValue("movement_Type", e.target.value);
                                                            setSelectedMovementType(e.target.value);
                                                        }}
                                                        value={selectedMovementType}
                                                    >
                                                        <option value="Alınan Çek">Alınan Çek</option>
                                                        <option value="Verilen Çek">Verilen Çek</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="current_ID" className="form-label">Current Account</label>
                                                    <Select
                                                        value={selectCurrentAccount}
                                                        onChange={handleCurrentAccount}
                                                        options={currentAccount}
                                                        placeholder="Select Current Account"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="debtor" className="col-md-2 col-form-label">Debtor</label>
                                                    <input
                                                        type="text"
                                                        name="debtor"
                                                        className="form-control"
                                                        value={debtor}
                                                        readOnly
                                                    />
                                                    <ErrorMessage name="debtor" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="registration_Date" className="col-md-2 col-form-label">Registration Date</label>
                                                    <Field
                                                        type="date"
                                                        name="registration_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="registration_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="explanation" className="col-md-2 col-form-label">Explanation</label>
                                                    <Field
                                                        type="text"
                                                        name="explanation"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="explanation" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="portfolio_No" className="col-md-2 col-form-label">Portfolio No</label>
                                                    <Field
                                                        type="text"
                                                        name="portfolio_No"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="portfolio_No" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="serial_Number" className="col-md-2 col-form-label">Serial Number</label>
                                                    <Field
                                                        type="text"
                                                        name="serial_Number"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="serial_Number" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="expiry_Date" className="col-md-2 col-form-label">Expiry Date</label>
                                                    <Field
                                                        type="date"
                                                        name="expiry_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="expiry_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="bank_Id" className="form-label">Bank</label>
                                                    <Select
                                                        value={selectBank}
                                                        onChange={handleBank}
                                                        options={bank}
                                                        placeholder="Select Bank"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="bank_Branch" className="col-md-2 col-form-label">Bank Branch</label>
                                                    <Field
                                                        type="text"
                                                        name="bank_Branch"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="bank_Branch" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="amount" className="col-md-2 col-form-label">Amount</label>
                                                    <Field
                                                        type="number"
                                                        name="amount"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="amount" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="check_Picture" className="col-md-2 col-form-label">Check Picture</label>
                                                    <input
                                                        type="file"
                                                        name="check_Picture"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            handleFileChange(e);
                                                            setFieldValue("check_Picture", e.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    {initialValues.check_Picture && (
                                                        <div className="FormerPicture">
                                                            <img src={initialValues.check_Picture} alt="Check" width="100" />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CheckPortfolioUpdatePage;
*/}






{/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CheckPortfolioUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    
    const authUser = getLoggedinUser();
    const companyId = authUser.CompanyId;
    
    const [selectedMovementType, setSelectedMovementType] = useState('');
    const [debtor, setDebtor] = useState('');
    const [currentAccountName, setCurrentAccountName] = useState('');
    const [companyName, setCompanyName] = useState(""); 
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [current_ID, setCurrent_ID] = useState("");
    const [selectBank, setSelectBank] = useState(null);
    const [bank, setBank] = useState([]);
    const [bank_ID, setBank_ID] = useState("");
    const [check_Picture, setCheck_Picture] = useState("");
    const [initialValues, setInitialValues] = useState({
        id: "",
        movement_Type: "",
        current_ID: "",
        debtor: "",
        registration_Date: "",
        explanation: "",
        portfolio_No: "",
        serial_Number: "",
        expiry_Date: "",
        bank_Id: "",
        bank_Branch: "",
        amount: "",
        check_Picture: "",
        updatedUserId: authUser.Id,
        status: true,
    });

    useEffect(() => {
        api.get("/Company/GetCompanyById?id=" + companyId)
            .then(res => {
                setCompanyName(res.companyName);
            })
            .catch(error => {
                console.error("Error fetching company data:", error);
            });
    }, [companyId]);

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCurrentAccountName(selectedOption.label);
        setCurrent_ID(selectedOption ? selectedOption.value : '');
        const debtorValue = selectedMovementType === "Alınan Çek" ? selectedOption.label : companyName;
        setDebtor(debtorValue);
    };

    const handleBank = (selectedOption) => {
        setSelectBank(selectedOption);
        setBank_ID(selectedOption ? selectedOption.value : '');
    };

    useEffect(() => {
        api.get('/CheckPortfolio/GetCheckPortfolioById?id=' + _id)
            .then(res => {
                setInitialValues({
                    id: res.id,
                    movement_Type: res.movement_Type,
                    current_ID: res.current_ID,
                    debtor: res.movement_Type === "Alınan Çek" ? currentAccountName : companyName,
                    registration_Date: res.registration_Date.split('T')[0],
                    explanation: res.explanation,
                    portfolio_No: res.portfolio_No,
                    serial_Number: res.serial_Number,
                    expiry_Date: res.expiry_Date.split('T')[0],
                    bank_Id: res.bank_Id,
                    bank_Branch: res.bank_Branch,
                    amount: res.amount,
                    check_Picture: res.check_Picture,
                    companyId: res.companyId
                });
                setSelectCurrentAccount({ label: res.current_Name, value: res.current_ID });
                setSelectBank({ label: res.bank_Name, value: res.bank_ID });
                setSelectedMovementType(res.movement_Type);
                setCurrentAccountName(res.current_Name); 
            })
            .catch(err => console.log(err));
    }, [_id]);

    useEffect(() => {
        api.get("/CurrentAccount/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id.toString()
            }));
            setCurrentAccount(formattedOptions);
        });      
        api.get("/Bank/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id.toString()
            }));
            setBank(formattedOptions);
        });      
    }, [companyId]);

    useEffect(() => {
        updateDebtorValue(selectedMovementType);
    }, [selectedMovementType]);

    const updateDebtorValue = (selectedMovementType) => {
        const debtorValue = selectedMovementType === "Alınan Çek" ? currentAccountName : companyName; 
        setDebtor(debtorValue);
    };

    document.title = "Çek Portföy Güncelle | İlekaSoft";

    const validationSchema = Yup.object({
        movement_Type: Yup.string().required('This field is required'),
        current_ID: Yup.string().required('This field is required'),
      //  debtor: Yup.string().required('This field is required'),
        registration_Date: Yup.string().required('This field is required'),
        explanation: Yup.string().required('This field is required'),  
        portfolio_No: Yup.string().required('This field is required'),
        serial_Number: Yup.string().required('This field is required'),
        expiry_Date: Yup.string().required('This field is required'),
        bank_Id: Yup.string().required('This field is required'),
        bank_Branch: Yup.string().required('This field is required'),
        amount: Yup.number().required('This field is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const updatedValues = {
                ...values,
                current_ID: selectCurrentAccount.value,
                bank_ID: selectBank.value
            };

            const res = await api.create("/CheckPortfolio/UpdateCheckPortfolio", updatedValues);
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000
            });
            window.location.href = `/ÇekPortfoyler`;
        } catch (err) {
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
            setSubmitting(false);
        }
    };

    const handleFileChange = (e) => {
        const element = document.querySelector('.FormerPicture');
        if (element) {
            element.style.display = 'none';
        }
    
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setInitialValues(prevState => ({
                    ...prevState,
                    check_Picture: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Çek Portföy Güncelle" breadcrumbItem="Çek Portföyler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <label htmlFor="movement_Type" className="col-md-2 col-form-label">Movement Type</label>
                                                    <select
                                                        name="movement_Type"
                                                        className="form-control"
                                                        onChange={(e) => setSelectedMovementType(e.target.value)}
                                                        value={selectedMovementType}
                                                    >
                                                        <option value="Alınan Çek">Alınan Çek</option>
                                                        <option value="Verilen Çek">Verilen Çek</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="current_ID" className="form-label">Current Account</label>
                                                    <Select
                                                        value={selectCurrentAccount}
                                                        onChange={handleCurrentAccount}
                                                        options={currentAccount}
                                                        placeholder="Select Current Account"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="debtor" className="col-md-2 col-form-label">Debtor</label>
                                                    <input
                                                        type="text"
                                                        name="debtor"
                                                        className="form-control"
                                                        value={debtor}
                                                        readOnly
                                                    />
                                                    <ErrorMessage name="debtor" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="registration_Date" className="col-md-2 col-form-label">Registration Date</label>
                                                    <Field
                                                        type="date"
                                                        name="registration_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="registration_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="explanation" className="col-md-2 col-form-label">Explanation</label>
                                                    <Field
                                                        type="text"
                                                        name="explanation"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="explanation" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="portfolio_No" className="col-md-2 col-form-label">Portfolio No</label>
                                                    <Field
                                                        type="text"
                                                        name="portfolio_No"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="portfolio_No" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="serial_Number" className="col-md-2 col-form-label">Serial Number</label>
                                                    <Field
                                                        type="text"
                                                        name="serial_Number"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="serial_Number" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="expiry_Date" className="col-md-2 col-form-label">Expiry Date</label>
                                                    <Field
                                                        type="date"
                                                        name="expiry_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="expiry_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="bank_Id" className="col-md-2 col-form-label">Bank</label>
                                                    <Select
                                                        value={selectBank}
                                                        onChange={handleBank}
                                                        options={bank}
                                                        placeholder="Select Bank"
                                                    />
                                                    <ErrorMessage name="bank_Id" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="bank_Branch" className="col-md-2 col-form-label">Bank Branch</label>
                                                    <Field
                                                        type="text"
                                                        name="bank_Branch"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="bank_Branch" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="amount" className="col-md-2 col-form-label">Amount</label>
                                                    <Field
                                                        type="number"
                                                        name="amount"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="amount" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="check_Picture" className="col-md-2 col-form-label">Check Picture</label>
                                                    <input
                                                        type="file"
                                                        name="check_Picture"
                                                        className="form-control"
                                                        onChange={handleFileChange}
                                                    />
                                                    <div className="FormerPicture">
                                                        <img src={initialValues.check_Picture} alt="Check" style={{ width: '100px', height: '100px' }} />
                                                    </div>
                                                    <ErrorMessage name="check_Picture" component="div" className="text-danger" />
                                                </div>
                                                <div className="text-center mt-4">
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Update</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CheckPortfolioUpdatePage;

{/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CheckPortfolioUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    
    const authUser = getLoggedinUser();

    const companyId = authUser.CompanyId;

      
    const [selectedMovementType, setSelectedMovementType] = useState('');
    const [debtor, setDebtor] = useState('');

   {/*
     const handleMovementTypeChange = (e) => {
        setSelectedMovementType(e.target.value);
        updateDebtorValue(selectedMovementType);
    };

const handleMovementTypeChange = (e) => {
    const newMovementType = e.target.value;
    setSelectedMovementType(newMovementType);
    const debtorValue = newMovementType === "Alınan Çek" ? currentAccountName : companyName;
    setDebtor(debtorValue);
    alert(debtor);
};*/}

{/*
const [currentAccountName, setCurrentAccountName] = useState('');
const [companyName, setCompanyName] = useState(""); 

useEffect(() => {
    api.get("/Company/GetCompanyById?id=" + companyId)
        .then(res => {
            const companyNameFromApi = res.companyName; // JSON verisinden şirket adını alın
            setCompanyName(companyNameFromApi); // Şirket adını state'e ayarlayın
           // alert(companyName);
        })
        .catch(error => {
            console.error("Error fetching company data:", error);
        });
}, [companyId]);

    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [current_ID, setCurrent_ID] = useState("");

    const [selectBank, setSelectBank] = useState(null);
    const [bank, setBank] = useState([]);
    const [bank_ID, setBank_ID] = useState("");

    const [check_Picture, setCheck_Picture] = useState("");
                   

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCurrentAccountName(selectCurrentAccount.label)                                             
        setCurrent_ID(selectedOption ? selectedOption.value : '');  

        const debtorValue = selectedMovementType === "Alınan Çek" ? currentAccountName : companyName; 
        setDebtor(debtorValue);
        alert(debtorValue);
    };

    const handleBank = (selectedOption) => {
        setSelectBank(selectedOption);
        setBank_ID(selectedOption ? selectedOption.value : '');
    };

    const updatedUserId = authUser.Id;

    const [initialValues, setInitialValues] = useState({
        id: "",
        movement_Type: "",
        current_ID: "",
        debtor: "",
        registration_Date: "",
        explanation: "",
        portfolio_No: "",
        serial_Number: "",
        expiry_Date: "",
        bank_Id: "",
        bank_Branch: "",
        amount: "",
        check_Picture: "",
        updatedUserId: updatedUserId,
        status: true,


    });

    {/*useEffect(() => {
        api.get('/CheckPortfolio/GetCheckPortfolioById?id=' + _id)
            .then(res => {
                setInitialValues({
                    id: res.id,
                    movement_Type: res.movement_Type,
                    current_ID: res.current_ID,
                    debtor: res.debtor,
                    registration_Date: res.registration_Date.split('T')[0],
                    explanation: res.explanation,
                    portfolio_No: res.portfolio_No,
                    serial_Number: res.serial_Number,
                    expiry_Date: res.expiry_Date.split('T')[0],
                    bank_Id: res.bank_Id,
                    bank_Branch: res.bank_Branch,
                    amount: res.amount,
                    check_Picture: res.check_Picture,
                    companyId:res.companyId

                });
                setSelectCurrentAccount({ label: res.current_Name, value: res.current_ID });
                setSelectBank({ label: res.bank_Name, value: res.bank_ID });
                setMovement_TypeSelection(res.movement_Type);
                alert(movement_TypeSelection);

            })
            .catch(err => console.log(err));
    }, [_id]);
    */}

    {/*
    useEffect(() => {
        api.get('/CheckPortfolio/GetCheckPortfolioById?id=' + _id)
            .then(res => {
                setInitialValues({
                    id: res.id,
                    movement_Type: res.movement_Type,
                    current_ID: res.current_ID,
                    debtor: res.movement_Type === "alınan çek" ? currentAccountName : companyName,

                    registration_Date: res.registration_Date.split('T')[0],
                    explanation: res.explanation,
                    portfolio_No: res.portfolio_No,
                    serial_Number: res.serial_Number,
                    expiry_Date: res.expiry_Date.split('T')[0],
                    bank_Id: res.bank_Id,
                    bank_Branch: res.bank_Branch,
                    amount: res.amount,
                    check_Picture: res.check_Picture,
                    companyId: res.companyId
                });
                setSelectCurrentAccount({ label: res.current_Name, value: res.current_ID });
                setSelectBank({ label: res.bank_Name, value: res.bank_ID });
                setSelectedMovementType(res.movement_Type);
                setCurrentAccountName(res.current_Name); 
                //alert(currentAccountName);
            })
            .catch(err => console.log(err));
    }, [_id, setInitialValues, setSelectCurrentAccount, setSelectBank, setSelectedMovementType]);
    
    useEffect(() => {
        if (selectedMovementType !== null) {
            //alert(selectedMovementType);
        }
    }, [selectedMovementType]);
    
    
    useEffect(() => {
        var companyId = authUser.CompanyId;
        api.get("/CurrentAccount/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id.toString()
            }));
            setCurrentAccount(formattedOptions);
        });      
        api.get("/Bank/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id.toString()
            }));
            setBank(formattedOptions);
        });      
       


    }, []);

    document.title = "Çek Portföy Güncelle | İlekaSoft";

    const validationSchema = Yup.object({
        movement_Type: Yup.string().required('This field is required'),
        current_ID: Yup.string().required('This field is required'),
        debtor: Yup.string().required('This field is required'),
        registration_Date: Yup.string().required('This field is required'),
        explanation: Yup.string().required('This field is required'),  
        portfolio_No: Yup.string().required('This field is required'),
        serial_Number: Yup.string().required('This field is required'),
        expiry_Date: Yup.string().required('This field is required'),
        bank_Id: Yup.string().required('This field is required'),
        bank_Branch: Yup.string().required('This field is required'),
        amount: Yup.number().required('This field is required'),
        //check_Picture: Yup.string().required('This field is required'),

    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const updatedValues = {
                ...values,
                current_ID: selectCurrentAccount.value,
                bank_ID: selectBank.value
            };

            const res = await api.create("/CheckPortfolio/UpdateCheckPortfolio", updatedValues);
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000
            });
            window.location.href = `/ÇekPortfoyler`;
        } catch (err) {
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
            setSubmitting(false);
        }
    };



    {/*const handleFileChange = (e) => {
        const element = document.querySelector('.FormerPicture');
        if (element) {
            element.style.display = 'none';
        }

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCheck_Picture(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };*/}


    {/*
    const handleFileChange = (e) => {
        const element = document.querySelector('.FormerPicture');
        if (element) {
            element.style.display = 'none';
        }
    
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setInitialValues(prevState => ({
                    ...prevState,
                    check_Picture: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };


    useEffect(() => {
        updateDebtorValue(selectedMovementType);
    }, [selectedMovementType]);

    const updateDebtorValue = (selectedMovementType) => {
      //  const debtorValue = selectedMovementType === "Alınan Çek" ? "Alınan Borçlu" : "Verilen Borçlu";
      const debtorValue = selectedMovementType === "Alınan Çek" ? currentAccountName : companyName; 
      setDebtor(debtorValue);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Çek Portföy Güncelle" breadcrumbItem="Çek Portföyler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div className="form-group">
                <label htmlFor="movement_Type" className="col-md-2 col-form-label">movement_Type</label>
                <select
                    name="movement_Type"
                    className="form-control"
                    onChange={(e) => setSelectedMovementType(e.target.value)}
                    value={selectedMovementType}
                >
                    <option value="Alınan Çek">Alınan Çek</option>
                    <option value="Verilen Çek">Verilen Çek</option>
                </select>
            </div>
                                                <div className="mb-3">
                                                    <label htmlFor="current_ID" className="form-label">current_ID</label>
                                                    <Select
                                                        value={selectCurrentAccount}
                                                        onChange={handleCurrentAccount}
                                                        options={currentAccount}
                                                        placeholder="current_ID Seç"
                                                    />
                                                </div>
                                               {/* <div className="form-group">
                                                    <label htmlFor="debtor" className="col-md-2 col-form-label">debtor</label>
                                                    <Field
                                                        type="text"
                                                        name="debtor"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="debtor" component="div" className="text-danger" />
                                                </div> */}



                                                {/*
                                               <div className="form-group">
                                               <label htmlFor="debtor" className="col-md-2 col-form-label">debtor</label>
                <input
                    type="text"
                    name="debtor"
                    className="form-control"
                    value={debtor}
                    readOnly
                />
    <ErrorMessage name="debtor" component="div" className="text-danger" />
</div>
 
                                                <div className="form-group">
                                                    <label htmlFor="registration_Date" className="col-md-2 col-form-label">registration_Date</label>
                                                    <Field
                                                        type="date"
                                                        name="registration_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="registration_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="explanation" className="col-md-2 col-form-label">explanation</label>
                                                    <Field
                                                        type="text"
                                                        name="explanation"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="explanation" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="portfolio_No" className="col-md-2 col-form-label">portfolio_No</label>
                                                    <Field
                                                        type="text"
                                                        name="portfolio_No"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="portfolio_No" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="serial_Number" className="col-md-2 col-form-label">serial_Number</label>
                                                    <Field
                                                        type="text"
                                                        name="serial_Number"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="serial_Number" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="expiry_Date" className="col-md-2 col-form-label">expiry_Date</label>
                                                    <Field
                                                        type="date"
                                                        name="expiry_Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="expiry_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="bank_Id" className="form-label">bank_Id</label>
                                                    <Select
                                                        value={selectBank}
                                                        onChange={handleBank}
                                                        options={bank}
                                                        placeholder="bank_Id Seç"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="bank_Branch" className="col-md-2 col-form-label">bank_Branch</label>
                                                    <Field
                                                        type="text"
                                                        name="bank_Branch"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="bank_Branch" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="amount" className="col-md-2 col-form-label">Tutar</label>
                                                    <Field
                                                        type="number"
                                                        name="amount"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="amount" component="div" className="text-danger" />
                                                </div>
                                                {/*<div className="form-group">
                                                    <label htmlFor="check_Picture" className="col-md-2 col-form-label">check_Picture</label>
                                                    <Field
                                                        type="text"
                                                        name="check_Picture"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="check_Picture" component="div" className="text-danger" />
                                                </div>*/}


                                                {/*
                                                                       <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
  <label htmlFor="formFile" className="form-label">Çek Resmi</label>
  <input className="form-control" type="file" id="formFile" onChange={handleFileChange} />
  <img className="FormerPicture" src={`data:image/jpeg;base64, ${check_Picture}`} alt="Check Picture" style={{ maxWidth: "100px" }} />
</div>


                                                <button
                                                    type="submit"
                                                    className="btn btn-primary waves-effect waves-light"
                                                    disabled={isSubmitting}
                                                >
                                                    Güncelle
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default CheckPortfolioUpdatePage;


















{/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

const CheckPortfolioUpdatePage = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const [id, setId] = useState("");
    const [movement_Type, setMovement_Type] = useState("");
    const [current_ID, setCurrent_ID] = useState("");
    const [registration_Date, setRegistration_Date] = useState("");
    const [explanation, setExplanation] = useState("");
    const [portfolio_No, setPortfolio_No] = useState("");
    const [serial_Number, setSerial_Number] = useState("");
    const [debtor, setDebtor] = useState("");
    const [expiry_Date, setExpiry_Date] = useState("");
    const [bank_Name, setBank_Name] = useState("");
    const [bank_Branch, setBank_Branch] = useState("");
    const [check_Picture, setCheck_Picture] = useState("");
    const [amount, setAmount] = useState("");

    const [movementTypeInfo, setMovementTypeInfo] = useState([]);
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [selectBanklist, setSelectBanklist] = useState(null);
    const [banklist, setBanklist] = useState([]);
    const [updatedUserId, setUpdatedUserId] = useState(authUser.Id);


    useEffect(() => {
        const movementTypeData = [
            { id: 1, movementTypeName: 'İlk Tür' },
            { id: 2, movementTypeName: 'İkinci Tür' },
            { id: 3, movementTypeName: 'Üçüncü Tür' }
        ];
        setMovementTypeInfo(movementTypeData);
        var companyId = authUser.CompanyId;
        api.get("/CurrentAccount/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id.toString()
            }));
            setCurrentAccount(formattedOptions);
        });
        api.get("/Banklist/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.bank_Name
            }));
            setBanklist(formattedOptions);
        });

        // Fetch the check movement data
        api.get('/CheckPortfolio/GetCheckPortfolioById?id=' + _id)
            .then(res => {
                setId(res.id);
                setMovement_Type(res.movement_Type);
                setCurrent_ID(res.current_ID);
                setRegistration_Date(res.registration_Date.split('T')[0]); // Tarihi yyyy-mm-dd formatına getir
                setExplanation(res.explanation);
                setPortfolio_No(res.portfolio_No);
                setSerial_Number(res.serial_Number);
                setDebtor(res.debtor);
                setExpiry_Date(res.expiry_Date.split('T')[0]); // Tarihi yyyy-mm-dd formatına getir
                setBank_Name(res.bank_Name);
                setBank_Branch(res.bank_Branch);
                setCheck_Picture(res.check_Picture);
                setAmount(res.amount);

                // Set selected options for current account and bank list
                setSelectCurrentAccount({ label: res.current_Name, value: res.current_ID });
                setSelectBanklist({ label: res.bank_Name, value: res.bank_Name });
            })
            .catch(err => console.log(err));
    }, []);

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCurrent_ID(selectedOption ? selectedOption.value : '');
    };

    const handleBanklist = (selectedOption) => {
        setSelectBanklist(selectedOption);
        setBank_Name(selectedOption ? selectedOption.value : '');
    };

    document.title = "Çek Portföy Guncelle | İlekaSoft";

    const handleSubmit = async () => {
        try {
            const response = await axios.post('https://localhost:7199/api/CheckPortfolio/UpdateCheckPortfolio', {
                id: id,
                movement_Type: movement_Type,
                current_ID: current_ID,
                registration_Date: registration_Date,
                explanation: explanation,
                portfolio_No: portfolio_No,
                serial_Number: serial_Number,
                debtor: debtor,
                expiry_Date: expiry_Date,
                bank_Name: bank_Name,
                bank_Branch: bank_Branch,
                check_Picture: check_Picture,
                amount: amount,
                updatedUserId: updatedUserId,
                //status:true


            });
            console.log(response.data);
           // window.location.href = `/ÇekPortföyler`;
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000 
            });
       // window.location.href = `/ÇekHareketler`;
        navigate('/ÇekPortföyler')
        } catch (error) {
            console.error('Error updating check movement:', error);
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    }
    };

    const handleChangeRegistrationDate = (event) => {
        const { value } = event.target;
        setRegistration_Date(value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case "Movement_Type":
                setMovement_Type(value);
                break;
            case "Current_ID":
                setCurrent_ID(value);
                break;
            case "Registration_Date":
                setRegistration_Date(value);
                break;
            case "Explanation":
                setExplanation(value);
                break;
            case "Portfolio_No":
                setPortfolio_No(value);
                break;
            case "Serial_Number":
                setSerial_Number(value);
                break;
            case "Debtor":
                setDebtor(value);
                break;
            case "Expiry_Date":
                setExpiry_Date(value);
                break;
            case "Bank_Branch":
                setBank_Branch(value);
                break;
            case "Check_Picture":
                setCheck_Picture(value);
                break;
            case "Amount":
                setAmount(value);
                break;
            default:
                break;
        }
    };

    const handleFileChange = (e) => {
        const element = document.querySelector('.FormerPicture');
        if (element) {
            element.style.display = 'none';
        }

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCheck_Picture(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Çek Portföy Güncelle" breadcrumbItem="Çek Portföyler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <label htmlFor="Movement_Type" className="form-label">Hareket Tipi</label>
                                        <select className="form-control" name="Movement_Type" value={movement_Type} onChange={handleChange} required>
                                            <option value="">Lütfen MovementType birimini seçiniz</option>
                                            {movementTypeInfo.map((item) => (
                                                <option value={item.id} key={item.id}>{item.movementTypeName}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="Current_ID" className="form-label">Cari Adı</label>
                                        <Select
                                            value={selectCurrentAccount}
                                            onChange={handleCurrentAccount}
                                            options={currentAccount}
                                            placeholder="CurrentAccount Seç"
                                        />
                                    </div>
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Kayıt Tarihi</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="Registration_Date"
                                        value={registration_Date}
                                        onChange={handleChangeRegistrationDate}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Açıklama</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Explanation"
                                        value={explanation}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Portföy No</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Portfolio_No"
                                        value={portfolio_No}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Seri No</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Serial_Number"
                                        value={serial_Number}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Borçlu</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Debtor"
                                        value={debtor}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Vade Tarihi</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="Expiry_Date"
                                        value={expiry_Date}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Banka Adı</label>
                                    <Select
                                        value={selectBanklist}
                                        onChange={handleBanklist}
                                        options={banklist}
                                        placeholder="Banklist Seç"
                                    />
                                                                     <label htmlFor="example-text-input" className="col-md-2 col-form-label">Banka Şubesi</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Bank_Branch"
                                        value={bank_Branch}
                                        onChange={handleChange}
                                    />
                       
                       <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
  <label htmlFor="formFile" className="form-label">Çek Resmi</label>
  <input className="form-control" type="file" id="formFile" onChange={handleFileChange} />
  <img className="FormerPicture" src={`data:image/jpeg;base64, ${check_Picture}`} alt="Check Picture" style={{ maxWidth: "100px" }} />
</div>

                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Tutar</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="Amount"
                                        value={amount}
                                        onChange={handleChange}
                                    />


                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Güncelle</button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CheckPortfolioUpdatePage;


{/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';

const CheckPortfolioUpdatePage = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const [movementTypeInfo, setMovementTypeInfo] = useState([]);
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [selectBanklist, setSelectBanklist] = useState(null);
    const [banklist, setBanklist] = useState([]);
    const [updatedUserId, setUpdatedUserId] = useState(authUser.Id);

    useEffect(() => {
        const movementTypeData = [
            { id: 1, movementTypeName: 'İlk Tür' },
            { id: 2, movementTypeName: 'İkinci Tür' },
            { id: 3, movementTypeName: 'Üçüncü Tür' }
        ];
        setMovementTypeInfo(movementTypeData);
        var companyId = authUser.CompanyId;
        api.get("/CurrentAccount/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id.toString()
            }));
            setCurrentAccount(formattedOptions);
        });
        api.get("/Banklist/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.bank_Name
            }));
            setBanklist(formattedOptions);
        });

        // Fetch the check movement data
        api.get('/CheckPortfolio/GetCheckPortfolioById?id=' + _id)
            .then(res => {
                setSelectCurrentAccount({ label: res.current_Name, value: res.current_ID });
                setSelectBanklist({ label: res.bank_Name, value: res.bank_Name });
            })
            .catch(err => console.log(err));
    }, []);

    const validationSchema = Yup.object().shape({
        movement_Type: Yup.string().required('Hareket Tipi gereklidir'),
        current_ID: Yup.string().required('Cari Adı gereklidir'),
        registration_Date: Yup.date().required('Kayıt Tarihi gereklidir'),
        explanation: Yup.string().required('Açıklama gereklidir'),
        portfolio_No: Yup.string().required('Portföy No gereklidir'),
        serial_Number: Yup.string().required('Seri No gereklidir'),
        debtor: Yup.string().required('Borçlu gereklidir'),
        expiry_Date: Yup.date().required('Vade Tarihi gereklidir'),
        bank_Name: Yup.string().required('Banka Adı gereklidir'),
        bank_Branch: Yup.string().required('Banka Şubesi gereklidir'),
        amount: Yup.number().required('Tutar gereklidir')
    });

    const handleSubmit = async (values) => {
        try {
            const response = await axios.post('https://localhost:7199/api/CheckPortfolio/UpdateCheckPortfolio', {
                ...values,
                id: _id,
                updatedUserId: updatedUserId,
            });
            console.log(response.data);
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000 
            });
            navigate('/çekPortfoyler');
        } catch (error) {
            console.error('Error updating check movement:', error);
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });
        }
    };

    const CheckPictureField = () => {
        const { setFieldValue, values } = useFormikContext();
        return (
            <div className="mb-3" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <label htmlFor="check_Picture" className="form-label">Çek Resmi</label>
                <input
                    type="file"
                    className="form-control"
                    id="check_Picture"
                    name="check_Picture"
                    onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        setFieldValue("check_Picture", file);
                    }}
                />
                {values.check_Picture && (
                    <img src={URL.createObjectURL(values.check_Picture)} alt="Check Picture" style={{ maxWidth: "100px" }} />
                )}
                <ErrorMessage name="check_Picture" component="div" className="text-danger" />
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Çek Portföy Güncelle" breadcrumbItem="Çek Portföyler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={{
                                            movement_Type: '',
                                            current_ID: '',
                                            registration_Date: '',
                                            explanation: '',
                                            portfolio_No: '',
                                            serial_Number: '',
                                            debtor: '',
                                            expiry_Date: '',
                                            bank_Name: '',
                                            bank_Branch: '',
                                            amount: ''
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ errors, touched }) => (
                                            <Form>
                                                <div className="mb-3">
                                                    <label htmlFor="movement_Type" className="form-label">Hareket Tipi</label>
                                                    <Field as="select" className="form-control" name="movement_Type">
                                                        <option value="">Lütfen MovementType birimini seçiniz</option>
                                                        {movementTypeInfo.map((item) => (
                                                            <option value={item.id} key={item.id}>{item.movementTypeName}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage name="movement_Type" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="current_ID" className="form-label">Cari Adı</label>
                                                    <Select
                                                        value={selectCurrentAccount}
                                                        onChange={(option) => setSelectCurrentAccount(option)}
                                                        options={currentAccount}
                                                        placeholder="CurrentAccount Seç"
                                                    />
                                                    {errors.current_ID && touched.current_ID ? (
                                                        <div className="text-danger">{errors.current_ID}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="registration_Date" className="form-label">Kayıt Tarihi</label>
                                                    <Field
                                                        type="date"
                                                        className="form-control"
                                                        name="registration_Date"
                                                    />
                                                    <ErrorMessage name="registration_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="explanation" className="form-label">Açıklama</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="explanation"
                                                    />
                                                    <ErrorMessage name="explanation" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="portfolio_No" className="form-label">Portföy No</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="portfolio_No"
                                                    />
                                                    <ErrorMessage name="portfolio_No" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="serial_Number" className="form-label">Seri No</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="serial_Number"
                                                    />
                                                    <ErrorMessage name="serial_Number" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="debtor" className="form-label">Borçlu</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="debtor"
                                                    />
                                                    <ErrorMessage name="debtor" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="expiry_Date" className="form-label">Vade Tarihi</label>
                                                    <Field
                                                        type="date"
                                                        className="form-control"
                                                        name="expiry_Date"
                                                    />
                                                    <ErrorMessage name="expiry_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="bank_Name" className="form-label">Banka Adı</label>
                                                    <Select
                                                        value={selectBanklist}
                                                        onChange={(option) => setSelectBanklist(option)}
                                                        options={banklist}
                                                        placeholder="Banka Adı Seç"
                                                    />
                                                    {errors.bank_Name && touched.bank_Name ? (
                                                        <div className="text-danger">{errors.bank_Name}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="bank_Branch" className="form-label">Banka Şubesi</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="bank_Branch"
                                                    />
                                                    <ErrorMessage name="bank_Branch" component="div" className="text-danger" />
                                                </div>
                                                <CheckPictureField />
                                                <div className="mb-3">
                                                    <label htmlFor="amount" className="form-label">Tutar</label>
                                                    <Field
                                                        type="number"
                                                        className="form-control"
                                                        name="amount"
                                                    />
                                                    <ErrorMessage name="amount" component="div" className="text-danger" />
                                                </div>
                                                <button type="submit" className="btn btn-primary">
                                                    Güncelle
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CheckPortfolioUpdatePage;*/}

