
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

const CostTypeAdd = () => {
    const api =new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    const initialValues = {
        Main_Cost_Type: '',
        Fixed_Costs: '',
        General_Costs: '',
        Transport_Costs: '',
        Personnel_Costs: '',
        Tax_Insurance_and_Similar_Costs: '',
        Communication_Costs: '',        
        CompanyId: authUser.CompanyId,
        CreatedUserId: authUser.Id,
        status: true
    };

    const validationSchema = Yup.object().shape({
        Main_Cost_Type: Yup.string().required('Ana Gider Türü zorunludur'),
        Fixed_Costs: Yup.number().required('Sabit Giderler zorunludur'),
        General_Costs: Yup.number().required('Genel Giderler zorunludur'),
        Transport_Costs: Yup.number().required('Ulaşım Giderleri zorunludur'),
        Personnel_Costs: Yup.number().required('Personel Giderleri zorunludur'),
        Tax_Insurance_and_Similar_Costs: Yup.number().required('Vergi, SGK vb. Giderler zorunludur'),
        Communication_Costs: Yup.number().required('İletişim Giderleri zorunludur')
    });

    const handleSubmit = (values, { setSubmitting }) => {
        api.create('/CostType/AddCostType', values)
            .then(res => {
                console.log("başarılı");
                navigate('/GiderTürler')
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="GiderTürler" breadcrumbItem="GiderTür Ekle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div className="mb-3">
                                                    <label htmlFor="Main_Cost_Type" className="form-label">Ana Gider Türü</label>
                                                    <Field as="select" className="form-control" name="Main_Cost_Type" required>
                                                        <option value="">Ana Gider Türü</option>
                                                        <option value="İlk">İlk</option>
                                                        <option value="İkinci">İkinci</option>
                                                        <option value="Üçüncü">Üçüncü</option>
                                                    </Field>
                                                    <ErrorMessage name="Main_Cost_Type" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
    <label htmlFor="Fixed_Costs" className="form-label">Sabit Giderler</label>
    <Field type="text" className="form-control" name="Fixed_Costs" />
    <ErrorMessage name="Fixed_Costs" component="div" className="text-danger" />
</div>
<div className="mb-3">
    <label htmlFor="General_Costs" className="form-label">Genel Giderler</label>
    <Field type="text" className="form-control" name="General_Costs" />
    <ErrorMessage name="General_Costs" component="div" className="text-danger" />
</div>
<div className="mb-3">
    <label htmlFor="Transport_Costs" className="form-label">Ulaşım Giderleri</label>
    <Field type="text" className="form-control" name="Transport_Costs" />
    <ErrorMessage name="Transport_Costs" component="div" className="text-danger" />
</div>
<div className="mb-3">
    <label htmlFor="Personnel_Costs" className="form-label">Personel Giderleri</label>
    <Field type="text" className="form-control" name="Personnel_Costs" />
    <ErrorMessage name="Personnel_Costs" component="div" className="text-danger" />
</div>
<div className="mb-3">
    <label htmlFor="Tax_Insurance_and_Similar_Costs" className="form-label">Vergi, SGK vb. Giderler</label>
    <Field type="text" className="form-control" name="Tax_Insurance_and_Similar_Costs" />
    <ErrorMessage name="Tax_Insurance_and_Similar_Costs" component="div" className="text-danger" />
</div>
<div className="mb-3">
    <label htmlFor="Communication_Costs" className="form-label">İletişim Giderleri</label>
    <Field type="text" className="form-control" name="Communication_Costs" />
    <ErrorMessage name="Communication_Costs" component="div" className="text-danger" />
</div>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary waves-effect waves-light"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? 'Kaydediliyor...' : 'Kaydet'}
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CostTypeAdd;


{/*import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

const CostTypeAdd = () => {
    const api =new APIClient();
    const authUser = getLoggedinUser();

    const [costType, setCostType] = useState({
        Main_Cost_Type: '',
        Fixed_Costs: '',
        General_Costs: '',
        Transport_Costs: '',
        Personnel_Costs: '',
        Tax_Insurance_and_Similar_Costs: '',
        Communication_Costs: '',        
CompanyId: authUser.CompanyId,
CreatedUserId: authUser.Id,
status:true


    });

    const [main_Cost_TypeInfo, setMain_Cost_TypeInfo] = useState([]);

    document.title = "GiderTür Ekle  | İlekaSoft";
    const navigate = useNavigate();
   
    useEffect(() => {
        // Para birimi bilgilerini ayarla
        const main_Cost_TypeData = [
            { id: 1, main_Cost_TypeName: 'İlk' },
            { id: 2, main_Cost_TypeName: 'İkinci' },
            { id: 3, main_Cost_TypeName: 'Üçüncü' }
        ];
        setMain_Cost_TypeInfo(main_Cost_TypeData);
    }, []);


 

    const handleSubmit = () => {
        api.create('/CostType/AddCostType', costType)
            .then(res => {
                console.log("başarılı");
               // window.location.href = `/GiderTürler`;
                navigate('/GiderTürler')
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }



    const handleChange = (e) => {
        const { name, value } = e.target;
        setCostType({ ...costType, [name]: value });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="GiderTürler" breadcrumbItem="GiderTür Ekle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {/* Your form inputs */}
                                    {/* Assuming you have input fields and a submit button */}
                                    {/* Example for Account Number input */}
                                   {/* <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Main_Cost_Type    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Main_Cost_Type"
                                        value={costType.Main_Cost_Type}
                                        onChange={handleChange}
    />*/}

    {/*
                                        <div>
                                        <label htmlFor="Main_Cost_Type" className="form-label">Ana Gider Türü</label>
                                        <select className="form-control" name="Main_Cost_Type" value={costType.Main_Cost_Type} onChange={handleChange} required>
                                            <option value="">Ana Gider Türü</option>
                                            {main_Cost_TypeInfo.map((item, key) => (
                                                <option value={item.main_Cost_TypeName} key={key}>{item.main_Cost_TypeName}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Sabit Giderler    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Fixed_Costs"
                                        value={costType.Fixed_Costs}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Genel Giderler   
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="General_Costs"
                                        value={costType.General_Costs}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Ulaşım Giderleri      
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Transport_Costs"
                                        value={costType.Transport_Costs}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Personel Giderleri    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Personnel_Costs"
                                        value={costType.Personnel_Costs}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Vergi, Sgk Vb. Giderler   
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Tax_Insurance_and_Similar_Costs"
                                        value={costType.Tax_Insurance_and_Similar_Costs}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    İletişim Giderleri   
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Communication_Costs"
                                        value={costType.Communication_Costs}
                                        onChange={handleChange}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Kaydet
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CostTypeAdd;

*/}

