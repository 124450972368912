import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'react-bootstrap';
import { Button } from 'reactstrap';

import { Link } from 'react-router-dom';
import CompanyAdd from '../../../Pages/Company/CompanyAdd';
import CompanyUpdate from '../../../Pages/Company/CompanyUpdate';
import CompanyDelete from '../../../Pages/Company/DeleteCompany';
import { useNavigate } from 'react-router-dom';

const TableListModal = (props) => {

    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedItem, setSelectedItem] = useState();

    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = (selectedItem) => {
        setShow2(true);
        setSelectedItem(selectedItem);
    };

    const handleClose3 = () => setShow3(false);
    const handleShow3 = (selectedItem) => {
        setShow3(true);
        setSelectedItem(selectedItem);
    };
    const [table, setTable] = useState([]);

    useEffect(() => {
        setTable([...props.companyArray]);
        console.log(table,"burada");
        setShow2(false);
    }, [props.companyArray]);
    
    useEffect(() => {
        setTable([...props.companyArray]);
        console.log(table,"burada");
        setShow3(false);
    }, [props.companyArray]);
    
    const [showDetail, setShowDetail] = useState(false);


    const handleCloseDetail = () => setShowDetail(false);
        const handleShowDetail = (item) => {
            setShowDetail(true);
            setSelectedItem(item);
        };


    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                            <Button color="success" className="add-btn" onClick={() => {
    //window.location.href = `/FirmaEkle`;
    navigate('/FirmaEkle');
}}>
    <i className="ri-add-line align-bottom me-1"></i>  Ekle
</Button>
{/* 
   <Button color="success" className="add-btn" onClick={handleShow} id="create-btn" data-toggle="modal">
                                                    <i className="ri-add-line align-bottom me-1"></i> Ekle Modal
                                                </Button>

*/}

                                              
                                               {/* <Button color="success" className="add-btn" onClick={handleShow} id="create-btn" data-toggle="modal">
                                                    <i className="ri-add-line align-bottom me-1"></i> Ekle Modal
</Button>*/}
                                            </div>
                                        </Col>
                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <div className="search-box ms-2">
                                                    <input type="text" className="form-control search" placeholder="Search..." />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col" style={{ width: "50px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                        </div>
                                                    </th>
                                                    {
                                                        props.tableHeadArray.map((element, index) => (
                                                            <th key={index} className="sort" data-sort={element}>{element}</th>
                                                        ))
                                                    }
                                                    <th>İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {
                                                    props.companyArray.map((item) => (
                                                        <tr key={item.id}>
                                                            <th scope="row">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                                                </div>
                                                            </th>
                                                           


                                                          {/*  {Object.keys(item).map((key) => {
  if (key === 'companyName' || key === 'address' || key === 'companySector' || key === 'email' || key === 'taxOffice' || key === 'telNo' || key === 'vergiKimlikNumarası_TcKimlikNumarası' || key === 'ustFirmaId' || key === 'isDemo'  || key === 'provinceName'  || key === 'distinctName') {
    return <td data-sort={key}>{item[key]}</td>;
  }
  return null; 
})}*/}

{Object.keys(item).map((key) => {
    if ([
        'companyName', 'address', 'companySector', 'email', 
        'taxOffice', 'telNo', 'identityNo', 
        , 'isDemo', 'provinceName', 'distinctName' , 'streetName' , 'website' , 'postalCode'
    ].includes(key)) {
        return (
            <td data-sort={key} key={key}>
                {key === 'isDemo' ? (
                    <input type="checkbox" checked={item[key]} readOnly />
                ) : (
                    item[key]
                )}
            </td>
        );
    }
    return null;
})}



                                                            <td style={{ width: "200px" }}>
                                                                <div className="d-flex gap-2" >
                                                           {/* 
    <div className="edit">
    <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => handleShow2(item)}>Düzenle</button>
    </div>
*/}         
                                                               

                                                                    <div className="edit"> 
                                                                     <button
                                                                      className="btn btn-sm btn-warning edit-item-btn"
                                                                       onClick={() => {
                                                                       const parametre = item.id; 
                                                                       navigate(`/firmaGuncelleSayfa?parametre=${parametre}`);
                                                                       //window.location.href = `/firmaGuncelleSayfa?parametre=${parametre}`;
                                                                       }} >  Düzenle </button> 
                                                                   
  
                                                                   </div>
                                                                    <div className="edittwo">
                                                                        <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShow3(item)}> Sil</button>
                                                                    </div>
                                                                     <div className="editthree">
                                                                        <button className="btn btn-sm btn-info detail-item-btn" onClick={() => handleShowDetail(item)}>Detay</button>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <div className="noresult" style={{ display: "none" }}>
                                            <div className="text-center">
                                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                    colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                </lord-icon>
                                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                                <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p>
                                            </div>
                                        </div>
                                    </div>

                                   {/*  <div className="d-flex justify-content-end">
                                        <div className="pagination-wrap hstack gap-2">
                                            <Link className="page-item pagination-prev disabled" to="#">
                                            Önceki
                                            </Link>
                                            <ul className="pagination listjs-pagination mb-0"></ul>
                                            <Link className="page-item pagination-next" to="#">
                                                Sonraki
                                            </Link>
                                        </div>
                                            </div>*/}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title>
                       Ekle
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CompanyAdd />
                </Modal.Body>

            </Modal>
            <Modal show={show2} onHide={handleClose2} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title> Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <CompanyUpdate selectedItem={selectedItem} handleClose2={()=>handleClose2} />
                </Modal.Body>
            </Modal>       
            <Modal show={show3} onHide={handleClose3} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title> Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <CompanyDelete selectedItem={selectedItem} handleClose2={()=>handleClose3} />
                </Modal.Body>
            </Modal>      
            <Modal show={showDetail} onHide={handleCloseDetail} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header id="detayheader" className="modal-header" closeButton>
        <Modal.Title className="modal-title">Detaylar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="modal-content">
        <p className="modal-item"><strong>Şirket Adı:</strong> {selectedItem?.companyName}</p>
        <p className="modal-item"><strong>Adres:</strong> {selectedItem?.address}</p>
        <p className="modal-item"><strong>Şirket Sektörü:</strong> {selectedItem?.companySector}</p>
        <p className="modal-item"><strong>Email:</strong> {selectedItem?.email}</p>
        <p className="modal-item"><strong>İl:</strong> {selectedItem?.provinceId}</p>
        <p className="modal-item"><strong>İlçe:</strong> {selectedItem?.districtId}</p>
        <p className="modal-item"><strong>Vergi Dairesi:</strong> {selectedItem?.taxOffice}</p>
        <p className="modal-item"><strong>Telefon Numarası:</strong> {selectedItem?.telNo}</p>
        <p className="modal-item"><strong>Vergi Kimlik Numarası / TC Kimlik Numarası:</strong> {selectedItem?.identityNo}</p>
        <p className="modal-item"><strong>Sokak Adı:</strong> {selectedItem?.streetName}</p>
        <p className="modal-item"><strong>Web Sitesi:</strong> {selectedItem?.website}</p>
        <p className="modal-item"><strong>Posta Kodu:</strong> {selectedItem?.postalCode}</p>
        <p className="modal-item"><strong>Demo mu ?:</strong> {selectedItem?.isDemo ? <>&#10004; demodur</> : <>&#10060; demo değildir</>}</p>      

        </div>
    </Modal.Body>
</Modal>

<style jsx>{`
    .modal-content {
        padding: 20px;
    }
    .modal-item {
        margin-bottom: 15px;
        font-size: 16px;
    }
    .modal-title {
        font-size: 20px;
        font-weight: bold;
    }
        #detayheader{
                margin-left: 15px;
                margin-right: 15px;

        }
`}</style>

        </React.Fragment>
    )
}

export default TableListModal;







