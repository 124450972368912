import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';

import { useNavigate  } from 'react-router-dom';

const Invoice_Serial_Management_E_ArchiveAdd = () => {
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    const currentYear = new Date().getFullYear();

    const api = new APIClient();
    const [invoice_Serial_Management, setInvoice_Serial_Management] = useState({
        E_Invoice_Serial_Prefix: '',
        E_Invoice_Serial_No: '',
        Cashbox_Starting_Balance: '',
        E_Invoice_Serial_Year: '',
        E_Archive_Serial_Prefix: '',
        E_Archive_Serial_No: '',
        E_Archive_Serial_Year: currentYear.toString(),
        Delivery_Note_Serial_Prefix: '',
        Delivery_Note_Serial_No: '',
        Delivery_Note_Serial_Year: '',
        DefaultStatus: false,
        CompanyId: authUser.CompanyId,
CreatedUserId: authUser.Id,
status:true

    });

    document.title = "SeriYönetim Ekle  | İlekaSoft";

    const handleSubmit = async () => {
        try {
            const response = await api.create('/Invoice_Serial_Management/AddInvoice_Serial_Management', invoice_Serial_Management);
            console.log(response); 
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000 
            });
        //window.location.href = `/SeriYönetimler`;
        navigate('/SeriYönetimler')

        } catch (error) {
            console.error('Error adding bank:', error);
            toast.error("Kaydedilemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        setInvoice_Serial_Management({ ...invoice_Serial_Management, [name]: fieldValue });
    };

    return (
        <React.Fragment>
           
                                 {/*   <label htmlFor="E_Invoice_Serial_Prefix" className="col-md-2 col-form-label">
                                        E_Invoice_Serial_Prefix    
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="E_Invoice_Serial_Prefix"
                                        value={invoice_Serial_Management.E_Invoice_Serial_Prefix}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="E_Invoice_Serial_No" className="col-md-2 col-form-label">
                                        E_Invoice_Serial_No   
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="E_Invoice_Serial_No"
                                        value={invoice_Serial_Management.E_Invoice_Serial_No}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="E_Invoice_Serial_Year" className="col-md-2 col-form-label">
                                        E_Invoice_Serial_Year    
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="E_Invoice_Serial_Year"
                                        value={invoice_Serial_Management.E_Invoice_Serial_Year}
                                        onChange={handleChange}
    />*/}
                               <div style={{  padding: '10px' }}>     <label htmlFor="E_Archive_Serial_Prefix" className="col-md-2 col-form-label">
                                    E Arşiv Seri Ön Eki 
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="E_Archive_Serial_Prefix"
                                        value={invoice_Serial_Management.E_Archive_Serial_Prefix}
                                        onChange={handleChange}
                                    /> </div>
                                    <div style={{  padding: '10px' }}>
                                    <label htmlFor="E_Archive_Serial_No" className="col-md-2 col-form-label">
                                    E Arşiv Sıra No       
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="E_Archive_Serial_No"
                                        value={invoice_Serial_Management.E_Archive_Serial_No}
                                        onChange={handleChange}
                                    /></div>
                                    <div style={{  padding: '10px' }}>
                                    <label htmlFor="E_Archive_Serial_Year" className="col-md-2 col-form-label">
                                    E Arşiv Seri Yılı   
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="E_Archive_Serial_Year"
                                        value={currentYear.toString()}
                                        onChange={handleChange}
                                    />
                                    </div>
                                    {/*label htmlFor="Delivery_Note_Serial_Prefix" className="col-md-2 col-form-label">
                                        Delivery_Note_Serial_Prefix     
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Delivery_Note_Serial_Prefix"
                                        value={invoice_Serial_Management.Delivery_Note_Serial_Prefix}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="Delivery_Note_Serial_No" className="col-md-2 col-form-label">
                                        Delivery_Note_Serial_No     
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Delivery_Note_Serial_No"
                                        value={invoice_Serial_Management.Delivery_Note_Serial_No}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="Delivery_Note_Serial_Year" className="col-md-2 col-form-label">
                                        Delivery_Note_Serial_Year     
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Delivery_Note_Serial_Year"
                                        value={invoice_Serial_Management.Delivery_Note_Serial_Year}
                                        onChange={handleChange}
/>*/}                          <div style={{  padding: '10px' }}>
                                    <label htmlFor="DefaultStatus" className="col-md-2 col-form-label">
                                    Varsayılan
                                    </label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="DefaultStatus"
                                        checked={invoice_Serial_Management.DefaultStatus}
                                        onChange={handleChange}
                                    /> </div>

<div style={{  padding: '10px' }}>
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Kaydet
                                    </button>
                              </div>
        </React.Fragment>
    );
};

export default Invoice_Serial_Management_E_ArchiveAdd;
