import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import CashboxTransfersAdd from '../../../Pages/CashboxTransfers/CashboxTransfersAdd';
import CashboxTransfersUpdate from '../../../Pages/CashboxTransfers/CashboxTransfersUpdate';
import CashboxTransfersDelete from '../../../Pages/CashboxTransfers/DeleteCashboxTransfers';

const TableListModal = (props) => {
    const [table, setTable] = useState([]);
    const [filterType, setFilterType] = useState('');
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTable([...props.companyArray]);
    }, [props.companyArray]);

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedItem, setSelectedItem] = useState();

    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = (selectedItem) => {
        setShow2(true);
        setSelectedItem(selectedItem);
    };

    const handleClose3 = () => setShow3(false);
    const handleShow3 = (selectedItem) => {
        setShow3(true);
        setSelectedItem(selectedItem);
    };


    const [showDetail, setShowDetail] = useState(false);


    const handleCloseDetail = () => setShowDetail(false);
        const handleShowDetail = (item) => {
            setShowDetail(true);
            setSelectedItem(item);
        };


        
    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <Button color="success" className="add-btn" onClick={() => {
                                                window.location.href = `/KasaTransferEkle`;
                                            }}>
                                                <i className="ri-add-line align-bottom me-1"></i> Ekle
                                            </Button>
                                        </Col>
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                                <select className="form-control" onChange={handleFilterChange}>
                                                    <option value="">Tümü</option>
                                                    <option value="Kasa Virman">Kasa Virman</option>
                                                    <option value="Kasadan Bankaya Tahsilat">Kasadan Bankaya Tahsilat</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col" style={{ width: "50px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                        </div>
                                                    </th>
                                                    <th scope="col">Transfer Türü</th>
                                                    <th scope="col">Belge / Makbuz No</th>
                                                    <th scope="col">Kaynak Kasa</th>
                                                    {filterType !== 'Kasadan Bankaya Tahsilat' && <th scope="col">Hedef Kasa</th>}
                                                    {filterType !== 'Kasa Virman' && <th scope="col">Hedef Banka</th>}
                                                    <th scope="col">Tarih</th>
                                                    <th scope="col">Tutar</th>
                                                    <th scope="col">Açıklama</th>
                                                    <th>İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {table.map((item) => {
                                                    if (filterType === '' || item.kvOrKbt === filterType) {
                                                        return (
                                                            <tr key={item.id}>
                                                                <th scope="row">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                                                    </div>
                                                                </th>
                                                                {Object.keys(item).map((key) => {
                                                                    if (
                                                                        key === 'kvOrKbt' ||
                                                                        key === 'document_Receipt_No' ||
                                                                        key === 'sourceCashboxName' ||
                                                                        (key === 'targetCashboxName' && filterType !== 'kasadan bankaya tahsilat') ||
                                                                        (key === 'targetBankName' && filterType !== 'Kasa virman') ||
                                                                        key === 'date' ||
                                                                        key === 'amount' ||
                                                                        key === 'explanation'
                                                                    ) {
                                                                        return <td key={key} data-sort={key}>{item[key]}</td>;
                                                                    }
                                                                    return null;
                                                                })}
                                                                <td style={{ width: '200px' }}>
                                                                    <div className="d-flex gap-2">
                                                                        <div className="edit">
                                                                            <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/kasaTransferGuncelleSayfa?parametre=${item.id}`}> Düzenle</button>
                                                                        </div>
                                                                        <div className="edittwo">
                                                                            <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShow3(item)}>  Sil</button>
                                                                        </div>
                                                                        <div className="editthree">
                                                                        <button className="btn btn-sm btn-info detail-item-btn" onClick={() => handleShowDetail(item)}>Detay</button>
                                                                    </div>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedItem ? 'Düzenle' : 'Ekle'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedItem ? (
                        <CashboxTransfersUpdate selectedItem={selectedItem} handleClose={handleClose} />
                    ) : (
                        <CashboxTransfersAdd handleClose={handleClose} />
                    )}
                </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={handleClose3} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title> Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CashboxTransfersDelete selectedItem={selectedItem} handleClose2={() => handleClose3} />
                </Modal.Body>
            </Modal>
            
   <Modal show={showDetail} onHide={handleCloseDetail} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header id="detayheader" className="modal-header" closeButton>
        <Modal.Title className="modal-title">Detaylar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="modal-content">
        <p className="modal-item"><strong>Transfer Türü:</strong> {selectedItem?.kvOrKbt}</p>
        <p className="modal-item"><strong>Belge / Makbuz Numarası:</strong> {selectedItem?.document_Receipt_No}</p>
        <p className="modal-item"><strong>Kaynak Kasa:</strong> {selectedItem?.sourceCashbox}</p>
        <p className="modal-item"><strong>Hedef Kasa:</strong> {selectedItem?.targetCashbox}</p>
        <p className="modal-item"><strong>Hesap Banka:</strong> {selectedItem?.targetBank}</p>
        <p className="modal-item"><strong>Tarih:</strong> {selectedItem?.date}</p>
        <p className="modal-item"><strong>Tutar:</strong> {selectedItem?.amount}</p>
        <p className="modal-item"><strong>Açıklama:</strong> {selectedItem?.explanation}</p>


        </div>
    </Modal.Body>
</Modal>

<style jsx>{`
    .modal-content {
        padding: 20px;
    }
    .modal-item {
        margin-bottom: 15px;
        font-size: 16px;
    }
    .modal-title {
        font-size: 20px;
        font-weight: bold;
    }
        #detayheader{
                margin-left: 15px;
                margin-right: 15px;

        }
`}</style>


        </React.Fragment>
    );
}

export default TableListModal;
