
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const LabelAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    document.title = "Etiket Ekle  | İlekaSoft";

    const validationSchema = Yup.object().shape({
        Label_Name: Yup.string()
            .required('Etiket adı gerekli'),
    });

    const formik = useFormik({
        initialValues: {
            Label_Name: '',
            CompanyId: authUser.CompanyId,
            CreatedUserId: authUser.Id,
            status: true
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const response = await api.create('/Label/AddLabel', values);
                console.log(response);
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
                window.location.href = `/Etiketler`;
                //navigate('/Etiketler')
            } catch (error) {
                console.error('Error adding bank:', error);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            }
        },
    });

    return (
        <React.Fragment>
            <div style={{ padding: '10px' }}>
                <label htmlFor="Label_Name" className="col-md-2 col-form-label">
                    Etiket Adı
                </label>
                <input
                    className="form-control"
                    id="Label_Name"
                    name="Label_Name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Label_Name}
                />
                {formik.touched.Label_Name && formik.errors.Label_Name ? (
                    <div className="text-danger">{formik.errors.Label_Name}</div>
                ) : null}
            </div>
            <div style={{ padding: '10px' }}>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={formik.handleSubmit}
                >
                    Kaydet
                </button>
            </div>
        </React.Fragment>
    );
};

export default LabelAdd;

{/*import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

const LabelAdd = () => {
    const api =new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();


    const [label, setLabel] = useState({
        Label_Name: '',
CompanyId: authUser.CompanyId,
CreatedUserId: authUser.Id,
status:true

    });

    document.title = "Etiket Ekle  | İlekaSoft";

    const handleSubmit = async () => {
        try {
           //const response = await axios.post('https://localhost:7199/api/Bank/AddBank', bank);
            const response = await api.create('/Label/AddLabel', label);
            console.log(response); 
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000 
            });
        //window.location.href = `/Etiketler`;
        navigate('/Etiketler')

        } catch (error) {
            console.error('Error adding bank:', error);
            toast.error("Kaydedilemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });

        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLabel({ ...label, [name]: value });
    };
  

    return (
        <React.Fragment>
         <div style={{  padding: '10px' }}>

                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Etiket Adı   
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Label_Name"
                                        value={label.Label_Name}
                                        onChange={handleChange}
                                    />     </div><div style={{  padding: '10px' }}>
                  
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Kaydet
                                    </button> </div>
                              
        </React.Fragment>
    );
};

export default LabelAdd;

*/}

