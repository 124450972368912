import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

const BanklistAdd = () => {
    const api =new APIClient();
    const authUser = getLoggedinUser();

    const [banklist, setBanklist] = useState({
        Bank_Name: '',
CompanyId: authUser.CompanyId,
CreatedUserId: authUser.Id,
status:true

    });

    document.title = "Banka Adı Ekle  | İlekaSoft";

    const navigate = useNavigate();

    const handleSubmit = () => {
        api.create('/Banklist/AddBanklist', banklist)
            .then(res => {
                console.log("başarılı");
                //window.location.href = `/BankaListeler`;
                navigate('/BankaListeler')
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }


   

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBanklist({ ...banklist, [name]: value });
    };

    return (
        <React.Fragment>
           {/* <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Bankalisteler" breadcrumbItem="Bankaliste Ekle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {/* Your form inputs */}
                                    {/* Assuming you have input fields and a submit button */}
                                    {/* Example for Account Number input */}
                                    <div style={{  padding: '10px' }}> <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Banka Adı   
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Bank_Name"
                                        value={banklist.Bank_Name}
                                        onChange={handleChange}
                                    />
                                    </div> 
                                    <div style={{  padding: '10px' }}>
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Kaydet
                                    </button>
                                    </div>
                              {/*  </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
    </div>*/}
        </React.Fragment>
    );
};

export default BanklistAdd;

