import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';
import { WarehousesAddModal } from '../../components/Warehouses/WarehousesAddModal';
import { WarehousesTableList } from '../../components/Warehouses/WarehousesTableList';

const Index = () => {
    document.title = "Depo | İlekaSoft";
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [warehouses, setWarehouses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const api = new APIClient();
    const authUser = getLoggedinUser();
    const companyId = authUser.CompanyId;

    useEffect(() => {
        setUser(authUser);
        fetchData();
    }, []);

    const fetchData = () => {
        api.get(`/Warehouses/GetAll?companyId=${companyId}`)
            .then(res => setWarehouses(res))
            .catch(err => console.error(err));
    };

    const handleChange = () => {
        fetchData();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = warehouses.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < Math.ceil(warehouses.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPages = Math.ceil(warehouses.length / itemsPerPage);

        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <li key={i} className="page-item">
                    <Button
                        className={`page-link ${currentPage === i ? 'active' : ''}`}
                        onClick={() => paginate(i)}
                    >
                        {i}
                    </Button>
                </li>
            );
        }
        return pageNumbers;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Depolar" breadcrumbItem="Depolar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <WarehousesAddModal user={user} addChange={handleChange} />
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Search..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        {warehouses.length === 0 ? (
                                            <div className="text-center">
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/msoeawqm.json"
                                                    trigger="loop"
                                                    colors="primary:#121331,secondary:#08a88a"
                                                    style={{ width: "75px", height: "75px" }}
                                                />
                                                <h5 className="mt-2">Veri Bulunamadı</h5>
                                                <p className="text-muted mb-0">Sistemde kayıtlı depo bulunmamaktadır.</p>
                                            </div>
                                        ) : (
                                            <>
                                                <WarehousesTableList user={user} isChange={handleChange} warehouses={currentItems} />
                                                <div className="d-flex justify-content-end">
                                                    <div className="pagination-wrap hstack gap-2">
                                                        <Button className="page-item pagination-prev" onClick={prevPage} disabled={currentPage === 1}>
                                                            Önceki
                                                        </Button>
                                                        <ul className="pagination listjs-pagination mb-0">
                                                            {renderPageNumbers()}
                                                        </ul>
                                                        <Button className="page-item pagination-next" onClick={nextPage} disabled={currentPage === Math.ceil(warehouses.length / itemsPerPage)}>
                                                            Sonraki
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <style jsx>{`
                                            .pagination .page-link {
                                                color: grey;
                                            }
                                            .pagination .page-link.active {
                                                color: white;
                                            }
                                        `}</style>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Index;
