import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon';
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CompanyAdd = () => {
    const api = new APIClient();
    const navigate = useNavigate();
    const authUser = getLoggedinUser();

    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    const [demo, setDemo] = useState(false); // Checkbox state


    const initialValues = {
        CompanyName: '',
        Address: '',
        CompanySector: '',
        DistrictId: '',
        Email: '',
        ProvinceId: '',
        TaxOffice: '',
        TelNo: '',
        IdentityNo: '',
        UstFirmaId: authUser.CompanyId,
        IsDemo: demo,
        StreetName: '',
        Website: '',
        PostalCode: '',
        CompanyId: parseInt(authUser.CompanyId),
        CreatedUserId: parseInt(authUser.Id),
        UpdatedUserId: parseInt(authUser.Id),
        status: true
    };

    const validationSchema = Yup.object().shape({
        CompanyName: Yup.string().required('Şirket adı zorunludur'),
        Address: Yup.string().required('Adres zorunludur'),
        CompanySector: Yup.string().required('Şirket sektörü zorunludur'),
        Email: Yup.string().email('Geçerli bir email adresi giriniz').required('Email zorunludur'),
        ProvinceId: Yup.string().required('İl seçiniz'),
        DistrictId: Yup.string().required('İlçe seçiniz'),
        TaxOffice: Yup.string().required('Vergi dairesi zorunludur'),
        TelNo: Yup.string().required('Telefon numarası zorunludur'),
        IdentityNo: Yup.string().required('Vergi kimlik numarası/TC kimlik numarası zorunludur'),
        StreetName: Yup.string().required('Sokak Adı zorunludur'),
        Website: Yup.string().required('Web Sitesi zorunludur'),
        PostalCode: Yup.string().required('Posta Kodu zorunludur')
    });

    const handleSubmit = async (values, { setSubmitting, setFieldValue }) => {
        try {
            values.IsDemo = demo;

            console.log("geliyor");
            console.log(values);
            const response = await api.create('/Company/AddCompany', values);
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000
            });
            navigate(`/firmaKullanıcıEkle/${response}`);
        } catch (error) {
            console.error('Error adding company:', error);
            toast.error("Kaydedilemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
        } finally {
            setSubmitting(false);
        }
    };

    const handleProvinceChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption);
        setSelectedDistrictOption(null);
    };

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrictOption(selectedOption);
    };

    const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        // Update demo state based on checkbox
        if (checked) {
            setDemo(true); // Checkbox işaretlendiğinde true olarak ayarla
        } else {
            setDemo(false); // Checkbox işaret kaldırıldığında false olarak ayarla
        }
    };
    
    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Firma Ekle" breadcrumbItem="Firmalar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <ToastContainer />
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                            handleSubmit(values, { setSubmitting, setFieldValue });
                                        }}
                                    >
                                        {({ isSubmitting, setFieldValue, values }) => (
                                            <Form>
                                                <Row className="mb-3">
                                                    <Col md={6}>
                                                        <label htmlFor="CompanyName" className="col-form-label">Şirket Adı</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="CompanyName"
                                                        />
                                                        <ErrorMessage name="CompanyName" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="Address" className="col-form-label">Adres</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="Address"
                                                        />
                                                        <ErrorMessage name="Address" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="CompanySector" className="col-form-label">Şirket Sektörü</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="CompanySector"
                                                        />
                                                        <ErrorMessage name="CompanySector" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="Email" className="col-form-label">Email</label>
                                                        <Field
                                                            className="form-control"
                                                            type="email"
                                                            name="Email"
                                                        />
                                                        <ErrorMessage name="Email" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label className="col-md-3 col-form-label">İl</label>
                                                        <div className="col-md-9">
                                                            <Province
                                                                selectedOption={selectedProvinceOption}
                                                                handleChange={(selectedOption) => {
                                                                    handleProvinceChange(selectedOption);
                                                                    setFieldValue('ProvinceId', selectedOption ? selectedOption.value : '');
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <label className="col-md-3 col-form-label">İlçe</label>
                                                        <div className="col-md-9">
                                                            <District
                                                                province={selectedProvinceOption}
                                                                selectedOption={selectedDistrictOption}
                                                                handleChange={(selectedOption) => {
                                                                    handleDistrictChange(selectedOption);
                                                                    setFieldValue('DistrictId', selectedOption ? selectedOption.value : '');
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="TaxOffice" className="col-form-label">Vergi Dairesi</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="TaxOffice"
                                                        />
                                                        <ErrorMessage name="TaxOffice" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="TelNo" className="col-form-label">Telefon Numarası</label>
                                                        <Field
                                                            className="form-control"
                                                            type="tel"
                                                            name="TelNo"
                                                        />
                                                        <ErrorMessage name="TelNo" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="IdentityNo" className="col-form-label">Vergi Kimlik Numarası / TC Kimlik Numarası</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="IdentityNo"
                                                        />
                                                        <ErrorMessage name="IdentityNo" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="StreetName" className="col-form-label">Sokak Adı</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="StreetName"
                                                        />
                                                        <ErrorMessage name="StreetName" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="Website" className="col-form-label">Web Sitesi</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="Website"
                                                        />
                                                        <ErrorMessage name="Website" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="PostalCode" className="col-form-label">Posta Kodu</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="PostalCode"
                                                        />
                                                        <ErrorMessage name="PostalCode" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
    <div style={{ marginTop: '20px' }}>
        <label>
            <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(e, setFieldValue)}
            />
            <span style={{ marginLeft: '5px' }}>Demo mu ?</span>
        </label>
    </div>
</Col>

                                                </Row>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary mt-3"
                                                    disabled={isSubmitting}
                                                >
                                                    Ekle
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CompanyAdd;




{/*
import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon';
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CompanyAdd = () => {
    const api = new APIClient();
    const navigate = useNavigate();
    const authUser = getLoggedinUser();

    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    const initialValues = {
        CompanyName: '',
        Address: '',
        CompanySector: '',
        DistrictId: '',
        Email: '',
        ProvinceId: '',
        TaxOffice: '',
        TelNo: '',
        IdentityNo: '',
        UstFirmaId: authUser.CompanyId,
        IsDemo: false,
        StreetName: '',
        Website: '',
        PostalCode: '',
        CompanyId: parseInt(authUser.CompanyId),
        CreatedUserId: parseInt(authUser.Id),
        UpdatedUserId: parseInt(authUser.Id),
        status: true
    };

    const validationSchema = Yup.object().shape({
        CompanyName: Yup.string().required('Şirket adı zorunludur'),
        Address: Yup.string().required('Adres zorunludur'),
        CompanySector: Yup.string().required('Şirket sektörü zorunludur'),
        Email: Yup.string().email('Geçerli bir email adresi giriniz').required('Email zorunludur'),
        ProvinceId: Yup.string().required('İl seçiniz'),
        DistrictId: Yup.string().required('İlçe seçiniz'),
        TaxOffice: Yup.string().required('Vergi dairesi zorunludur'),
        TelNo: Yup.string().required('Telefon numarası zorunludur'),
        IdentityNo: Yup.string().required('Vergi kimlik numarası/TC kimlik numarası zorunludur'),
        StreetName: Yup.string().required('Sokak Adı zorunludur'),
        Website: Yup.string().required('Web Sitesi zorunludur'),
        PostalCode: Yup.string().required('Posta Kodu zorunludur')
    });

    const handleSubmit = async (values, { setSubmitting, setFieldValue }) => {
        try {
            console.log("geliyor");
            console.log(values);
            const response = await api.create('/Company/AddCompany', values);
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000
            });
            navigate(`/firmaKullanıcıEkle/${response}`); // Use navigate to navigate programmatically
        } catch (error) {
            console.error('Error adding company:', error);
            toast.error("Kaydedilemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
        } finally {
            setSubmitting(false);
        }
    };
    

const handleProvinceChange = (selectedOption) => {
    setSelectedProvinceOption(selectedOption);
    setSelectedDistrictOption(null);
};

const handleDistrictChange = (selectedOption) => {
    setSelectedDistrictOption(selectedOption);
};

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Firma Ekle" breadcrumbItem="Firmalar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <ToastContainer />
                                    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={(values, { setSubmitting, setFieldValue }) => {
        handleSubmit(values, { setSubmitting, setFieldValue }); // Pass setFieldValue here
    }}
>
                                        {({ isSubmitting, setFieldValue }) => (
                                            <Form>
                                                <Row className="mb-3">
                                                    <Col md={6}>
                                                        <label htmlFor="CompanyName" className="col-form-label">Şirket Adı</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="CompanyName"
                                                        />
                                                        <ErrorMessage name="CompanyName" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="Address" className="col-form-label">Adres</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="Address"
                                                        />
                                                        <ErrorMessage name="Address" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="CompanySector" className="col-form-label">Şirket Sektörü</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="CompanySector"
                                                        />
                                                        <ErrorMessage name="CompanySector" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="Email" className="col-form-label">Email</label>
                                                        <Field
                                                            className="form-control"
                                                            type="email"
                                                            name="Email"
                                                        />
                                                        <ErrorMessage name="Email" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label className="col-md-3 col-form-label">İl</label>
                                                        <div className="col-md-9">
                                                        <Province
                selectedOption={selectedProvinceOption}
                handleChange={(selectedOption) => {
                    handleProvinceChange(selectedOption);
                    setFieldValue('ProvinceId', selectedOption ? selectedOption.value : '');
                }}
            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <label className="col-md-3 col-form-label">İlçe</label>
                                                        <div className="col-md-9">
                                                        <District
                province={selectedProvinceOption}
                selectedOption={selectedDistrictOption}
                handleChange={(selectedOption) => {
                    handleDistrictChange(selectedOption);
                    setFieldValue('DistrictId', selectedOption ? selectedOption.value : '');
                }}
            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="TaxOffice" className="col-form-label">Vergi Dairesi</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="TaxOffice"
                                                        />
                                                        <ErrorMessage name="TaxOffice" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="TelNo" className="col-form-label">Telefon Numarası</label>
                                                        <Field
                                                            className="form-control"
                                                            type="tel"
                                                            name="TelNo"
                                                        />
                                                        <ErrorMessage name="TelNo" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="IdentityNo" className="col-form-label">Vergi Kimlik Numarası / TC Kimlik Numarası</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="IdentityNo"
                                                        />
                                                        <ErrorMessage name="IdentityNo" component="div" className="text-danger" />
                                                    </Col>
                                            
                                                    <Col md={6}>
                                                        <label htmlFor="StreetName" className="col-form-label">Sokak Adı</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="StreetName"
                                                        />
                                                        <ErrorMessage name="StreetName" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="Website" className="col-form-label">Web Sitesi</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="Website"
                                                        />
                                                        <ErrorMessage name="Website" component="div" className="text-danger" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="PostalCode" className="col-form-label">Posta Kodu</label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="PostalCode"
                                                        />
                                                        <ErrorMessage name="PostalCode" component="div" className="text-danger" />
                                                    </Col>
                                                </Row>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary waves-effect waves-light"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? "Kaydediliyor..." : "Kaydet"}
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CompanyAdd;
*/}





{/*import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from 'react-router-dom';
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon';
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CompanyUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get('parametre');
    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    useEffect(() => {
        api.get('/Company/GetCompanyById?id=' + _id)
            .then(res => {
                setSelectedProvinceOption({ value: res.provinceId, label: res.provinceName });
                setSelectedDistrictOption({ value: res.districtId, label: res.distinctName });
            })
            .catch(err => console.log(err));
    }, [_id]);

    document.title = 'Firma Güncelle | İlekaSoft';

    const initialValues = {
        companyName: '',
        address: '',
        companySector: '',
        email: '',
        taxOffice: '',
        telNo: '',
        identityNo: '',
        isDemo: false,
        streetName:'',
        website:'',
        postalCode:''
    };

    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required('Firma adı zorunludur'),
        address: Yup.string().required('Adres zorunludur'),
        companySector: Yup.string().required('Firma sektörü zorunludur'),
        email: Yup.string().email('Geçerli bir email giriniz').required('Email zorunludur'),
        taxOffice: Yup.string().required('Vergi dairesi zorunludur'),
        telNo: Yup.string().required('Telefon numarası zorunludur'),
        identityNo: Yup.string().required('Vergi/T.C. kimlik numarası zorunludur'),
        streetName: Yup.string().required('Sokak Adı zorunludur'),
        website: Yup.string().required('Web Sitesi zorunludur'),
        postalCode: Yup.string().required('Posta Kodu zorunludur'),

    });

    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const response = await api.create('/Company/UpdateCompany', values);
            toast.success('Başarıyla kaydedildi', {
                position: 'bottom-right',
                autoClose: 10000,
            });
            navigate('/Firmalar');
        } catch (error) {
            console.error('Error updating company:', error);
            toast.error('Güncellenemedi', {
                position: 'bottom-right',
                autoClose: 10000,
            });
        }
        setSubmitting(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Firma Güncelle" breadcrumbItem="Firmalar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <label htmlFor="companyName">Firma Adı</label>
                                                <Field type="text" name="companyName" className="form-control" />
                                                <ErrorMessage name="companyName" component="div" className="text-danger" />

                                                <label htmlFor="address">Adres</label>
                                                <Field type="text" name="address" className="form-control" />
                                                <ErrorMessage name="address" component="div" className="text-danger" />

                                                <label htmlFor="companySector">Firma Sektör</label>
                                                <Field type="text" name="companySector" className="form-control" />
                                                <ErrorMessage name="companySector" component="div" className="text-danger" />

                                                <label htmlFor="email">Email</label>
                                                <Field type="email" name="email" className="form-control" />
                                                <ErrorMessage name="email" component="div" className="text-danger" />

                                                <label htmlFor="taxOffice">Vergi Dairesi</label>
                                                <Field type="text" name="taxOffice" className="form-control" />
                                                <ErrorMessage name="taxOffice" component="div" className="text-danger" />

                                                <label htmlFor="telNo">Tel No</label>
                                                <Field type="tel" name="telNo" className="form-control" />
                                                <ErrorMessage name="telNo" component="div" className="text-danger" />

                                                <label htmlFor="identityNo">
                                                IdentityNo
                                                </label>
                                                <Field type="text" name="identityNo" className="form-control" />
                                                <ErrorMessage name="identityNo" component="div" className="text-danger" />

                                                <div className="form-check">
                                                    <Field type="checkbox" name="isDemo" className="form-check-input" />
                                                    <label htmlFor="isDemo" className="form-check-label">
                                                        Demo mu?
                                                    </label>
                                                </div>

                                                <label htmlFor="streetName">Sokak Adı</label>
                                                <Field type="text" name="streetName" className="form-control" />
                                                <ErrorMessage name="streetName" component="div" className="text-danger" />
                                                
                                                <label htmlFor="website">Web Sitesi</label>
                                                <Field type="text" name="website" className="form-control" />
                                                <ErrorMessage name="website" component="div" className="text-danger" />
                                                
                                                <label htmlFor="postalCode">Posta Kodu</label>
                                                <Field type="text" name="postalCode" className="form-control" />
                                                <ErrorMessage name="postalCode" component="div" className="text-danger" />

                                                <button type="submit" className="btn btn-primary waves-effect waves-light" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Kaydediliyor...' : 'Firmayı Güncelle'}
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CompanyUpdatePage;
*/}









{/*import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon';
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown';
import { useNavigate  } from 'react-router-dom';

const CompanyAdd = () => {
    const api = new APIClient();

    const navigate = useNavigate();

    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    const authUser = getLoggedinUser();

    const [company, setCompany] = useState({
        CompanyName: '',
        Address: '',
        CompanySector: '',
        DistrictId: '',
        Email: '',
        ProvinceId: '',
        TaxOffice: '',
        TelNo: '',
        VergiKimlikNumarası_TcKimlikNumarası: '',
        UstFirmaId: authUser.CompanyId,
        IsDemo: 0,
        StreetName: '',
        Website: '',
        PostalCode: '',
        //Id: 0,
        CompanyId: authUser.CompanyId,
        CreatedUserId: authUser.Id,
        status: true

    });

    document.title = "Firma Ekle | İlekaSoft";

    const handleSubmit = async () => {
        try {
            const response = await api.create('/Company/AddCompany', company);
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000
            });
            //navigate(`/firmaKullanıcıEkle/` + response)
            window.location.href = `/firmaKullanıcıEkle/` + response;
        } catch (error) {
            console.error('Error adding company:', error);
            toast.error("Kaydedilemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCompany({ ...company, [name]: value });
    };

    const handleProvinceChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption);
        setCompany({ ...company, ProvinceId: selectedOption ? selectedOption.value : '' });
        setSelectedDistrictOption(null);
    };

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrictOption(selectedOption);
        setCompany({ ...company, DistrictId: selectedOption ? selectedOption.value : '' });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Firma Ekle" breadcrumbItem="Firmalar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <ToastContainer />
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <label htmlFor="CompanyName" className="col-form-label">Şirket Adı</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="CompanyName"
                                                value={company.CompanyName}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="CompanySector" className="col-form-label">Şirket Sektörü</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="CompanySector"
                                                value={company.CompanySector}
                                                onChange={handleChange}
                                            />
                                        </Col>

                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <label htmlFor="TaxOffice" className="col-form-label">Vergi Dairesi</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="TaxOffice"
                                                value={company.TaxOffice}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="VergiKimlikNumarası_TcKimlikNumarası" className="col-form-label">Vergi Kimlik Numarası / TC Kimlik Numarası</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="VergiKimlikNumarası_TcKimlikNumarası"
                                                value={company.VergiKimlikNumarası_TcKimlikNumarası}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">

                                        <Col md={6}>
                                            <label htmlFor="Email" className="col-form-label">Email</label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                name="Email"
                                                value={company.Email}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="TelNo" className="col-form-label">Telefon Numarası</label>
                                            <input
                                                className="form-control"
                                                type="tel"
                                                name="TelNo"
                                                value={company.TelNo}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">

                                    <Col md={6}>
                                        <label htmlFor="Email" className="col-form-label">Websitesi</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="Website"
                                            value={company.Website}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="TelNo" className="col-form-label">Posta Kodu</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="PostalCode"
                                            value={company.PostalCode}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    </Row>
                                   
                                  
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <label htmlFor="ProvinceId" className="col-form-label">İl</label>
                                            <Province selectedOption={selectedProvinceOption} handleChange={handleProvinceChange} />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="DistrictId" className="col-form-label">İlçe</label>
                                            <District province={selectedProvinceOption} selectedOption={selectedDistrictOption} handleChange={handleDistrictChange} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <label htmlFor="StreetName" className="col-form-label">Cadde</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="StreetName"
                                                value={company.StreetName}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                       
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <label htmlFor="Address" className="col-form-label">Adres</label>
                                            <textarea
                                                className="form-control"
                                                type="text"
                                                name="Address"
                                                value={company.Address}
                                                onChange={handleChange}
                                            />
<<<<<<< HEAD
    </Col>*/}

    {/*
    <Col md={6}>
    <label htmlFor="IsDemo" className="col-sm-2 col-form-label">Demo mu?</label>
    <div className="col-sm-10">
        <input
            type="checkbox"
            checked={company.IsDemo === 1}
            onChange={(e) => {
                handleChange({ target: { name: 'IsDemo', value: e.target.checked ? 1 : 0 } });
              //  alert(company.IsDemo);
            }}
        />
    </div>
      <input 
        style={{ visibility: 'hidden' }}
        className="form-control"
        type="number"
        name="IsDemo"
        value={company.IsDemo}
        onChange={handleChange}
    />
</Col>
                         </Row>



//ÇAKIŞMA İLE GELENLER




                         </Col>
                                    </Row>
                                    <Row className="mb-3">
                                       
                                       {/*<Col md={6}>
                                           <label htmlFor="IsDemo" className="col-form-label">Demo Durumu</label>
                                           <input
                                               className="form-control"
                                               type="number"
                                               name="IsDemo"
                                               value={company.IsDemo}
                                               onChange={handleChange}
                                           />
                                           </Col>
                                       <Col md={6}>
                                           <label htmlFor="IsDemo" className="col-sm-2 col-form-label">Demo mu?</label>
                                           <div className="col-sm-10">
                                               <input
                                                   type="checkbox"
                                                   checked={company.IsDemo === 1}
                                                   onChange={(e) => {
                                                       handleChange({ target: { name: 'IsDemo', value: e.target.checked ? 1 : 0 } });
                                                       //  alert(company.IsDemo);
                                                   }}
                                               />
                                           </div>
                                           <input
                                               style={{ visibility: 'hidden' }}
                                               className="form-control"
                                               type="number"
                                               name="IsDemo"
                                               value={company.IsDemo}
                                               onChange={handleChange}
                                           />
                                       </Col>
                                   </Row>



                                   <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Kaydet
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CompanyAdd;
*/}




