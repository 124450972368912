import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'reactstrap';
import { APIClient } from '../../helpers/api_helper';
import { Province } from '../Dropdown_Il_Ilce/Provincedropdwon';
import { District } from '../Dropdown_Il_Ilce/DistrictDropDown';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    warehouseName: Yup.string().required('Depo adı gereklidir.'),
    address: Yup.string().required('Adres gereklidir.'),
    currentProvince_ID: Yup.string().required('İl gereklidir.'),
    currentDistrict_ID: Yup.string().required('İlçe gereklidir.')
});

export const WarehousesUpdate = ({ user, isChange, group }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [authUser, setAuthUser] = useState(user);
    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    const api = new APIClient();

    function openModal() {
        setOpen(!open);
        console.log(authUser);
    }

    const handleChange = () => {
        isChange();
    };

    useEffect(() => {
        setAuthUser(user);
        setSelectedProvinceOption({ label: group.provinceName, value: group.currentProvince_ID });
        setSelectedDistrictOption({ label: group.districtName, value: group.currentDistrict_ID });
    }, [user, group]);

    const handleProvinceChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption);
        setSelectedDistrictOption(null);
    };

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrictOption(selectedOption);
    };

    const handleSubmit = (values, { setSubmitting }) => {
        const UserGroup = {
            id: group.id,
            warehouseName: values.warehouseName,
            address: values.address,
            currentProvince_ID: values.currentProvince_ID,
            currentDistrict_ID: values.currentDistrict_ID,
            updatedUserId: authUser.Id,
            status: true,
            defaultStatus: false,
        };

        api.create("/Warehouses/UpdateWarehouses", UserGroup)
            .then(res => {
                console.log("Başarılı");
                window.location.href = `/Depolar`;
                toast.success("Başarıyla güncellendi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
                setSubmitting(false);
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Güncellenemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
                setSubmitting(false);
            });
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <div>
                <Container>
                    <Row>
                        <Col>
                            <div className="d-flex gap-1">
                                <button className="btn btn-sm btn-warning edit-item-btn"
                                    data-bs-toggle="modal" onClick={openModal} data-bs-target="#showModal">Düzenle</button>
                            </div>
                            <Modal
                                isOpen={open}
                                toggle={openModal}
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Güncelle
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setOpen(false)}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <Formik
                                        initialValues={{
                                            warehouseName: group.warehouseName,
                                            address: group.address,
                                            currentProvince_ID: selectedProvinceOption ? selectedProvinceOption.value : '',
                                            currentDistrict_ID: selectedDistrictOption ? selectedDistrictOption.value : '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ errors, touched, setFieldValue }) => (
                                            <Form>
                                                <div className="mb-3">
                                                    <label htmlFor="warehouseName" className="col-form-label">Depo Adı</label>
                                                    <Field name="warehouseName" className="form-control" placeholder="Depo Adı Giriniz" />
                                                    {errors.warehouseName && touched.warehouseName ? (
                                                        <div className="text-danger">{errors.warehouseName}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="address" className="col-form-label">Adres</label>
                                                    <Field name="address" className="form-control" placeholder="Adres Giriniz" />
                                                    {errors.address && touched.address ? (
                                                        <div className="text-danger">{errors.address}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="currentProvince_ID" className="col-form-label">İl</label>
                                                    <Province selectedOption={selectedProvinceOption} handleChange={(option) => {
                                                        setFieldValue('currentProvince_ID', option ? option.value : '');
                                                        handleProvinceChange(option);
                                                    }} />
                                                    {errors.currentProvince_ID && touched.currentProvince_ID ? (
                                                        <div className="text-danger">{errors.currentProvince_ID}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="currentDistrict_ID" className="col-form-label">İlçe</label>
                                                    <District province={selectedProvinceOption} selectedOption={selectedDistrictOption} handleChange={(option) => {
                                                        setFieldValue('currentDistrict_ID', option ? option.value : '');
                                                        handleDistrictChange(option);
                                                    }} />
                                                    {errors.currentDistrict_ID && touched.currentDistrict_ID ? (
                                                        <div className="text-danger">{errors.currentDistrict_ID}</div>
                                                    ) : null}
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-bs-dismiss="modal"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        Kapat
                                                    </button>
                                                    <Button type="submit" color="primary">Kaydet</Button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
