import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const BankTransferAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const [bankIds, setBankIds] = useState([]);
    const [currentAccountIds, setCurrentAccountIds] = useState([]);
    const [sourceBankIds, setSourceBankIds] = useState([]);
    const [targetBankIds, setTargetBankIds] = useState([]);
    const [targetCashboxIds, setTargetCashboxIds] = useState([]);

    const [showForm, setShowForm] = useState(false);
    const companyId = authUser.CompanyId;

    useEffect(() => {
        fetchBankIds();
        fetchCurrentAccountIds();
        fetchSourceBankIds();
        fetchTargetBankIds();
        fetchTargetCashboxIds();
    }, [companyId]);

    const fetchBankIds = () => {
        api.get(`/Bank/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.account_Name,
                    value: item.id
                }));
                setBankIds(formattedOptions);
                console.log("banka listesi geliyor");
                console.log(res);
            })
            .catch(error => console.error("Error fetching banks:", error));
    };

    const fetchCurrentAccountIds = () => {
        api.get(`/CurrentAccount/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.currentName,
                    value: item.id
                }));
                setCurrentAccountIds(formattedOptions);
            })
            .catch(error => console.error("Error fetching current accounts:", error));
    };

    const fetchSourceBankIds = () => {
        api.get(`/Bank/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.account_Name,
                    value: item.id
                }));
                setSourceBankIds(formattedOptions);
            })
            .catch(error => console.error("Error fetching source banks:", error));
    };

    const fetchTargetBankIds = () => {
        api.get(`/Bank/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.account_Name,
                    value: item.id
                }));
                setTargetBankIds(formattedOptions);
            })
            .catch(error => console.error("Error fetching target banks:", error));
    };

    const fetchTargetCashboxIds = () => {
        api.get(`/Cashbox/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id
                }));
                setTargetCashboxIds(formattedOptions);
            })
            .catch(error => console.error("Error fetching target cashboxes:", error));
    };

    const handleSubmit = async (values, { setSubmitting }) => {

        if(values.HeOrBvOrBkt == "Havale / Eft")
            {
values.SourceBankId = 0;
values.TargetBankId =0;
values.TargetCashboxId = 0;
            }
        else if(values.HeOrBvOrBkt =="Banka Virman")
            {

values.BankId=0;
values.CurrentAccountId =0;
values.TargetCashboxId=0;


            }
            else if(values.HeOrBvOrBkt =="Bankadan Kasaya Transfer")
                {

values.BankId=0;
values.CurrentAccountId=0;
values.TargetBankId =0;

                }



        console.log("geliyor");
        console.log(values);
        try {
            await api.create("/BankTransfer/AddBankTransfer", values);
            toast.success("Başarıyla kaydedildi", { position: "bottom-right", autoClose: 10000 });
            setTimeout(() => {
                navigate(`/BankaTransferler`);
                // window.location.href = `/BankaTransferler`;
            }, 1500);
        } catch (error) {
            console.error("Error:", error);
            toast.error("Kaydedilemedi", { position: "bottom-right", autoClose: 10000 });
        } finally {
            setSubmitting(false);
        }
    };

    const initialValues = {
        HeOrBvOrBkt: '',
        BankId: '',
        CurrentAccountId: '',
        SourceBankId: '',
        TargetBankId: '',
        TargetCashboxId: '',
        Date: '',
        Amount: '',
        OperationType: '',
        Explanation: '',
        Document_Receipt_No: '',
        CompanyId: parseInt(authUser.CompanyId),
        CreatedUserId: parseInt(authUser.Id),
        Status: true
    };

    const heOrBvOrBktOptions = [
        { label: 'Havale / Eft', value: 'Havale / Eft' },
        { label: 'Banka Virman', value: 'Banka Virman' },
        { label: 'Bankadan Kasaya Transfer', value: 'Bankadan Kasaya Transfer' }
    ];

    const operationTypeOptions = [
        { label: 'Gelen Havale/ Eft', value: 'Gelen Havale/ Eft' },
        { label: 'Giden Havale/ Eft', value: 'Giden Havale/ Eft' },
    ];

{/* const validationSchema = Yup.object().shape({
        HeOrBvOrBkt: Yup.string().required('Bu alan gereklidir'),
        BankId: Yup.string().required('Bu alan gereklidir'),
        CurrentAccountId: Yup.string().required('Bu alan gereklidir'),
        SourceBankId: Yup.string().required('Bu alan gereklidir'),
        TargetBankId: Yup.string().required('Bu alan gereklidir'),
        TargetCashboxId: Yup.string().required('Bu alan gereklidir'),
        Date: Yup.string().required('Bu alan gereklidir'),
        Amount: Yup.string().required('Bu alan gereklidir'),
        OperationType: Yup.string().required('Bu alan gereklidir'),
        Explanation: Yup.string().required('Bu alan gereklidir'),
        Document_Receipt_No: Yup.string().required('Bu alan gereklidir'),
    });
*/}
const validationSchema = Yup.object().shape({
    HeOrBvOrBkt: Yup.string().required('Bu alan gereklidir'),
    Document_Receipt_No: Yup.string().required('Bu alan gereklidir'),
    BankId: Yup.string().test(
        'BankId',
        'Bu alan gereklidir',
        function (value) {
            const { HeOrBvOrBkt } = this.parent;
            if (HeOrBvOrBkt !== 'Banka Virman' && HeOrBvOrBkt !== 'Bankadan Kasaya Transfer') {
                return value !== undefined && value !== '';
            }
            return true;
        }
    ),
    CurrentAccountId: Yup.string().test(
        'CurrentAccountId',
        'Bu alan gereklidir',
        function (value) {
            const { HeOrBvOrBkt } = this.parent;
            if (HeOrBvOrBkt !== 'Banka Virman' && HeOrBvOrBkt !== 'Bankadan Kasaya Transfer') {
                return value !== undefined && value !== '';
            }
            return true;
        }
    ),
    SourceBankId: Yup.string().test(
        'SourceBankId',
        'Bu alan gereklidir',
        function (value) {
            const { HeOrBvOrBkt } = this.parent;
            if (HeOrBvOrBkt !== 'Havale / Eft') {
                return value !== undefined && value !== '';
            }
            return true;
        }
    ),
    TargetBankId: Yup.string().test(
        'TargetBankId',
        'Bu alan gereklidir',
        function (value) {
            const { HeOrBvOrBkt } = this.parent;
            if (HeOrBvOrBkt !== 'Havale / Eft' && HeOrBvOrBkt !== 'Bankadan Kasaya Transfer') {
                return value !== undefined && value !== '';
            }
            return true;
        }
    ),
    TargetCashboxId: Yup.string().test(
        'TargetCashboxId',
        'Bu alan gereklidir',
        function (value) {
            const { HeOrBvOrBkt } = this.parent;
            if (HeOrBvOrBkt !== 'Havale / Eft' && HeOrBvOrBkt !== 'Banka Virman') {
                return value !== undefined && value !== '';
            }
            return true;
        }
    ),
    Date: Yup.string().required('Bu alan gereklidir'),
    Amount: Yup.string().required('Bu alan gereklidir'),
    OperationType: Yup.string().test(
        'OperationType',
        'Bu alan gereklidir',
        function (value) {
            const { HeOrBvOrBkt } = this.parent;
            if (HeOrBvOrBkt !== 'Banka Virman' && HeOrBvOrBkt !== 'Bankadan Kasaya Transfer') {
                return value !== undefined && value !== '';
            }
            return true;
        }
    ),
    Explanation: Yup.string().required('Bu alan gereklidir')
});


    return (
        <React.Fragment>
            <div className="page-content" style={{ maxHeight: 'calc(150vh - 200px)', overflowY: 'auto' }}>
                <Container fluid={true}>
                    <Breadcrumbs title="Banka Transfer Ekle" breadcrumbItem="Banka Transferler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting, setFieldValue, values }) => (
                                            <Form>
                                                <FormGroup>
                                                    <Label for="HeOrBvOrBkt">Transfer Türü</Label>
                                                    <Select
                                                        name="HeOrBvOrBkt"
                                                        value={heOrBvOrBktOptions.find(option => option.value === values.HeOrBvOrBkt)}
                                                        onChange={option => setFieldValue('HeOrBvOrBkt', option.value)}
                                                        options={heOrBvOrBktOptions}
                                                        placeholder="Transfer Türü Seçiniz"
                                                    />
                                                    <ErrorMessage name="HeOrBvOrBkt" component="div" className="text-danger" />
                                                </FormGroup>

                                                {values.HeOrBvOrBkt === 'Havale / Eft' && (
                                                    <React.Fragment>
                                                        <FormGroup>
                                                            <Label for="BankId">Banka Hesabı</Label>
                                                            <Select
                                                                name="BankId"
                                                                value={bankIds.find(option => option.value === values.BankId)}
                                                                onChange={option => setFieldValue('BankId', option.value)}
                                                                options={bankIds}
                                                                placeholder="Banka Seçiniz"
                                                            />
                                                            <ErrorMessage name="BankId" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="CurrentAccountId">Mevcut Hesap</Label>
                                                            <Select
                                                                name="CurrentAccountId"
                                                                value={currentAccountIds.find(option => option.value === values.CurrentAccountId)}
                                                                onChange={option => setFieldValue('CurrentAccountId', option.value)}
                                                                options={currentAccountIds}
                                                                placeholder="Mevcut Hesap Seçiniz"
                                                            />
                                                            <ErrorMessage name="CurrentAccountId" component="div" className="text-danger" />
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )}

                                                {values.HeOrBvOrBkt === 'Banka Virman' && (
                                                    <React.Fragment>
                                                        <FormGroup>
                                                            <Label for="SourceBankId">Kaynak Banka Hesabı</Label>
                                                            <Select
                                                                name="SourceBankId"
                                                                value={sourceBankIds.find(option => option.value === values.SourceBankId)}
                                                                onChange={option => setFieldValue('SourceBankId', option.value)}
                                                                options={sourceBankIds}
                                                                placeholder="Kaynak Banka Seçiniz"
                                                            />
                                                            <ErrorMessage name="SourceBankId" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="TargetBankId">Hedef Banka Hesabı</Label>
                                                            <Select
                                                                name="TargetBankId"
                                                                value={targetBankIds.find(option => option.value === values.TargetBankId)}
                                                                onChange={option => setFieldValue('TargetBankId', option.value)}
                                                                options={targetBankIds}
                                                                placeholder="Hedef Banka Seçiniz"
                                                            />
                                                            <ErrorMessage name="TargetBankId" component="div" className="text-danger" />
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )}

                                                {values.HeOrBvOrBkt === 'Bankadan Kasaya Transfer' && (
                                                    <React.Fragment>
                                                        <FormGroup>
                                                            <Label for="SourceBankId">Kaynak Banka Hesabı</Label>
                                                            <Select
                                                                name="SourceBankId"
                                                                value={sourceBankIds.find(option => option.value === values.SourceBankId)}
                                                                onChange={option => setFieldValue('SourceBankId', option.value)}
                                                                options={sourceBankIds}
                                                                placeholder="Kaynak Banka Seçiniz"
                                                            />
                                                            <ErrorMessage name="SourceBankId" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="TargetCashboxId">Hedef Kasa Adı</Label>
                                                            <Select
                                                                name="TargetCashboxId"
                                                                value={targetCashboxIds.find(option => option.value === values.TargetCashboxId)}
                                                                onChange={option => setFieldValue('TargetCashboxId', option.value)}
                                                                options={targetCashboxIds}
                                                                placeholder="Hedef Kasa Seçiniz"
                                                            />
                                                            <ErrorMessage name="TargetCashboxId" component="div" className="text-danger" />
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )}

                                                <FormGroup>
                                                    <Label for="Date">Tarih</Label>
                                                    <Field
                                                        type="date"
                                                        name="Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Date" component="div" className="text-danger" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="Amount">Tutar</Label>
                                                    <Field
                                                        type="number"
                                                        name="Amount"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Amount" component="div" className="text-danger" />
                                                </FormGroup>

                                                {values.HeOrBvOrBkt === 'Havale / Eft' && (
                                                    <FormGroup>
                                                        <Label for="OperationType">İşlem Türü</Label>
                                                        <Select
                                                            name="OperationType"
                                                            value={operationTypeOptions.find(option => option.value === values.OperationType)}
                                                            onChange={option => setFieldValue('OperationType', option.value)}
                                                            options={operationTypeOptions}
                                                            placeholder="İşlem Türü Seçiniz"
                                                        />
                                                        <ErrorMessage name="OperationType" component="div" className="text-danger" />
                                                    </FormGroup>
                                                )}

                                                <FormGroup>
                                                    <Label for="Explanation">Açıklama</Label>
                                                    <Field
                                                        type="textarea"
                                                        name="Explanation"
                                                        placeholder="Açıklama Giriniz"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Explanation" component="div" className="text-danger" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="Document_Receipt_No">Belge/Makbuz No</Label>
                                                    <Field
                                                        type="text"
                                                        name="Document_Receipt_No"
                                                        placeholder="Belge / Makbuz No Girinz"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Document_Receipt_No" component="div" className="text-danger" />
                                                </FormGroup>

                                                <Button color="primary" type="submit" disabled={isSubmitting}>Kaydet</Button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default BankTransferAdd;

{/*
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const BankTransferAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();

    const [bankIds, setBankIds] = useState([]);
    const [currentAccountIds, setCurrentAccountIds] = useState([]);
    const [sourceBankIds, setSourceBankIds] = useState([]);
    const [targetBankIds, setTargetBankIds] = useState([]);
    const [targetCashboxIds, setTargetCashboxIds] = useState([]);




    const [showForm, setShowForm] = useState(false);
    const companyId = authUser.CompanyId;

    useEffect(() => {
        fetchBankIds();
        fetchCurrentAccountIds();
        fetchSourceBankIds();
        fetchTargetBankIds();
        fetchTargetCashboxIds()
    }, [companyId]);

   

    const fetchBankIds = () => {
        api.get(`/Bank/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.bank_Name,
                    value: item.id.toString()
                }));
                setBankIds(formattedOptions);
            })
            .catch(error => console.error("Error fetching banks:", error));
    };

    const fetchCurrentAccountIds = () => {
        api.get(`/CurrentAccount/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.currentName,
                    value: item.id.toString()
                }));
                setCurrentAccountIds(formattedOptions);
            })
            .catch(error => console.error("Error fetching banks:", error));
    };

    const fetchSourceBankIds = () => {
        api.get(`/Bank/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.bank_Name,
                    value: item.id.toString()
                }));
                setSourceBankIds(formattedOptions);
            })
            .catch(error => console.error("Error fetching banks:", error));
    };

    const fetchTargetBankIds = () => {
        api.get(`/Bank/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.bank_Name,
                    value: item.id.toString()
                }));
                setTargetBankIds(formattedOptions);
            })
            .catch(error => console.error("Error fetching banks:", error));
    };

    const fetchTargetCashboxIds = () => {
        api.get(`/Cashbox/GetAll?companyId=${companyId}`)
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id.toString()
                }));
                setTargetCashboxIds(formattedOptions);
            })
            .catch(error => console.error("Error fetching banks:", error));
    };




    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await api.create("/BankTransfer/AddBankTransfer", values);
            toast.success("Başarıyla kaydedildi", { position: "bottom-right", autoClose: 10000 });
            setTimeout(() => {
                window.location.href = `/BankaTransferler`;
            }, 1500);
        } catch (error) {
            console.error("Error:", error);
            toast.error("Kaydedilemedi", { position: "bottom-right", autoClose: 10000 });
        } finally {
            setSubmitting(false);
        }
    };

    const initialValues = {
        HeOrBvOrBkt:'',
        BankId:'',
        CurrentAccountId:'',
        SourceBankId:'',
        TargetBankId:'',
        TargetCashboxId:'',
        Date:'',
        Amount:'',
        OperationType:'',
        Explanation:'',
        Document_Receipt_No:'',

        CompanyId: authUser.CompanyId,
        CreatedUserId: authUser.Id,
        Status: true
    };

    const heOrBvOrBktOptions = [
        { label: 'Havale / Eft', value: 'Havale / Eft' },
        { label: 'Banka Virman', value: 'Banka Virman' },
        { label: 'Bankadan Kasaya Transfer', value: 'Bankadan Kasaya Transfer' }
    ];

    const operationTypeOptions = [
        { label: 'İlk Operasyon Türü', value: 'İlk Operasyon Türü' },
        { label: 'İkinci Operasyon Türü', value: 'İkinci Operasyon Türü' },
    ];

    return (
        <React.Fragment>
            <div className="page-content" style={{ maxHeight: 'calc(150vh - 200px)', overflowY: 'auto' }}>
                <Container fluid={true}>
                    <Breadcrumbs title="Banka Transfer Ekle" breadcrumbItem="Banka Transferler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting, setFieldValue, values }) => (
                                            <Form>

                                                <FormGroup>
                                                    <Label for="HeOrBvOrBkt">Transfer Türü</Label>
                                                    <Select
                                                        name="HeOrBvOrBkt"
                                                        value={heOrBvOrBktOptions.find(option => option.value === values.HeOrBvOrBkt)}
                                                        onChange={option => setFieldValue('KvOrKbt', option.value)}
                                                        options={heOrBvOrBktOptions}
                                                        placeholder="Transfer Türü Seçin"
                                                    />
                                                    <ErrorMessage name="HeOrBvOrBkt" component="div" className="text-danger" />
                                                </FormGroup>

                                                {values.HeOrBvOrBkt === 'Havale / Eft' && (

                                                <FormGroup>
                                                    <Label for="BankId">BankId</Label>
                                                    <Select
                                                        name="BankId"
                                                        value={bankIds.find(option => option.value === values.BankId)}
                                                        onChange={option => setFieldValue('BankId', option.value)}
                                                        options={bankIds}
                                                        placeholder="BankId Seçin"
                                                    />
                                                    <ErrorMessage name="BankId" component="div" className="text-danger" />
                                                </FormGroup>
                                                )}

{values.HeOrBvOrBkt === 'Havale / Eft' && (

                                                <FormGroup>
                                                    <Label for="CurrentAccountId">CurrentAccountId</Label>
                                                    <Select
                                                        name="CurrentAccountId"
                                                        value={currentAccountIds.find(option => option.value === values.CurrentAccountId)}
                                                        onChange={option => setFieldValue('CurrentAccountId', option.value)}
                                                        options={currentAccountIds}
                                                        placeholder="CurrentAccountId Seçin"
                                                    />
                                                    <ErrorMessage name="CurrentAccountId" component="div" className="text-danger" />
                                                </FormGroup>
   )}


{values.HeOrBvOrBkt === 'Banka Virman'  || 'Bankadan Kasaya Transfer' && (

                                                <FormGroup>
                                                    <Label for="SourceBankId">SourceBankId</Label>
                                                    <Select
                                                        name="SourceBankId"
                                                        value={sourceBankIds.find(option => option.value === values.SourceBankId)}
                                                        onChange={option => setFieldValue('SourceBankId', option.value)}
                                                        options={sourceBankIds}
                                                        placeholder="SourceBankId Seçin"
                                                    />
                                                    <ErrorMessage name="SourceBankId" component="div" className="text-danger" />
                                                </FormGroup>
 )}

{values.HeOrBvOrBkt === 'Banka Virman'  && (

                                                <FormGroup>
                                                    <Label for="TargetBankId">TargetBankId</Label>
                                                    <Select
                                                        name="TargetBankId"
                                                        value={targetBankIds.find(option => option.value === values.TargetBankId)}
                                                        onChange={option => setFieldValue('TargetBankId', option.value)}
                                                        options={targetBankIds}
                                                        placeholder="TargetBankId Seçin"
                                                    />
                                                    <ErrorMessage name="TargetBankId" component="div" className="text-danger" />
                                                </FormGroup>
 )}
{values.HeOrBvOrBkt === 'Bankadan Kasaya Transfer'  && (

                                                <FormGroup>
                                                    <Label for="TargetCashboxId">TargetCashboxId</Label>
                                                    <Select
                                                        name="TargetCashboxId"
                                                        value={targetCashboxIds.find(option => option.value === values.TargetCashboxId)}
                                                        onChange={option => setFieldValue('TargetCashboxId', option.value)}
                                                        options={targetCashboxIds}
                                                        placeholder="TargetCashboxId Seçin"
                                                    />
                                                    <ErrorMessage name="TargetCashboxId" component="div" className="text-danger" />
                                                </FormGroup>
)}
                                                <FormGroup>
                                                    <Label for="Date">Tarih</Label>
                                                    <Field
                                                        type="date"
                                                        name="Date"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Date" component="div" className="text-danger" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="Amount">Tutar</Label>
                                                    <Field
                                                        type="number"
                                                        name="Amount"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Amount" component="div" className="text-danger" />
                                                </FormGroup>
                                                {values.HeOrBvOrBkt === 'Havale / Eft'  && (

                                                <FormGroup>
                                                    <Label for="OperationType">OperationType</Label>
                                                    <Select
                                                        name="OperationType"
                                                        value={operationTypeOptions.find(option => option.value === values.OperationType)}
                                                        onChange={option => setFieldValue('OperationType', option.value)}
                                                        options={operationTypeOptions}
                                                        placeholder="OperationType Seçin"
                                                    />
                                                    <ErrorMessage name="OperationType" component="div" className="text-danger" />
                                                </FormGroup>
 )}
                                                <FormGroup>
                                                    <Label for="Explanation">Açıklama</Label>
                                                    <Field
                                                        type="textarea"
                                                        name="Explanation"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Explanation" component="div" className="text-danger" />
                                                </FormGroup>


                                                {values.HeOrBvOrBkt === 'Banka Virman'  || 'Bankadan Kasaya Transfer' && (

                                                <FormGroup>
                                                    <Label for="Document_Receipt_No">Document_Receipt_No</Label>
                                                    <Field
                                                        type="text"
                                                        name="Document_Receipt_No"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="Document_Receipt_No" component="div" className="text-danger" />
                                                </FormGroup>
 )}

                                                <Button color="primary" type="submit" disabled={isSubmitting}>Kaydet</Button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default BankTransferAdd;
*/}