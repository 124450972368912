
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';
const CashboxtypeUpdate = (props) => {
    const api =new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const [cashbox_Payment, setCashbox_Payment] = useState(props.selectedItem.cashbox_Payment);
    const [cashbox_Collection, setCashbox_Collection] = useState(props.selectedItem.cashbox_Collection);
    const [cashbox_Transfer, setCashbox_Transfer] = useState(props.selectedItem.cashbox_Transfer);
    const [updatedUserId, setUpdatedUserId] = useState(authUser.Id);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedCashboxtype = {
            Id:props.selectedItem.id,
            cashbox_Payment: cashbox_Payment,
            cashbox_Collection: cashbox_Collection,
            cashbox_Transfer: cashbox_Transfer,
            updatedUserId: updatedUserId
            //status:true


        };

        try {
            await api.create("/Cashboxtype/UpdateCashboxtype", updatedCashboxtype);
            
            props.handleClose2(); // Modalı kapatıyoruz
            navigate(`/KasaTurler`);
            //window.location.href = `/KasaTurler`;


        } catch (error) {
            console.error("Error updating current group:", error);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Kasa Ödeme</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Cashbox_Payment"
                    name="cashbox_Payment"
                    value={cashbox_Payment}
                    onChange={(e) => setCashbox_Payment(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Kasa Tahsilat</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Cashbox_Collection"
                    name="cashbox_Collection"
                    value={cashbox_Collection}
                    onChange={(e) => setCashbox_Collection(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Kasa Devir</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Cashbox_Transfer"
                    name="cashbox_Transfer"
                    value={cashbox_Transfer}
                    onChange={(e) => setCashbox_Transfer(e.target.value)}
                    required
                />
            </Form.Group>

          
            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
            Kasa Türü Güncelle
            </Button>
        </Form>
    );
};

export default CashboxtypeUpdate;
