import React, { useEffect, useState } from 'react';
import { Form, Button } from "react-bootstrap";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

const CashboxTransfersUpdate = (props) => {
    const api = new APIClient();
    const authUser = getLoggedinUser();

    const { selectedItem, handleClose2 } = props;

    const navigate = useNavigate();

    const [kvOrKbt , setKvOrKbt ] = useState(selectedItem.kvOrKbt);
    const [document_Receipt_No , setDocument_Receipt_No ] = useState(selectedItem.document_Receipt_No );
    const [sourceCashbox , setSourceCashbox ] = useState(selectedItem.sourceCashbox);
    const [targetCashbox , setTargetCashbox] = useState(selectedItem.targetCashbox );
    const [date , setDate] = useState(selectedItem.date );
    const [amount , setAmount ] = useState(selectedItem.amount );
    const [explanation , setExplanation ] = useState(selectedItem.explanation );


    const [updatedUserId, setUpdatedUserId] = useState(authUser.Id);

    const [cashboxsourceOptions, setCashboxsourceOptions] = useState([]);
    const [selectedCashboxsourceOption, setSelectedCashboxsourceOption] = useState({ label: selectedItem.cashbox_Name, value: selectedItem.cashbox_ID });

    const [cashboxtargetOptions, setCashboxtargetOptions] = useState([]);
    const [selectedtargetCashboxOption, setSelectedCashboxtargetOption] = useState({ label: selectedItem.cashbox_Name, value: selectedItem.cashbox_ID });

  
    var companyId = authUser.CompanyId;


    useEffect(() => {
        const fetchData = async () => {
            try {
                const cashboxsourceData = await api.get("/Cashbox/GetAll?companyId="+companyId);
                const formattedCashboxsource = cashboxsourceData.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id.toString()
                }));
                setCashboxsourceOptions(formattedCashboxsource);
                
                const cashboxtargetData = await api.get("/Cashbox/GetAll?companyId="+companyId);
                const formattedCashboxtarget = cashboxtargetData.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id.toString()
                }));
                setCashboxtargetOptions(formattedCashboxtarget);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [api]);

    const handleCashboxsourceChange = (selectedOption) => {
        setSelectedCashboxsourceOption(selectedOption);
        setSourceCashbox(selectedOption ? selectedOption.value : '');
    };

    const handleCashboxatargetChange = (selectedOption) => {
        setSelectedCashboxtargetOption(selectedOption);
        setTargetCashbox(selectedOption ? selectedOption.value : '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedCashboxTransfers = {
            id: selectedItem.id,
            kvOrKbt : kvOrKbt ,
            document_Receipt_No : document_Receipt_No ,
            sourceCashbox : sourceCashbox ,
            targetCashbox : targetCashbox,
            date: date,
            amount: amount,
            explanation : explanation,
            updatedUserId: updatedUserId,
            //status: true
        };

        try {
            await api.create("/CashboxTransfers/UpdateCashboxTransfers ", updatedCashboxTransfers);
            handleClose2();
            navigate(`/KasaTransferler`);
            //window.location.href = `/KasaTransferler`;
        } catch (error) {
            console.error("Error updating cashbox collection:", error);
        }
    };

    function formatDate(date) {
        const d = new Date(date);
        return d.toISOString().split('T')[0];
    }

    return (
        <Form onSubmit={handleSubmit}>
             <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>KvOrKbt </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="KvOrKbt "
                    value={kvOrKbt}
                    onChange={(e) => setKvOrKbt(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Belge / Makbuz No</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Document Receipt No"
                    value={document_Receipt_No}
                    onChange={(e) => setDocument_Receipt_No(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>SourceCashbox  </Form.Label>
                <Select
                    value={selectedCashboxsourceOption}
                    onChange={handleCashboxsourceChange}
                    options={cashboxsourceOptions}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>TargetCashbox  </Form.Label>
                <Select
                    value={selectedtargetCashboxOption}
                    onChange={handleCashboxatargetChange}
                    options={cashboxtargetOptions}
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Tarih</Form.Label>
                <Form.Control
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Açıklama</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Explanation"
                    value={explanation}
                    onChange={(e) => setExplanation(e.target.value)}
                    required
                />
            </Form.Group>

            <Button variant="success" type="submit" style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
                Kasa Transgferi Güncelle
            </Button>
        </Form>
    );
};

export default CashboxTransfersUpdate;
