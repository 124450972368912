
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

const validationSchema = Yup.object().shape({
    main_Cost_Type: Yup.string().required('Ana Gider Türü zorunludur'),
    fixed_Costs: Yup.number().required('Sabit Giderler zorunludur'),
    general_Costs: Yup.number().required('Genel Giderler zorunludur'),
    transport_Costs: Yup.number().required('Ulaşım Giderleri zorunludur'),
    personnel_Costs: Yup.number().required('Personel Giderleri zorunludur'),
    tax_Insurance_and_Similar_Costs: Yup.number().required('Vergi, Sgk Vb. Giderler zorunludur'),
    communication_Costs: Yup.number().required('İletişim Giderleri zorunludur'),
});

const CostTypeUpdatePage = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const [main_Cost_TypeInfo, setMain_Cost_TypeInfo] = useState([]);

    useEffect(() => {
        const main_Cost_TypeData = [
            { id: 1, main_Cost_TypeName: 'İlk' },
            { id: 2, main_Cost_TypeName: 'İkinci' },
            { id: 3, main_Cost_TypeName: 'Üçüncü' }
        ];
        setMain_Cost_TypeInfo(main_Cost_TypeData);

        api.get('/CostType/GetCostTypeById?id='+_id)
        .then(res => {
            // Set your state variables here
        })
        .catch(err => console.log(err));
    }, []);

    document.title = "GiderTÜr Guncelle | İlekaSoft";

    const handleSubmit = async (values) => {
        try {
            const response = await axios.post('https://portalapi.ilekasoft.com/api/CostType/UpdateCostType', {
                id: values.id,
                main_Cost_Type: values.main_Cost_Type,
                fixed_Costs: values.fixed_Costs,
                general_Costs: values.general_Costs,
                transport_Costs: values.transport_Costs,
                personnel_Costs: values.personnel_Costs,
                tax_Insurance_and_Similar_Costs: values.tax_Insurance_and_Similar_Costs,
                communication_Costs: values.communication_Costs,
                updatedUserId: authUser.Id,
            });
    
            console.log(response.data); 
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    
            window.location.href = `/GiderTürler`;
        } catch (error) {
            console.error('GiderTür güncelleme hatası:', error);
            toast.error("Kaydedilemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="GiderTürler" breadcrumbItem="GiderTür Guncelle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={{
                                            main_Cost_Type: '',
                                            fixed_Costs: '',
                                            general_Costs: '',
                                            transport_Costs: '',
                                            personnel_Costs: '',
                                            tax_Insurance_and_Similar_Costs: '',
                                            communication_Costs: '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ errors, touched }) => (
                                            <Form>
                                                <div>
                                                    <label htmlFor="main_Cost_Type" className="form-label">Ana Gider Türü</label>
                                                    <Field as="select" className="form-control" name="main_Cost_Type" required>
                                                        <option value="">Ana Gider Türü</option>
                                                        {main_Cost_TypeInfo.map((item, key) => (
                                                            <option value={item.main_Cost_TypeName} key={key}>{item.main_Cost_TypeName}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage name="main_Cost_Type" component="div" className="text-danger" />
                                                </div>
                                                <div>
    <label htmlFor="fixed_Costs" className="form-label">Sabit Giderler</label>
    <Field type="text" className="form-control" name="fixed_Costs" />
    <ErrorMessage name="fixed_Costs" component="div" className="text-danger" />
</div>
<div>
    <label htmlFor="general_Costs" className="form-label">Genel Giderler</label>
    <Field type="text" className="form-control" name="general_Costs" />
    <ErrorMessage name="general_Costs" component="div" className="text-danger" />
</div>
<div>
    <label htmlFor="transport_Costs" className="form-label">Ulaşım Giderleri</label>
    <Field type="text" className="form-control" name="transport_Costs" />
    <ErrorMessage name="transport_Costs" component="div" className="text-danger" />
</div>
<div>
    <label htmlFor="personnel_Costs" className="form-label">Personel Giderleri</label>
    <Field type="text" className="form-control" name="personnel_Costs" />
    <ErrorMessage name="personnel_Costs" component="div" className="text-danger" />
</div>
<div>
    <label htmlFor="tax_Insurance_and_Similar_Costs" className="form-label">Vergi, SGK vb. Giderler</label>
    <Field type="text" className="form-control" name="tax_Insurance_and_Similar_Costs" />
    <ErrorMessage name="tax_Insurance_and_Similar_Costs" component="div" className="text-danger" />
</div>
<div>
    <label htmlFor="communication_Costs" className="form-label">İletişim Giderleri</label>
    <Field type="text" className="form-control" name="communication_Costs" />
    <ErrorMessage name="communication_Costs" component="div" className="text-danger" />
</div>
                                                <button type="submit" className="btn btn-primary waves-effect waves-light">Güncelle</button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CostTypeUpdatePage;





{/*import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'; 
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

const CostTypeUpdatePage = () => {
    //gelen id parametresini al
    const api = new APIClient();
    const authUser = getLoggedinUser();

    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");
    
    const [id, setId] = useState("");
    const [main_Cost_Type, setMain_Cost_Type] = useState("");
    const [fixed_Costs, setFixed_Costs] = useState("");
    const [general_Costs, setGeneral_Costs] = useState("");
    const [transport_Costs, setTransport_Costs] = useState("");
    const [personnel_Costs, setPersonnel_Costs] = useState("");
    const [tax_Insurance_and_Similar_Costs, setTax_Insurance_and_Similar_Costs] = useState("");
    const [communication_Costs, setCommunication_Costs] = useState("");

    const [main_Cost_TypeInfo, setMain_Cost_TypeInfo] = useState([]);

    const [updatedUserId, setUpdatedUserId] = useState(authUser.Id);


    useEffect(() => {
        // Para birimi bilgilerini ayarla
        const main_Cost_TypeData = [
            { id: 1, main_Cost_TypeName: 'İlk' },
            { id: 2, main_Cost_TypeName: 'İkinci' },
            { id: 3, main_Cost_TypeName: 'Üçüncü' }
        ];
        setMain_Cost_TypeInfo(main_Cost_TypeData);

        // Gider türü bilgilerini getir
        api.get('/CostType/GetCostTypeById?id='+_id)
        .then(res => {
            setId(res.id);
            setMain_Cost_Type(res.main_Cost_Type);
            setFixed_Costs(res.fixed_Costs);
            setGeneral_Costs(res.general_Costs);
            setTransport_Costs(res.transport_Costs);
            setPersonnel_Costs(res.personnel_Costs);
            setTax_Insurance_and_Similar_Costs(res.tax_Insurance_and_Similar_Costs);
            setCommunication_Costs(res.communication_Costs);
        })
        .catch(err => console.log(err));
    }, []);

    document.title = "GiderTÜr Guncelle | İlekaSoft";

    const handleSubmit = async () => {
        try {
            const response = await axios.post('https://localhost:7199/api/CostType/UpdateCostType', {
                id: id,
                main_Cost_Type: main_Cost_Type,
                fixed_Costs: fixed_Costs,
                general_Costs: general_Costs,
                transport_Costs: transport_Costs,
                personnel_Costs: personnel_Costs,
                tax_Insurance_and_Similar_Costs: tax_Insurance_and_Similar_Costs,
                communication_Costs: communication_Costs,
                updatedUserId: updatedUserId,
                //status:true


            });
    
            console.log(response.data); 
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    
            window.location.href = `/GiderTürler`;
        } catch (error) {
            console.error('GiderTür güncelleme hatası:', error);
            toast.error("Kaydedilemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });
        }
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        // name değerine göre ilgili state'i güncelle
        switch (name) {
            case "Main_Cost_Type":
                setMain_Cost_Type(value);
                break;
            case "Fixed_Costs":
                setFixed_Costs(value);
                break;
            case "General_Costs":
                setGeneral_Costs(value);
                break;
            case "Transport_Costs":
                setTransport_Costs(value);
                break;
            case "Personnel_Costs":
                setPersonnel_Costs(value);
                break;
            case "Tax_Insurance_and_Similar_Costs":
                setTax_Insurance_and_Similar_Costs(value);
                break;
            case "Communication_Costs":
                setCommunication_Costs(value);
                break;
            default:
                break;
        }
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="GiderTürler" breadcrumbItem="GiderTür Guncelle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                   
                                 {/*   <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Main_Cost_Type 
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Main_Cost_Type"
                                        value={CostType.main_Cost_Type}
                                        onChange={handleChange}
    />*/}


    {/*
    <div>
                                        <label htmlFor="Main_Cost_Type" className="form-label">Ana Gider Türü</label>
                                        <select className="form-control" name="Main_Cost_Type" value={main_Cost_Type} onChange={handleChange} required>
                                            <option value="">Ana Gider Türü</option>
                                            {main_Cost_TypeInfo.map((item, key) => (
                                                <option value={item.main_Cost_TypeName} key={key}>{item.main_Cost_TypeName}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Sabit Giderler    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Fixed_Costs"
                                        value={fixed_Costs}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Genel Giderler    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="General_Costs"
                                        value={general_Costs}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Ulaşım Giderleri
    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Transport_Costs"
                                        value={transport_Costs}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Personel Giderleri    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Personnel_Costs"
                                        value={personnel_Costs}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Vergi, Sgk Vb. Giderler  
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Tax_Insurance_and_Similar_Costs"
                                        value={tax_Insurance_and_Similar_Costs}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    İletişim Giderleri  
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Communication_Costs"
                                        value={communication_Costs}
                                        onChange={handleChange}
                                    />
                                 
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Güncelle
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CostTypeUpdatePage;

*/}