import React from "react";
import Routes from "./Routes/index";
import '@fortawesome/fontawesome-free/css/all.min.css';

// Import Scss
import './assets/scss/theme.scss';

// Fake Backend 
import fakeBackend from "./helpers/AuthType/fakeBackend";
import authProcess from "./helpers/AuthType/authProcess";

// Activating fake backend
// authProcess()



function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
