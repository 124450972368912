import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { toast } from 'react-toastify';

export const ModuleAddModal = ({user,addChange}) => {
    const [open, setOpen] = useState(false);
    const [authUser,setAuthUser] = useState(user);
    function openModal() {
        setOpen(!open)
        console.log(authUser)
    }
    const api = new APIClient();
    const handleChange=()=>{
        addChange()
    }
    const [moduleName, setModuleName] = useState("");
    const [demoTime, setDemoTime] = useState("");
    const [salesPrice,setSalesPrice] = useState("");
    useEffect(()=>{
        setAuthUser(user)
    },[user])
    const Module={
        moduleName:moduleName,
        demoTime:demoTime,
        salesPrice:salesPrice,
        companyId: authUser.CompanyId ,
        createdUserId: authUser.Id,
        updatedUserId: authUser.Id,
        status:true,
    }
 
    const handleSubmit = () => {
        api.create("/Module/AddModule", Module).then(res => {
            openModal()
            handleChange()
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    }).catch(e => console.log(e))
    toast.error("Kaydedilemedi", {
        position: "bottom-right",
        autoClose: 10000 
    });

    }
    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col>

                            <div className="d-flex gap-1">
                                <Button color="success" className="add-btn" onClick={() => {
                                    openModal();
                                }} id="create-btn"><i className="ri-add-line align-bottom me-1"></i>Ekle</Button>
                                {/* <Button color="soft-danger"
                                // onClick="deleteMultiple()"
                                ><i className="ri-delete-bin-2-line"></i></Button> */}
                      
                      
                
                                </div>


                            <Modal
                                isOpen={open}
                                toggle={() => {
                                    openModal();
                                }}

                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                         Ekle
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                                Modül Adı 
                                            </label>
                                            <input className="form-control" type="text" placeholder='Modül Adı Giriniz' onChange={x => setModuleName(x.target.value)} />

                                        </div>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                               Demo Süresi
                                            </label>
                                            <input className="form-control" type="text" placeholder='Demo Süresi Giriniz' onChange={x => setDemoTime(x.target.value)} />

                                        </div>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="groupName"
                                                className="col-form-label"
                                            >
                                               Satış Fiyatı
                                            </label>
                                            <input className="form-control" type="text" placeholder='Satış Fiyatı Giriniz' onChange={x => setSalesPrice(x.target.value)} />

                                        </div>

                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        Kapat
                                    </button>
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary">
                                        Kaydet
                                    </button>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}



