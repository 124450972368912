import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Input, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const CashboxtransactionsAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const [cashbox, setCashbox] = useState([]);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [paymentOrCollection, setPaymentOrCollection] = useState('');

    const companyId = authUser.CompanyId;

    useEffect(() => {
        api.get("/Cashbox/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id
            }));
            setCashbox(formattedOptions);
        });
        api.get("/CurrentAccount/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id
            }));
            setCurrentAccount(formattedOptions);
        });
    }, [companyId]);

    const validationSchema = Yup.object().shape({
        PaymentOrCollection: Yup.string().required('Bu alan gereklidir'),
        Document_Receipt_No: Yup.string().required('Bu alan gereklidir'),
        Cashbox_ID: Yup.string().required('Bu alan gereklidir'),
        Date: Yup.date().required('Bu alan gereklidir'),
        Current_ID: Yup.string().required('Bu alan gereklidir'),
        Explanation: Yup.string().required('Bu alan gereklidir'),
        Amount: Yup.number()
            .typeError('Bu alan gereklidir')
            .required('Bu alan gereklidir')
            .positive('Pozitif bir değer olması gerekmektedir'),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        const cashboxtransactions = {
            ...values,
            CompanyId: authUser.CompanyId,
            CreatedUserId: authUser.Id,
            status: true
        };
        api.create("/Cashboxtransactions/AddCashboxtransactions", cashboxtransactions)
            .then(res => {
                console.log("başarılı");
                navigate(`/KasaIslemleri`);
                //window.location.href = `/KasaIslemleri`;
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
                setSubmitting(false);
            });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Kasa İşlemi Ekles" breadcrumbItem="Kasa İşlemleri" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={{
                                            PaymentOrCollection: '',
                                            Document_Receipt_No: '',
                                            Cashbox_ID: '',
                                            Date: '',
                                            Current_ID: '',
                                            Explanation: '',
                                            Amount: '' // 0,
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ setFieldValue, isSubmitting, values }) => (
                                            <Form>
                                                <FormGroup>
                                                    <Label for="PaymentOrCollection">Ödeme / Tahsilat</Label>
                                                    <Field
                                                        as="select"
                                                        name="PaymentOrCollection"
                                                        id="PaymentOrCollection"
                                                        className="form-control"
                                                        onChange={e => {
                                                            setFieldValue('PaymentOrCollection', e.target.value);
                                                            setPaymentOrCollection(e.target.value);
                                                        }}
                                                    >
                                                        <option value="" label="Seçiniz" />
                                                        <option value="Kasa Ödeme" label="Kasa Ödeme" />
                                                        <option value="Tahsilat" label="Tahsilat" />
                                                    </Field>
                                                    <ErrorMessage name="PaymentOrCollection" component="div" className="text-danger" />
                                                </FormGroup>

                                                {paymentOrCollection && (
                                                    <>
                                                        <FormGroup>
                                                            <Label for="Document_Receipt_No">Belge ve Makbuz Numarası</Label>
                                                            <Field
                                                                type="text"
                                                                name="Document_Receipt_No"
                                                                id="Document_Receipt_No"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Document_Receipt_No" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <div className="mb-3">
                                                            <Label for="Cashbox_ID" className="form-label">Kasa</Label>
                                                            <Select
                                                                name="Cashbox_ID"
                                                                options={cashbox}
                                                                onChange={option => setFieldValue('Cashbox_ID', option ? option.value : '')}
                                                                placeholder="Kasa Seçiniz"
                                                            />
                                                            <ErrorMessage name="Cashbox_ID" component="div" className="text-danger" />
                                                        </div>
                                                        <FormGroup>
                                                            <Label for="Date">Tarih</Label>
                                                            <Field
                                                                type="date"
                                                                name="Date"
                                                                id="Date"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Date" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <div className="mb-3">
                                                            <Label for="Current_ID" className="form-label">Cari</Label>
                                                            <Select
                                                                name="Current_ID"
                                                                options={currentAccount}
                                                                onChange={option => setFieldValue('Current_ID', option ? option.value : '')}
                                                                placeholder="Cari Seçiniz"
                                                            />
                                                            <ErrorMessage name="Current_ID" component="div" className="text-danger" />
                                                        </div>
                                                        <FormGroup>
                                                            <Label for="Explanation">Açıklama</Label>
                                                            <Field
                                                                type="textarea"
                                                                name="Explanation"
                                                                id="Explanation"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Explanation" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="Amount">Tutar</Label>
                                                            <Field
                                                                type="number"
                                                                name="Amount"
                                                                id="Amount"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Amount" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <Button color="primary" type="submit" disabled={isSubmitting}>Kaydet</Button>
                                                    </>
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CashboxtransactionsAdd;



{/*import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Input, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';

const CashboxtransactionsAdd = () => {
    const api =new APIClient();
    const authUser = getLoggedinUser();


    const [cashboxtransactions, setCashboxtransactions] = useState({
        PaymentOrCollection: 0,
        Document_Receipt_No: '',
        Cashbox_ID: '',
        Date: '',
        Current_ID: 0,
        Explanation: '',
        Amount: 0,
        CompanyId: authUser.CompanyId,
CreatedUserId: authUser.Id,
status:true

    });


    const [selectCashbox, setSelectCashbox] = useState(null);
    const [cashbox, setCashbox] = useState([]);
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);

    var companyId = authUser.CompanyId;

    useEffect(() => {
            api.get("/Cashbox/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.cashbox_Name,
                value: item.id.toString()
            }));
            setCashbox(formattedOptions);
        });
        api.get("/CurrentAccount/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id.toString()
            }));
            setCurrentAccount(formattedOptions);
        });
    }, []);

    const handleCashbox = (selectedOption) => {
        setSelectCashbox(selectedOption);
        setCashboxtransactions({ ...cashboxtransactions, Cashbox_ID: selectedOption ? selectedOption.value : '' });
    };

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCashboxtransactions({ ...cashboxtransactions, Current_ID: selectedOption ? selectedOption.value : '' });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCashboxtransactions({ ...cashboxtransactions, [name]: value });
    };

 


    const handleSubmit = () => {
        api.create("/Cashboxtransactions/AddCashboxtransactions", cashboxtransactions)
            .then(res => {
                console.log("başarılı");
                window.location.href = `/KasaIslemleri`;
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                // Navigate("/cariler") // Yorum satırı, gerekirse açabilirsiniz
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            });
    }

    function formatDate(date) {
        const d = new Date(date);
        return d.toISOString().split('T')[0];
    }

    return (
        <React.Fragment>
              {/*
                          <div className="page-content">
  <Container fluid={true}>
                    <Breadcrumbs title="KasaTahsilatlar" breadcrumbItem="KasaTahsilat Ekle" />
                    <Row>
                        <Col>
                            <Card>
    <CardBody>*/}
    {/*}
    <FormGroup>
                                        <Label for="PaymentOrCollection">Ödeme / Tahsilat</Label>
                                        <Input
                                            type="text"
                                            name="PaymentOrCollection"
                                            id="PaymentOrCollection"
                                            value={cashboxtransactions.PaymentOrCollection}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="Document_Receipt_No">Belge ve Makbuz Numarası</Label>
                                        <Input
                                            type="text"
                                            name="Document_Receipt_No"
                                            id="Document_Receipt_No"
                                            value={cashboxtransactions.Document_Receipt_No}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                   {/* <FormGroup>
                                        <Label for="Cashbox_ID">Cashbox_ID</Label>
                                        <Input
                                            type="text"
                                            name="Cashbox_ID"
                                            id="Cashbox_ID"
                                            value={cashboxcollection.Cashbox_ID}
                                            onChange={handleChange}
                                        />
    </FormGroup>*/} {/*}

<div className="mb-3">
                                        <label htmlFor="Cashbox_ID" className="form-label">Kasa</label>
                                        <Select
                                            value={selectCashbox}
                                            onChange={handleCashbox}
                                            options={cashbox}
                                            placeholder="CashboxId Seç"
                                        />
                                    </div>
                                    <FormGroup>
                                        <Label for="Date">Tarih</Label>
                                        <Input
                                            type="date"
                                            name="Date"
                                            id="Date"
                                            value={cashboxtransactions.Date}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                   {/* <FormGroup>
                                        <Label for="Current_ID">Current_ID</Label>
                                        <Input
                                            type="number"
                                            name="Current_ID"
                                            id="Current_ID"
                                            value={cashboxcollection.Current_ID}
                                            onChange={handleChange}
                                        />
</FormGroup>*/}{/*}
<div className="mb-3"> 
                                        <label htmlFor="Current_ID" className="form-label">Cari</label>
                                        <Select
                                            value={selectCurrentAccount}
                                            onChange={handleCurrentAccount}
                                            options={currentAccount}
                                            placeholder="CurrentAccount Seç"
                                        />
    </div>
                                    <FormGroup>
                                        <Label for="Explanation">Açıklama</Label>
                                        <Input
                                            type="textarea"
                                            name="Explanation"
                                            id="Explanation"
                                            value={cashboxtransactions.Explanation}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="Amount">Tutar</Label>
                                        <Input
                                            type="textarea"
                                            name="Amount"
                                            id="Amount"
                                            value={cashboxtransactions.Amount}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    <Button color="primary" onClick={handleSubmit}>Kaydet</Button>
                               {/* </CardBody>
                            </Card>
                        </Col>
                    </Row>
                                </div>

</Container>*/} {/*}
        </React.Fragment>
    );
};

export default CashboxtransactionsAdd;
*/}
