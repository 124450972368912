import React, { useState, useEffect } from 'react';
import { Card, Col, Container, Row, CardBody, FormGroup, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import Breadcrumbs from "../../Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../../helpers/api_helper';
import './TableListModal.css';

const TableListModal = (props) => {
    const [selectedCashbox, setSelectedCashbox] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [cashboxes, setCashboxes] = useState([]);
    const [totalEntries, setTotalEntries] = useState(0);
    const [totalExits, setTotalExits] = useState(0);
    const [remainingBalance, setRemainingBalance] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showDetail, setShowDetail] = useState(false);

    const api = new APIClient();

    useEffect(() => {
        if (Array.isArray(props.companyArray)) {
            setFilteredData([...props.companyArray]);
        } else {
            console.error('props.companyArray is not an array:', props.companyArray);
        }
    }, [props.companyArray]);

    const authUser = getLoggedinUser();
    const companyId = authUser.CompanyId;

    useEffect(() => {
        api.get(`/Cashbox/GetAll?companyId=${companyId}`).then(res => {
            if (Array.isArray(res)) {
                const formattedOptions = res.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id
                }));
                // Add the "Tümü" option at the beginning of the array
                formattedOptions.unshift({ label: 'Tümü', value: '' });
                setCashboxes(formattedOptions);
            } else {
                console.error('API response is not an array:', res);
            }
        }).catch(err => {
            console.error('API call failed:', err);
        });
    }, [companyId]);

    useEffect(() => {
        calculateTotals();
    }, [filteredData]);

    const handleCashboxChange = (selectedOption) => {
        setSelectedCashbox(selectedOption);
        if (selectedOption.value === '') {
            // "Tümü" seçeneği seçildiğinde tüm veriyi göster
            setFilteredData([...props.companyArray]);
        } else {
            filterData(selectedOption.value);
        }
    };

    const filterData = (cashboxId) => {
        if (Array.isArray(props.companyArray)) {
            const filtered = props.companyArray.filter(item => item.cashbox_Id === cashboxId);
            setFilteredData(filtered);
        } else {
            console.error('props.companyArray is not an array:', props.companyArray);
        }
    };

    const calculateTotals = () => {
        let totalEntries = 0;
        let totalExits = 0;

        filteredData.forEach(item => {
            totalEntries += parseFloat(item.entered) || 0;
            totalExits += parseFloat(item.exited) || 0;
        });

        setTotalEntries(totalEntries);
        setTotalExits(totalExits);
        setRemainingBalance(totalEntries - totalExits);
    };

    const handleShowDetail = (item) => {
        setSelectedItem(item);
        setShowDetail(true);
    };

    const handleCloseDetail = () => {
        setShowDetail(false);
        setSelectedItem(null);
    };

    return (
        <Container fluid>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Row className="mb-3">
                                <Col lg={4}>
                                    <Card className="bg-info text-white">
                                        <CardBody className="d-flex justify-content-center align-items-center">
                                            <h5 style={{ color: 'white', marginBottom: '0' }}>Toplam Giriş: <span>{totalEntries} TL</span></h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={4}>
                                    <Card className="bg-danger text-white">
                                        <CardBody className="d-flex justify-content-center align-items-center">
                                            <h5 style={{ color: 'white', marginBottom: '0' }}>Toplam Çıkış: <span>{totalExits} TL</span></h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={4}>
                                    <Card className="bg-success text-white">
                                        <CardBody className="d-flex justify-content-center align-items-center">
                                            <h5 style={{ color: 'white', marginBottom: '0' }}>Kalan Bakiye: <span>{remainingBalance} TL</span></h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Row className="g-4 mb-3">
                                <Col className="col-sm-auto">
                                    <FormGroup>
                                        <Select
                                            value={selectedCashbox}
                                            options={cashboxes}
                                            onChange={handleCashboxChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col className="col-sm">
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="table-responsive table-card mt-3 mb-1">
                                <table className="table align-middle table-nowrap" id="customerTable">
                                    <thead className="table-light">
                                        <tr>
                                           {/* <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                </div>
                                            </th>*/}
                                            {props.tableHeadArray && props.tableHeadArray.map((element, index) => (
                                                <th key={index} className="sort" data-sort={element}>{element}</th>
                                            ))}
                                            <th>İşlemler</th> {/* Yeni sütun başlığı */}
                                        </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                        {Array.isArray(filteredData) && filteredData.map((item) => (
                                            <tr key={item.id}>
                                              {/*}  <th scope="row">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                                    </div>
                                                </th> */}
                                                {Object.keys(item).map((key) => {
                                                    if (['date', 'cashbox_Name', 'document_Receipt_No', 'type', 'entered', 'exited', 'invoiceNumber', 'costName', 'promissorry_Note_Portfolio_No', 'bankName', 'currentAccounName', 'checkPortfolio_No', 'transferName', 'explanation'].includes(key)) {
                                                        return <td key={key} data-sort={key}>{item[key]}</td>;
                                                    }
                                                    return null;
                                                })}
                                                <td>
                                                    <div className="editthree">
                                                        <button className="btn btn-sm btn-info detail-item-btn" onClick={() => handleShowDetail(item)}>Detay</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal isOpen={showDetail} toggle={handleCloseDetail} size="lg" centered>
                <ModalHeader toggle={handleCloseDetail}>Detaylar</ModalHeader>
                <ModalBody>
                    <div className="modal-content">
                        {selectedItem && (
                            <>
                                {/* Display item details here */}
                                <div className="modal-item">
                                <p className="modal-item"><strong>Tarih:</strong> {selectedItem?.date}</p>
                                <p className="modal-item"><strong>Kasa:</strong> {selectedItem?.cashbox_Id}</p>                                </div>
                                <p className="modal-item"><strong>Belge / Makbuz No	:</strong> {selectedItem?.document_Receipt_No}</p>
                                <p className="modal-item"><strong>Tür:</strong> {selectedItem?.type}</p>
                                <p className="modal-item"><strong>Giriş:</strong> {selectedItem?.entered}</p>
                                <p className="modal-item"><strong>Çıkış:</strong> {selectedItem?.exited}</p>
                                <p className="modal-item"><strong>Fatura Numarası:</strong> {selectedItem?.invoiceId}</p>
                                <p className="modal-item"><strong>Gider İsmi:</strong> {selectedItem?.costId}</p>
                                <p className="modal-item"><strong>Senet Portöy No:</strong> {selectedItem?.promissory_NotesId}</p>
                                <p className="modal-item"><strong>Banka Adı:</strong> {selectedItem?.bankId}</p>
                                <p className="modal-item"><strong>Cari Adı:</strong> {selectedItem?.currentAccountId}</p>
                                <p className="modal-item"><strong>Çek Portföy No:</strong> {selectedItem?.checkPortfolioId}</p>
                                <p className="modal-item"><strong>Virman Adı:</strong> {selectedItem?.transferId}</p>
                                <p className="modal-item"><strong>Açıklama:</strong> {selectedItem?.explanation}</p>

                                {/* Add more item details as needed */}
                            </>
                        )}
                    </div>
                </ModalBody>
            </Modal>

            <style jsx>{`
                .modal-content {
                    padding: 20px;
                }
                .modal-item {
                    margin-bottom: 15px;
                    font-size: 16px;
                }
                .modal-title {
                    font-size: 20px;
                    font-weight: bold;
                }
                #detayheader {
                    margin-left: 15px;
                    margin-right: 15px;
                }
            `}</style>
        </Container>
    );
};

export default TableListModal;
