import React, { useState, useEffect } from 'react';
// import { CurrentGroupUpdateModal } from './CurrentGroupUpdateModal';
// import { CurrentGroupDeleteModal } from './CurrentGroupDeleteModal';
import { CurrentAccountDeleteModal } from './CurrentAccountDeleteModal';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
export const CurrentAccountTableList = ({ user,isChange,userGrupInfo }) => {
    console.log(userGrupInfo,"userGrupInfo");
    console.log('idsi geliyormuymuş');
    console.log(userGrupInfo);

    const [selectedItem, setSelectedItem] = useState();
    
  const [showDetail, setShowDetail] = useState(false);


  const handleCloseDetail = () => setShowDetail(false);
      const handleShowDetail = (item) => {
          setShowDetail(true);
          setSelectedItem(item);
      };


    return (
        <React.Fragment>
            <table className="table align-middle table-nowrap" id="customerTable">
                <thead className="table-light">
                    <tr>
                        <th scope="col" style={{ width: "50px" }}>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                            </div>
                        </th>

                        <th className="sort" data-sort="id">No</th>
                        <th className="sort" data-sort="groupName">Cari Adı</th>
                        <th className="sort" data-sort="groupName">Cari Kodu</th>
                        <th className="sort" data-sort="groupName">TCKN / VKN</th>
                        <th className="sort" data-sort="groupName">E-Mail</th>
                        <th className="sort" data-sort="groupName">Telefon No</th>

                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody className="list form-check-all" >
                    {
                    userGrupInfo.map((item, index) => (
                        <tr key={item.id}>
                            <th scope="row">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                </div>
                            </th>

                            <td data-sort="groupName">{index + 1}</td>
                            <td data-sort="currentName">{item.currentName}</td>
                            <td data-sort="currentCode">{item.currentCode}</td>
                            <td data-sort="currentTCKN_VKN">{item.currentTCKN_VKN}</td>
                            <td data-sort="currentEmail">{item.currentEmail}</td>
                            <td data-sort="currentPhoneNumber">{item.currentPhoneNumber}</td>

                            <td style={{ width: "200px" }}>
                                <div className="d-flex gap-2" >
                                    <div className="edit">
                                    <Link color="warning" className="btn btn-sm btn-warning edit-item-btn" to={"/Cariler/CariGuncelle/"+item.id}>
                                        Düzenle
                                        </Link>
                                        
                                    
                                       {/* <CurrentGroupUpdateModal user={user} group={item} isChange={isChange}/> */}
                                    </div>
                                    <div className="remove">
                                        <CurrentAccountDeleteModal id={item.id} isChange={isChange}/>
                                    </div>
                                    <div className="editthree">
                                                                        <button className="btn btn-sm btn-info detail-item-btn" onClick={() => handleShowDetail(item)}>Detay</button>
                                                                    </div>

                                </div>
                            </td>  
                        </tr>
                    ))
                    }
            </tbody>
        </table>
        <Modal show={showDetail} onHide={handleCloseDetail} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header id="detayheader" className="modal-header" closeButton>
        <Modal.Title className="modal-title">Detaylar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="modal-content">
            <p className="modal-item"><strong>Cari Türü:</strong> {selectedItem?.currentType}</p>
            <p className="modal-item"><strong>Cari Kodu:</strong> {selectedItem?.currentCode}</p>
            <p className="modal-item"><strong>Cari Adı:</strong> {selectedItem?.currentFirstName}</p>
            <p className="modal-item"><strong>Cari Soyadı:</strong> {selectedItem?.currentLastName}</p>
            <p className="modal-item"><strong>Cari Ünvanı:</strong> {selectedItem?.currentName}</p>
            <p className="modal-item"><strong>TCKN/VKN No:</strong> {selectedItem?.currentTCKN_VKN}</p>
            <p className="modal-item"><strong>Vergi Dairesi:</strong> {selectedItem?.currentTaxAdministration}</p>
            <p className="modal-item"><strong>Cari Adresi:</strong> {selectedItem?.currentAddress}</p>
            <p className="modal-item"><strong>Cari Email:</strong> {selectedItem?.currentEmail}</p>
            <p className="modal-item"><strong>Cari Telefon Numarası:</strong> {selectedItem?.currentPhoneNumber}</p>
            <p className="modal-item"><strong>Cari Web Sitesi:</strong> {selectedItem?.currentWebsite}</p>
            <p className="modal-item"><strong>Cari Grup:</strong> {selectedItem?.currentGroup_ID}</p>
            <p className="modal-item"><strong>İl:</strong> {selectedItem?.currentProvince_ID}</p>
            <p className="modal-item"><strong>İlçe:</strong> {selectedItem?.currentDistrict_ID}</p>
        </div>
    </Modal.Body>
</Modal>

<style jsx>{`
    .modal-content {
        padding: 20px;
    }
    .modal-item {
        margin-bottom: 15px;
        font-size: 16px;
    }
    .modal-title {
        font-size: 20px;
        font-weight: bold;
    }
        #detayheader{
                margin-left: 15px;
                margin-right: 15px;

        }
`}</style>


        </React.Fragment >
    )
}
