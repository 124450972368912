import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { APIClient } from '../../helpers/api_helper'
export const District = ({ selectedOption,handleChange,province}) => {
    const api = new APIClient()
    const [districtOption,setDistrictOption] = useState([])

    useEffect(()=>{
        if (province) {
            api.get("/District/GetDistrictByProvinceId?id="+province.value).then(res=>{

                const formattedOptions  = res.map(c=>({
                    value: c.districtId,
                    label: c.districtName       
                }))
                setDistrictOption(formattedOptions)
            })
        }
    },[province])
 const turkishCharacterRegex = keyword => keyword
  .replace(/[ıİiI]/g, '[ıİiI]')
  .replace(/[şŞsS]/g, '[şŞsS]')
  .replace(/[çÇcC]/g, '[çÇcC]')
  .replace(/[ğĞgG]/g, '[ğĞgG]')
  .replace(/[öÖoO]/g, '[öÖoO]')
  .replace(/[üÜuU]/g, '[üÜuU]');


 const customFilter = (option, searchText) =>((turkishCharacterRegex(option.data.label)).toLowerCase().includes(turkishCharacterRegex(searchText).toLowerCase()));
  return (


 <Select
      value={selectedOption}
       onChange={handleChange}
      options={districtOption} 
      isSearchable={true}
      filterOption={customFilter}
      placeholder={selectedOption ? "" : "İlçe Seç"}

      />
   
  )
  
}
