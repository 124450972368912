import React, { useEffect, useState } from 'react';
import { Form, Button } from "react-bootstrap";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
const CashboxtransactionsUpdate = (props) => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    const { selectedItem, handleClose2 } = props;

    const [paymentOrCollection, setPaymentOrCollection] = useState(selectedItem.paymentOrCollection);
    const [documentReceiptNo, setDocumentReceiptNo] = useState(selectedItem.document_Receipt_No);
    const [cashboxID, setCashboxID] = useState(selectedItem.cashbox_ID);
    const [date, setDate] = useState(formatDate(selectedItem.date));
    const [currentID, setCurrentID] = useState(selectedItem.current_ID);
    const [explanation, setExplanation] = useState(selectedItem.explanation);
    const [amount, setAmount] = useState(selectedItem.amount);

    const [updatedUserId, setUpdatedUserId] = useState(authUser.Id);

    const [cashboxOptions, setCashboxOptions] = useState([]);
    const [selectedCashboxOption, setSelectedCashboxOption] = useState({ label: selectedItem.cashbox_Name, value: selectedItem.cashbox_ID });

    const [currentAccountOptions, setCurrentAccountOptions] = useState([]);
    const [selectedAccountOption, setSelectedAccountOption] = useState({ label: selectedItem.current_Name, value: selectedItem.current_ID });

    var companyId = authUser.CompanyId;


    useEffect(() => {
        const fetchData = async () => {
            try {
                const cashboxData = await api.get("/Cashbox/GetAll?companyId="+companyId);
                const formattedCashbox = cashboxData.map(item => ({
                    label: item.cashbox_Name,
                    value: item.id.toString()
                }));
                setCashboxOptions(formattedCashbox);
                
                const currentAccountData = await api.get("/CurrentAccount/GetAll?companyId="+companyId);
                const formattedCurrentAccount = currentAccountData.map(item => ({
                    label: item.currentName,
                    value: item.id.toString()
                }));
                setCurrentAccountOptions(formattedCurrentAccount);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [api]);

    const handleCashboxChange = (selectedOption) => {
        setSelectedCashboxOption(selectedOption);
        setCashboxID(selectedOption ? selectedOption.value : '');
    };

    const handleCurrentAccountChange = (selectedOption) => {
        setSelectedAccountOption(selectedOption);
        setCurrentID(selectedOption ? selectedOption.value : '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedCashboxtransactions = {
            id: selectedItem.id,
        paymentOrCollection: paymentOrCollection,
            document_Receipt_No: documentReceiptNo,
            cashbox_ID: cashboxID,
            date: date,
            current_ID: currentID,
            explanation: explanation,
            amount: amount,
            updatedUserId: updatedUserId,
            //status: true
        };

        try {
            await api.create("/Cashboxtransactions/UpdateCashboxtransactions", updatedCashboxtransactions);
            handleClose2();
            navigate(`/KasaIslemleri`);
            //window.location.href = `/KasaIslemleri`;
        } catch (error) {
            console.error("Error updating cashbox collection:", error);
        }
    };

    function formatDate(date) {
        const d = new Date(date);
        return d.toISOString().split('T')[0];
    }

    return (
        <Form onSubmit={handleSubmit}>

<Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>PaymentOrCollection</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="PaymentOrCollection"
                    value={paymentOrCollection}
                    onChange={(e) => setPaymentOrCollection(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Belge / Makbuz No</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Document Receipt No"
                    value={documentReceiptNo}
                    onChange={(e) => setDocumentReceiptNo(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Kasa </Form.Label>
                <Select
                    value={selectedCashboxOption}
                    onChange={handleCashboxChange}
                    options={cashboxOptions}
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Tarih</Form.Label>
                <Form.Control
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Cari </Form.Label>
                <Select
                    value={selectedAccountOption}
                    onChange={handleCurrentAccountChange}
                    options={currentAccountOptions}
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Açıklama</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Explanation"
                    value={explanation}
                    onChange={(e) => setExplanation(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                />
            </Form.Group>

            <Button variant="success" type="submit" style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
                Kasa İşlemi Güncelle
            </Button>
        </Form>
    );
};

export default CashboxtransactionsUpdate;
