import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Input, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const CheckPortfolioAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();
    
    const [bank, setBank] = useState([]);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [movement_Type, setMovement_Type] = useState('');

    const [currentAccountName, setCurrentAccountName] = useState('');
    const [companyName, setCompanyName] = useState(""); 
    const companyId = authUser.CompanyId;

    useEffect(() => {
        api.get("/Company/GetCompanyById?id=" + companyId)
            .then(res => {
                const companyNameFromApi = res.companyName;
                setCompanyName(companyNameFromApi);
            })
            .catch(error => {
                console.error("Error fetching company data:", error);
            });
    }, [companyId]);

    const [fileInput, setFileInput] = useState(null);

    useEffect(() => {
        api.get("/Bank/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.id
            }));
            setBank(formattedOptions);
        });
        api.get("/CurrentAccount/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id,
                currentName: item.currentName
            }));
            setCurrentAccount(formattedOptions);
        });
    }, [companyId]);

    const validationSchema = Yup.object().shape({
        Movement_Type: Yup.string().required('Bu alan gereklidir'),
        Current_ID: Yup.string().required('Bu alan gereklidir'),
        Registration_Date: Yup.string().required('Bu alan gereklidir'),
        Explanation: Yup.string().required('Bu alan gereklidir'),
        Portfolio_No: Yup.string().required('Bu alan gereklidir'),
        Serial_Number: Yup.string().required('Bu alan gereklidir'),
        Expiry_Date: Yup.string().required('Bu alan gereklidir'),
        Bank_Id: Yup.string().required('Bu alan gereklidir'),
        Bank_Branch: Yup.string().required('Bu alan gereklidir'),
        Amount: Yup.number().required('Bu alan gereklidir'),
        Check_Picture: Yup.mixed().required('Bu alan gereklidir') // Add validation for Check_Picture
    });

    const handleFileChange = (e, setFieldValue) => {
        const file = e.target.files[0];
        setFileInput(file);
        setFieldValue('Check_Picture', file); // Set the field value to trigger validation
    };

    const handleSubmit = (values, { setSubmitting }) => {
        let dataToSend = { ...values };
    
        if (fileInput) {
            convertToBase64(fileInput).then(base64String => {
                dataToSend = {
                    ...dataToSend,
                    Check_Picture: base64String,
                    CompanyId: authUser.CompanyId,
                    CreatedUserId: authUser.Id,
                    status: true,
                    debtor: movement_Type === "Alınan Çek" ? currentAccountName : companyName
                };
    
                api.create("/CheckPortfolio/AddCheckPortfolio", dataToSend)
                    .then(res => {
                        navigate(`/ÇekPortfoyler`);
                        toast.success("Başarıyla kaydedildi", {
                            position: "bottom-right",
                            autoClose: 10000
                        });
                        setSubmitting(false);
                    })
                    .catch(err => {
                        console.error("Hata: ", err);
                        toast.error("Kaydedilemedi", {
                            position: "bottom-right",
                            autoClose: 10000
                        });
                        setSubmitting(false);
                    });
            });
        } else {
            setSubmitting(false);
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = error => reject(error);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Çek PortFöy Ekle" breadcrumbItem="Çek Portföyler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={{
                                            Movement_Type: '',
                                            Current_ID: '',
                                            Debtor: '',
                                            Registration_Date: '',
                                            Explanation: '',
                                            Portfolio_No: '',
                                            Serial_Number: '',
                                            Expiry_Date: '',
                                            Bank_Id: '',
                                            Bank_Branch: '',
                                            Amount: '',
                                            Check_Picture: ''
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ setFieldValue, isSubmitting, values }) => (
                                            <Form>
                                                <FormGroup>
                                                    <Label for="Movement_Type">Hareket Tipi</Label>
                                                    <Field
                                                        as="select"
                                                        name="Movement_Type"
                                                        id="Movement_Type"
                                                        className="form-control"
                                                        onChange={e => {
                                                            setFieldValue('Movement_Type', e.target.value);
                                                            setMovement_Type(e.target.value);
                                                        }}
                                                    >
                                                        <option value="" label="Seçiniz" />
                                                        <option value="Alınan Çek" label="Alınan Çek" />
                                                        <option value="Verilen Çek" label="Verilen Çek" />
                                                    </Field>
                                                    <ErrorMessage name="Movement_Type" component="div" className="text-danger" />
                                                </FormGroup>

                                                {movement_Type && (
                                                    <>
                                                        <div className="mb-3">
                                                            <Label for="Current_ID" className="form-label">Cari</Label>
                                                            <Select
                                                                name="Current_ID"
                                                                options={currentAccount}
                                                                onChange={option => {
                                                                    setFieldValue('Current_ID', option ? option.value : '');
                                                                    setCurrentAccountName(option ? option.currentName : '');
                                                                }}
                                                                placeholder="CurrentAccount Seç"
                                                            />
                                                            <ErrorMessage name="Current_ID" component="div" className="text-danger" />
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label for="Debtor" className="form-label">Borçlu</Label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                name="Debtor"
                                                                id="Debtor"
                                                                value={movement_Type === "Alınan Çek" ? currentAccountName : companyName}
                                                                readOnly
                                                            />
                                                            <ErrorMessage name="Debtor" component="div" className="text-danger" />
                                                        </div>
                                                
                                                        <FormGroup>
                                                            <Label for="Registration_Date">Kayıt Tarihi</Label>
                                                            <Field
                                                                type="date"
                                                                name="Registration_Date"
                                                                id="Registration_Date"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Registration_Date" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="Explanation">Açıklama</Label>
                                                            <Field
                                                                type="textarea"
                                                                name="Explanation"
                                                                id="Explanation"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Explanation" component="div" className="text-danger" />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label for="Portfolio_No">Portföy Numarası</Label>
                                                            <Field
                                                                type="textarea"
                                                                name="Portfolio_No"
                                                                id="Portfolio_No"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Portfolio_No" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="Serial_Number">Seri Numarası</Label>
                                                            <Field
                                                                type="textarea"
                                                                name="Serial_Number"
                                                                id="Serial_Number"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Serial_Number" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="Expiry_Date">Vade Tarihi</Label>
                                                            <Field
                                                                type="date"
                                                                name="Expiry_Date"
                                                                id="Expiry_Date"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Expiry_Date" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <div className="mb-3">
                                                            <Label for="Bank_Id" className="form-label">Banka Adı</Label>
                                                            <Select
                                                                name="Bank_Id"
                                                                options={bank}
                                                                onChange={option => setFieldValue('Bank_Id', option ? option.value : '')}
                                                                placeholder="Banka Seç"
                                                            />
                                                            <ErrorMessage name="Bank_Id" component="div" className="text-danger" />
                                                        </div>
                                                        <FormGroup>
                                                            <Label for="Bank_Branch">Banka Şubesi</Label>
                                                            <Field
                                                                type="textarea"
                                                                name="Bank_Branch"
                                                                id="Bank_Branch"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Bank_Branch" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="Amount">Miktar</Label>
                                                            <Field
                                                                type="number"
                                                                name="Amount"
                                                                id="Amount"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Amount" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="Check_Picture">Çek Resmi</Label>
                                                            <Input
                                                                type="file"
                                                                name="Check_Picture"
                                                                id="Check_Picture"
                                                                className="form-control"
                                                                onChange={(e) => handleFileChange(e, setFieldValue)}
                                                            />
                                                            <ErrorMessage name="Check_Picture" component="div" className="text-danger" />
                                                        </FormGroup>
                                                      {/*  <div className="text-end">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                disabled={isSubmitting}
                                                            >
                                                                Kaydet
                                                            </button>
                                                        </div> */}
                                                        <div className="text-end">
    <button
        type="submit"
        className="btn btn-primary"
        style={{ marginRight: "1200px" }} // Sağ margin için stil ekledik
        disabled={isSubmitting}
    >
        Kaydet
    </button>
</div>

                                                    </>
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </div>
        </React.Fragment>
    );
};

export default CheckPortfolioAdd;








{/*
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';

const CheckPortfolioAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    const [checkPortfolio, setCheckPortfolio] = useState({
        Movement_Type: '',
        Current_ID: '',
        Registration_Date: '',
        Explanation: '',
        Portfolio_No: '',
        Serial_Number: '',
        Debtor: '',
        Expiry_Date: '',
        Bank_Id: '',
        Bank_Branch: '',
        Check_Picture: '',
        Amount: '',
        CompanyId: authUser.CompanyId,
        CreatedUserId: authUser.Id,
        status: true
    });

    const [movementTypeInfo, setMovementTypeInfo] = useState([]);
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const [selectBanklist, setSelectBanklist] = useState(null);
    const [banklist, setBanklist] = useState([]);
    const [fileInput, setFileInput] = useState(null);

    document.title = "Çek Portfolyo Ekle | İlekaSoft";

    useEffect(() => {
        const movementTypeData = [
            { id: 1, movementTypeName: 'İlk Tür' },
            { id: 2, movementTypeName: 'İkinci Tür' },
            { id: 3, movementTypeName: 'Üçüncü Tür' }
        ];
        setMovementTypeInfo(movementTypeData);
        var companyId = authUser.CompanyId;
        api.get("/CurrentAccount/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id.toString()
            }));
            setCurrentAccount(formattedOptions);
        });
        api.get("/Banklist/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.bank_Name
            }));
            setBanklist(formattedOptions);
        });
    }, []);

    const validationSchema = Yup.object().shape({
        Movement_Type: Yup.string().required('Hareket Tipi gereklidir'),
        Current_ID: Yup.string().required('Cari Adı gereklidir'),
        Registration_Date: Yup.date().required('Kayıt Tarihi gereklidir'),
        Explanation: Yup.string().required('Açıklama gereklidir'),
        Portfolio_No: Yup.string().required('Portföy No gereklidir'),
        Serial_Number: Yup.string().required('Seri No gereklidir'),
        Debtor: Yup.string().required('Borçlu gereklidir'),
        Expiry_Date: Yup.date().required('Vade Tarihi gereklidir'),
        Bank_Id: Yup.string().required('Banka Adı gereklidir'),
        Bank_Branch: Yup.string().required('Banka Şubesi gereklidir'),
        Amount: Yup.number().required('Tutar gereklidir')
    });

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFileInput(file);
        convertToBase64(file);
    };

    const convertToBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            setCheckPortfolio({ ...checkPortfolio, Check_Picture: base64String });
        };
        reader.onerror = (error) => {
            console.error('Error converting to base64:', error);
        };
    };

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setCheckPortfolio({ ...checkPortfolio, Current_ID: selectedOption ? selectedOption.value : '' });
    };

    const handleBanklist = (selectedOption) => {
        setSelectBanklist(selectedOption);
        setCheckPortfolio({ ...checkPortfolio, Bank_Id: selectedOption ? selectedOption.value : '' });
    };

    const handleSubmit = (values) => {
        const dataToSend = { ...values, CompanyId: authUser.CompanyId, CreatedUserId: authUser.Id, status: true };
        api.create("/CheckPortfolio/AddCheckPortfolio", dataToSend)
            .then(res => {
                navigate('/ÇekPortfoyler');
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            });
    };

    const CheckPictureField = () => {
        const { setFieldValue, values } = useFormikContext();
        return (
            <div className="mb-3" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <label htmlFor="Check_Picture" className="form-label">Çek Resmi</label>
                <input
                    type="file"
                    className="form-control"
                    id="Check_Picture"
                    name="Check_Picture"
                    onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        setFieldValue("Check_Picture", file);
                    }}
                />
                {values.Check_Picture && (
                    <img src={URL.createObjectURL(values.Check_Picture)} alt="Check Picture" style={{ maxWidth: "100px" }} />
                )}
                <ErrorMessage name="Check_Picture" component="div" className="text-danger" />
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Çek Portföyü Ekle" breadcrumbItem="Çek Portföyleri" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={checkPortfolio}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ errors, touched }) => (
                                            <Form>
                                                <div className="mb-3">
                                                    <label htmlFor="Movement_Type" className="form-label">Hareket Tipi</label>
                                                    <Field as="select" className="form-control" name="Movement_Type">
                                                        <option value="">Lütfen MovementType birimini seçiniz</option>
                                                        {movementTypeInfo.map((item) => (
                                                            <option value={item.id} key={item.id}>{item.movementTypeName}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage name="Movement_Type" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="Current_ID" className="form-label">Cari Adı</label>
                                                    <Select
                                                        value={selectCurrentAccount}
                                                        onChange={handleCurrentAccount}
                                                        options={currentAccount}
                                                        placeholder="CurrentAccount Seç"
                                                    />
                                                    {errors.Current_ID && touched.Current_ID ? (
                                                        <div className="text-danger">{errors.Current_ID}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="Registration_Date" className="form-label">Kayıt Tarihi</label>
                                                    <Field
                                                        type="date"
                                                        className="form-control"
                                                        name="Registration_Date"
                                                    />
                                                    <ErrorMessage name="Registration_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="Explanation" className="form-label">Açıklama</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="Explanation"
                                                    />
                                                    <ErrorMessage name="Explanation" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="Portfolio_No" className="form-label">Portföy No</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="Portfolio_No"
                                                    />
                                                    <ErrorMessage name="Portfolio_No" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="Serial_Number" className="form-label">Seri No</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="Serial_Number"
                                                    />
                                                    <ErrorMessage name="Serial_Number" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="Debtor" className="form-label">Borçlu</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="Debtor"
                                                    />
                                                    <ErrorMessage name="Debtor" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="Expiry_Date" className="form-label">Vade Tarihi</label>
                                                    <Field
                                                        type="date"
                                                        className="form-control"
                                                        name="Expiry_Date"
                                                    />
                                                    <ErrorMessage name="Expiry_Date" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="Bank_Id" className="form-label">Banka Id</label>
                                                    <Select
                                                        value={selectBanklist}
                                                        onChange={handleBanklist}
                                                        options={banklist}
                                                        placeholder="Banka Seç"
                                                    />
                                                    {errors.Bank_Id && touched.Bank_Id ? (
                                                        <div className="text-danger">{errors.Bank_Id}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="Bank_Branch" className="form-label">Banka Şubesi</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="Bank_Branch"
                                                    />
                                                    <ErrorMessage name="Bank_Branch" component="div" className="text-danger" />
                                                </div>
                                                <div className="mb-3">
                                                    <CheckPictureField />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="Amount" className="form-label">Tutar</label>
                                                    <Field
                                                        type="number"
                                                        className="form-control"
                                                        name="Amount"
                                                    />
                                                    <ErrorMessage name="Amount" component="div" className="text-danger" />
                                                </div>
                                                <button type="submit" className="btn btn-primary">Kaydet</button>
                                            </Form>
                                        )}
                                    </Formik>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CheckPortfolioAdd;
*/}




