import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate  } from 'react-router-dom';

const validationSchema = yup.object().shape({
    Account_Number: yup.string().required('Hesap No gerekli'),
    Iban: yup.string().required('IBAN gerekli'),
    Currency: yup.string().required('Para Birimi gerekli'),
    Account_Starting_Balance: yup.number().required('Hesap Başlangıç Bakiyesi gerekli'),
    Account_Name: yup.string().required('Hesap Adı gerekli'),
    Bank_Name: yup.string().required('Banka Adı gerekli'),
    Bank_Branch: yup.string().required('Banka Şubesi gerekli'),
    Branch_Phone_Number: yup.string().required('Şube Telefon No gerekli'),
    Branch_Representative: yup.string().required('Şube Temsilcisi gerekli'),
    Branch_Representative_eMail: yup.string().email('Geçersiz e-posta adresi').required('Şube Temsilcisi e-Posta gerekli'),
    Branch_Address: yup.string().required('Şube Adres gerekli'),
});

const BankAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();

    const [invoiceable, setInvoiceable] = useState(false); // Checkbox state
    const [posDefined, setPosDefined] = useState(false); // Checkbox state
    const [mailOrderDefined, setMailOrderDefined] = useState(false); // Checkbox state

    
    const navigate = useNavigate();

    const [currencyInfo, setCurrencyInfo] = useState([]);
    const [banklist, setBanklist] = useState([]);

    useEffect(() => {
        const currencyData = [
            { id: 1, currencyName: 'TRY' },
            { id: 2, currencyName: 'EUR' },
            { id: 3, currencyName: 'USD' }
        ];
        setCurrencyInfo(currencyData);
        const companyId = authUser.CompanyId;
        api.get("/Banklist/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.bank_Name
            }));
            setBanklist(formattedOptions);
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            Account_Number: '',
            Iban: '',
            Currency: '',
            Account_Starting_Balance: '',
            Account_Name: '',
            Bank_Name: '',
            Bank_Branch: '',
            Branch_Phone_Number: '',
            Branch_Representative: '',
            Branch_Representative_eMail: '',
            Branch_Address: '',
            IsInvoiceable: invoiceable,
            IsPosDefined: posDefined,
            IsMailOrderDefined: mailOrderDefined,
            CompanyId: authUser.CompanyId,
            CreatedUserId: authUser.Id,
            status: true,
        },
        validationSchema,
        onSubmit: (values) => {
            values.IsInvoiceable = invoiceable;
            values.IsPosDefined = posDefined;
            values.IsMailOrderDefined = mailOrderDefined;

            api.create('/Bank/AddBank', values)
                .then(res => {
                    console.log("başarılı");
                    //window.location.href = `/Bankalar`;
                    navigate('/Bankalar')
                    toast.success("Başarıyla kaydedildi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Kaydedilemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    const handleBanklist = (selectedOption) => {
        formik.setFieldValue('Bank_Name', selectedOption ? selectedOption.value : '');
    };


    
    const handleIsInvoiceableCheckboxChange = (event) => {
        const { checked } = event.target;
        // Update demo state based on checkbox
        if (checked) {
            setInvoiceable(true); // Checkbox işaretlendiğinde true olarak ayarla
        } else {
            setInvoiceable(false); // Checkbox işaret kaldırıldığında false olarak ayarla
        }

    };
    
    const handleIsPosDefinedCheckboxChange = (event) => {
        const { checked } = event.target;
        // Update demo state based on checkbox
        if (checked) {
            setPosDefined(true); // Checkbox işaretlendiğinde true olarak ayarla
        } else {
            setPosDefined(false); // Checkbox işaret kaldırıldığında false olarak ayarla
        }

    };
    
    const handleIsMailOrderDefinedCheckboxChange = (event) => {
        const { checked } = event.target;
        // Update demo state based on checkbox
        if (checked) {
            setMailOrderDefined(true); // Checkbox işaretlendiğinde true olarak ayarla
        } else {
            setMailOrderDefined(false); // Checkbox işaret kaldırıldığında false olarak ayarla
        }

    };
    


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Banka Hesabı Ekle" breadcrumbItem="Banka Hesapları" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="Account_Number" className="form-label">Hesap No</label>
                                            <input
                                                className={`form-control ${formik.touched.Account_Number && formik.errors.Account_Number ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Account_Number"
                                                placeholder="Hesap No Giriniz"
                                                value={formik.values.Account_Number}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Account_Number && formik.errors.Account_Number ? (
                                                <div className="invalid-feedback">{formik.errors.Account_Number}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Iban" className="form-label">IBAN</label>
                                            <input
                                                className={`form-control ${formik.touched.Iban && formik.errors.Iban ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Iban"
                                                placeholder="İban Giriniz"
                                                value={formik.values.Iban}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Iban && formik.errors.Iban ? (
                                                <div className="invalid-feedback">{formik.errors.Iban}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Currency" className="form-label">Para Birimi</label>
                                            <select
                                                className={`form-control ${formik.touched.Currency && formik.errors.Currency ? 'is-invalid' : ''}`}
                                                name="Currency"
                                                placeholder="Para Birimini Seçiniz"
                                                value={formik.values.Currency}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                required
                                            >
                                                <option value="">Lütfen para birimini seçiniz</option>
                                                {currencyInfo.map((item, key) => (
                                                    <option value={item.currencyName} key={key}>{item.currencyName}</option>
                                                ))}
                                            </select>
                                            {formik.touched.Currency && formik.errors.Currency ? (
                                                <div className="invalid-feedback">{formik.errors.Currency}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Account_Starting_Balance" className="form-label">Hesap Başlangıç Bakiyesi</label>
                                            <input
                                                className={`form-control ${formik.touched.Account_Starting_Balance && formik.errors.Account_Starting_Balance ? 'is-invalid' : ''}`}
                                                type="number"
                                                name="Account_Starting_Balance"
                                                placeholder="Başlangıç Bakiyesi Giriniz"
                                                value={formik.values.Account_Starting_Balance}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Account_Starting_Balance && formik.errors.Account_Starting_Balance ? (
                                                <div className="invalid-feedback">{formik.errors.Account_Starting_Balance}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Account_Name" className="form-label">Hesap Adı</label>
                                            <input
                                                className={`form-control ${formik.touched.Account_Name && formik.errors.Account_Name ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Account_Name"
                                                placeholder="Hesap  Adı Giriniz"
                                                value={formik.values.Account_Name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Account_Name && formik.errors.Account_Name ? (
                                                <div className="invalid-feedback">{formik.errors.Account_Name}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Bank_Name" className="form-label">Banka Adı</label>
                                            <Select
                                                value={banklist.find(option => option.value === formik.values.Bank_Name) || ''}
                                                onChange={handleBanklist}
                                                options={banklist}
                                                placeholder="Banka Adı Seçiniz"
                                                name="Bank_Name"
                                                className={`react-select ${formik.touched.Bank_Name && formik.errors.Bank_Name ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.Bank_Name && formik.errors.Bank_Name ? (
                                                <div className="invalid-feedback d-block">{formik.errors.Bank_Name}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Bank_Branch" className="form-label">Banka Şubesi</label>
                                            <input
                                                className={`form-control ${formik.touched.Bank_Branch && formik.errors.Bank_Branch ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Bank_Branch"
                                                placeholder="Banka Şubesi Giriniz"
                                                value={formik.values.Bank_Branch}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Bank_Branch && formik.errors.Bank_Branch ? (
                                                <div className="invalid-feedback">{formik.errors.Bank_Branch}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Branch_Phone_Number" className="form-label">Şube Telefon No</label>
                                            <input
                                                className={`form-control ${formik.touched.Branch_Phone_Number && formik.errors.Branch_Phone_Number ? 'is-invalid' : ''}`}
                                                type="tel"
                                                name="Branch_Phone_Number"
                                                placeholder="Şube Telefon Numarası Giriniz"
                                                value={formik.values                                                .Branch_Phone_Number}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Branch_Phone_Number && formik.errors.Branch_Phone_Number ? (
                                                <div className="invalid-feedback">{formik.errors.Branch_Phone_Number}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Branch_Representative" className="form-label">Şube Temsilcisi</label>
                                            <input
                                                className={`form-control ${formik.touched.Branch_Representative && formik.errors.Branch_Representative ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Branch_Representative"
                                                placeholder="Şube Temsilcisi Giriniz"
                                                value={formik.values.Branch_Representative}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Branch_Representative && formik.errors.Branch_Representative ? (
                                                <div className="invalid-feedback">{formik.errors.Branch_Representative}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Branch_Representative_eMail" className="form-label">Şube Temsilcisi e-Posta</label>
                                            <input
                                                className={`form-control ${formik.touched.Branch_Representative_eMail && formik.errors.Branch_Representative_eMail ? 'is-invalid' : ''}`}
                                                type="email"
                                                name="Branch_Representative_eMail"
                                                placeholder="Şube Temsilcisi Emaili Giriniz"
                                                value={formik.values.Branch_Representative_eMail}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Branch_Representative_eMail && formik.errors.Branch_Representative_eMail ? (
                                                <div className="invalid-feedback">{formik.errors.Branch_Representative_eMail}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="Branch_Address" className="form-label">Şube Adres</label>
                                            <input
                                                className={`form-control ${formik.touched.Branch_Address && formik.errors.Branch_Address ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="Branch_Address"
                                                placeholder="Şube Adresi Giriniz"
                                                value={formik.values.Branch_Address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.Branch_Address && formik.errors.Branch_Address ? (
                                                <div className="invalid-feedback">{formik.errors.Branch_Address}</div>
                                            ) : null}
                                        </div>
                                        <Col md={6}>
    <div style={{ marginTop: '20px' }}>
        <label>
            <input
                type="checkbox"
                onChange={(e) => handleIsInvoiceableCheckboxChange(e)}
            />
            <span style={{ marginLeft: '5px' }}>Faturada Olabilir Mi ?</span>
        </label>
    </div>
</Col>
<Col md={6}>
    <div style={{ marginTop: '20px' }}>
        <label>
            <input
                type="checkbox"
                onChange={(e) => handleIsPosDefinedCheckboxChange(e)}
            />
            <span style={{ marginLeft: '5px' }}>Pos Tanımlı Mı ?</span>
        </label>
    </div>
</Col>
<Col md={6}>
    <div style={{ marginTop: '20px' }}>
        <label>
            <input
                type="checkbox"
                onChange={(e) => handleIsMailOrderDefinedCheckboxChange(e)}
            />
            <span style={{ marginLeft: '5px' }}>Mail Order Tanımlı Mı ?</span>
        </label>
    </div>
</Col>


                                        <button
                                            type="submit"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Kaydet
                                        </button>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default BankAdd;


