import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'react-bootstrap';
import { Button } from 'reactstrap';

import { Link } from 'react-router-dom';
import Invoice_Serial_Management_E_InvoiceAdd from '../../../Pages/Invoice_Serial_Management/Invoice_Serial_Management_E_InvoiceAdd';
import Invoice_Serial_Management_E_InvoiceUpdate from '../../../Pages/Invoice_Serial_Management/Invoice_Serial_Management_E_InvoiceUpdate';

const TableListModal_E_Invoice = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedItem, setSelectedItem] = useState();

    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = (selectedItem) => {
        setShow2(true);
        setSelectedItem(selectedItem);
    };

    const handleClose3 = () => setShow3(false);
    const handleShow3 = (selectedItem) => {
        setShow3(true);
        setSelectedItem(selectedItem);
    };
    const [table, setTable] = useState([]);

    useEffect(() => {
        setTable([...props.companyArray]);
        console.log(table,"burada");
        setShow2(false);
    }, [props.companyArray]);
    
    useEffect(() => {
        setTable([...props.companyArray]);
        console.log(table,"burada");
        setShow3(false);
    }, [props.companyArray]);
    

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                          {/*    <Button color="success" className="add-btn" onClick={() => {
    window.location.href = `/KasaEkle`;
}}>
  
    <i className="ri-add-line align-bottom me-1"></i> Ekle

</Button>*/} 
<Button color="success" className="add-btn" onClick={handleShow} id="create-btn" data-toggle="modal">
                                                    <i className="ri-add-line align-bottom me-1"></i> Ekle 
                                                </Button>

                                               
                                               {/* <Button color="success" className="add-btn" onClick={handleShow} id="create-btn" data-toggle="modal">
                                                    <i className="ri-add-line align-bottom me-1"></i> Ekle Modal
</Button>*/}

                                            </div>
                                        </Col>
                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <div className="search-box ms-2">
                                                    <input type="text" className="form-control search" placeholder="Search..." />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col" style={{ width: "50px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                        </div>
                                                    </th>
                                                    {
                                                        props.tableHeadArray.map((element, index) => (
                                                            <th key={index} className="sort" data-sort={element}>{element}</th>
                                                        ))
                                                    }
                                                    <th>İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {
                                                    props.companyArray.map((item) => (
                                                        <tr key={item.id}>
                                                            <th scope="row">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                                                </div>
                                                                </th>
{Object.keys(item).map((key) => {
  if (
    key === 'e_Invoice_Serial_Prefix' ||
    key === 'e_Invoice_Serial_No' ||
    key === 'e_Invoice_Serial_Year' ||
    //key === 'e_Archive_Serial_Prefix' ||
    //key === 'e_Archive_Serial_No' ||
   // key === 'e_Archive_Serial_Year' ||
    //key === 'delivery_Note_Serial_Prefix' ||
    //key === 'delivery_Note_Serial_No' ||
    //key === 'delivery_Note_Serial_Year' ||
    key === 'defaultStatus'
  ) {
    return (
      <td data-sort={key} key={key}>
        {key === 'defaultStatus' ? (
          <input type="checkbox" checked={item[key]} readOnly />
        ) : (
          item[key]
        )}
      </td>
    );
  }
  return null;
})}

                                                            <td style={{ width: "200px" }}>
                                                                <div className="d-flex gap-2" >
                                                                    
                                                                 <div className="edit">
    <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => handleShow2(item)}> Düzenle </button>
    </div>

                                                                   {/* 
    <button
                                                                      className="btn btn-sm btn-warning edit-item-btn"
                                                                       onClick={() => {
                                                                       const parametre = item.id; 
                                                                       window.location.href = `/kasaGuncelleSayfa?parametre=${parametre}`;
                                                                       }} > Düzenle </button> 
*/} <div className="edit"> 
                                                                    
                                                                   
  
                                                                   </div>
                                                                   {/* <div className="edittwo">
                                                                        <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShow3(item)}>Sil</button>
                                                                    </div> */}
                                                                   
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <div className="noresult" style={{ display: "none" }}>
                                            <div className="text-center">
                                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                    colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                </lord-icon>
                                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                                <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="d-flex justify-content-end">
                                        <div className="pagination-wrap hstack gap-2">
                                            <Link className="page-item pagination-prev disabled" to="#">
                                                Previous
                                            </Link>
                                            <ul className="pagination listjs-pagination mb-0"></ul>
                                            <Link className="page-item pagination-next" to="#">
                                                Next
                                            </Link>
                                        </div>
                                            </div>*/}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title>
                    Ekle
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Invoice_Serial_Management_E_InvoiceAdd />
                </Modal.Body>

            </Modal>
            <Modal show={show2} onHide={handleClose2} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title>  Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Invoice_Serial_Management_E_InvoiceUpdate selectedItem={selectedItem} handleClose2={()=>handleClose2} />
                </Modal.Body>
            </Modal>       
        </React.Fragment>
    )
}

export default TableListModal_E_Invoice;







