import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';
const CheckPortfolioUpdate = (props) => {
    const api =new APIClient();
    const [movement_Type, setMovement_Type] = useState(props.selectedItem.movement_Type);
    const [current_ID, setCurrent_ID] = useState(props.selectedItem.current_ID);
    const [registration_Date, setRegistration_Date] = useState(props.selectedItem.registration_Date);
    const [explanation, setExplanation] = useState(props.selectedItem.explanation);
    const [portfolio_No, setPortfolio_No] = useState(props.selectedItem.portfolio_No);
    const [serial_Number, setSerial_Number] = useState(props.selectedItem.serial_Number);
    const [debtor, setDebtor] = useState(props.selectedItem.debtor);
    const [expiry_Date, setExpiry_Date] = useState(props.selectedItem.expiry_Date);
    const [bank_Name, setBank_Name] = useState(props.selectedItem.bank_Name);
    const [bank_Branch, setBank_Branch] = useState(props.selectedItem.bank_Branch);
    const [check_Picture, setCheck_Picture] = useState(props.selectedItem.check_Picture);
    const [amount, setAmount] = useState(props.selectedItem.amount);


    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedCheckPortfolio = {
            id: props.selectedItem.id,
            movement_Type: movement_Type,
            current_ID: current_ID,
            registration_Date: registration_Date,
            explanation: explanation,
            portfolio_No: portfolio_No,
            serial_Number: serial_Number,
            debtor: debtor,
            expiry_Date: expiry_Date,
            bank_Name: bank_Name,
            bank_Branch: bank_Branch,
            check_Picture: check_Picture,
            amount: amount

        };

        try {
           //await axios.post("/Bank/UpdateBank", updatedBank);
           await api.create("/CheckPortfolio/UpdateCheckPortfolio", updatedCheckPortfolio);
            props.handleClose2(); // Modalı kapatıyoruz
            navigate(`/ÇekPortföyler`);
            //window.location.href = `/ÇekPortföyler`;

        } catch (error) {
            console.error("Error updating current group:", error);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Movement_Type</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Movement_Type"
                    name="movement_Type"
                    value={movement_Type}
                    onChange={(e) => setMovement_Type(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Current_ID</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Current_ID"
                    name="current_ID"
                    value={current_ID}
                    onChange={(e) => setCurrent_ID(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Registration_Date</Form.Label>
                <Form.Control
                    type="date"
                    placeholder="Registration_Date"
                    name="registration_Date"
                    value={registration_Date}
                    onChange={(e) => setRegistration_Date(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Explanation</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Explanation"
                    name="explanation"
                    value={explanation}
                    onChange={(e) => setExplanation(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Portfolio_No</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Portfolio_No"
                    name="portfolio_No"
                    value={portfolio_No}
                    onChange={(e) => setPortfolio_No(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Serial_Number</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Serial_Number"
                    name="serial_Number"
                    value={serial_Number}
                    onChange={(e) => setSerial_Number(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Debtor</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Debtor"
                    name="debtor"
                    value={debtor}
                    onChange={(e) => setDebtor(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Expiry_Date</Form.Label>
                <Form.Control
                    type="date"
                    placeholder="Expiry_Date"
                    name="expiry_Date"
                    value={expiry_Date}
                    onChange={(e) => setExpiry_Date(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Bank_Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Bank_Name"
                    name="bank_Name"
                    value={bank_Name}
                    onChange={(e) => setBank_Name(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Bank_Branch</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Bank_Branch"
                    name="bank_Branch"
                    value={bank_Branch}
                    onChange={(e) => setBank_Branch(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Check_Picture</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Check_Picture"
                    name="check_Picture"
                    value={check_Picture}
                    onChange={(e) => setCheck_Picture(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Amount"
                    name="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                />
            </Form.Group>

            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
            Çek Portföy Düzenle
            </Button>
        </Form>
    );
};

export default CheckPortfolioUpdate;
