import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';
const CashboxcollectionUpdate = (props) => {
    const api =new APIClient();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const id = props.selectedItem.id;        
        try {
            // await axios.post("/Bank/DeleteBank?id="+id);
            await api.create("/Cashboxcollection/DeleteCashboxcollection?id="+id);
            props.handleClose2(); // Close the modal
            navigate(`/KasaTahsilatlar`);
            //window.location.href = `/KasaTahsilatlar`;
        } catch (error) {
            console.error("Error updating current group:", error);
        }
    };
    

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Silmek İstediğimize emin misiniz</Form.Label>              
            </Form.Group>
            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
            Kasa Tahsilatı Sil
            </Button>
        </Form>
    );
};

export default CashboxcollectionUpdate;
