// utils.js
export function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  export function getCurrencySymbol(moneyUnit) {
    switch (moneyUnit) {
      case 1:
        return 'TL';
      case 2:
        return '$';
      case 3:
        return '€';
      case 4:
        return '£';
      default:
        return '';
    }
  }  