import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from 'react-router-dom';
import { UserTableList } from '../../components/User/UserTable';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { ToastContainer } from 'react-toastify';

const User = () => {
    const api = new APIClient();
    document.title ="Kullanıcılar | İlekaSoft";
    
    const [result, setResult] = useState("none");
    const [user, setUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const authUser = getLoggedinUser();
    const companyId = authUser.CompanyId;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        api.get(`/Users/GetAll?companyId=${companyId}`).then(res => {
            if (res.length === 0) {
                setResult("block");
            } else {
                setUser(res);
            }
        }).catch(err => console.log(err));
    };

    const handleChange = () => {
        fetchData();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = user.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < Math.ceil(user.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPages = Math.ceil(user.length / itemsPerPage);

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <li key={i} className="page-item">
                        <Button className={`page-link ${currentPage === i ? 'active' : ''}`} onClick={() => paginate(i)}>
                            {i}
                        </Button>
                    </li>
                );
            }
        } else {
            if (currentPage > 2) {
                pageNumbers.push(
                    <li key={1} className="page-item">
                        <Button className="page-link" onClick={() => paginate(1)}>
                            1
                        </Button>
                    </li>
                );
                if (currentPage > 3) {
                    pageNumbers.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
                }
            }

            const startPage = currentPage === 1 ? currentPage : currentPage - 1;
            const endPage = currentPage === totalPages ? currentPage : currentPage + 1;

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <li key={i} className="page-item">
                        <Button className={`page-link ${currentPage === i ? 'active' : ''}`} onClick={() => paginate(i)}>
                            {i}
                        </Button>
                    </li>
                );
            }

            if (currentPage < totalPages - 1) {
                if (currentPage < totalPages - 2) {
                    pageNumbers.push(<li key="ellipsis2" className="page-item disabled"><span className="page-link">...</span></li>);
                }
                pageNumbers.push(
                    <li key={totalPages} className="page-item">
                        <Button className="page-link" onClick={() => paginate(totalPages)}>
                            {totalPages}
                        </Button>
                    </li>
                );
            }
        }

        return pageNumbers;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <ToastContainer />
                <Container fluid={true}>
                    <Breadcrumbs title="Kullanıcılar" breadcrumbItem="Kullanıcılar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Link color="success" className="add-btn btn btn-success" to={"/kullanici/kullaniciEkle"} id="create-btn">
                                                        <i className="ri-add-line align-bottom me-1"></i>Ekle
                                                    </Link>
                                                  {/*  <Button color="soft-danger">
                                                        <i className="ri-delete-bin-2-line"></i>
    </Button>*/}
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Search..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <UserTableList userData={currentItems} isChange={handleChange} />
                                            <div className="noresult" style={{ display: result }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Üzgünüm.Listelenecek veri yok.!!</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Button className="page-item pagination-prev" onClick={prevPage} disabled={currentPage === 1}>
                                                    Önceki
                                                </Button>
                                                <ul className="pagination listjs-pagination mb-0">
                                                    {renderPageNumbers()}
                                                </ul>
                                                <Button className="page-item pagination-next" onClick={nextPage} disabled={currentPage === Math.ceil(user.length / itemsPerPage)}>
                                                    Sonraki
                                                </Button>
                                            </div>
                                        </div>
                                        <style jsx>{`
                                            .pagination .page-link {
                                                color: grey; /* Tüm sayfa numaraları için gri renk */
                                            }
                                            .pagination .page-link.active {
                                                color: white; /* Aktif sayfa numarası için beyaz renk */
                                            }
                                        `}</style>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
};

export default User;
