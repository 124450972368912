import { setAuthorization } from "./api_helper";
import { APIClient } from "./api_helper";
import { jwtDecode } from "jwt-decode";
const api =new APIClient();
const loginJwt = async ({ email, password }) => {
        let token;
        const res = await api.create("/Auth/Login", { email, password }).then(res=>{
            setAuthorization(res.token)
            const jwt_decode = jwtDecode(res.token);
            token = jwt_decode;
        }).catch(e=>{
            throw "Email veya Şifre Hatalı"
        })
        
    return token;
}

export { loginJwt};
