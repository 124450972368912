import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from "react-router-dom";


import { useNavigate  } from 'react-router-dom';

const validationSchema = yup.object().shape({
    account_Number: yup.string().required('Hesap No gerekli'),
    iban: yup.string().required('IBAN gerekli'),
    currency: yup.string().required('Para Birimi gerekli'),
    account_Starting_Balance: yup.number().required('Hesap Başlangıç Bakiyesi gerekli'),
    account_Name: yup.string().required('Hesap Adı gerekli'),
    bank_Name: yup.string().required('Banka Adı gerekli'),
    bank_Branch: yup.string().required('Banka Şubesi gerekli'),
    branch_Phone_Number: yup.string().required('Şube Telefon No gerekli'),
    branch_Representative: yup.string().required('Şube Temsilcisi gerekli'),
    branch_Representative_eMail: yup.string().email('Geçersiz e-posta adresi').required('Şube Temsilcisi e-Posta gerekli'),
    branch_Address: yup.string().required('Şube Adres gerekli'),
});

const BankUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const [invoiceable, setInvoiceable] = useState(false); // Checkbox state
    const [posDefined, setPosDefined] = useState(false); // Checkbox state
    const [mailOrderDefined, setMailOrderDefined] = useState(false); // Checkbox state

    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [bankOptions, setBankOptions] = useState([]);
    const [selectedBankOption, setSelectedBankOption] = useState(null);

    useEffect(() => {
        setCurrencyOptions([
            { id: 1, currencyName: 'TRY' },
            { id: 2, currencyName: 'EUR' },
            { id: 3, currencyName: 'USD' }
        ]);
        const companyId = authUser.CompanyId;

        api.get("/Banklist/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.bank_Name,
                value: item.bank_Name
            }));
            setBankOptions(formattedOptions);
        });
    }, []);

    useEffect(() => {
        api.get('/Bank/GetBankById?id=' + _id)
            .then(res => {
                formik.setValues({
                    id: res.id,
                    account_Number: res.account_Number,
                    iban: res.iban,
                    currency: res.currency,
                    account_Starting_Balance: res.account_Starting_Balance,
                    account_Name: res.account_Name,
                    bank_Name: res.bank_Name,
                    bank_Branch: res.bank_Branch,
                    branch_Phone_Number: res.branch_Phone_Number,
                    branch_Representative: res.branch_Representative,
                    branch_Representative_eMail: res.branch_Representative_eMail,
                    branch_Address: res.branch_Address,
                    updatedUserId: authUser.Id,
                    isInvoiceable: res.isInvoiceable,
                    isPosDefined: res.isPosDefined,
                    isMailOrderDefined: res.isMailOrderDefined,

                });
                setSelectedBankOption({ label: res.bank_Name, value: res.bank_Name });
                setInvoiceable(res.isInvoiceable);
                setPosDefined(res.isPosDefined);
                setMailOrderDefined(res.isMailOrderDefined);

            })
            .catch(err => console.log(err));
    }, [_id]);

    document.title = "Banka Güncelle | İlekaSoft";

    const formik = useFormik({
        initialValues: {
            account_Number: '',
            iban: '',
            currency: '',
            account_Starting_Balance: '',
            account_Name: '',
            bank_Name: '',
            bank_Branch: '',
            branch_Phone_Number: '',
            branch_Representative: '',
            branch_Representative_eMail: '',
            branch_Address: '',
            updatedUserId: authUser.Id,
            isInvoiceable: invoiceable,
            isPosDefined: posDefined,
            isMailOrderDefined: mailOrderDefined

        },
        validationSchema,
        onSubmit: (values) => {
            values.isInvoiceable = invoiceable;
            values.isPosDefined = posDefined;
            values.isMailOrderDefined = mailOrderDefined;


            api.create("/Bank/UpdateBank", values)
                .then(res => {
                    console.log("başarılı");
                    //window.location.href = `/Bankalar`;
                    navigate('/Bankalar')
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    const handleBankSelectChange = (selectedOption) => {
        setSelectedBankOption(selectedOption);
        formik.setFieldValue('bank_Name', selectedOption ? selectedOption.value : '');
    };


    const handleIsInvoiceableCheckboxChange = (event) => {
        const { checked } = event.target;
        
        if(checked == false)
            {
                setInvoiceable(true); // demo state'ini güncelle
                formik.setFieldValue('isInvoiceable', true); // formik values içinde isDemo'yu güncelle
            }
        else if(checked == true)
            {

                setInvoiceable(false); // demo state'ini güncelle
                formik.setFieldValue('isInvoiceable', false); // formik values içinde isDemo'yu güncelle
            }
    };
    
    const handleIsPosDefinedCheckboxChange = (event) => {
        const { checked } = event.target;
        
        if(checked == false)
            {
                setPosDefined(true); // demo state'ini güncelle
                formik.setFieldValue('isPosDefined', true); // formik values içinde isDemo'yu güncelle
            }
        else if(checked == true)
            {

                setPosDefined(false); // demo state'ini güncelle
                formik.setFieldValue('isPosDefined', false); // formik values içinde isDemo'yu güncelle
            }
    };
    
    const handleIsMailOrderDefinedCheckboxChange = (event) => {
        const { checked } = event.target;
        
        if(checked == false)
            {
                setMailOrderDefined(true); // demo state'ini güncelle
                formik.setFieldValue('isMailOrderDefined', true); // formik values içinde isDemo'yu güncelle
            }
        else if(checked == true)
            {

                setMailOrderDefined(false); // demo state'ini güncelle
                formik.setFieldValue('isMailOrderDefined', false); // formik values içinde isDemo'yu güncelle
            }
    };
    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Banka Hesabı Güncelle" breadcrumbItem="Banka Hesapları" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="account_Number" className="form-label">Hesap No</label>
                                            <input
                                                className={`form-control ${formik.touched.account_Number && formik.errors.account_Number ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="account_Number"
                                                placeholder="Hesap Adı Giriniz"
                                                value={formik.values.account_Number}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.account_Number && formik.errors.account_Number ? (
                                                <div className="invalid-feedback">{formik.errors.account_Number}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="iban" className="form-label">IBAN</label>
                                            <input
                                                className={`form-control ${formik.touched.iban && formik.errors.iban ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="iban"
                                                placeholder="İban Giriniz"
                                                value={formik.values.iban}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.iban && formik.errors.iban ? (
                                                <div className="invalid-feedback">{formik.errors.iban}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="currency" className="form-label">Para Birimi</label>
                                            <select
                                                className={`form-control ${formik.touched.currency && formik.errors.currency ? 'is-invalid' : ''}`}
                                                name="currency"
                                                placeholder="Para Birimini Seçiniz"
                                                value={formik.values.currency}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                required
                                            >
                                                <option value="">Lütfen para birimini seçiniz</option>
                                                {currencyOptions.map((item, key) => (
                                                    <option value={item.currencyName} key={key}>{item.currencyName}</option>
                                                ))}
                                            </select>
                                            {formik.touched.currency && formik.errors.currency ? (
                                                <div className="invalid-feedback">{formik.errors.currency}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="account_Starting_Balance" className="form-label">Hesap Başlangıç Bakiyesi</label>
                                            <input
                                                className={`form-control ${formik.touched.account_Starting_Balance && formik.errors.account_Starting_Balance ? 'is-invalid' : ''}`}
                                                type="number"
                                                placeholder="Başlangıç Bakiyesini Giriniz"
                                                name="account_Starting_Balance"
                                                value={formik.values.account_Starting_Balance}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.account_Starting_Balance && formik.errors.account_Starting_Balance ? (
                                                <div className="invalid-feedback">{formik.errors.account_Starting_Balance}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="account_Name" className="form-label">Hesap Adı</label>
                                            <input
                                                className={`form-control ${formik.touched.account_Name && formik.errors.account_Name ? 'is-invalid' : ''}`}
                                                type="text"
                                                placeholder="Hesap Adını Giriniz"
                                                name="account_Name"
                                                value={formik.values.account_Name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.account_Name && formik.errors.account_Name ? (
                                                <div className="invalid-feedback">{formik.errors.account_Name}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="bank_Name" className="form-label">Banka Adı</label>
                                            <Select
                                                value={selectedBankOption}
                                                onChange={handleBankSelectChange}
                                                options={bankOptions}
                                                placeholder="Banka Adı Seçiniz"
                                                className={`react-select ${formik.touched.bank_Name && formik.errors.bank_Name ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.bank_Name && formik.errors.bank_Name ? (
                                                <div className="invalid-feedback d-block">{formik.errors.bank_Name}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="bank_Branch" className="form-label">Banka Şubesi</label>
                                            <input
                                                className={`form-control ${formik.touched.bank_Branch && formik.errors.bank_Branch ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="bank_Branch"
                                                placeholder="Banka Şubesi Giriniz"
                                                value={formik.values.bank_Branch}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.bank_Branch && formik.errors.bank_Branch ? (
                                                <div className="invalid-feedback">{formik.errors.bank_Branch}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="branch_Phone_Number" className="form-label">Şube Telefon No</label>
                                            <input
                                                className={`form-control ${formik.touched.branch_Phone_Number && formik.errors.branch_Phone_Number ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="branch_Phone_Number"
                                                placeholder="Şube Telefon Numarası Giriniz"
                                                value={formik.values.branch_Phone_Number}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.branch_Phone_Number && formik.errors.branch_Phone_Number ? (
                                                <div className="invalid-feedback">{formik.errors.branch_Phone_Number}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="branch_Representative" className="form-label">Şube Temsilcisi</label>
                                            <input
                                                className={`form-control ${formik.touched.branch_Representative && formik.errors.branch_Representative ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="branch_Representative"
                                                placeholder="Şube Temsilcisi Giriniz"
                                                value={formik.values.branch_Representative}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.branch_Representative && formik.errors.branch_Representative ? (
                                                <div className="invalid-feedback">{formik.errors.branch_Representative}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="branch_Representative_eMail" className="form-label">Şube Temsilcisi e-Posta</label>
                                            <input
                                                className={`form-control ${formik.touched.branch_Representative_eMail && formik.errors.branch_Representative_eMail ? 'is-invalid' : ''}`}
                                                type="email"
                                                name="branch_Representative_eMail"
                                                placeholder="Şube Temsilcisi Emaili Giriniz"
                                                value={formik.values.branch_Representative_eMail}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.branch_Representative_eMail && formik.errors.branch_Representative_eMail ? (
                                                <div className="invalid-feedback">{formik.errors.branch_Representative_eMail}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="branch_Address" className="form-label">Şube Adres</label>
                                            <input
                                                className={`form-control ${formik.touched.branch_Address && formik.errors.branch_Address ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="branch_Address"
                                                placeholder="Şube Adresi Giriniz"
                                                value={formik.values.branch_Address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.branch_Address && formik.errors.branch_Address ? (
                                                <div className="invalid-feedback">{formik.errors.branch_Address}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
    <Col md={6}>
    <div style={{ marginTop: '20px' }}>
            <input
                type="checkbox"          
              checked={invoiceable}
                onClick={handleIsInvoiceableCheckboxChange} // Checkbox değiştiğinde handleCheckboxChange fonksiyonunu çağır
            />
                        <span style={{ marginLeft: '5px' }}>Demo mu ?</span>
            {/* Opsiyonel olarak label eklenebilir
            <label className="form-check-label" htmlFor="isDemo">
                Demo
            </label>
            */}
        </div>
    </Col>
</div>

<div className="mb-3">
    <Col md={6}>
    <div style={{ marginTop: '20px' }}>
            <input
                type="checkbox"          
              checked={posDefined}
                onClick={handleIsPosDefinedCheckboxChange} // Checkbox değiştiğinde handleCheckboxChange fonksiyonunu çağır
            />
                        <span style={{ marginLeft: '5px' }}>Pos Tanımlı mı ?</span>
            {/* Opsiyonel olarak label eklenebilir
            <label className="form-check-label" htmlFor="isDemo">
                Demo
            </label>
            */}
        </div>
    </Col>
</div>

<div className="mb-3">
    <Col md={6}>
    <div style={{ marginTop: '20px' }}>
            <input
                type="checkbox"          
              checked={mailOrderDefined}
                onClick={handleIsMailOrderDefinedCheckboxChange} // Checkbox değiştiğinde handleCheckboxChange fonksiyonunu çağır
            />
                        <span style={{ marginLeft: '5px' }}>Mail Order Tanımlı mı ?</span>
            {/* Opsiyonel olarak label eklenebilir
            <label className="form-check-label" htmlFor="isDemo">
                Demo
            </label>
            */}
        </div>
    </Col>
</div>

                                        <div>
                                            <button className="btn btn-primary" type="submit">Güncelle</button>
                                        </div>
                                    </form>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BankUpdatePage;
