import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate  } from 'react-router-dom';

const BanklistUpdate = (props) => {
    const api =new APIClient();
    const authUser = getLoggedinUser();

    const [bank_Name, setBank_Name] = useState(props.selectedItem.bank_Name);
    const [updatedUserId, setUpdatedUserId] = useState(authUser.Id);

    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedBanklist = {
            id: props.selectedItem.id,
            bank_Name: bank_Name,
            updatedUserId: updatedUserId,
            //status:true


        };

        try {
            await api.create("/Banklist/UpdateBanklist", updatedBanklist);
            
            props.handleClose2(); // Modalı kapatıyoruz
            //window.location.href = `/BankaListeler`;
            navigate('/BankaListeler')

        } catch (error) {
            console.error("Error updating current group:", error);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Banka Adı</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Banka Adı"
                    name="bank_Name"
                    value={bank_Name}
                    onChange={(e) => setBank_Name(e.target.value)}
                    required
                />
            </Form.Group>


            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
            Banka Adını Düzenle
            </Button>
        </Form>
    );
};

export default BanklistUpdate;


