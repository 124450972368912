import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import CheckPortfolioAdd from '../../../Pages/CheckPortfolio/CheckPortfolioAdd';
import CheckPortfolioUpdate from '../../../Pages/CheckPortfolio/CheckPortfolioUpdate';
import CheckPortfolioDelete from '../../../Pages/CheckPortfolio/DeleteCheckPortfolio';
import { useNavigate } from 'react-router-dom';

const TableListModal = (props) => {
    const [table, setTable] = useState([]);
    const [filterType, setFilterType] = useState('');
    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setTable([...props.companyArray]);
    }, [props.companyArray]);

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };




    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedItem, setSelectedItem] = useState();

    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = (selectedItem) => {
        setShow2(true);
        setSelectedItem(selectedItem);
    };

    const handleClose3 = () => setShow3(false);
    const handleShow3 = (selectedItem) => {
        setShow3(true);
        setSelectedItem(selectedItem);
    };

    const [showDetail, setShowDetail] = useState(false);


    const handleCloseDetail = () => setShowDetail(false);
        const handleShowDetail = (item) => {
            setShowDetail(true);
            setSelectedItem(item);
        };
    
    
    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                <Row className="g-4 mb-3">
    <Col className="col-sm-auto">
        <Button color="success" className="add-btn" onClick={() => {
            //window.location.href = `/ÇekPortfoyEkle`;
           navigate(`/ÇekPortfoyEkle`);
        }}>
            <i className="ri-add-line align-bottom me-1"></i> Ekle
        </Button>
    </Col>
    <Col className="col-sm-auto">
        <div className="d-flex gap-1">
            <select className="form-control" onChange={handleFilterChange}>
                <option value="">Tümü</option>
                <option value="Alınan Çek">Alınan Çek</option>
                <option value="Verilen Çek">Verilen Çek</option>
            </select>
        </div>
    </Col>
</Row>

                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col" style={{ width: "50px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                        </div>
                                                    </th>
                                                    <th scope="col">Hareket Tipi</th>
                                                    <th scope="col">Cari Adı Soyadı veya Unvanı</th>
                                                    <th scope="col">Kayıt Tarihi</th>
                                                    <th scope="col">Açıklama</th>
                                                    <th scope="col">Portföy Numarası</th>
                                                    <th scope="col">Seri Numarası</th>
                                                    <th scope="col">Borçlu</th>
                                                    <th scope="col">Vade Tarihi</th>
                                                    <th scope="col">Banka Adı</th>
                                                    <th scope="col">Banka Şubesi</th>
                                                    <th scope="col">Miktar</th>
                                                    <th scope="col">Çek Resmi</th>


                                                    <th>İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {table.map((item) => {
                                                    if (filterType === '' || item.movement_Type === filterType) {
                                                        return (
                                                            <tr key={item.id}>
                                                                <th scope="row">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                                                    </div>
                                                                </th>
                                                                {Object.keys(item).map((key) => {
  if (key === 'check_Picture') {
    return (
      <td data-sort={key}>
<img 
  src={`data:image/jpeg;base64, ${item[key]}`} 
  alt="Check Picture" 
  style={{ width: "100px", height: "65px", objectFit: "cover" }} 
/>      </td>
    );
  } else if (key === 'movement_Type' || key === 'current_Name' || key === 'debtor' || key === 'registration_Date' || key === 'explanation' || key === 'portfolio_No' || key === 'serial_Number' || key === 'expiry_Date' || key === 'bank_Name' || key === 'bank_Branch' || key === 'amount') {
    return <td data-sort={key}>{item[key]}</td>;
  }
  return null; 
})}
{/*
                                                                {Object.keys(item).map((key) => {
                                                                    if (
                                                                        key === 'movement_Type' ||
                                                                        key === 'current_Name' ||
                                                                        key === 'debtor' ||
                                                                        key === 'registration_Date' ||
                                                                        key === 'explanation' ||
                                                                        key === 'portfolio_No' ||
                                                                        key === 'serial_Number' ||
                                                                        key === 'expiry_Date' ||
                                                                        key === 'bank_Name' ||
                                                                        key === 'bank_Branch' ||
                                                                        key === 'amount' ||
                                                                        key === 'check_Picture' 

                                                                    ) {
                                                                        return <td data-sort={key}>{item[key]}</td>;
                                                                    }
                                                                    return null;
                                                                })}
*/}
                                                                <td style={{ width: '200px' }}>
                                                                    <div className="d-flex gap-2">
                                                                      {/*  <div className="edit">
                                                                            <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => handleShow2(item)}> Düzenle</button>
                                                                        </div> */}
                                                                         <div className="edit">
                                                                            <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => navigate(`/çekPortfoyGuncelleSayfa?parametre=${item.id}`)}> Düzenle</button>
                                                                         {/*   <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => window.location.href = `/çekPortfoyGuncelleSayfa?parametre=${item.id}`}> Düzenle</button>*/}

                                                                        </div>
                                                                    
                                                                        <div className="edittwo">
                                                                        <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShow3(item)}>  Sil</button>
                                                                    </div>
                                                                    <div className="editthree">
                                                                        <button className="btn btn-sm btn-info detail-item-btn" onClick={() => handleShowDetail(item)}>Detay</button>
                                                                    </div>


                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedItem ? 'Düzenle' : 'Ekle'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedItem ? (
                        <CheckPortfolioUpdate selectedItem={selectedItem} handleClose={handleClose} />
                    ) : (
                        <CheckPortfolioAdd handleClose={handleClose} />
                    )}
                </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={handleClose3} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title> Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <CheckPortfolioDelete selectedItem={selectedItem} handleClose2={()=>handleClose3} />
                </Modal.Body>
            </Modal>      
            <Modal show={showDetail} onHide={handleCloseDetail} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header id="detayheader" className="modal-header" closeButton>
        <Modal.Title className="modal-title">Detaylar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="modal-content">
        <p className="modal-item"><strong>Hareket Tipi:</strong> {selectedItem?.movement_Type}</p>
        <p className="modal-item"><strong>Cari:</strong> {selectedItem?.current_ID}</p>
        <p className="modal-item"><strong>Borçlu:</strong> {selectedItem?.debtor}</p>
        <p className="modal-item"><strong>Kayıt Tarihi:</strong> {selectedItem?.registration_Date}</p>
        <p className="modal-item"><strong>Açıklama:</strong> {selectedItem?.explanation}</p>
        <p className="modal-item"><strong>Portföy Numarası:</strong> {selectedItem?.portfolio_No}</p>
        <p className="modal-item"><strong>Seri Numarası:</strong> {selectedItem?.serial_Number}</p>
        <p className="modal-item"><strong>Vade Tarihi:</strong> {selectedItem?.expiry_Date}</p>
        <p className="modal-item"><strong>Banka Adı:</strong> {selectedItem?.bank_Id}</p>
        <p className="modal-item"><strong>Banka Şubesi:</strong> {selectedItem?.bank_Branch}</p>
        <p className="modal-item"><strong>Miktar:</strong> {selectedItem?.amount}</p>
        <p className="modal-item"><strong>Çek Resmi:</strong> {selectedItem?.check_Picture}</p>
           
        </div>
    </Modal.Body>
</Modal>

<style jsx>{`
    .modal-content {
        padding: 20px;
    }
    .modal-item {
        margin-bottom: 15px;
        font-size: 16px;
    }
    .modal-title {
        font-size: 20px;
        font-weight: bold;
    }
        #detayheader{
                margin-left: 15px;
                margin-right: 15px;

        }
`}</style>



        </React.Fragment>
    );
}

export default TableListModal;











{/*
import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'react-bootstrap';
import { Button } from 'reactstrap';

import { Link } from 'react-router-dom';
import CheckPortfolioAdd from '../../../Pages/CheckPortfolio/CheckPortfolioAdd';
import CheckPortfolioUpdate from '../../../Pages/CheckPortfolio/CheckPortfolioUpdate';
import CheckPortfolioDelete from '../../../Pages/CheckPortfolio/DeleteCheckPortfolio';

const TableListModal = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedItem, setSelectedItem] = useState();

    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = (selectedItem) => {
        setShow2(true);
        setSelectedItem(selectedItem);
    };

    const handleClose3 = () => setShow3(false);
    const handleShow3 = (selectedItem) => {
        setShow3(true);
        setSelectedItem(selectedItem);
    };
    const [table, setTable] = useState([]);

    useEffect(() => {
        setTable([...props.companyArray]);
        console.log(table,"burada");
        setShow2(false);
    }, [props.companyArray]);
    
    useEffect(() => {
        setTable([...props.companyArray]);
        console.log(table,"burada");
        setShow3(false);
    }, [props.companyArray]);
    

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <div className="d-flex gap-1">
                                            <Button color="success" className="add-btn" onClick={() => {
    window.location.href = `/çekPortfoyEkle`;
}}>
    <i className="ri-add-line align-bottom me-1"></i>  Ekle
</Button>
{/* 
   <Button color="success" className="add-btn" onClick={handleShow} id="create-btn" data-toggle="modal">
                                                    <i className="ri-add-line align-bottom me-1"></i> Ekle Modal
                                                </Button>



                                            
                                                <Button color="success" className="add-btn" onClick={handleShow} id="create-btn" data-toggle="modal">
                                                    <i className="ri-add-line align-bottom me-1"></i> Ekle Modal
</Button>
                                            </div>
                                        </Col>
                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <div className="search-box ms-2">
                                                    <input type="text" className="form-control search" placeholder="Search..." />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col" style={{ width: "50px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                        </div>
                                                    </th>
                                                    {
                                                        props.tableHeadArray.map((element, index) => (
                                                            <th key={index} className="sort" data-sort={element}>{element}</th>
                                                        ))
                                                    }
                                                    <th>İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {
                                                    props.companyArray.map((item) => (
                                                        <tr key={item.id}>
                                                            <th scope="row">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                                                </div>
                                                            </th>
                                                            {Object.keys(item).map((key) => {
  if (key === 'check_Picture') {
    return (
      <td data-sort={key}>
        <img src={`data:image/jpeg;base64, ${item[key]}`} alt="Check Picture" style={{ maxWidth: "100px" }} />
      </td>
    );
  } else if (key === 'movement_Type' || key === 'registration_Date' || key === 'explanation' || key === 'portfolio_No' || key === 'serial_Number' || key === 'debtor' || key === 'expiry_Date' || key === 'bank_Name' || key === 'bank_Branch' || key === 'amount' || key === 'current_Name') {
    return <td data-sort={key}>{item[key]}</td>;
  }
  return null; 
})}
                                                            <td style={{ width: "200px" }}>
                                                                <div className="d-flex gap-2" >
                                                         
    <div className="edit">
    <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => handleShow2(item)}>Düzenle</button>
    </div>
     
                                                               

                                                                    <div className="edit"> 
                                                                     <button
                                                                      className="btn btn-sm btn-warning edit-item-btn"
                                                                       onClick={() => {
                                                                       const parametre = item.id; 
                                                                       window.location.href = `/çekPortfoyGuncelleSayfa?parametre=${parametre}`;
                                                                       }} >  Düzenle </button> 
                                                                   
  
                                                                   </div>
                                                                    <div className="edittwo">
                                                                        <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => handleShow3(item)}> Sil</button>
                                                                    </div>
                                                                   
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <div className="noresult" style={{ display: "none" }}>
                                            <div className="text-center">
                                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                    colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                </lord-icon>
                                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                                <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                    orders for you search.</p>
                                            </div>
                                        </div>
                                    </div>

                                     <div className="d-flex justify-content-end">
                                        <div className="pagination-wrap hstack gap-2">
                                            <Link className="page-item pagination-prev disabled" to="#">
                                            Önceki
                                            </Link>
                                            <ul className="pagination listjs-pagination mb-0"></ul>
                                            <Link className="page-item pagination-next" to="#">
                                                Sonraki
                                            </Link>
                                        </div>
                                            </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title>
                       Ekle
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CheckPortfolioAdd />
                </Modal.Body>

            </Modal>
            <Modal show={show2} onHide={handleClose2} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title> Düzenle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <CheckPortfolioUpdate selectedItem={selectedItem} handleClose2={()=>handleClose2} />
                </Modal.Body>
            </Modal>       
            <Modal show={show3} onHide={handleClose3} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title> Sil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <CheckPortfolioDelete selectedItem={selectedItem} handleClose2={()=>handleClose3} />
                </Modal.Body>
            </Modal>      
        </React.Fragment>
    )
}

export default TableListModal;
*/}





