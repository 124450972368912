import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Input, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
{/*import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, FormGroup, Label, Input, Button } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; */}


const Promissory_NotePortfolioAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();

    const navigate = useNavigate();
    const [currentAccount, setCurrentAccount] = useState([]);
    const [movement_Type, setMovement_Type] = useState('');

    const [currentAccountName, setCurrentAccountName] = useState('');
    const [companyName, setCompanyName] = useState(""); 
    const companyId = authUser.CompanyId;

    useEffect(() => {
        api.get("/Company/GetCompanyById?id=" + companyId)
            .then(res => {
                const companyNameFromApi = res.companyName; // JSON verisinden şirket adını alın
                setCompanyName(companyNameFromApi); // Şirket adını state'e ayarlayın
               // alert(companyName);
            })
            .catch(error => {
                console.error("Error fetching company data:", error);
            });
    }, [companyId]);



    //const [fileInput, setFileInput] = useState(null);
    const [promissory_NotePortfolio, setPromissory_NotePortfolio] = useState({}); // State for storing check portfolio data

    
    useEffect(() => {
        
        api.get("/CurrentAccount/GetAll?companyId=" + companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id,
                currentName: item.currentName

            }));
            setCurrentAccount(formattedOptions);
        });
    }, [companyId]);

    const validationSchema = Yup.object().shape({
        Movement_Type: Yup.string().required('Bu alan gereklidir'),
        Current_ID: Yup.string().required('Bu alan gereklidir'),
       // Debtor: Yup.string().required('Required'),
        Registration_Date: Yup.string().required('Bu alan gereklidir'),
        Explanation: Yup.string().required('Bu alan gereklidir'),
        Portfolio_No: Yup.string().required('Bu alan gereklidir'),
        Serial_Number: Yup.string().required('Bu alan gereklidir'),
        Amount: Yup.number().required('Bu alan gereklidir'),
        Expiry_Date: Yup.string().required('Bu alan gereklidir'),
        //Check_Picture: Yup.string().required('Required'),

       
    });

    
   


    {/*const handleSubmit = (values, { setSubmitting }) => {
        const checkPortfolio = {
            ...values,
            CompanyId: authUser.CompanyId,
            CreatedUserId: authUser.Id,
            status: true
        };
        api.create("/CheckPortfolio/AddCheckPortfolio", checkPortfolio)
            .then(res => {
                console.log("başarılı");
                window.location.href = `/ÇekPortfoyler`;
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
                setSubmitting(false);
            });
    }*/}
   {/* const handleSubmit = (values) => {
        const dataToSend = { ...values, CompanyId: authUser.CompanyId, CreatedUserId: authUser.Id, status: true };
        api.create("/CheckPortfolio/AddCheckPortfolio", dataToSend)
            .then(res => {
                window.location.href = `/ÇekPortfoyler`;
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            })
            .catch(err => {
                console.error("Hata: ", err);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000
                });
            });
    };
*/}
const handleSubmit = (values) => {
    //const dataToSend = { ...values, ...checkPortfolio, CompanyId: authUser.CompanyId, CreatedUserId: authUser.Id, status: true };
    const dataToSend = {
        ...values,
        ...promissory_NotePortfolio,
        CompanyId: authUser.CompanyId,
        CreatedUserId: authUser.Id,
        status: true,
        debtor: movement_Type === "alınan çek" ? currentAccountName : companyName
    };
    
    api.create("/Promissory_NotePortfolio/AddPromissory_NotePortfolio", dataToSend)
        .then(res => {
        navigate(`/SenetPortfoyler`);
            //window.location.href = `/SenetPortfoyler`;
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000
            });
        })
        .catch(err => {
            console.error("Hata: ", err);
            toast.error("Kaydedilemedi", {
                position: "bottom-right",
                autoClose: 10000
            });
        });
};
    {/*onst CheckPictureField = () => {
        const { setFieldValue, values } = useFormikContext();
        return (
            <div className="mb-3" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <label htmlFor="Check_Picture" className="form-label">Çek Resmi</label>
                <input
                    type="file"
                    className="form-control"
                    id="Check_Picture"
                    name="Check_Picture"
                    onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        setFieldValue("Check_Picture", file);
                    }}
                />
                {values.Check_Picture && (
                    <img src={URL.createObjectURL(values.Check_Picture)} alt="Check Picture" style={{ maxWidth: "100px" }} />
                )}
                <ErrorMessage name="Check_Picture" component="div" className="text-danger" />
            </div>
        );
    };*/}
   



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Senet PortFöy Ekle" breadcrumbItem="Senet Portföyler" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik
                                        initialValues={{
                                            Movement_Type: '',
                                            Current_ID: '',
                                            Debtor: '',
                                            Registration_Date: '',
                                            Explanation: '',
                                            Portfolio_No: '',
                                            Serial_Number: '',
                                            Expiry_Date: '',
                                            Amount: '',

                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ setFieldValue, isSubmitting, values }) => (
                                            <Form>
                                                <FormGroup>
                                                    <Label for="Movement_Type">Hareket Tipi</Label>
                                                    <Field
                                                        as="select"
                                                        name="Movement_Type"
                                                        id="Movement_Type"
                                                        className="form-control"
                                                        onChange={e => {
                                                            setFieldValue('Movement_Type', e.target.value);
                                                            setMovement_Type(e.target.value);
                                                        }}
                                                    >
                                                        <option value="" label="Seçiniz" />
                                                        <option value="Alınan Çek" label="Alınan Çek" />
                                                        <option value="Verilen Çek" label="Verilen Çek" />
                                                    </Field>
                                                    <ErrorMessage name="Movement_Type" component="div" className="text-danger" />
                                                </FormGroup>

                                                {movement_Type && (
                                                    <>
 <div className="mb-3">
                                                            <Label for="Current_ID" className="form-label">Cari</Label>
                                                            <Select
                                                                name="Current_ID"
                                                                options={currentAccount}
                                                               // onChange={option => setFieldValue('Current_ID', option ? option.value : '')}
                                                               onChange={option => {
                                                                setFieldValue('Current_ID', option ? option.value : '');
                                                                setCurrentAccountName(option ? option.currentName : '');
                                                            }} 
                                                               placeholder="CurrentAccount Seç"
                                                            />
                                                            <ErrorMessage name="Current_ID" component="div" className="text-danger" />
                                                        </div>

                                                       {/* <FormGroup>
                                                            <Label for="Debtor">Debtor</Label>
                                                            <Field
                                                                type="text"
                                                                name="Debtor"
                                                                id="Debtor"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Debtor" component="div" className="text-danger" />
                                                        </FormGroup>*/}

                                                        {/*
                                                           <FormGroup>
            <Label for="Debtor">Debtor</Label>
            <Field
                type="text"
                name="Debtor"
                id="Debtor"
                className="form-control"
                value={movement_Type === "Alınan Çek" ? "alınançek" : "verilençek"} // Set default value based on Movement_Type
                readOnly
            />
            <ErrorMessage name="Debtor" component="div" className="text-danger" />
        </FormGroup>*/}

<div className="mb-3">
                                                    <label htmlFor="Debtor" className="form-label">Borçlu</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="Debtor"
                                                        id="Debtor"
                                                        value={movement_Type === "Alınan Çek" ? currentAccountName : companyName} 
                                                        readOnly
                                                    />
                                                    <ErrorMessage name="Debtor" component="div" className="text-danger" />
                                                </div>
                                                
                                                        <FormGroup>
                                                            <Label for="Registration_Date">Kayıt Tarihi</Label>
                                                            <Field
                                                                type="date"
                                                                name="Registration_Date"
                                                                id="Registration_Date"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Registration_Date" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="Explanation">Açıklama</Label>
                                                            <Field
                                                                type="textarea"
                                                                placeholder="Açıklama Giriniz"
                                                                name="Explanation"
                                                                id="Explanation"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Explanation" component="div" className="text-danger" />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label for="Portfolio_No">Portföy No</Label>
                                                            <Field
                                                                type="textarea"
                                                                placeholder="Portföy No Giriniz"
                                                                name="Portfolio_No"
                                                                id="Portfolio_No"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Portfolio_No" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="Serial_Number">Seri No</Label>
                                                            <Field
                                                                type="textarea"
                                                                placeholder="Seri No Giriniz"
                                                                name="Serial_Number"
                                                                id="Serial_Number"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Serial_Number" component="div" className="text-danger" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="Expiry_Date">Vade Tarihi</Label>
                                                            <Field
                                                                type="date"
                                                                name="Expiry_Date"
                                                                id="Expiry_Date"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Expiry_Date" component="div" className="text-danger" />
                                                        </FormGroup>
                                                       
                                                        <FormGroup>
                                                            <Label for="Amount">Tutar</Label>
                                                            <Field
                                                                type="number"
                                                                placeholder="Tutar Giriniz"
                                                                name="Amount"
                                                                id="Amount"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Amount" component="div" className="text-danger" />
                                                        </FormGroup>
                                                       {/* <FormGroup>
                                                            <Label for="Check_Picture">Check_Picture</Label>
                                                            <Field
                                                                type="text"
                                                                name="Check_Picture"
                                                                id="Check_Picture"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Check_Picture" component="div" className="text-danger" />
                                                        </FormGroup>
                                                                                                            <CheckPictureField />*/}

                                                        <Button color="primary" type="submit" disabled={isSubmitting}>Kaydet</Button>
                                                    </>
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Promissory_NotePortfolioAdd;
{/*
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

const Promissory_NotePortfolioAdd = () => {
    const api = new APIClient();
    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    document.title = "Senet Portfoy Ekle  | İlekaSoft";


    const [movementTypeInfo, setMovementTypeInfo] = useState([]);
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);


    


    // Formik kullanarak form yönetimi
    const formik = useFormik({
        initialValues: {
            Movement_Type: '',
            Current_ID: '',
            Registration_Date: '',
            Explanation: '',
            Portfolio_No: '',
            Serial_Number: '',
            Debtor: '',
            Expiry_Date: '',
            Amount: ''
        },
        validationSchema: Yup.object({
            Movement_Type: Yup.string().required('Hareket Tipi zorunlu'),
            Current_ID: Yup.string().required('Cari Adı zorunlu'),
            Registration_Date: Yup.date().required('Kayıt Tarihi zorunlu'),
            Explanation: Yup.string().required('Açıklama zorunlu'),
            Portfolio_No: Yup.string().required('Portföy No zorunlu'),
            Serial_Number: Yup.string().required('Seri No zorunlu'),
            Debtor: Yup.string().required('Borçlu zorunlu'),
            Expiry_Date: Yup.date().required('Vade Tarihi zorunlu'),
            Amount: Yup.number().required('Tutar zorunlu')
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await api.create('/Promissory_NotePortfolio/AddPromissory_NotePortfolio', values);
                console.log(response); 
                toast.success("Başarıyla kaydedildi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
                navigate('/SenetPortfoyler');
            } catch (error) {
                console.error('Error adding bank:', error);
                toast.error("Kaydedilemedi", {
                    position: "bottom-right",
                    autoClose: 10000 
                });
            }
            setSubmitting(false);
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Senet Portföy Ekle" breadcrumbItem="Senet Portföy Ekle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="Movement_Type" className="form-label">Hareket Tipi</label>
                                            <select
                                                className="form-control"
                                                id="Movement_Type"
                                                name="Movement_Type"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.Movement_Type}
                                            >
                                                <option value="">Hareket Tipi</option>
                                                <option value="İlk Tür">İlk Tür</option>
                                                <option value="İkinci Tür">İkinci Tür</option>
                                                <option value="Üçüncü Tür">Üçüncü Tür</option>
                                            </select>
                                            {formik.touched.Movement_Type && formik.errors.Movement_Type ? (
                                                <div className="text-danger">{formik.errors.Movement_Type}</div>
                                            ) : null}
                                        </div>
                                        
                                        <div className="mb-3">
                                            <label htmlFor="Current_ID" className="form-label">Cari Adı</label>
                                            <Select
                                                value={formik.values.Current_ID}
                                                onChange={(selectedOption) => formik.setFieldValue("Current_ID", selectedOption)}
                                                options={currentAccount}
                                                placeholder="CurrentAccount Seç"
                                            />
                                            {formik.touched.Current_ID && formik.errors.Current_ID ? (
                                                <div className="text-danger">{formik.errors.Current_ID}</div>
                                            ) : null}
                                        </div>
<label htmlFor="Registration_Date" className="col-md-2 col-form-label">
    Kayıt Tarihi
</label>
<input
    className="form-control"
    type="date"
    id="Registration_Date"
    name="Registration_Date"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.Registration_Date}
/>
{formik.touched.Registration_Date && formik.errors.Registration_Date ? (
    <div className="text-danger">{formik.errors.Registration_Date}</div>
) : null}

<label htmlFor="Explanation" className="col-md-2 col-form-label">
    Açıklama
</label>
<input
    className="form-control"
    type="text"
    id="Explanation"
    name="Explanation"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.Explanation}
/>
{formik.touched.Explanation && formik.errors.Explanation ? (
    <div className="text-danger">{formik.errors.Explanation}</div>
) : null}

<label htmlFor="Portfolio_No" className="col-md-2 col-form-label">
    Portföy No
</label>
<input
    className="form-control"
    type="text"
    id="Portfolio_No"
    name="Portfolio_No"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.Portfolio_No}
/>
{formik.touched.Portfolio_No && formik.errors.Portfolio_No ? (
    <div className="text-danger">{formik.errors.Portfolio_No}</div>
) : null}

<label htmlFor="Serial_Number" className="col-md-2 col-form-label">
    Seri No
</label>
<input
    className="form-control"
    type="text"
    id="Serial_Number"
    name="Serial_Number"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.Serial_Number}
/>
{formik.touched.Serial_Number && formik.errors.Serial_Number ? (
    <div className="text-danger">{formik.errors.Serial_Number}</div>
) : null}

<label htmlFor="Debtor" className="col-md-2 col-form-label">
    Borçlu
</label>
<input
    className="form-control"
    type="text"
    id="Debtor"
    name="Debtor"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.Debtor}
/>
{formik.touched.Debtor && formik.errors.Debtor ? (
    <div className="text-danger">{formik.errors.Debtor}</div>
) : null}

<label htmlFor="Expiry_Date" className="col-md-2 col-form-label">
    Vade Tarihi
</label>
<input
    className="form-control"
    type="date"
    id="Expiry_Date"
    name="Expiry_Date"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.Expiry_Date}
/>
{formik.touched.Expiry_Date && formik.errors.Expiry_Date ? (
    <div className="text-danger">{formik.errors.Expiry_Date}</div>
) : null}

<label htmlFor="Amount" className="col-md-2 col-form-label">
    Tutar
</label>
<input
    className="form-control"
    type="text"
    id="Amount"
    name="Amount"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.Amount}
/>
{formik.touched.Amount && formik.errors.Amount ? (
    <div className="text-danger">{formik.errors.Amount}</div>
) : null}


                                        <button
                                            type="submit"
                                            className="btn btn-primary waves-effect waves-light"
                                            disabled={formik.isSubmitting}
                                        >
                                            {formik.isSubmitting ? "Kaydediliyor..." : "Kaydet"}
                                        </button>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Promissory_NotePortfolioAdd;
*/}






{/*import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

const Promissory_NoteAdd = () => {
    const api =new APIClient();
    const authUser = getLoggedinUser();

    const [promissory_Note, setPromissory_Note] = useState({
        Movement_Type: '',
        Current_ID: '',
        Registration_Date: '',
        Explanation: '',
        Portfolio_No: '',
        Serial_Number: '',
        Debtor: '',
        Expiry_Date: '',
        Amount: '',
        Branch_Representative_eMail: '',
        Branch_Address: '',
        CompanyId: authUser.CompanyId,
CreatedUserId: authUser.Id,
UpdatedUserId:authUser.Id,
status:true

    });

    const [movementTypeInfo, setMovementTypeInfo] = useState([]);
    const [selectCurrentAccount, setSelectCurrentAccount] = useState(null);
    const [currentAccount, setCurrentAccount] = useState([]);
    const navigate = useNavigate();

    document.title = "Senet Ekle  | İlekaSoft";

    useEffect(() => {
        const movementTypeData = [
            { id: 1, movementTypeName: 'İlk Tür' },
            { id: 2, movementTypeName: 'İkinci Tür' },
            { id: 3, movementTypeName: 'Üçüncü Tür' }
        ];
        var companyId = authUser.CompanyId;
        setMovementTypeInfo(movementTypeData); 
        api.get("/CurrentAccount/GetAll?companyId="+companyId).then(res => {
            const formattedOptions = res.map(item => ({
                label: item.currentName,
                value: item.id.toString()
            }));
            setCurrentAccount(formattedOptions);
        });
    }, []);

    const handleCurrentAccount = (selectedOption) => {
        setSelectCurrentAccount(selectedOption);
        setPromissory_Note({ ...promissory_Note, Current_ID: selectedOption ? selectedOption.value : '' });
    };

    const handleSubmit = async () => {
        try {
           //const response = await axios.post('https://localhost:7199/api/Bank/AddBank', bank);
            const response = await api.create('/Promissory_Note/AddPromissory_Note', promissory_Note);
            console.log(response); 
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000 
            });
       // window.location.href = `/Senetler`;
                    navigate('/Senetler')
        } catch (error) {
            console.error('Error adding bank:', error);
            toast.error("Kaydedilemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });

        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPromissory_Note({ ...promissory_Note, [name]: value });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Senet Ekle" breadcrumbItem="Senet Ekle" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {/* Your form inputs */}
                                    {/* Assuming you have input fields and a submit button */}
                                    {/* Example for Account Number input */}
                                    {/*<label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Movement_Type    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Movement_Type"
                                        value={promissory_Note.Movement_Type}
                                        onChange={handleChange}
    />*/}

    {/*
    <div className="mb-3">
    <label htmlFor="Movement_Type" className="form-label">Hareket Tipi</label>
    <select className="form-control" name="Movement_Type" value={promissory_Note.Movement_Type} onChange={handleChange} required>
        <option value="">Hareket Tipi</option>
        {movementTypeInfo.map((item) => (
            <option value={item.id} key={item.id}>{item.movementTypeName}</option>
        ))}
    </select>
</div>

                                   {/* <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Current_ID    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Current_ID"
                                        value={promissory_Note.Current_ID}
                                        onChange={handleChange}
    />*/}



    {/*
    <div className="mb-3"> 
                                        <label htmlFor="Current_ID" className="form-label">Cari Adı</label>
                                        <Select
                                            value={selectCurrentAccount}
                                            onChange={handleCurrentAccount}
                                            options={currentAccount}
                                            placeholder="CurrentAccount Seç"
                                        />
    </div>
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Kayıt Tarihi     
                                            </label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="Registration_Date"
                                        value={promissory_Note.Registration_Date}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Açıklama     
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Explanation"
                                        value={promissory_Note.Explanation}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Portföy No     
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Portfolio_No"
                                        value={promissory_Note.Portfolio_No}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Seri No   
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Serial_Number"
                                        value={promissory_Note.Serial_Number}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Borçlu  
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Debtor"
                                        value={promissory_Note.Debtor}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Vade Tarihi      
                                            </label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="Expiry_Date"
                                        value={promissory_Note.Expiry_Date}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Tutar  
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Amount"
                                        value={promissory_Note.Amount}
                                        onChange={handleChange}
                                    />
                                
                                    {/* Other input fields */}
                                    {/* Submit button */}
                                 
                                 
                                 {/*   <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                        Kaydet
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Promissory_NoteAdd;

*/}

