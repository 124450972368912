import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object().shape({
    companyName: yup.string().required('Firma adı zorunludur'),
    address: yup.string().required('Adres zorunludur'),
    companySector: yup.string().required('Firma sektörü zorunludur'),
    email: yup.string().email('Geçerli bir email adresi giriniz').required('Email zorunludur'),
    provinceId: yup.string().required('İl seçiniz'),
    districtId: yup.string().required('İlçe seçiniz'),
    taxOffice: yup.string().required('Vergi dairesi zorunludur'),
    telNo: yup.string().required('Telefon numarası zorunludur'),
    identityNo: yup.string().required('Vergi kimlik numarası/TC kimlik numarası zorunludur'),
    streetName: yup.string().required('Sokak Adı zorunludur'),
    website: yup.string().required('Web Sitesi zorunludur'),
    postalCode: yup.string().required('Posta Kodu zorunludur')
});

const CompanyUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    const [provinceOptions, setProvinceOptions] = useState([]);
    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [distinctOptions, setDistinctOptions] = useState([]);
    const [selectedDistinctOption, setSelectedDistinctOption] = useState(null);
    const [demo, setDemo] = useState(false); // Checkbox state


    const formik = useFormik({
        initialValues: {
            id:_id,
            companyName: '',
            address: '',
            companySector: '',
            email: '',
            provinceId: '',
            districtId: '',
            taxOffice: '',
            telNo: '',
            identityNo: '',
            streetName: '',
            website: '',
            postalCode: '',
            isDemo: demo, // demo state'inin başlangıç değeri olarak isDemo'yu ayarla
        },
        validationSchema,
        onSubmit: (values) => {
            values.isDemo = demo;
            values.ustFirmaId = authUser.CompanyId;
            values.companyId = parseInt(authUser.CompanyId);
           // values.createdUserId = parseInt(authUser.Id);
            values.updatedUserId = parseInt(authUser.Id);
            values.status = true;

            api.create("/Company/UpdateCompany", values)
                .then(res => {
                    console.log("Başarılı");
                    navigate('/Firmalar');
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    useEffect(() => {
        api.get("/Province/GetAll")
            .then(res => {
                const formattedOptions = res.map(item => ({
                    label: item.name,
                    value: item.id
                }));
                setProvinceOptions(formattedOptions);
            })
            .catch(error => {
                console.error("Hata oluştu:", error);
                // Hata durumunda gerekli işlemler yapılabilir, örneğin hata mesajı gösterilebilir.
            });
    }, []);

    useEffect(() => {
        if (_id) {
            api.get('/Company/GetCompanyById?id=' + _id)
                .then(res => {
                    formik.setValues({
                        companyName: res.companyName,
                        address: res.address,
                        companySector: res.companySector,
                        districtId: res.districtId,
                        email: res.email,
                        provinceId: res.provinceId,
                        taxOffice: res.taxOffice,
                        telNo: res.telNo,
                        identityNo: res.identityNo,
                        streetName: res.streetName,
                        website: res.website,
                        postalCode: res.postalCode,
                        isDemo: res.isDemo,
                        id:_id
                    });

                    setSelectedProvinceOption({ label: res.provinceName, value: res.provinceId });
                    setSelectedDistinctOption({ label: res.distinctName, value: res.distinctId });
                    setDemo(res.isDemo);

                    api.get("/District/GetDistrictByProvinceId?id=" + res.provinceId)
                        .then(res => {
                            const formattedOptions = res.map(item => ({
                                label: item.districtName,
                                value: item.districtId
                            }));
                            setDistinctOptions(formattedOptions);
                        })
                        .catch(error => {
                            console.error("Hata oluştu:", error);
                        });
                })
                .catch(err => console.log(err));
        }
    }, [_id]);

    const handleProvinceSelectChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption);
        formik.setFieldValue('provinceId', selectedOption ? selectedOption.value : '');
        setSelectedDistinctOption({ label: "İlçe Seçiniz", value: "" });



        api.get("/District/GetDistrictByProvinceId?id=" + selectedOption.value)
        .then(res => {
            const formattedOptions = res.map(item => ({
                label: item.districtName,
                value: item.districtId
            }));
            setDistinctOptions(formattedOptions);
        })
        .catch(error => {
            console.error("Hata oluştu:", error);
        });
    };

    const handleDistinctSelectChange = (selectedOption) => {
        setSelectedDistinctOption(selectedOption);
        formik.setFieldValue('districtId', selectedOption ? selectedOption.value : '');
    };
    const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        
        if(checked == false)
            {
                setDemo(true); // demo state'ini güncelle
                formik.setFieldValue('isDemo', true); // formik values içinde isDemo'yu güncelle
            }
        else if(checked == true)
            {

                setDemo(false); // demo state'ini güncelle
                formik.setFieldValue('isDemo', false); // formik values içinde isDemo'yu güncelle
            }
    };
    

    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Firma Güncelle" breadcrumbItem="Firmalar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>

                                        <div className="mb-3">
                                            <label htmlFor="companyName" className="form-label">Firma Adı</label>
                                            <input
                                                className={`form-control ${formik.touched.companyName && formik.errors.companyName ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="companyName"
                                                name="companyName"
                                                placeholder="Firma Adı Giriniz"
                                                value={formik.values.companyName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.companyName && formik.errors.companyName ? (
                                                <div className="invalid-feedback">{formik.errors.companyName}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">Adres</label>
                                            <input
                                                className={`form-control ${formik.touched.address && formik.errors.address ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="address"
                                                name="address"
                                                placeholder="Adres Giriniz"
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.address && formik.errors.address ? (
                                                <div className="invalid-feedback">{formik.errors.address}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="companySector" className="form-label">Firma Sektör</label>
                                            <input
                                                className={`form-control ${formik.touched.companySector && formik.errors.companySector ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="companySector"
                                                name="companySector"
                                                placeholder="Firma Sektör Giriniz"
                                                value={formik.values.companySector}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.companySector && formik.errors.companySector ? (
                                                <div className="invalid-feedback">{formik.errors.companySector}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="email"
                                                name="email"
                                                placeholder="Email Giriniz"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="invalid-feedback">{formik.errors.email}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="taxOffice" className="form-label">Vergi Dairesi</label>
                                            <input
                                                className={`form-control ${formik.touched.taxOffice && formik.errors.taxOffice ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="taxOffice"
                                                name="taxOffice"
                                                placeholder="Vergi Dairesi Giriniz"
                                                value={formik.values.taxOffice}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.taxOffice && formik.errors.taxOffice ? (
                                                <div className="invalid-feedback">{formik.errors.taxOffice}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="telNo" className="form-label">Telefon Numarası</label>
                                            <input
                                                className={`form-control ${formik.touched.telNo && formik.errors.telNo ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="telNo"
                                                name="telNo"
                                                placeholder="Telefon Numarası Giriniz"
                                                value={formik.values.telNo}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.telNo && formik.errors.telNo ? (
                                                <div className="invalid-feedback">{formik.errors.telNo}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="identityNo" className="form-label">Vergi Kimlik Numarası / TC Kimlik Numarası</label>
                                            <input
                                                className={`form-control ${formik.touched.identityNo && formik.errors.identityNo ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="identityNo"
                                                name="identityNo"
                                                placeholder="Vergi Kimlik Numarası / TC Kimlik Numarası Giriniz"
                                                value={formik.values.identityNo}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.identityNo && formik.errors.identityNo ? (
                                                <div className="invalid-feedback">{formik.errors.identityNo}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="streetName" className="form-label">Sokak Adı</label>
                                            <input
                                                className={`form-control ${formik.touched.streetName && formik.errors.streetName ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="streetName"
                                                name="streetName"
                                                placeholder="Sokak Adı Giriniz"
                                                value={formik.values.streetName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.streetName && formik.errors.streetName ? (
                                                <div className="invalid-feedback">{formik.errors.streetName}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="website" className="form-label">Web Sitesi</label>
                                            <input
                                                className={`form-control ${formik.touched.website && formik.errors.website ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="website"
                                                name="website"
                                                placeholder="Web Sitesi Giriniz"
                                                value={formik.values.website}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.website && formik.errors.website ? (
                                                <div className="invalid-feedback">{formik.errors.website}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="postalCode" className="form-label">Posta Kodu</label>
                                            <input
                                                className={`form-control ${formik.touched.postalCode && formik.errors.postalCode ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="postalCode"
                                                name="postalCode"
                                                placeholder="Posta Kodu Giriniz"
                                                value={formik.values.postalCode}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.postalCode && formik.errors.postalCode ? (
                                                <div className="invalid-feedback">{formik.errors.postalCode}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="provinceId" className="form-label">İl</label>
                                            <Select
                                                value={selectedProvinceOption}
                                                onChange={handleProvinceSelectChange}
                                                options={provinceOptions}
                                                placeholder="İl Seçiniz"
                                                className={`react-select ${formik.touched.provinceId && formik.errors.provinceId ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.provinceId && formik.errors.provinceId ? (
                                                <div className="invalid-feedback d-block">{formik.errors.provinceId}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="districtId" className="form-label">İlçe</label>
                                            <Select
                                                value={selectedDistinctOption}
                                                onChange={handleDistinctSelectChange}
                                                options={distinctOptions}
                                                placeholder="İlçe Seçiniz"
                                                className={`react-select ${formik.touched.districtId && formik.errors.districtId ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.districtId && formik.errors.districtId ? (
                                                <div className="invalid-feedback d-block">{formik.errors.districtId}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
    <Col md={6}>
    <div style={{ marginTop: '20px' }}>
            <input
                type="checkbox"          
              checked={demo}
                onClick={handleCheckboxChange} // Checkbox değiştiğinde handleCheckboxChange fonksiyonunu çağır
            />
                        <span style={{ marginLeft: '5px' }}>Demo mu ?</span>
            {/* Opsiyonel olarak label eklenebilir
            <label className="form-check-label" htmlFor="isDemo">
                Demo
            </label>
            */}
        </div>
    </Col>
</div>

                                        <div>
                                            <button className="btn btn-primary" type="submit">Güncelle</button>
                                        </div>
                                    </form>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CompanyUpdatePage;


{/*import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object().shape({
    companyName: yup.string().required('Firma adı zorunludur'),
    address: yup.string().required('Adres zorunludur'),
    companySector: yup.string().required('Firma sektörü zorunludur'),
    email: yup.string().email('Geçerli bir email adresi giriniz').required('Email zorunludur'),
    provinceId: yup.string().required('İl seçiniz'),
    districtId: yup.string().required('İlçe seçiniz'),
    taxOffice: yup.string().required('Vergi dairesi zorunludur'),
    telNo: yup.string().required('Telefon numarası zorunludur'),
    identityNo: yup.string().required('Vergi kimlik numarası/TC kimlik numarası zorunludur'),
    streetName: yup.string().required('Sokak Adı zorunludur'),
    website: yup.string().required('Web Sitesi zorunludur'),
    postalCode: yup.string().required('Posta Kodu zorunludur')
});

const CompanyUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get("parametre");

    const authUser = getLoggedinUser();
    const navigate = useNavigate();

    const [provinceOptions, setProvinceOptions] = useState([]);
    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [distinctOptions, setDistinctOptions] = useState([]);
    const [selectedDistinctOption, setSelectedDistinctOption] = useState(null);
    const [demo, setDemo] = useState(false); // Checkbox state

    const formik = useFormik({
        initialValues: {
            companyName: '',
            address: '',
            companySector: '',
            email: '',
            provinceId: '',
            districtId: '',
            taxOffice: '',
            telNo: '',
            identityNo: '',
            streetName: '',
            website: '',
            postalCode: '',
            isDemo: false
        },
        validationSchema,
        onSubmit: (values) => {
            values.isDemo = demo;
            values.ustFirmaId = authUser.CompanyId;
            values.companyId = parseInt(authUser.CompanyId);
            values.createdUserId = parseInt(authUser.Id);
            values.updatedUserId = parseInt(authUser.Id);
            values.status = true;

            api.create("/Company/UpdateCompany", values)
                .then(res => {
                    console.log("Başarılı");
                    navigate('/Firmalar');
                    toast.success("Başarıyla güncellendi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                })
                .catch(err => {
                    console.error("Hata: ", err);
                    toast.error("Güncellenemedi", {
                        position: "bottom-right",
                        autoClose: 10000
                    });
                });
        },
    });

    useEffect(() => {
        api.get("/Province/GetAll")
            .then(res => {
                const formattedOptions = res.data.map(item => ({
                    label: item.name,
                    value: item.id.toString()
                }));
                setProvinceOptions(formattedOptions);
            })
            .catch(error => {
                console.error("Hata oluştu:", error);
                // Hata durumunda gerekli işlemler yapılabilir, örneğin hata mesajı gösterilebilir.
            });
    }, []);

    useEffect(() => {
        if (_id) {
            api.get('/Company/GetCompanyById?id=' + _id)
                .then(res => {
                    formik.setValues({
                        companyName: res.data.companyName,
                        address: res.data.address,
                        companySector: res.data.companySector,
                        districtId: res.data.districtId,
                        email: res.data.email,
                        provinceId: res.data.provinceId,
                        taxOffice: res.data.taxOffice,
                        telNo: res.data.telNo,
                        identityNo: res.data.identityNo,
                        streetName: res.data.streetName,
                        website: res.data.website,
                        postalCode: res.data.postalCode,
                        isDemo: res.data.isDemo,
                    });

                    setSelectedProvinceOption({ label: res.data.provinceName, value: res.data.provinceId });
                    setSelectedDistinctOption({ label: res.data.distinctName, value: res.data.distinctId });

                    api.get("/District/GetDistrictByProvinceId?id=" + res.data.provinceId)
                        .then(res => {
                            const formattedOptions = res.data.map(item => ({
                                label: item.name,
                                value: item.id.toString()
                            }));
                            setDistinctOptions(formattedOptions);
                        })
                        .catch(error => {
                            console.error("Hata oluştu:", error);
                        });

                })
                .catch(err => console.log(err));
        }
    }, [_id]);

    const handleProvinceSelectChange = (selectedOption) => {
        setSelectedProvinceOption(selectedOption);
        formik.setFieldValue('provinceId', selectedOption ? selectedOption.value : '');
    };

    const handleDistinctSelectChange = (selectedOption) => {
        setSelectedDistinctOption(selectedOption);
        formik.setFieldValue('districtId', selectedOption ? selectedOption.value : '');
    };

    const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        setDemo(checked);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Firma Güncelle" breadcrumbItem="Firmalar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>

                                        <div className="mb-3">
                                            <label htmlFor="companyName" className="form-label">Firma Adı</label>
                                            <input
                                                className={`form-control ${formik.touched.companyName && formik.errors.companyName ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="companyName"
                                                name="companyName"
                                                placeholder="Firma Adı Giriniz"
                                                value={formik.values.companyName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.companyName && formik.errors.companyName ? (
                                                <div className="invalid-feedback">{formik.errors.companyName}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">Adres</label>
                                            <input
                                                className={`form-control ${formik.touched.address && formik.errors.address ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="address"
                                                name="address"
                                                placeholder="Adres Giriniz"
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.address && formik.errors.address ? (
                                                <div className="invalid-feedback">{formik.errors.address}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="companySector" className="form-label">Firma Sektör</label>
                                            <input
                                                className={`form-control ${formik.touched.companySector && formik.errors.companySector ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="companySector"
                                                name="companySector"
                                                placeholder="Firma Sektör Giriniz"
                                                value={formik.values.companySector}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.companySector && formik.errors.companySector ? (
                                                <div className="invalid-feedback">{formik.errors.companySector}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="email"
                                                name="email"
                                                placeholder="Email Giriniz"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="invalid-feedback">{formik.errors.email}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="taxOffice" className="form-label">Vergi Dairesi</label>
                                            <input
                                                className={`form-control ${formik.touched.taxOffice && formik.errors.taxOffice ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="taxOffice"
                                                name="taxOffice"
                                                placeholder="Vergi Dairesi Giriniz"
                                                value={formik.values.taxOffice}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.taxOffice && formik.errors.taxOffice ? (
                                                <div className="invalid-feedback">{formik.errors.taxOffice}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="telNo" className="form-label">Telefon Numarası</label>
                                            <input
                                                className={`form-control ${formik.touched.telNo && formik.errors.telNo ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="telNo"
                                                name="telNo"
                                                placeholder="Telefon Numarası Giriniz"
                                                value={formik.values.telNo}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.telNo && formik.errors.telNo ? (
                                                <div className="invalid-feedback">{formik.errors.telNo}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="identityNo" className="form-label">Vergi Kimlik Numarası / TC Kimlik Numarası</label>
                                            <input
                                                className={`form-control ${formik.touched.identityNo && formik.errors.identityNo ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="identityNo"
                                                name="identityNo"
                                                placeholder="Vergi Kimlik Numarası / TC Kimlik Numarası Giriniz"
                                                value={formik.values.identityNo}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.identityNo && formik.errors.identityNo ? (
                                                <div className="invalid-feedback">{formik.errors.identityNo}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="streetName" className="form-label">Sokak Adı</label>
                                            <input
                                                className={`form-control ${formik.touched.streetName && formik.errors.streetName ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="streetName"
                                                name="streetName"
                                                placeholder="Sokak Adı Giriniz"
                                                value={formik.values.streetName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.streetName && formik.errors.streetName ? (
                                                <div className="invalid-feedback">{formik.errors.streetName}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="website" className="form-label">Web Sitesi</label>
                                            <input
                                                className={`form-control ${formik.touched.website && formik.errors.website ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="website"
                                                name="website"
                                                placeholder="Web Sitesi Giriniz"
                                                value={formik.values.website}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.website && formik.errors.website ? (
                                                <div className="invalid-feedback">{formik.errors.website}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="postalCode" className="form-label">Posta Kodu</label>
                                            <input
                                                className={`form-control ${formik.touched.postalCode && formik.errors.postalCode ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="postalCode"
                                                name="postalCode"
                                                placeholder="Posta Kodu Giriniz"
                                                value={formik.values.postalCode}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.postalCode && formik.errors.postalCode ? (
                                                <div className="invalid-feedback">{formik.errors.postalCode}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="provinceId" className="form-label">İl</label>
                                            <Select
                                                value={selectedProvinceOption}
                                                onChange={handleProvinceSelectChange}
                                                options={provinceOptions}
                                                placeholder="İl Seçiniz"
                                                className={`react-select ${formik.touched.provinceId && formik.errors.provinceId ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.provinceId && formik.errors.provinceId ? (
                                                <div className="invalid-feedback d-block">{formik.errors.provinceId}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="districtId" className="form-label">İlçe</label>
                                            <Select
                                                value={selectedDistinctOption}
                                                onChange={handleDistinctSelectChange}
                                                options={distinctOptions}
                                                placeholder="İlçe Seçiniz"
                                                className={`react-select ${formik.touched.districtId && formik.errors.districtId ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.districtId && formik.errors.districtId ? (
                                                <div className="invalid-feedback d-block">{formik.errors.districtId}</div>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Demo mu?</label>
                                            <Col md={6}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="isDemo"
                                                        name="isDemo"
                                                        checked={demo}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="isDemo">
                                                        Demo
                                                    </label>
                                                </div>
                                            </Col>
                                        </div>

                                        <div>
                                            <button className="btn btn-primary" type="submit">Güncelle</button>
                                        </div>
                                    </form>
                                    <ToastContainer />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CompanyUpdatePage;
*/}







{/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from 'react-router-dom';
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon';
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CompanyUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get('parametre');
    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    useEffect(() => {
        api.get('/Company/GetCompanyById?id=' + _id)
            .then(res => {
                setSelectedProvinceOption({ value: res.provinceId, label: res.provinceName });
                setSelectedDistrictOption({ value: res.districtId, label: res.distinctName });
            })
            .catch(err => console.log(err));
    }, [_id]);

    document.title = 'Firma Güncelle | İlekaSoft';

    const initialValues = {
        companyName: '',
        address: '',
        companySector: '',
        email: '',
        taxOffice: '',
        telNo: '',
        identityNo: '',
        isDemo: false,
        streetName:'',
        website:'',
        postalCode:''
    };

    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required('Firma adı zorunludur'),
        address: Yup.string().required('Adres zorunludur'),
        companySector: Yup.string().required('Firma sektörü zorunludur'),
        email: Yup.string().email('Geçerli bir email giriniz').required('Email zorunludur'),
        taxOffice: Yup.string().required('Vergi dairesi zorunludur'),
        telNo: Yup.string().required('Telefon numarası zorunludur'),
        identityNo: Yup.string().required('Vergi/T.C. kimlik numarası zorunludur'),
        streetName: Yup.string().required('Sokak Adı zorunludur'),
        website: Yup.string().required('Web Sitesi zorunludur'),
        postalCode: Yup.string().required('Posta Kodu zorunludur'),

    });

    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const response = await api.create('/Company/UpdateCompany', values);
            toast.success('Başarıyla kaydedildi', {
                position: 'bottom-right',
                autoClose: 10000,
            });
            navigate('/Firmalar');
        } catch (error) {
            console.error('Error updating company:', error);
            toast.error('Güncellenemedi', {
                position: 'bottom-right',
                autoClose: 10000,
            });
        }
        setSubmitting(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Firma Güncelle" breadcrumbItem="Firmalar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <label htmlFor="companyName">Firma Adı</label>
                                                <Field type="text" name="companyName" className="form-control" />
                                                <ErrorMessage name="companyName" component="div" className="text-danger" />

                                                <label htmlFor="address">Adres</label>
                                                <Field type="text" name="address" className="form-control" />
                                                <ErrorMessage name="address" component="div" className="text-danger" />

                                                <label htmlFor="companySector">Firma Sektör</label>
                                                <Field type="text" name="companySector" className="form-control" />
                                                <ErrorMessage name="companySector" component="div" className="text-danger" />

                                                <label htmlFor="email">Email</label>
                                                <Field type="email" name="email" className="form-control" />
                                                <ErrorMessage name="email" component="div" className="text-danger" />

                                                <label htmlFor="taxOffice">Vergi Dairesi</label>
                                                <Field type="text" name="taxOffice" className="form-control" />
                                                <ErrorMessage name="taxOffice" component="div" className="text-danger" />

                                                <label htmlFor="telNo">Tel No</label>
                                                <Field type="tel" name="telNo" className="form-control" />
                                                <ErrorMessage name="telNo" component="div" className="text-danger" />

                                                <label htmlFor="identityNo">
                                                IdentityNo
                                                </label>
                                                <Field type="text" name="identityNo" className="form-control" />
                                                <ErrorMessage name="identityNo" component="div" className="text-danger" />

                                                <div className="form-check">
                                                    <Field type="checkbox" name="isDemo" className="form-check-input" />
                                                    <label htmlFor="isDemo" className="form-check-label">
                                                        Demo mu?
                                                    </label>
                                                </div>

                                                <label htmlFor="streetName">Sokak Adı</label>
                                                <Field type="text" name="streetName" className="form-control" />
                                                <ErrorMessage name="streetName" component="div" className="text-danger" />
                                                
                                                <label htmlFor="website">Web Sitesi</label>
                                                <Field type="text" name="website" className="form-control" />
                                                <ErrorMessage name="website" component="div" className="text-danger" />
                                                
                                                <label htmlFor="postalCode">Posta Kodu</label>
                                                <Field type="text" name="postalCode" className="form-control" />
                                                <ErrorMessage name="postalCode" component="div" className="text-danger" />

                                                <button type="submit" className="btn btn-primary waves-effect waves-light" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Kaydediliyor...' : 'Firmayı Güncelle'}
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CompanyUpdatePage;
*/}




{/*import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper';
import { useLocation } from 'react-router-dom';
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon';
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CompanyUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get('parametre');
    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    useEffect(() => {
        api.get('/Company/GetCompanyById?id=' + _id)
            .then(res => {
                setSelectedProvinceOption({ value: res.provinceId, label: res.provinceName });
                setSelectedDistrictOption({ value: res.districtId, label: res.distinctName });
            })
            .catch(err => console.log(err));
    }, [_id]);

    document.title = 'Firma Güncelle | İlekaSoft';

    const initialValues = {
        companyName: '',
        address: '',
        companySector: '',
        email: '',
        taxOffice: '',
        telNo: '',
        identityNo: '',
        isDemo: false,
        streetName:'',
        website:'',
        postalCode:''
    };

    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required('Firma adı zorunludur'),
        address: Yup.string().required('Adres zorunludur'),
        companySector: Yup.string().required('Firma sektörü zorunludur'),
        email: Yup.string().email('Geçerli bir email giriniz').required('Email zorunludur'),
        taxOffice: Yup.string().required('Vergi dairesi zorunludur'),
        telNo: Yup.string().required('Telefon numarası zorunludur'),
        identityNo: Yup.string().required('Vergi/T.C. kimlik numarası zorunludur'),
        streetName: Yup.string().required('Sokak Adı zorunludur'),
        website: Yup.string().required('Web Sitesi zorunludur'),
        postalCode: Yup.string().required('Posta Kodu zorunludur'),

    });

    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const response = await api.create('/Company/UpdateCompany', values);
            toast.success('Başarıyla kaydedildi', {
                position: 'bottom-right',
                autoClose: 10000,
            });
            navigate('/Firmalar');
        } catch (error) {
            console.error('Error updating company:', error);
            toast.error('Güncellenemedi', {
                position: 'bottom-right',
                autoClose: 10000,
            });
        }
        setSubmitting(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Firma Güncelle" breadcrumbItem="Firmalar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <label htmlFor="companyName">Firma Adı</label>
                                                <Field type="text" name="companyName" className="form-control" />
                                                <ErrorMessage name="companyName" component="div" className="text-danger" />

                                                <label htmlFor="address">Adres</label>
                                                <Field type="text" name="address" className="form-control" />
                                                <ErrorMessage name="address" component="div" className="text-danger" />

                                                <label htmlFor="companySector">Firma Sektör</label>
                                                <Field type="text" name="companySector" className="form-control" />
                                                <ErrorMessage name="companySector" component="div" className="text-danger" />

                                                <label htmlFor="email">Email</label>
                                                <Field type="email" name="email" className="form-control" />
                                                <ErrorMessage name="email" component="div" className="text-danger" />

                                                <label htmlFor="taxOffice">Vergi Dairesi</label>
                                                <Field type="text" name="taxOffice" className="form-control" />
                                                <ErrorMessage name="taxOffice" component="div" className="text-danger" />

                                                <label htmlFor="telNo">Tel No</label>
                                                <Field type="tel" name="telNo" className="form-control" />
                                                <ErrorMessage name="telNo" component="div" className="text-danger" />

                                                <label htmlFor="identityNo">
                                                IdentityNo
                                                </label>
                                                <Field type="text" name="identityNo" className="form-control" />
                                                <ErrorMessage name="identityNo" component="div" className="text-danger" />

                                                <div className="form-check">
                                                    <Field type="checkbox" name="isDemo" className="form-check-input" />
                                                    <label htmlFor="isDemo" className="form-check-label">
                                                        Demo mu?
                                                    </label>
                                                </div>

                                                <label htmlFor="streetName">Sokak Adı</label>
                                                <Field type="text" name="streetName" className="form-control" />
                                                <ErrorMessage name="streetName" component="div" className="text-danger" />
                                                
                                                <label htmlFor="website">Web Sitesi</label>
                                                <Field type="text" name="website" className="form-control" />
                                                <ErrorMessage name="website" component="div" className="text-danger" />
                                                
                                                <label htmlFor="postalCode">Posta Kodu</label>
                                                <Field type="text" name="postalCode" className="form-control" />
                                                <ErrorMessage name="postalCode" component="div" className="text-danger" />

                                                <button type="submit" className="btn btn-primary waves-effect waves-light" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Kaydediliyor...' : 'Firmayı Güncelle'}
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CompanyUpdatePage;
*/}



{/*import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'
import { useLocation } from 'react-router-dom';
import { Province } from '../../components/Dropdown_Il_Ilce/Provincedropdwon';
import { District } from '../../components/Dropdown_Il_Ilce/DistrictDropDown';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

const CompanyUpdatePage = () => {
    const api = new APIClient();
    const searchParams = new URLSearchParams(useLocation().search);
    const _id = searchParams.get('parametre');
    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const [company, setCompany] = useState({
        id: '',
        companyName: '',
        address: '',
        companySector: '',
        provinceId: '',
        districtId: '',
        email: '',
        taxOffice: '',
        telNo: '',
        vergiKimlikNumarası_TcKimlikNumarası: '',
        ustFirmaId: '',
        isDemo: 0,
        updatedUserId: authUser.Id,
       // companyId: authUser.CompanyId,
        //status:true


    });

    const [selectedProvinceOption, setSelectedProvinceOption] = useState(null);
    const [selectedDistrictOption, setSelectedDistrictOption] = useState(null);

    useEffect(() => {
        api.get('/Company/GetCompanyById?id=' + _id)
            .then(res => {
                const { id, companyName, address, companySector, provinceId, districtId, email, taxOffice, telNo, vergiKimlikNumarası_TcKimlikNumarası, ustFirmaId, isDemo } = res;
                setCompany({
                    id,
                    companyName,
                    address,
                    companySector,
                    provinceId,
                    districtId,
                    email,
                    taxOffice,
                    telNo,
                    vergiKimlikNumarası_TcKimlikNumarası,
                    ustFirmaId,
                    isDemo,
                    companyId: authUser.CompanyId,
                    updatedUserId: authUser.Id,
                    status:true
                });
                setSelectedProvinceOption({ value: res.provinceId, label: res.provinceName });
                setSelectedDistrictOption({ value: res.districtId , label: res.distinctName });
            })
            .catch(err => console.log(err));
    }, [_id]);

    document.title = 'Firma Güncelle | İlekaSoft';

    const handleChange = e => {
        const { name, value } = e.target;
        setCompany(prevState => ({ ...prevState, [name]: value }));
    };

    const handleProvinceChange = selectedOption => {
        setSelectedProvinceOption(selectedOption);
        setCompany(prevState => ({ ...prevState, ProvinceId: selectedOption.value }));
        setSelectedDistrictOption(null);
    };

    const handleDistrictChange = selectedOption => {
        setSelectedDistrictOption(selectedOption);
        setCompany(prevState => ({ ...prevState, DistrictId: selectedOption.value }));
    };

    const handleCheckboxChange = e => {
        const { checked } = e.target;
        setCompany(prevState => ({ ...prevState, isDemo: checked ? 1 : 0 }));
    };



    const handleSubmit = async () => {
        try {
            const response = await api.create('/Company/UpdateCompany', company);
            toast.success("Başarıyla kaydedildi", {
                position: "bottom-right",
                autoClose: 10000 
            });
           // window.location.href = '/Firmalar';
            navigate('/Firmalar')
        } catch (error) {
            console.error('Error updating company:', error);
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Firma Güncelle" breadcrumbItem="Firmalar" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                        
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Firma Adı    
                                            </label>
                                            <input
                                        className="form-control"
                                        type="text"
                                        name="companyName"
                                        value={company.companyName}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Adres    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="address"
                                        value={company.address}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Firma Sektör   
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="companySector"
                                        value={company.companySector}
                                        onChange={handleChange}
                                    />
                                   {/* <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    DistrictId    
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="DistrictId"
                                        value={Company.districtId}
                                        onChange={handleChange}
    />*/}


    {/*
                                                    <label className="col-md-3 col-form-label">İl</label>
                                                <div className="col-md-9">
                                                    <Province selectedOption={selectedProvinceOption} handleChange={handleProvinceChange} />

                                                </div>

                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Email   
                                            </label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        name="email"
                                        value={company.email}
                                        onChange={handleChange}
                                    />
                                   {/* <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    ProvinceId  
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="ProvinceId"
                                        value={Company.provinceId}
                                        onChan
                                        ge={handleChange}
/>*/}

{/*

<label className="col-md-3 col-form-label">İlçe</label>
                                                <div className="col-md-9">
                                                    <District province={selectedProvinceOption} selectedOption={selectedDistrictOption} handleChange={handleDistrictChange} />
                                                </div>

                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Vergi Dairesi 
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="taxOffice"
                                        value={company.taxOffice}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Tel No
                                            </label>
                                    <input
                                        className="form-control"
                                        type="tel"
                                        name="telNo"
                                        value={company.telNo}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Vergi Kimlik Numarası / TC Kimlik Numarası  
                                            </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="vergiKimlikNumarası_TcKimlikNumarası"
                                        value={company.vergiKimlikNumarası_TcKimlikNumarası}
                                        onChange={handleChange}
                                    />
                                  {/*  <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    Üst Firma Id
                                            </label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="ustFirmaId"
                                        value={company.ustFirmaId}
                                        onChange={handleChange}
                                    />
{/*
<label htmlFor="IsDemo" className="col-sm-2 col-form-label">Demo mu?</label>
    <div className="col-sm-10">
        <input
            type="checkbox"
            checked={company.IsDemo === 1}
            onChange={(e) => {
                handleChange({ target: { name: 'IsDemo', value: e.target.checked ? 1 : 0 } });
                alert(company.IsDemo);
            }}
        />
    </div>
      <input 
        style={{ visibility: 'hidden' }}
        className="form-control"
        type="number"
        name="isDemo"
        value={company.isDemo}
        onChange={handleChange}
    />



                                  {/*}  <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                    IsDemo
                                            </label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="isDemo"
                                        value={company.isDemo}
                                        onChange={handleChange}
        />*/}



        {/*
          <label htmlFor="IsDemo" className="col-sm-2 col-form-label">Demo mu?</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="checkbox"
                                            checked={company.isDemo === 1}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>

                                       <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={handleSubmit}
                                    >
                                       Firmayı  Güncelle
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CompanyUpdatePage;

*/}
