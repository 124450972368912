import React, { useState } from 'react'
import { GroupDeleteModal } from './GroupDeleteModal';
import { GroupUpdateModal } from './GroupUpdateModal';


export const GroupTableList = ({ user, isChange, userGrupInfo }) => {
    

    return (
        <React.Fragment>
            <table className="table align-middle table-nowrap" id="customerTable">
                <thead className="table-light">
                    <tr>
                        <th scope="col" style={{ width: "50px" }}>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                            </div>
                        </th>

                        <th className="sort" data-sort="id">Id</th>
                        <th className="sort" data-sort="groupName">Kullanıcı Grubu Adı</th>

                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody className="list form-check-all pagination-list" >
                    {
                        userGrupInfo.map((item) => (
                            <tr key={item.id} className='pagi-list'>
                                <th scope="row">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                    </div>
                                </th>

                                <td data-sort="id">{item.id}</td>
                                <td data-sort="groupName">{item.groupName}</td>

                                <td style={{ width: "200px" }}>
                                    <div className="d-flex gap-2" >
                                        <div className="edit">
                                            <GroupUpdateModal user={user} group={item} isChange={isChange} />
                                        </div>
                                        <div className="remove">
                                            <GroupDeleteModal id={item.id} isChange={isChange} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
           
        </React.Fragment >
    )
   
}
