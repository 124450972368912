import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { APIClient, getLoggedinUser } from '../../helpers/api_helper'
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate  } from 'react-router-dom';

const Invoice_Serial_Management_E_InvoiceUpdate = (props) => {
    const currentYear = new Date().getFullYear();
    const authUser = getLoggedinUser();

    const navigate = useNavigate();

    const api = new APIClient();
    const [e_Invoice_Serial_Prefix, setE_Invoice_Serial_Prefix] = useState(props.selectedItem.e_Invoice_Serial_Prefix);
    const [e_Invoice_Serial_No, setE_Invoice_Serial_No] = useState(props.selectedItem.e_Invoice_Serial_No);
    const [e_Invoice_Serial_Year, setE_Invoice_Serial_Year] = useState(props.selectedItem.e_Invoice_Serial_Year);
    const [e_Archive_Serial_Prefix, setE_Archive_Serial_Prefix] = useState(props.selectedItem.e_Archive_Serial_Prefix);
    const [e_Archive_Serial_No, setE_Archive_Serial_No] = useState(props.selectedItem.e_Archive_Serial_No);
    const [e_Archive_Serial_Year, setE_Archive_Serial_Year] = useState(props.selectedItem.e_Archive_Serial_Year);
    const [delivery_Note_Serial_Prefix, setDelivery_Note_Serial_Prefix] = useState(props.selectedItem.delivery_Note_Serial_Prefix);
    const [delivery_Note_Serial_No, setDelivery_Note_Serial_No] = useState(props.selectedItem.delivery_Note_Serial_No);
    const [delivery_Note_Serial_Year, setDelivery_Note_Serial_Year] = useState(props.selectedItem.delivery_Note_Serial_Year);
    const [defaultStatus, setDefaultStatus] = useState(props.selectedItem.defaultStatus);
    const [updatedUserId, setUpdatedUserId] = useState(authUser.Id);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedInvoice_Serial_Management = {
            id: props.selectedItem.id,
            e_Invoice_Serial_Prefix: e_Invoice_Serial_Prefix,
            e_Invoice_Serial_No: e_Invoice_Serial_No,
            e_Invoice_Serial_Year: currentYear.toString(),
            e_Archive_Serial_Prefix: e_Archive_Serial_Prefix,
            e_Archive_Serial_No: e_Archive_Serial_No,
            e_Archive_Serial_Year: e_Archive_Serial_Year,
            delivery_Note_Serial_Prefix: delivery_Note_Serial_Prefix,
            delivery_Note_Serial_No: delivery_Note_Serial_No,
            delivery_Note_Serial_Year: delivery_Note_Serial_Year,
            defaultStatus: defaultStatus,
            updatedUserId: updatedUserId,
            //status:true


        };

        try {
            await api.create("/Invoice_Serial_Management/UpdateInvoice_Serial_Management", updatedInvoice_Serial_Management);
            
            toast.success("Başarıyla güncellendi", {
                position: "bottom-right",
                autoClose: 10000 
            });
        //window.location.href = `/SeriYönetimler`;
                    navigate('/SeriYönetimler')
        } catch (error) {
            toast.error("Güncellenemedi", {
                position: "bottom-right",
                autoClose: 10000 
            });
    }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        if (name === 'defaultStatus') {
            setDefaultStatus(fieldValue);
        } else {
            switch (name) {
                case 'e_Invoice_Serial_Prefix':
                    setE_Invoice_Serial_Prefix(value);
                    break;
                case 'e_Invoice_Serial_No':
                    setE_Invoice_Serial_No(value);
                    break;
                case 'e_Invoice_Serial_Year':
                    setE_Invoice_Serial_Year(value);
                    break;
                case 'e_Archive_Serial_Prefix':
                    setE_Archive_Serial_Prefix(value);
                    break;
                case 'e_Archive_Serial_No':
                    setE_Archive_Serial_No(value);
                    break;
                case 'e_Archive_Serial_Year':
                    setE_Archive_Serial_Year(value);
                    break;
                case 'delivery_Note_Serial_Prefix':
                    setDelivery_Note_Serial_Prefix(value);
                    break;
                case 'delivery_Note_Serial_No':
                    setDelivery_Note_Serial_No(value);
                    break;
                case 'delivery_Note_Serial_Year':
                    setDelivery_Note_Serial_Year(value);
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>E Fatura Seri Ön Eki </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="E_Invoice_Serial_Prefix"
                    name="e_Invoice_Serial_Prefix"
                    value={e_Invoice_Serial_Prefix}
                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>E Fatura Sıra No </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="E_Invoice_Serial_No"
                    name="e_Invoice_Serial_No"
                    value={e_Invoice_Serial_No}
                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>E Fatura Seri Yılı  </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="E_Invoice_Serial_Year"
                    name="e_Invoice_Serial_Year"
                    value={currentYear.toString()}
                    onChange={handleChange}
                    required
                />
            </Form.Group>
{/*
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>E_Archive_Serial_Prefix</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="E_Archive_Serial_Prefix"
                    name="e_Archive_Serial_Prefix"
                    value={e_Archive_Serial_Prefix}
                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>E_Archive_Serial_No</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="E_Archive_Serial_No"
                    name="e_Archive_Serial_No"
                    value={e_Archive_Serial_No}
                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>E_Archive_Serial_Year</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="E_Archive_Serial_Year"
                    name="e_Archive_Serial_Year"
                    value={e_Archive_Serial_Year}
                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Delivery_Note_Serial_Prefix</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Delivery_Note_Serial_Prefix"
                    name="delivery_Note_Serial_Prefix"
                    value={delivery_Note_Serial_Prefix}
                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Delivery_Note_Serial_No</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Delivery_Note_Serial_No"
                    name="delivery_Note_Serial_No"
                    value={delivery_Note_Serial_No}
                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Delivery_Note_Serial_Year</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Delivery_Note_Serial_Year"
                    name="delivery_Note_Serial_Year"
                    value={delivery_Note_Serial_Year}
                    onChange={handleChange}
                    required
                />
            </Form.Group>
    */}
            <Form.Group style={{ marginTop: "10px" }}>
                <Form.Label>Varsayılan</Form.Label>
                <Form.Check
                    type="checkbox"
                    name="defaultStatus"
                    checked={defaultStatus}
                    onChange={handleChange}
                />
            </Form.Group>

            <Button variant="success" type="submit" block style={{ marginTop: "10px", width: "200px", marginLeft: "130px" }}>
                Seri Yönetimi Güncelle
            </Button>
        </Form>
    );
};

export default Invoice_Serial_Management_E_InvoiceUpdate;
